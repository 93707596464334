import { tManager } from "../../models/manager";

export const defaultManager: tManager = {
  _id: "",
  name: "",
  email: "",
  hotels: [],
  images: [],
  method: "local",
  local: { password: "" },
  facebook: { id: "" },
  google: { id: "" },
  active: true,
  isDummy: false,
  shadowAccount: false,
  isConsultant: false,
  settings: {},
  phoneNumber: "",

  lastAccess: new Date(),
  lastLogin: new Date(),

  activeHotel: "",

  confirmEmailToken: "",
  emailConfirmed: true,

  forgotPasswordValidUnitl: new Date(),
  forgotPasswordToken: "",
};
