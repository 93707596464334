import React, { useContext } from "react";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import Flex from "../../../../components/Flex";
import MeasureConsumption from "../../../../components/MeasureConsumption";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import PageSection from "../../../../components/PageSection";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  TE,
  tMeasureEnergySpecific,
  tMeasureWaterSpecific,
  TW,
} from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import {
  convertEnergyUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { getMeasureInfo } from "../../../../utils/measures";
import { ConsumptionContext } from "../context";

const LiveData: React.FC<{ dataTypeToggle: JSX.Element }> = ({
  dataTypeToggle,
}) => {
  const {
    setDataTypeToggleDisabled,
    state: { dataTypeToggleDisabled },
  } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces },
  } = useHotelMeasuresTrackedState();

  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  const electricitySpecificMeasures: {
    measureKey: tMeasureEnergySpecific;
  }[] = [];
  const waterSpecificMeasures: {
    measureKey: tMeasureWaterSpecific;
  }[] = [];

  if (trackedMeasures.te) {
    MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
      if (trackedMeasures[m])
        electricitySpecificMeasures.push({ measureKey: m });
    });
  }
  if (trackedMeasures.tw) {
    MEASURES_WATER_SPECIFIC.forEach((m) => {
      if (trackedMeasures[m]) waterSpecificMeasures.push({ measureKey: m });
    });
  }

  const disableInputs =
    dataTypeToggleDisabled.electricityMeasuresRequest ||
    dataTypeToggleDisabled.waterMeasuresRequest;

  return (
    <div>
      <SecondaryTopBar>
        <Flex row middle gap={20}>
          {dataTypeToggle}
          <SimpleDateRangePicker
            disabled={disableInputs}
            onChange={setPeriod}
            value={period}
          />
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        {trackedMeasures.te && (
          <Flex column gap={16}>
            <PageSection
              {...{
                title: trans("pages.consumption.live.electricity.title"),
                description: trans(
                  "pages.consumption.live.electricity.description"
                ),
                icon: {
                  Element: ElectricBoltIcon,
                  fill: getMeasureInfo(TE).color,
                },
              }}
            />
            <MeasureConsumption
              measuresRequestStatusTracker={(status) => {
                setDataTypeToggleDisabled({
                  electricityMeasuresRequest: status === REQUEST_STATUS.PENDING,
                });
              }}
              mainMeasureKey={TE}
              specificMeasures={electricitySpecificMeasures}
              converter={convertEnergyUnit}
              mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
              binUnit={"day"}
              binValue={1}
              from={period[0]}
              to={period[1]}
            />
          </Flex>
        )}
        {trackedMeasures.tw && (
          <Flex column gap={16}>
            <PageSection
              {...{
                title: trans("pages.consumption.live.water.title"),
                description: trans("pages.consumption.live.water.description"),
                icon: {
                  Element: WaterDropIcon,
                  fill: getMeasureInfo(TW).color,
                },
              }}
            />
            <MeasureConsumption
              measuresRequestStatusTracker={(status) => {
                setDataTypeToggleDisabled({
                  waterMeasuresRequest: status === REQUEST_STATUS.PENDING,
                });
              }}
              mainMeasureKey={TW}
              specificMeasures={waterSpecificMeasures}
              converter={convertVolumeUnit}
              mainUnit={DEFAULT_WATER_MEASURE_UNIT}
              binUnit={"day"}
              binValue={1}
              from={period[0]}
              to={period[1]}
            />
          </Flex>
        )}
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default LiveData;
