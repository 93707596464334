import { useEffect, useState } from "react";

const useEffectSafe = (
  run: () => void,
  dependencies: any[],
  cleanup: () => void = () => {},
  timeout: number = 300
) => {
  const [realtimeout] = useState<number>(() => {
    return process.env.NODE_ENV === "production" ? 0 : timeout;
  });

  useEffect(() => {
    const t = setTimeout(() => {
      run();
    }, realtimeout);
    return () => {
      clearTimeout(t);
      if (process.env.NODE_ENV === "production") cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);
};

export default useEffectSafe;
