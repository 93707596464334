import React from "react";
import { ButtonProps } from "rsuite";
import SimpleButton from "..";
import { ReactComponent as FilterListIcon } from "../../../../assets/icons/filter_list.svg";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../../utils/colors";

interface iProps extends ButtonProps {}

const SimpleFilterButton: React.FC<iProps> = (props) => {
  const { trans } = useLocalizationState();
  return (
    <SimpleButton
      appearance="ghost"
      text={{
        text: trans("Filter").toUpperCase(),
        color: COLORS.secondary,
        size: 12,
      }}
      icon={{
        Element: FilterListIcon,
        fill: COLORS.secondary,
        size: 20,
      }}
      size="sm"
      {...{ ...props }}
    />
  );
};

export default SimpleFilterButton;
