import React, { useCallback, useMemo } from "react";
import { HotelStateContext } from "../../";
import useHotelState from "./useHotelState";
import {
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceAggregateId,
  tHotelSpaceId,
  tHotelSpaceName,
} from "../../../../models/hotel";
import { filter, find, groupBy, isArray, isUndefined } from "lodash";
import { tMeasure } from "../../../../models/measures";

const useHotelSpacesState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelSpacesState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const findSpace = useCallback(
    (space: tHotelSpaceId | tHotelSpaceName): tHotelSpace | undefined => {
      return find(hotel.spaces, (s) => s._id === space || s.name === space);
    },
    [hotel.spaces]
  );

  const findSpaces = useCallback(
    (spaceIds: tHotelSpaceId[]): tHotelSpace[] => {
      return hotel.spaces.filter((s) => spaceIds.includes(s._id));
    },
    [hotel.spaces]
  );
  const findSpaceByName = useCallback(
    (spaceName: string): tHotelSpace | undefined => {
      return find(hotel.spaces, (s) => s.name === spaceName);
    },
    [hotel.spaces]
  );

  const findSpaceAggregate = useCallback(
    (aggregateId: tHotelSpaceAggregateId): tHotelSpaceAggregate | undefined => {
      return find(hotel.spaceAggregates, (g) => g._id === aggregateId);
    },
    [hotel.spaceAggregates]
  );
  const findSpaceAggregates = useCallback(
    (
      aggregateId: tHotelSpaceAggregateId | tHotelSpaceAggregateId[]
    ): tHotelSpaceAggregate[] => {
      const finder = isArray(aggregateId) ? aggregateId : [aggregateId];
      return filter(hotel.spaceAggregates, (g) => finder.includes(g._id));
    },
    [hotel.spaceAggregates]
  );
  const findSpaceAggregateByName = useCallback(
    (name: string): tHotelSpaceAggregate | undefined => {
      return find(hotel.spaceAggregates, (g) => g.name === name);
    },
    [hotel.spaceAggregates]
  );

  const spaceAggregatesByCategory = useMemo(() => {
    const obj = {
      group: [],
      type: [],
      zone: [],
      ...groupBy(hotel.spaceAggregates, "category"),
    } as Record<"type" | "group" | "zone", tHotelSpaceAggregate[]>;
    return obj;
  }, [hotel.spaceAggregates]);

  const findSpaceSpaceAggregates = useCallback(
    (
      spaceId: tHotelSpaceId,
      categories?:
        | tHotelSpaceAggregate["category"][]
        | tHotelSpaceAggregate["category"]
    ): tHotelSpaceAggregate[] => {
      const spaceAggregatesToUse = isUndefined(categories)
        ? hotel.spaceAggregates
        : (isArray(categories) ? categories : [categories]).flatMap(
            (category) => spaceAggregatesByCategory[category]
          );

      const spaceAggregates = spaceAggregatesToUse.filter((g) =>
        g.spaces.includes(spaceId)
      );
      return spaceAggregates;
    },
    [hotel.spaceAggregates, spaceAggregatesByCategory]
  );

  const listSpacesThatMeasure = (measures: tMeasure[]) => {
    return hotel.spaces.filter((s) => {
      for (const m of measures) {
        if (s.measures[m].isMeasured) return true;
      }
      return false;
    });
  };

  const spacesMapById = Object.fromEntries(hotel.spaces.map((s) => [s._id, s]));

  return {
    listSpacesThatMeasure,
    findSpace,
    findSpaceByName,
    findSpaces,
    findSpaceAggregate,
    findSpaceAggregates,
    findSpaceAggregateByName,
    findSpaceSpaceAggregates,
    spaceAggregatesByCategory,
    spacesMapById,
    hotelId,
    hotel,
  };
};

export default useHotelSpacesState;
