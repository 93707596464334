import { orderBy } from "lodash";
import React, { useMemo, useState } from "react";
import { TableProps } from "rsuite";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import Graph from "./graph";
import List, { tTableItem } from "./list";

interface iProps {}

const Entries: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  const { hotel } = useHotelState();
  const [{ sortColumn, sortType }, setSort] = useState<
    Required<Pick<TableProps<tTableItem, string>, "sortType" | "sortColumn">>
  >({ sortColumn: "start", sortType: "desc" });

  const tableDataSorted: tTableItem[] = useMemo(() => {
    return orderBy(
      hotel.fleet.flatMap((f) => {
        const makeModel = `${f.vehicle.make} ${f.vehicle.model}`;

        return f.entries.map((fe) => ({
          entryId: fe._id,
          fleetId: f._id,
          licensePlate: f.licensePlate,
          makeModel,
          distanceKm: fe.distanceKm,
          fuelLiters: fe.fuelLiters,
          start: fe.start,
          end: fe.end,
        }));
      }),
      [sortColumn],
      [sortType]
    );
  }, [hotel.fleet, sortColumn, sortType]);

  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSection
          title={trans(
            "pages.fleet.archive.segments.usage.sections.overview.title"
          )}
          description={trans(
            "pages.fleet.archive.segments.usage.sections.overview.description"
          )}
          icon={QueryStatsIcon}
        />
        <Graph {...{ tableDataSorted }} />
      </Flex>
      <Flex column gap={16}>
        <PageSection
          title={trans(
            "pages.fleet.archive.segments.usage.sections.list.title"
          )}
          description={trans(
            "pages.fleet.archive.segments.usage.sections.list.description"
          )}
          icon={DirectionsCarIcon}
        />
        <List {...{ sortColumn, sortType, setSort, tableDataSorted }} />
      </Flex>
    </Flex>
  );
};

export default Entries;
