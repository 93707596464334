import React from "react";
import { InputGroup, InputNumber } from "rsuite";
import { tState2 } from ".";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import Flex from "../../Flex";
import InputWrapper from "../../InputWrapper";

interface iProps {
  status: nRequestStatus.tStatus;
  state: tState2["data"]["water"];
  errors: tState2["errors"]["water"];
  onChange(key: keyof tState2["data"]["water"], value: number | string): void;
}

const Water: React.FC<iProps> = ({ errors, state, onChange, status }) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={20}>
      <InputWrapper
        {...{
          label: trans("Water Consumption"),
          description: trans(
            "Enter your total water usage for the selected time period."
          ),
          error: errors["totalM3"],
        }}
      >
        <Flex row gap={12} style={{ width: "70%" }}>
          <InputGroup>
            <InputNumber
              disabled={status === REQUEST_STATUS.PENDING}
              min={0}
              value={state["totalM3"]}
              placeholder={"Total water consumption"}
              onChange={(value) => {
                if (value !== null) {
                  onChange("totalM3", value);
                }
              }}
            />
            <InputGroup.Addon>
              m<sup>3</sup>
            </InputGroup.Addon>
          </InputGroup>
          <InputGroup>
            <InputNumber
              disabled={status === REQUEST_STATUS.PENDING}
              min={0}
              value={state["price"]}
              placeholder={"Total water price"}
              onChange={(value) => {
                if (value !== null) {
                  onChange("price", value);
                }
              }}
            />
            <InputGroup.Addon>€</InputGroup.Addon>
          </InputGroup>
        </Flex>
      </InputWrapper>
    </Flex>
  );
};

export default Water;
