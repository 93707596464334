import { extend } from "lodash";
import { useEffect, useState } from "react";
import useHotelRolesState from "../context/Hotel/hooks/hotelState/useHotelRolesState";
import useProfileState from "../context/Profile/hooks/useProfileState";
import { tHotelStaffPermissions } from "../models/hotel";
import { STAFF_PERMISSIONS } from "../utils/hotels/permissions";

// const LS_DATA = "__sm-p__";

export type tProfilePermissions = ReturnType<typeof useProfilePermissions>;

const useProfilePermissions = () => {
  const { profile } = useProfileState();
  const { listStaffMemberRoles } = useHotelRolesState();
  const [permissions, setPermissions] = useState<tHotelStaffPermissions>(() => {
    const roles = listStaffMemberRoles(profile._id);

    const permissions = { ...STAFF_PERMISSIONS };
    roles.forEach((r) => extend(permissions, r.permissions));
    return permissions;
  });

  useEffect(() => {
    const roles = listStaffMemberRoles(profile._id);

    const permissions = { ...STAFF_PERMISSIONS };
    roles.forEach((r) => extend(permissions, r.permissions));

    setPermissions(permissions);
  }, [listStaffMemberRoles, profile._id]);

  return permissions;
};

export default useProfilePermissions;
