import { sortBy } from "lodash";
import React, { useMemo, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, IconButton, Loader, Tooltip, Whisper } from "rsuite";
import { ReactComponent as BadgeIcon } from "../../../assets/icons/badge.svg";
import { ReactComponent as DashbaordIcon } from "../../../assets/icons/dashboard.svg";
import { ReactComponent as EnergySavingIcon } from "../../../assets/icons/energy_program_saving.svg";
import { ReactComponent as KeyboardArrowDownIcon } from "../../../assets/icons/keyboard_arrow_down.svg";
import { ReactComponent as KeyboardArrowUpIcon } from "../../../assets/icons/keyboard_arrow_up.svg";
import { ReactComponent as KeyboardDoubleArrowLeftIcon } from "../../../assets/icons/keyboard_double_arrow_left.svg";
import { ReactComponent as KeyboardDoubleArrowRightIcon } from "../../../assets/icons/keyboard_double_arrow_right.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as MoreHorizIcon } from "../../../assets/icons/more_horiz.svg";
import { ReactComponent as NoytrallLogo } from "../../../assets/noytrall_logo.svg";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import PageNameTopBar from "../../../components/TopBar/PageNameTopBar";
import { HotelContextProvider } from "../../../context/Hotel";
import useHotelSettingsState from "../../../context/Hotel/hooks/hotelState/useHotelSettingsState";
import useHotelsListState from "../../../context/Hotel/hooks/hotelState/useHotelsListState";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import { LocalizationContextProvider } from "../../../context/Localization";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileDispatch from "../../../context/Profile/hooks/useProfileDispatch";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import { MENU_MAP } from "../../../utils/pages";
import { renderRequestedData } from "../../../utils/render";
import styles from "./styles.module.css";

const SIDEBAR_EXPANDED_LS = "__sbe__";

const CONTENT_WIDTH = 1128;
const TOTAL_WIDTH = CONTENT_WIDTH;
const MIN_MARGIN_HORIZONTAL = 16;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const WIDTH = TOTAL_WIDTH + 2 * MIN_MARGIN_HORIZONTAL;

type tMenuElement = {
  key: string;
  icon: tIconElement;
  transKey: string;
  to: string;
  index?: boolean;
  children?: {
    key: string;
    icon: tIconElement;
    transKey: string;
    to: string;
  }[];
};

const RootDesktop: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const location = useLocation();

  const {
    profile: { name, images, hotels },
    isResolved: isProfileResolved,
  } = useProfileState();

  const { hotel, status } = useHotelState();
  const { isSettingOn } = useHotelSettingsState();
  const { hotels: hotelsList } = useHotelsListState();
  const { setActiveHotel } = useProfileDispatch();
  const profilePermissions = useProfilePermissions();

  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(() => {
    const stored = localStorage.getItem(SIDEBAR_EXPANDED_LS);
    return !(stored === "false");
  });

  const LIST = useMemo(() => {
    const LIST: tMenuElement[][] = [];

    if (hotelsList.length > 1) {
      LIST.push([
        {
          key: "properties",
          ...MENU_MAP.properties,
        },
      ]);
    }
    LIST.push(
      [
        {
          key: "overview",
          ...MENU_MAP["overview"],
          index: true,
        },
        {
          key: "sustainability",
          ...MENU_MAP["sustainability"],
        },
      ],
      [
        {
          key: "spaces",
          ...MENU_MAP["spaces"],
        },

        {
          key: "guests",
          ...MENU_MAP["guests"],
        },
        {
          key: "occupancy",
          ...MENU_MAP["occupancy"],
        },
        {
          key: "consumption",
          ...MENU_MAP["consumption"],
          children: [
            {
              key: "consumption.electricity",
              ...MENU_MAP["consumption.electricity"],
            },
            {
              key: "consumption.water",
              ...MENU_MAP["consumption.water"],
            },
            {
              key: "consumption.fuels",
              ...MENU_MAP["consumption.fuels"],
            },
            {
              key: "consumption.waste",
              ...MENU_MAP["consumption.waste"],
            },
            {
              key: "consumption.laundry",
              ...MENU_MAP["consumption.laundry"],
            },
            {
              key: "consumption.fleet",
              ...MENU_MAP["consumption.fleet"],
            },
          ],
        },
      ],
      [
        {
          key: "awards",
          ...MENU_MAP["awards"],
        },
        {
          key: "challenges",
          ...MENU_MAP["challenges"],
        },
        {
          key: "loyalty",
          ...MENU_MAP["loyalty"],
        },
      ],
      [
        {
          key: "users",
          ...MENU_MAP["users"],
        },
      ]
    );

    if (
      isSettingOn("pages.guestAppProfile.show") &&
      profilePermissions.writeGuestAppData
    ) {
      LIST[LIST.length - 1].push({
        key: "profile",
        ...MENU_MAP["profile"],
      });
    }

    return LIST;
  }, [hotelsList.length, isSettingOn, profilePermissions.writeGuestAppData]);

  const toggleSidebarExpanded = () => {
    localStorage.setItem(
      SIDEBAR_EXPANDED_LS,
      `${sidebarExpanded ? "false" : "true"}`
    );
    setSidebarExpanded((prev) => !prev);
    setExpanded({});
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 200);
  };

  const divider = (props: any = {}) => (
    <div {...props} style={{ margin: "8px 0" }}>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_400,
        }}
      />
    </div>
  );

  const renderDesktopMenu = () => {
    const renderMenu = () => {
      return LIST.flatMap((compartment, i) =>
        [
          divider({ key: i }),
          (compartment as tMenuElement[]).map((routeElement, i) => {
            const { icon, key, transKey, children, to, index } = routeElement;
            const color = COLORS.light_white;

            if (children && children.length)
              return (
                <Flex
                  onMouseEnter={() => {
                    if (!sidebarExpanded)
                      setExpanded((prev) => ({
                        ...prev,
                        [key]: true,
                      }));
                  }}
                  onMouseLeave={() => {
                    if (!sidebarExpanded)
                      setExpanded((prev) => ({
                        ...prev,
                        [key]: false,
                      }));
                  }}
                  column
                  key={`${transKey}.has-child`}
                >
                  <Whisper
                    trigger="hover"
                    disabled={sidebarExpanded}
                    placement="right"
                    speaker={<Tooltip>{trans(transKey)}</Tooltip>}
                  >
                    <Flex
                      key={key}
                      center={!sidebarExpanded}
                      className={`${styles["navlink"]} ${
                        styles["navlink-content"]
                      } ${
                        sidebarExpanded
                          ? styles["navlink-content-expanded"]
                          : styles["navlink-content-not-expanded"]
                      } `}
                      style={{
                        cursor: "pointer",
                        padding: 0,
                        ...(location.pathname.slice(1) === to
                          ? {
                              backgroundColor: COLORS.primary,
                            }
                          : {}),
                      }}
                    >
                      {sidebarExpanded ? (
                        <Flex
                          row
                          style={{
                            width: "100%",
                          }}
                        >
                          <Flex
                            row
                            middle
                            basis={80}
                            style={{
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              paddingLeft: "24px",
                            }}
                            gap={16}
                            onClick={() => {
                              navigate("/consumption");
                            }}
                          >
                            <Icon
                              Element={icon}
                              fill={color}
                              width={24}
                              height={24}
                            />
                            <InterTag
                              text={trans(transKey)}
                              color={color}
                              size={16}
                              bold
                            />
                          </Flex>
                          <Flex
                            center
                            style={{
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              borderTopRightRadius: "8px",
                              borderBottomRightRadius: "8px",
                            }}
                            basis={20}
                            className={`${styles["expandable-menu-item-arrow"]}`}
                            onClick={() =>
                              setExpanded((prev) => ({
                                ...prev,
                                [key]: !!!expanded[key],
                              }))
                            }
                          >
                            <Icon
                              Element={
                                expanded[key]
                                  ? KeyboardArrowUpIcon
                                  : KeyboardArrowDownIcon
                              }
                              fill={COLORS.light_white}
                              size={24}
                            />
                          </Flex>
                        </Flex>
                      ) : (
                        <Flex
                          center
                          onClick={() => {
                            navigate("/consumption");
                          }}
                          style={{
                            paddingTop: "8px",
                            paddingBottom: "8px",
                            width: "100%",
                            borderRadius: "8px",
                          }}
                        >
                          <Icon
                            Element={icon}
                            fill={color}
                            width={24}
                            height={24}
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Whisper>
                  {expanded[key] === true &&
                    children.map((routeElement) => {
                      const { icon, key, transKey, to } = routeElement;
                      return (
                        <NavLink
                          key={key}
                          to={to}
                          className={({ isActive }) => {
                            return `${styles["navlink"]} ${
                              isActive || (location.pathname === "/" && index)
                                ? ` ${styles["active-link"]}`
                                : ""
                            }`;
                          }}
                        >
                          <Whisper
                            speaker={<Tooltip>{trans(transKey)}</Tooltip>}
                            disabled={sidebarExpanded}
                            trigger="hover"
                            placement="right"
                          >
                            <Flex
                              row
                              gap={16}
                              middle
                              center={!sidebarExpanded}
                              className={`${styles["navlink-content"]} ${
                                sidebarExpanded
                                  ? styles["navlink-content-child-expanded"]
                                  : styles["navlink-content-not-expanded"]
                              }`}
                            >
                              <Icon
                                Element={icon}
                                fill={color}
                                width={24}
                                height={24}
                              />
                              {sidebarExpanded && (
                                <InterTag
                                  bold
                                  text={trans(transKey)}
                                  color={color}
                                  size={16}
                                />
                              )}
                            </Flex>
                          </Whisper>
                        </NavLink>
                      );
                    })}
                </Flex>
              );

            if (to)
              return (
                <NavLink
                  key={key}
                  to={to}
                  className={({ isActive }) => {
                    return `${styles["navlink"]}${
                      isActive || (location.pathname === "/" && index)
                        ? ` ${styles["active-link"]}`
                        : ""
                    }`;
                  }}
                >
                  <Whisper
                    trigger="hover"
                    placement="right"
                    disabled={sidebarExpanded}
                    speaker={<Tooltip>{trans(transKey)}</Tooltip>}
                  >
                    <Flex
                      row
                      gap={16}
                      middle
                      center={!sidebarExpanded}
                      className={`${styles["navlink-content"]} ${
                        sidebarExpanded
                          ? styles["navlink-content-expanded"]
                          : styles["navlink-content-not-expanded"]
                      }`}
                    >
                      <Icon
                        Element={icon}
                        fill={color}
                        width={24}
                        height={24}
                      />
                      {sidebarExpanded && (
                        <InterTag
                          bold
                          text={trans(transKey)}
                          color={color}
                          size={16}
                        />
                      )}
                    </Flex>
                  </Whisper>
                </NavLink>
              );

            return null;
          }),
        ].flatMap((e) => e)
      );
    };

    const expandedSidemenu = () => {
      return (
        <Flex
          className={`${styles["sidemenu"]} ${styles["sidemenu-expanded"]}`}
          column
          color={COLORS.secondary}
        >
          <Flex gap={30} row middle center style={{ marginTop: "16px" }}>
            <Icon Element={NoytrallLogo} width={120} height={40} />
            <IconButton
              className={styles["sidemenu-toggle-button"]}
              onClick={toggleSidebarExpanded}
              icon={
                <Icon
                  size={24}
                  fill={COLORS.light_white}
                  Element={KeyboardDoubleArrowLeftIcon}
                />
              }
              circle
              size="xs"
              appearance="subtle"
            />
          </Flex>
          <div style={{ margin: "0 16px" }}>{divider()}</div>
          <Flex column center middle gap={12}>
            {images && images.length > 0 && (
              <Avatar circle src={images[0]} alt="" size={"lg"} />
            )}
            <Flex column middle gap={18}>
              <InterTag size={16} text={name} bold color={COLORS.light_white} />
              <SimpleWhisperPopoverDropdown
                options={sortBy(hotelsList, ["name"]).map(({ name, _id }) => ({
                  key: "name",
                  label: name,
                  onClick() {
                    setActiveHotel(_id, true);
                    window.location.reload();
                  },
                }))}
                disabled={!isProfileResolved || hotels.length <= 1}
              >
                <InterTag
                  hoverUnderline={!(!isProfileResolved || hotels.length <= 1)}
                  {...{ text: hotel.name, size: 14, color: COLORS.light_white }}
                />
              </SimpleWhisperPopoverDropdown>
            </Flex>
          </Flex>
          <Flex column gap={4} style={{ margin: "0 16px" }}>
            {renderMenu()}
          </Flex>
          <div style={{ width: "100%", padding: "8px 0" }}></div>
        </Flex>
      );
    };

    const notExpandedSidemenu = () => {
      return (
        <Flex
          className={`${styles["sidemenu"]} ${styles["sidemenu-not-expanded"]}`}
          column
          color={COLORS.secondary}
        >
          <Flex middle center style={{ marginTop: "16px" }}>
            <Icon Element={NoytrallSymbol} width={120} height={40} />
          </Flex>
          <div style={{ margin: "0 16px" }}>{divider()}</div>
          <Flex gap={12} column center middle>
            {images && images.length > 0 && (
              <Avatar circle src={images[0]} alt="" size={"lg"} />
            )}
            <IconButton
              className={styles["sidemenu-toggle-button"]}
              onClick={toggleSidebarExpanded}
              icon={
                <Icon
                  size={24}
                  fill={COLORS.light_white}
                  Element={KeyboardDoubleArrowRightIcon}
                />
              }
              circle
              size="xs"
              appearance="subtle"
            />
          </Flex>
          <div style={{ margin: "0 16px" }}>{renderMenu()}</div>
        </Flex>
      );
    };
    if (sidebarExpanded) return expandedSidemenu();
    return notExpandedSidemenu();
  };

  return (
    <Flex className={styles.app}>
      {renderDesktopMenu()}
      <Flex
        grow={1}
        className={styles.main}
        color={COLORS.app_background}
        style={{
          marginLeft: `${sidebarExpanded ? 255 : 88}px`,
          height: "100%",
        }}
      >
        <Flex
          style={{ width: "100%", height: "100%" }}
          center
          id="root-outlet-container"
        >
          <div
            id="outlet"
            style={{
              width: `90%`,
              position: "relative",
            }}
          >
            {["/settings", "/properties"].includes(location.pathname) ? (
              <Outlet />
            ) : (
              renderRequestedData(status, {
                resolved: () => <Outlet />,
                pending: () => (
                  <Flex column style={{ height: "100%" }} center middle>
                    {location.pathname.indexOf("settings") === -1 && (
                      <PageNameTopBar page="" />
                    )}
                    <Loader size="lg" />
                  </Flex>
                ),
                idle: () => (
                  <Flex column style={{ height: "100%" }} center middle>
                    {location.pathname.indexOf("settings") === -1 && (
                      <PageNameTopBar page="" />
                    )}
                    <Loader size="lg" />
                  </Flex>
                ),
                rejected: () => <Flex></Flex>,
              })
            )}
          </div>
        </Flex>
      </Flex>
    </Flex>
  );
};

const RootMobile: React.FC = () => {
  const location = useLocation();
  const { status } = useHotelState();

  const renderBottom = () => {
    return (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          height: "70px",
          left: 0,
          right: 0,
          backgroundColor: COLORS.secondary,
          zIndex: 43,
        }}
      >
        <Flex color={COLORS.secondary} row style={{ height: "100%" }} gap={8}>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${
                isActive || location.pathname === "/"
                  ? styles["mobile-navlink-active"]
                  : ""
              }`
            }
            to="/overview"
          >
            <Button
              style={{ height: "100%", width: "100%" }}
              appearance="subtle"
            >
              <Icon
                Element={DashbaordIcon}
                fill={COLORS.light_white}
                width={24}
                height={24}
              />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${
                isActive ? styles["mobile-navlink-active"] : ""
              }`
            }
            to="/sustainability"
          >
            <Button
              appearance="subtle"
              style={{ height: "100%", width: "100%" }}
            >
              <Icon
                Element={EnergySavingIcon}
                fill={COLORS.light_white}
                width={24}
                height={24}
              />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${
                isActive ? styles["mobile-navlink-active"] : ""
              }`
            }
            to="/spaces"
          >
            <Button
              appearance="subtle"
              style={{ height: "100%", width: "100%" }}
            >
              <Icon
                Element={MeetingRoomIcon}
                fill={COLORS.light_white}
                width={24}
                height={24}
              />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${
                isActive ? styles["mobile-navlink-active"] : ""
              }`
            }
            to="/users"
          >
            <Button
              appearance="subtle"
              style={{ height: "100%", width: "100%" }}
            >
              <Icon
                Element={BadgeIcon}
                fill={COLORS.light_white}
                width={24}
                height={24}
              />
            </Button>
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `${styles["mobile-navlink"]} ${
                isActive ? styles["mobile-navlink-active"] : ""
              }`
            }
            to="/more"
          >
            <Button
              appearance="subtle"
              style={{ height: "100%", width: "100%" }}
            >
              <Icon
                Element={MoreHorizIcon}
                fill={COLORS.light_white}
                width={24}
                height={24}
              />
            </Button>
          </NavLink>
        </Flex>
      </div>
    );
  };

  return (
    <div
      id="outlet"
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundColor: COLORS.app_background,
      }}
    >
      {renderRequestedData(status, {
        resolved: () => {
          const props =
            location.pathname === "/more"
              ? {}
              : { style: { padding: "20px 20px 80px 20px" } };
          return (
            <Flex column {...{ ...props, style: { ...props.style } }}>
              <Outlet />
              <div />
            </Flex>
          );
        },
        pending: () => (
          <Flex style={{ height: "100%", width: "100%" }} center middle>
            <Loader size="lg" />
          </Flex>
        ),
        idle: () => (
          <Flex style={{ height: "100%", width: "100%" }} center middle>
            <Loader size="lg" />
          </Flex>
        ),
        rejected: () => <Flex></Flex>,
      })}
      {renderBottom()}
    </div>
  );
};

const RootWrapped: React.FC = () => {
  const { isLaptop } = useWindowSizeState();
  return isLaptop ? <RootDesktop /> : <RootMobile />;
};

const Root: React.FC = () => {
  return (
    <HotelContextProvider>
      <LocalizationContextProvider>
        <RootWrapped />
      </LocalizationContextProvider>
    </HotelContextProvider>
  );
};

export default Root;
