export const LAUNDRY_ESTIMATED_ELECTRICITY_KWH_PER_TON = 180;
export const LAUNDRY_ESTIMATED_WATER_M3_PER_TON = 12;
export const LAUNDRY_ESTIMATED_NATURAL_GAS_KWH_PER_TON = 1560;
export const LAUNDRY_ESTIMATED_GASOLINE_L_PER_TON = 111;

export const calcLaundryEstimatedElectricityKWhPerTon = (laundryKg: number) =>
  (laundryKg * LAUNDRY_ESTIMATED_ELECTRICITY_KWH_PER_TON) / 1000;

export const calcLaundryEstimatedWaterM3PerTon = (laundryKg: number) =>
  (laundryKg * LAUNDRY_ESTIMATED_WATER_M3_PER_TON) / 1000;

export const calcLaundryNaturalGasKWhPerTon = (laundryKg: number) =>
  (laundryKg * LAUNDRY_ESTIMATED_NATURAL_GAS_KWH_PER_TON) / 1000;

export const calcLaundryGasolineLitersPerTon = (laundryKg: number) =>
  (laundryKg * LAUNDRY_ESTIMATED_GASOLINE_L_PER_TON) / 1000;
