import { createContext, useCallback, useState } from "react";

type tState = {
  dataTypeToggleDisabled: Record<
    | "guestsRequest"
    | "measuresRequest"
    | "homologueMeasuresRequest"
    | "benchmarkRequest"
    | "conversionFactorsRequest",
    boolean
  >;
};

const initialState: tState = {
  dataTypeToggleDisabled: {
    benchmarkRequest: false,
    guestsRequest: false,
    homologueMeasuresRequest: false,
    measuresRequest: false,
    conversionFactorsRequest: false,
  },
};

const SustainabilityContext = createContext<{
  state: tState;
  setDataTypeToggleDisabled: (
    arg: Partial<tState["dataTypeToggleDisabled"]>
  ) => void;
}>({ state: { ...initialState }, setDataTypeToggleDisabled(arg) {} });

const SustainabilityContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, setState] = useState({ ...initialState });

  const setDataTypeToggleDisabled = useCallback(
    (arg: Partial<tState["dataTypeToggleDisabled"]>) => {
      setState((prev) => ({
        ...prev,
        dataTypeToggleDisabled: { ...prev.dataTypeToggleDisabled, ...arg },
      }));
    },
    []
  );

  return (
    <SustainabilityContext.Provider
      value={{ state, setDataTypeToggleDisabled }}
    >
      {children}
    </SustainabilityContext.Provider>
  );
};

export { SustainabilityContext };

export default SustainabilityContextProvider;
