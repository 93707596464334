import React from "react";
import { Button } from "rsuite";
import { ReactComponent as ArrowLeftIcon } from "../../../../assets/icons/keyboard_arrow_left.svg";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../../utils/colors";
import Flex from "../../../Flex";
import Icon from "../../../Icons/Icon";
import InterTag from "../../../Text/Inter";

interface iProps {
  onDelete?(): void;
  onGoBack?(): void;
  onContinue?(): void;
  onSubmit?(): void;
  onSaveAndExit(): void;
  isLoading?: boolean;
}

const ActionButtons: React.FC<iProps> = ({
  onDelete,
  onContinue,
  onGoBack,
  onSaveAndExit,
  onSubmit,
  isLoading = false,
}) => {
  const { trans } = useLocalizationState();

  const flexBasisValue =
    (1 /
      [onContinue, onGoBack, onSubmit].filter((f) => f !== undefined).length) *
    100;

  const goBackButton = onGoBack ? (
    <Button
      style={{
        flexBasis: `${flexBasisValue}%`,
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingRight: "20px",
      }}
      appearance="subtle"
      onClick={onGoBack}
      disabled={isLoading}
    >
      <Flex row middle style={{ ...(isLoading ? { opacity: 0.5 } : {}) }}>
        <Icon Element={ArrowLeftIcon} size={24} fill={COLORS.primary} />
        <InterTag text={trans("Go Back")} size={14} color={COLORS.primary} />
      </Flex>
    </Button>
  ) : undefined;

  const onContinueButton = onContinue ? (
    <Button
      style={{
        flexBasis: `${flexBasisValue}%`,
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingRight: "20px",
      }}
      appearance="primary"
      onClick={onContinue}
      loading={isLoading}
      disabled={isLoading}
    >
      <InterTag
        style={{ visibility: !isLoading ? "visible" : "hidden" }}
        text={trans("Continue")}
        size={14}
        color={COLORS.white}
      />
    </Button>
  ) : undefined;

  const onSubmitButton = onSubmit ? (
    <Button
      style={{
        flexBasis: `${flexBasisValue}%`,
      }}
      appearance="ghost"
      onClick={onSubmit}
      loading={isLoading}
      disabled={isLoading}
    >
      <InterTag
        style={{ visibility: !isLoading ? "visible" : "hidden" }}
        text={trans("Submit")}
        size={14}
        color={COLORS.primary}
      />
    </Button>
  ) : undefined;

  const onExitButton = (
    <Button
      disabled={isLoading}
      style={{
        width: "100%",
        ...(isLoading ? { opacity: 0.5 } : {}),
      }}
      appearance="subtle"
      onClick={onSaveAndExit}
    >
      <InterTag size={14} color={COLORS.error} text={trans("Exit")} />
    </Button>
  );

  const onDeleteButton = onDelete ? (
    <Button
      appearance="ghost"
      color="red"
      disabled={isLoading}
      style={{
        width: "100%",
        ...(isLoading ? { opacity: 0.5 } : {}),
      }}
      onClick={onDelete}
    >
      <InterTag size={14} color={COLORS.error} text={trans("Remove")} />
    </Button>
  ) : undefined;

  if (
    !goBackButton &&
    !onContinueButton &&
    onSubmitButton &&
    !onDeleteButton &&
    onExitButton
  )
    return (
      <Flex row gap={8} style={{ margin: "0 25%" }}>
        {onExitButton}
        {onSubmitButton}
      </Flex>
    );

  return (
    <Flex column gap={8} style={{ margin: "0 25%" }}>
      <Flex row gap={8}>
        {goBackButton}
        {onContinueButton}
        {onSubmitButton}
      </Flex>
      <Flex row gap={8}>
        <Flex one>
          {onExitButton}
          {onDeleteButton}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ActionButtons;
