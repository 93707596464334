import React, { useContext } from "react";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import Flex from "../../../../components/Flex";
import MeasureConsumption from "../../../../components/MeasureConsumption";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import PageSection from "../../../../components/PageSection";
import RoadmapTable from "../../../../components/RoadmapTable";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SpaceMeasureConsumptionTable from "../../../../components/Tables/SpaceMeasureConsumptionTable";
import InterTag from "../../../../components/Text/Inter";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../../hooks/usePeriodState";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  TE,
  tMeasureEnergySpecific,
} from "../../../../models/measures";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { ConsumptionContext } from "../../consumption/context";

const LiveData: React.FC<{ dataTypeToggle: JSX.Element | null }> = ({
  dataTypeToggle,
}) => {
  const {
    setDataTypeToggleDisabled,
    state: { dataTypeToggleDisabled },
  } = useContext(ConsumptionContext);
  const { trans } = useLocalizationState();
  const {
    getMeasuresTracked,
    hotel: { spaces },
  } = useHotelMeasuresTrackedState();
  const [period, setPeriod] = usePeriodState();

  const trackedMeasures = getMeasuresTracked(spaces.map((s) => s._id));

  const specificMeasures: { measureKey: tMeasureEnergySpecific }[] = [];
  MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
    if (trackedMeasures[m]) specificMeasures.push({ measureKey: m });
  });

  const disableInputs =
    dataTypeToggleDisabled.electricityMeasuresRequest ||
    dataTypeToggleDisabled.waterMeasuresRequest;

  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8} middle>
          {dataTypeToggle}
          <SimpleDateRangePicker
            disabled={disableInputs}
            onChange={setPeriod}
            value={period}
          />
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        {trackedMeasures.te ? (
          <>
            <PageSection
              {...{
                title: trans("pages.electricity.live.sections.overview.title"),
                description: trans(
                  "pages.electricity.live.sections.overview.description"
                ),
                icon: QueryStatsIcon,
              }}
            >
              <MeasureConsumption
                measuresRequestStatusTracker={(status) => {
                  setDataTypeToggleDisabled({
                    electricityMeasuresRequest:
                      status === REQUEST_STATUS.PENDING,
                  });
                }}
                mainMeasureKey={TE}
                specificMeasures={specificMeasures}
                converter={convertEnergyUnit}
                mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
                binUnit={"day"}
                binValue={1}
                from={period[0]}
                to={period[1]}
              />
            </PageSection>
            <PageSection
              {...{
                title: trans("pages.electricity.live.sections.list.title"),
                description: trans(
                  "pages.electricity.live.sections.list.description"
                ),
                icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
              }}
            >
              <SpaceMeasureConsumptionTable
                mainMeasureKey="te"
                converter={convertEnergyUnit}
                from={period[0]}
                to={period[1]}
                {...{
                  tableHeader: {
                    icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
                    name: trans("general.measures_.electricity"),
                  },
                }}
              />
            </PageSection>
          </>
        ) : (
          <Flex>
            <InterTag
              text={trans("pages.electricity.live.not_measured")}
              size={16}
              color={COLORS.secondary}
            />
          </Flex>
        )}
        <RoadmapTable filterByType="electricity" />
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default LiveData;
