import React from "react";
import BaseCell from "../BaseCell";
import { Checkbox } from "rsuite";

interface iProps {
  rowData?: any;
  dataKey?: string;
  isChecked(rowData: any): boolean;
  onChange(rowData: any): void;
}

const SimpleCheckCell: React.FC<iProps> = ({
  isChecked,
  onChange,
  ...props
}) => {
  const { rowData } = props;
  return (
    <BaseCell {...{ ...props }}>
      <div>
        <Checkbox
          checked={isChecked(rowData)}
          onChange={() => onChange(rowData)}
          inline
        />
      </div>
    </BaseCell>
  );
};

export default SimpleCheckCell;
