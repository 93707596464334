import React from "react";
import { HotelDispatchContext } from "../";
import { tHotel, tHotelId } from "../../../models/hotel";

const useHotelDispatch = () => {
  const dispatch = React.useContext(HotelDispatchContext);

  if (!dispatch)
    throw Error("useHotelDispatch must be used within HotelStateContext");

  const updateHotel = (hotelId: string, hotel: Partial<tHotel>) => {
    dispatch({ type: "update hotel", hotelId, hotel });
  };

  const updatingHotel = () => {
    dispatch({ type: "pending" });
  };

  const resolveHotel = () => {
    dispatch({ type: "resolved" });
  };

  const setActiveHotel = (activeHotel: tHotelId) => {
    dispatch({ type: "select hotel", activeHotel });
  };

  return { dispatch, updateHotel, updatingHotel, resolveHotel, setActiveHotel };
};

export default useHotelDispatch;
