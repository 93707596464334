import { toNumber } from "lodash";
import React, { useEffect, useRef } from "react";
import { COLORS } from "../../utils/colors";

interface iProps {
  fromColor?: string;
  toColor?: string;
  style?: React.CSSProperties;
  height?: number | string;
  width?: number | string;
}

const SkeletonText: React.FC<iProps> = ({
  fromColor = COLORS.gray_200,
  toColor = COLORS.gray_400,
  width = 100,
  height = 20,
  style,
}) => {
  const ref = useRef<any>();

  useEffect(() => {
    if (ref.current) {
      ref.current.animate(
        [
          // key frames
          { backgroundColor: fromColor },
          { backgroundColor: toColor },
          { backgroundColor: fromColor },
        ],
        {
          // sync options
          duration: 1000,
          iterations: Infinity,
        }
      );
    }
  }, [fromColor, toColor]);

  const widthToNumber = toNumber(width);
  const widthProperty = isNaN(widthToNumber) ? width : `${widthToNumber}px`;

  const heightToNumber = toNumber(height);
  const heightProperty = isNaN(heightToNumber) ? height : `${heightToNumber}px`;

  return (
    <div
      ref={ref}
      style={{
        borderRadius: "4px",
        width: widthProperty,
        height: heightProperty,
        ...style,
      }}
    />
  );
};

export default SkeletonText;
