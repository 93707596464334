import { findIndex } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as RoomPreferencesIcon } from "../../../assets/icons/room_preferences.svg";
import { ReactComponent as StacksIcon } from "../../../assets/icons/stacks.svg";
import { ReactComponent as WorkspaceIcon } from "../../../assets/icons/workspaces.svg";
import bySpacePlaceholder from "../../../assets/placeholders/consumption-by-space.png";
import spacesManagementPlaceholder from "../../../assets/placeholders/spaces-management.png";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import PageSection, {
  iPageSectionProps,
} from "../../../components/PageSection";
import Segmentation from "../../../components/Segmentation";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import UnlockPageSection from "../../../components/UnlockPageSection";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import useScreenSize from "../../../hooks/useScreenSize";
import { COLORS } from "../../../utils/colors";
import AccommodationMeasures from "./accommodationMeasures";
import AddButton from "./addButton";
import SpaceAggregateConsumption, {
  iSpaceAggregateConsumptionProps,
} from "./spaceAggregateConsumption";
import SpaceAggregateTable from "./spaceAggregateTable";
import SpacesConsumption, {
  iSpacesConsumptionProps,
} from "./spacesConsumption";
import SpacesTable from "./spacesTable";

const TOP_LEVEL_FILTERS: ("spaces" | "groups" | "zones" | "types")[] = [
  "spaces",
  "groups",
  "zones",
  "types",
];

type tTopLevelFilter = Record<
  "spaces" | "groups" | "zones" | "types",
  {
    label: string;
    count: number;
    tableComponent: {
      PageSectionProps: iPageSectionProps;
      Element: React.FC<any>;
      props: Record<string, any>;
    };
    consumptionComponent:
      | {
          Element: React.FC<iSpaceAggregateConsumptionProps>;
          props: iSpaceAggregateConsumptionProps;
        }
      | {
          Element: React.FC<iSpacesConsumptionProps>;
          props: iSpacesConsumptionProps;
        };
    extraComponents: { Component: React.FC<any>; key: string }[];
  }
>;

interface iProps {
  outletContainerWidth: number;
}

const SpacesWrapped: React.FC<iProps> = ({ outletContainerWidth }) => {
  const { isMobile } = useWindowSizeState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { trans } = useLocalizationState();
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(0);
  const {
    hotel: { spaces },
    spaceAggregatesByCategory,
  } = useHotelSpacesState();

  useEffect(() => {
    const tab = searchParams.get("tab");

    if (tab === "groups") return setTopLevelFilterIndex(1);
    if (tab === "zones") return setTopLevelFilterIndex(2);
    if (tab === "types") return setTopLevelFilterIndex(3);
    setTopLevelFilterIndex(0);
  }, [searchParams]);

  const topLevelFilters: tTopLevelFilter = {
    spaces: {
      label: trans("pages.spaces.tabs.spaces.title"),
      count: spaces.length,
      tableComponent: {
        PageSectionProps: {
          title: trans("pages.spaces.tabs.spaces.management.title"),
          description: trans("pages.spaces.tabs.spaces.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpacesTable,
        props: { outletContainerWidth },
      },
      consumptionComponent: {
        Element: SpacesConsumption,
        props: {},
      },
      extraComponents: [
        { Component: AccommodationMeasures, key: "accommodation-measures" },
      ],
    },
    groups: {
      label: trans("pages.spaces.tabs.groups.title"),
      count: spaceAggregatesByCategory.group.length,
      tableComponent: {
        PageSectionProps: {
          title: trans("pages.spaces.tabs.groups.management.title"),
          description: trans("pages.spaces.tabs.groups.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpaceAggregateTable,
        props: {
          outletContainerWidth,
          category: "group",
        },
      },
      consumptionComponent: {
        Element: SpaceAggregateConsumption,
        props: {
          category: "group",
          PageSectionProps: {
            title: trans("pages.spaces.tabs.groups.consumption.title"),
            description: trans(
              "pages.spaces.tabs.groups.consumption.description"
            ),
            icon: WorkspaceIcon,
          },
        },
      },
      extraComponents: [],
    },
    zones: {
      label: trans("pages.spaces.tabs.zones.title"),
      count: spaceAggregatesByCategory.zone.length,
      tableComponent: {
        PageSectionProps: {
          title: trans("pages.spaces.tabs.zones.management.title"),
          description: trans("pages.spaces.tabs.zones.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpaceAggregateTable,
        props: {
          outletContainerWidth,
          category: "zone",
        },
      },
      consumptionComponent: {
        Element: SpaceAggregateConsumption,
        props: {
          category: "zone",
          PageSectionProps: {
            title: trans("pages.spaces.tabs.zones.consumption.title"),
            description: trans(
              "pages.spaces.tabs.zones.consumption.description"
            ),
            icon: StacksIcon,
          },
        },
      },
      extraComponents: [],
    },
    types: {
      label: trans("pages.spaces.tabs.types.title"),
      count: spaceAggregatesByCategory.type.length,
      tableComponent: {
        PageSectionProps: {
          title: trans("pages.spaces.tabs.types.management.title"),
          description: trans("pages.spaces.tabs.types.management.description"),
          icon: RoomPreferencesIcon,
        },
        Element: SpaceAggregateTable,
        props: {
          outletContainerWidth,
          category: "type",
        },
      },
      consumptionComponent: {
        Element: SpaceAggregateConsumption,
        props: {
          category: "type",
          PageSectionProps: {
            title: trans("pages.spaces.tabs.types.consumption.title"),
            description: trans(
              "pages.spaces.tabs.types.consumption.description"
            ),
            icon: CategoryIcon,
          },
        },
      },
      extraComponents: [],
    },
  };

  const active = TOP_LEVEL_FILTERS[topLevelFilterIndex];
  const segment = topLevelFilters[active];

  const {
    tableComponent: {
      Element: TableElement,
      props: tableProps,
      PageSectionProps,
    },
    consumptionComponent: {
      Element: ConsumptionElement,
      props: consumptionProps,
    },
    extraComponents,
  } = segment;

  const onSelectTopLevelFilter = (key: string) => {
    setTopLevelFilterIndex(findIndex(TOP_LEVEL_FILTERS, (k) => k === key));
  };

  const renderSegmentation = () => {
    const segmentationContainer = (
      <Flex grow={1}>
        <Segmentation
          appearance="subtle"
          active={active}
          onSelect={onSelectTopLevelFilter}
          options={TOP_LEVEL_FILTERS.map((key) => {
            const { label, count } = topLevelFilters[key];
            return {
              key,
              label: { text: label },
              count: count,
              onClick() {
                setSearchParams({ tab: key });
              },
            };
          })}
        />
      </Flex>
    );

    if (isMobile) {
      return (
        <Flex row between>
          {segmentationContainer}
        </Flex>
      );
    }

    return (
      <Flex row between>
        {segmentationContainer}
        <Flex
          middle
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
        >
          <AddButton />
        </Flex>
      </Flex>
    );
  };

  return (
    <Fragment>
      <Flex column gap={16}>
        <Flex column gap={8}>
          {isMobile && <AddButton />}
          {renderSegmentation()}
        </Flex>
        <Flex column gap={40}>
          {/* @ts-expect-error */}
          <ConsumptionElement key={active} {...consumptionProps} />
          <Flex column gap={16}>
            <PageSection {...PageSectionProps} />
            <TableElement key={active} {...tableProps} />
          </Flex>
          {extraComponents.map(({ Component, key }) => (
            <Component {...{ key }} />
          ))}
        </Flex>
      </Flex>
    </Fragment>
  );
};

const SpacesGuarded: React.FC = () => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Flex column gap={40}>
          <AccommodationMeasures />
          <Flex column gap={16}>
            <PageSection
              title={trans("pages.spaces.unlock.consumption.title")}
              description={trans("pages.spaces.unlock.consumption.description")}
              icon={MeetingRoomIcon}
            />
            <UnlockPageSection
              image={bySpacePlaceholder}
              title={trans("pages.spaces.unlock.consumption.unlock.title")}
              description={[0].map((i) =>
                trans(
                  `pages.spaces.unlock.consumption.unlock.description[${i}]`
                )
              )}
              style={{ paddingBottom: "200px" }}
            />
          </Flex>
          <Flex column gap={16}>
            <PageSection
              title={trans("pages.spaces.unlock.management.title")}
              description={trans("pages.spaces.unlock.management.description")}
              icon={RoomPreferencesIcon}
            />
            <UnlockPageSection
              image={spacesManagementPlaceholder}
              title={trans("pages.spaces.unlock.management.unlock.title")}
              description={[0].map((i) =>
                trans(`pages.spaces.unlock.management.unlock.description[${i}]`)
              )}
            />
          </Flex>
        </Flex>
        <PageBottomPadding />
      </>
    );

  if (!profilePermissions.readSpaces) return <NoPermissions />;

  if (!oc) return null;

  return (
    <>
      <SpacesWrapped outletContainerWidth={oc.width} />
      <PageBottomPadding />
    </>
  );
};

const Spaces: React.FC = () => {
  return (
    <div>
      <TopBar page="general.spaces" />
      <IpValidatorGuard>
        <SpacesGuarded />
      </IpValidatorGuard>
    </div>
  );
};

export default Spaces;
