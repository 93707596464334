import { round, sortBy, toInteger, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Grid, Row } from "rsuite";
import { ReactComponent as CompostIcon } from "../../../assets/icons/compost.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as RecyclingIcon } from "../../../assets/icons/recycling.svg";
import Flex from "../../../components/Flex";
import ApexChart from "../../../components/Graphs/Apex/Chart";
import Icon from "../../../components/Icons/Icon";
import InterTag from "../../../components/Text/Inter";
import useHotelManualDataState from "../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tIconElement } from "../../../interfaces/icon";
import { tWithRequired } from "../../../interfaces/others";
import { tHotelManualData } from "../../../models/hotel";
import { add2Arrays } from "../../../utils/arrays";
import { COLORS, hexLuminance } from "../../../utils/colors";
import { generateGraphCategories } from "../../../utils/graphs";
import { thousandsSeparation } from "../../../utils/numbers";

const Graph: React.FC = () => {
  const { getManualDataByYearWithoutZeroValueYears } =
    useHotelManualDataState();
  const manualDataByYear = useMemo(
    () => getManualDataByYearWithoutZeroValueYears(["waste"]),
    [getManualDataByYearWithoutZeroValueYears]
  );
  const [graphType, setGraphType] = useState<"line" | "bar">("line");
  const { trans } = useLocalizationState();
  const [selectedCards, setSelectedCards] = useState<number[]>([0]);
  const [selectedYears, setSelectedYears] = useState<number[]>([
    ...sortBy(Object.keys(manualDataByYear).map((y) => toInteger(y))),
  ]);
  const selectedYearsRef = useRef<number[]>([]);

  useEffect(() => {
    selectedYearsRef.current = selectedYears;
  }, [selectedYears]);

  useEffect(() => {
    setGraphType("bar");
  }, []);

  const seriesObj = useMemo(() => {
    const totalSeries: { name: string; data: number[] }[] = [];
    const mixedSeries: { name: string; data: number[] }[] = [];
    const organicSeries: { name: string; data: number[] }[] = [];
    const plasticSeries: { name: string; data: number[] }[] = [];
    const paperSeries: { name: string; data: number[] }[] = [];
    const glassSeries: { name: string; data: number[] }[] = [];

    Object.entries(manualDataByYear).forEach((entry) => {
      const [year, list] = entry;

      const totalData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const mixedData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const organicData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const plasticData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const paperData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      const glassData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      list.forEach((md) => {
        const { from } = md;
        // index 0 doest not exist to fit in graph
        const index = moment(from).month() + 1;
        if (md.waste) {
          mixedData[index] = md.waste.mixed.totalKg;
          organicData[index] = md.waste.organic.totalKg;
          plasticData[index] = md.waste.plastic.totalKg;
          paperData[index] = md.waste.paper.totalKg;
          glassData[index] = md.waste.glass.totalKg;
          totalData[index] =
            mixedData[index] +
            organicData[index] +
            plasticData[index] +
            paperData[index] +
            glassData[index];
        }
      });

      totalSeries.push({ name: year, data: totalData });
      mixedSeries.push({ name: year, data: mixedData });
      organicSeries.push({ name: year, data: organicData });
      plasticSeries.push({ name: year, data: plasticData });
      paperSeries.push({ name: year, data: paperData });
      glassSeries.push({ name: year, data: glassData });
    });

    return {
      total: totalSeries,
      mixed: mixedSeries,
      organic: organicSeries,
      plastic: plasticSeries,
      paper: paperSeries,
      glass: glassSeries,
    };
  }, [manualDataByYear]);

  const kgCalculated = useMemo(() => {
    let mixed = 0,
      organic = 0,
      plastic = 0,
      paper = 0,
      glass = 0;

    const selectedManualDataEntries = selectedYears.flatMap(
      (year) => manualDataByYear[year]
    );

    selectedManualDataEntries.forEach((item) => {
      if (item.waste) {
        mixed += item.waste.mixed.totalKg;
        organic += item.waste.organic.totalKg;
        plastic += item.waste.plastic.totalKg;
        paper += item.waste.paper.totalKg;
        glass += item.waste.glass.totalKg;
      }
    });

    return {
      total: mixed + organic + paper + plastic + glass,
      mixed,
      organic,
      paper,
      plastic,
      glass,
    };
  }, [manualDataByYear, selectedYears]);

  const cards: {
    key: keyof tWithRequired<tHotelManualData, "waste">["waste"];
    label: string;
    icon: tIconElement;
  }[] = [
    {
      key: "mixed",
      icon: DeleteIcon,
      label: trans("general.waste_types.mixed"),
    },
    {
      key: "organic",
      icon: CompostIcon,
      label: trans("general.waste_types.organic"),
    },
    {
      key: "plastic",
      icon: RecyclingIcon,
      label: trans("general.waste_types.plastic"),
    },
    {
      key: "paper",
      icon: RecyclingIcon,
      label: trans("general.waste_types.paper"),
    },
    {
      key: "glass",
      icon: RecyclingIcon,
      label: trans("general.waste_types.glass"),
    },
  ];

  const renderCards = () => {
    return (
      <div>
        <Grid fluid>
          <Row gutter={24}>
            <Col xs={8}>
              <Flex
                className="hover-box-shadow"
                gap={12}
                column
                color={COLORS.white}
                style={{
                  borderRadius: "8px",
                  padding: "8px",
                  border: `8px solid ${
                    selectedCards.length === 0 ? COLORS.waste : COLORS.white
                  }`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (selectedCards.length === 0) {
                    setSelectedCards([...cards.map((e, i) => i)]);
                  } else {
                    setSelectedCards([]);
                  }
                }}
              >
                <Flex row gap={8} middle>
                  <Icon Element={DeleteIcon} size={24} />
                  <InterTag
                    size={20}
                    text={trans("general.total")}
                    color={COLORS.secondary}
                  />
                </Flex>
                <Flex row gap={4} bottom center>
                  {(() => {
                    const value = kgCalculated["total"];

                    return (
                      <>
                        <InterTag
                          size={36}
                          text={thousandsSeparation(value)}
                          color={COLORS.secondary}
                        />
                        <InterTag
                          size={16}
                          text={"kg"}
                          color={COLORS.secondary}
                        />
                      </>
                    );
                  })()}
                </Flex>
              </Flex>
            </Col>
            {cards.map((elem, i) => {
              const { icon, key, label } = elem;
              const value = kgCalculated[key];
              return (
                <Col xs={8} key={key}>
                  <Flex
                    className="hover-box-shadow"
                    onClick={() => {
                      if (selectedCards.length === 0) setSelectedCards([i]);
                      else {
                        if (selectedCards.includes(i)) {
                          if (selectedCards.length === 1) setSelectedCards([]);
                          else
                            setSelectedCards(
                              selectedCards.filter((v) => v !== i)
                            );
                        } else {
                          setSelectedCards([...selectedCards, i]);
                        }
                      }
                    }}
                    color={COLORS.white}
                    column
                    gap={12}
                    style={{
                      borderRadius: "8px",
                      padding: "8px",
                      border: `8px solid ${
                        selectedCards.includes(i) ? COLORS.waste : COLORS.white
                      }`,
                      cursor: "pointer",
                    }}
                  >
                    <Flex row gap={8} middle>
                      <Icon Element={icon} size={24} />
                      <InterTag
                        size={20}
                        text={label}
                        color={COLORS.secondary}
                      />
                    </Flex>
                    <Flex row bottom gap={4} center>
                      <InterTag
                        size={36}
                        text={thousandsSeparation(value)}
                        color={COLORS.secondary}
                      />
                      <InterTag
                        size={16}
                        text={"kg"}
                        color={COLORS.secondary}
                      />
                    </Flex>
                  </Flex>
                </Col>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  };

  const categories = useMemo(
    () =>
      generateGraphCategories(
        "month",
        1,
        moment().startOf("year"),
        moment().endOf("year")
      ),
    []
  );

  const graph = () => {
    const series =
      selectedCards.length === 0
        ? seriesObj["total"]
        : (() => {
            const series: { name: string; data: number[] }[] = Object.keys(
              manualDataByYear
            ).map((year, i) => {
              let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

              selectedCards.forEach((cardIndex) => {
                const { key } = cards[cardIndex];
                data = add2Arrays(data, seriesObj[key][i].data);
              });

              return { name: year, data };
            });
            return series;
          })();

    return (
      <Flex column gap={12} className="card-m">
        <ApexChart
          {...{
            type: graphType,
            colors: [
              hexLuminance(COLORS.waste, 0.5),
              hexLuminance(COLORS.waste, 0.25),
              COLORS.waste,
              hexLuminance(COLORS.waste, -0.25),
              hexLuminance(COLORS.waste, -0.5),
            ],
            categories: [null, ...categories],
            series,
            yaxis: {
              labels: {
                show: true,
                formatter(val: any, opts: any) {
                  return `${round(val, 2)} kg`;
                },
              },
            },
            xaxis: {
              categories: [null, ...categories],
              labels: {
                show: true,
                formatter(value: any, timestamp: any, opts: any) {
                  if (value === null) return "";
                  return moment(value).format("MMM");
                },
              },
            },

            legend: {
              show: true,
              showForSingleSeries: true,
            },
            chart: {
              events: {
                legendClick(chart, seriesIndex, options) {
                  if (seriesIndex !== undefined) {
                    const year = toNumber(
                      options.globals.seriesNames[seriesIndex]
                    );
                    if (selectedYearsRef.current.includes(year)) {
                      setSelectedYears((prev) => [
                        ...prev.filter((y) => y !== year),
                      ]);
                    } else {
                      setSelectedYears((prev) => sortBy(uniq([...prev, year])));
                    }
                  }
                },
              },
            },
          }}
        />
      </Flex>
    );
  };

  return (
    <Flex column gap={24}>
      {graph()}
      {renderCards()}
    </Flex>
  );
};

export default React.memo(Graph);
