export const privacyPolicies: Record<"en" | "pt", string> = {
  en: `<div style="text-align: center">#Privacy Policy</div>

A Noytrall Technologies, Lda. (doravante referida como "Noytrall") está comprometida com o respeito pela privacidade dos utilizadores, bem como com a proteção e segurança dos seus dados pessoais.

Este texto tem como objetivo informar o utilizador sobre como a Noytrall trata os dados pessoais que são fornecidos através do website www.noytrall.com ou da aplicação web Noytrall.

## 1. Scope and Data Controller

This privacy policy applies to all personal data collected through the website www.noytrall.com ("Website") or the Noytrall web app ("App") operated by Noytrall Technologies, Lda.

Noytrall Technologies, Lda., with registration number 516661574 at the Commercial Registry Office of Coimbra, tax identification number 516661574, headquartered at Rua de São Martinho nº 8, Lourinha de Baixo, 3450-092 Marmeleira, with a share capital of 8,039.22 €, is responsible for processing your personal data.

Before using the Website or App, the user should carefully read this privacy policy and decide freely whether to provide their personal data to Noytrall.

By using the Website or App, the user guarantees that they are of legal age and that the data provided is true, accurate, complete, and current. The user is responsible for any discrepancies in the data provided. If the provided data belongs to a third party, the user guarantees that they have informed the third party about the conditions stated in this document and have obtained their authorization to provide their data to Noytrall for the indicated purposes.

For any questions regarding this privacy policy, you can contact Noytrall through the following contact points:

- Data Protection Officer (DPO): dpo@noytrall.com

## 2. Purposes of Processing and Legal Basis

The personal data collected through the Website and App are intended to inform, respond to, or follow up on user requests, and are processed for the following purposes:

- Handling, directing, and consulting inquiries and/or complaints;
- Contracting services and/or equipment;
- Requesting contact related to any service or equipment;
- Conducting environmental impact assessments;
- Joining partnerships promoted by Noytrall together with its business partners;
- Participating in Noytrall app activities under the gamification program;
- Engaging in campaigns and contests related to advantages and benefits;
- Communicating readings recorded by measurement equipment installed at the consumption site (meter).

The processing of personal data is necessary to perform the above operations in the context of executing the contract between the user and Noytrall, or as part of pre-contractual measures at the user's request.

Joining partnerships promoted by Noytrall, whether through the Website or the Web App, may require sharing and transmitting your personal data to Noytrall's commercial partners. Noytrall will only share your personal data with these partners to the extent strictly necessary and, where applicable, after obtaining your prior consent and acceptance of the specific partnership terms and conditions available at the time of data collection for this purpose.

The data subject can choose whether or not to participate in the gamification program. If you choose to participate, the collected data will be necessary for maintaining participation in the program, accessing gamification levels and experiences, as well as participating in the benefits and advantages provided. Participation in the gamification program is entirely voluntary. If you opt-in, we will collect and process data related to your consumption and its environmental impacts, as well as data on your participation in the activities and proposals of the gamification program. The collected data is necessary for effective participation in the gamification program.

As an interactive program, enabling push notifications allows us to send electronic messages related to the gamification program. If you do not wish to receive push notifications, you may choose not to initially enable them or cancel their delivery upon receiving any communication, though you may lose access to some program functionalities.

If you have not opted into the gamification program, the data necessary for participation will not be processed, and only the data required for other services you have contracted or subscribed to will be retained.

## 3. Recipients

The user's personal data may be disclosed to reputable service providers contracted by Noytrall, who will process the data exclusively for the purposes established by Noytrall and in accordance with the instructions issued by Noytrall. These providers strictly comply with legal regulations on personal data protection, information security, and other applicable standards under the terms of a written agreement entered into between the parties.

Additionally, the user's personal data may be disclosed to Noytrall's business partners for the purpose of joining a partnership, upon the user's request and after obtaining consent for this purpose.

Noytrall may also disclose the user's personal data to third parties when such transmission is necessary to comply with a legal obligation, a decision of the National Data Protection Commission or another relevant supervisory authority, or a court order. Personal data may also be disclosed for any other legitimate purpose provided by law.

## 4. International Data Transfers

Noytrall strives to ensure that the processing of personal data occurs entirely within the European Economic Area (EEA).

Exceptionally, and when strictly necessary for achieving the processing purposes described in this Policy, Noytrall may transfer the personal data collected to third countries or international organizations for which there is an adequacy decision by the European Commission or are subject to appropriate safeguards provided in the law, such as the execution of standard contractual clauses for the transfer of personal data (as per Article 46 of the GDPR). When transferring data to third countries or international organizations based on appropriate safeguards, Noytrall will ensure additional measures to guarantee that the personal data enjoy a level of protection essentially equivalent to that in the European Union.

Additionally, the data collected during your navigation on our website, notably through cookies and other similar technologies, may also be transferred outside the EEA if you consent to the placement of certain categories of cookies (e.g., analytical or advertising cookies). These cookies are placed by third parties who may transfer your data to countries that do not have an adequacy decision from the European Commission and that do not offer a level of personal data protection essentially equivalent to that of the European Union (e.g., the United States of America), as is the case with the web analysis and optimization service “Google Analytics,” provided by Google Ireland Limited. For example, sharing data with service providers outside the EEA (such as Google LLC) may involve disproportionate access to your data by the data importing country’s governmental and intelligence services or the impossibility of effectively exercising your rights. For more information on the use of these cookies, please consult our Cookie Policy.

Should the Data Subject wish to obtain more information about personal data processing carried out outside the EEA, including the safeguards adopted in data transfer, they may contact the data controllers using the contact details provided in this Policy.

## 5. Retention Period

Noytrall will retain users' personal data for the period necessary to fulfill the purposes for which they were collected. In the event of a contract between Noytrall and the user, the data will be retained for the duration of the contract plus the periods of limitation and expiration of the rights arising from it, unless Noytrall is required by law to keep them for a longer period.

Noytrall may also retain the data beyond these periods for statistical purposes, in which case the data should be anonymized.

## 6. User Rights

The user has the right to request from Noytrall access to personal data concerning them, as well as their rectification or erasure. They also have the right to restrict processing concerning the user, the right to object to processing, as well as the right to data portability, in accordance with the laws governing the processing of personal data.

The right to deletion is conditional in cases of personal data necessary for complying with legal obligations.

To exercise the aforementioned rights, the user may contact the data controller using the addresses provided in point no. 1 of this policy. The user also has the right to lodge a complaint with the National Data Protection Commission if they believe their rights regarding their personal data have been violated.

For any questions related to this privacy policy, the user may also contact Noytrall's data protection officer (DPO), whose email address is dpo@noytrall.com.

## 7. Security Measures

Noytrall makes its best efforts to protect users' personal data from unauthorized access through the Internet. For this purpose, it uses security systems, rules, and other procedures to ensure the protection of personal data, as well as to prevent unauthorized access, improper use, disclosure, loss, or destruction.

However, it is the users' responsibility to ensure and verify that the device used to access the Website and the Web App is adequately protected against harmful software, computer viruses, and worms. Users should be aware that without adequate security measures (e.g., secure browser configuration, updated antivirus software, firewall software, and not using software of dubious origin), the risk of personal data and passwords being accessed by unauthorized third parties is increased.

Additionally, if users detect any defect, bug, or anomaly on the Website or App, they must immediately report its existence to Noytrall and refrain from using the aforementioned to access commercial information, personal data of other users, alter the features of the Website or Web App, or, in any other way, exploit the defect, bug, or anomaly to use the digital channels irregularly, unlawfully, or differently from what Noytrall designed.

## 8. Sharing News

Through the Website and the Web App, the User can share relevant news on various social media platforms, including: Facebook, LinkedIn, and X. Noytrall cannot be held responsible for the processing of your personal data carried out by these platforms following your share. Therefore, users should carefully read the Terms and Conditions and Privacy Policies of these platforms before sharing news.

## 9. Cookies

The Website and the App use cookies. To learn more, please visit our Cookie Policy.

## 10. Changes to the Privacy Policy

Noytrall may change this Privacy Policy at any time. When such changes occur, they will be disclosed through the Website and/or the Web App and/or communicated to users via the contact details provided.
`,
  pt: `
<div style="text-align: center">#Política de Privacidade</div>

A Noytrall Technologies, Lda. (adiante “Noytrall”) tem como compromisso o respeito pela privacidade dos utilizadores bem como a proteção e a segurança dos seus dados pessoais.

Este texto pretende informar o utilizador sobre a forma como a Noytrall trata os dados pessoais que lhe são facultados através do website www.noytrall.com ou da web app Noytrall.

## 1. Âmbito de Aplicação e Responsável pelo Tratamento de Dados

Esta política de privacidade aplica-se a todos os dados pessoais recolhidos através do website www.noytrall.com (“Website”) ou da web app Noytrall (“App”) da Noytrall.

A entidade responsável pelo tratamento destes dados é a Noytrall Technologies, Lda., com o número único de matrícula na Conservatória do Registo Comercial de Coimbra e de pessoa coletiva 516661574, com sede na Rua de São Martinho nº 8 Lourinha de Baixo 3450-092 Marmeleira, e com capital social de 8.039,22 €.

O utilizador deve ler com atenção esta política de privacidade e decidir de forma livre se pretende facultar os seus dados pessoais à Noytrall antes de utilizar o Website ou a App.

O utilizador garante que é maior de idade e que os dados comunicados são verdadeiros, exatos, completos e atuais, sendo responsável por qualquer desconformidade. Caso os dados comunicados pertençam a um terceiro, o utilizador garante que informou esse terceiro sobre as condições previstas neste documento e que obteve a sua autorização para facultar os seus dados à Noytrall para as finalidades indicadas.

Pode contactar a Noytrall sobre qualquer questão relacionada com a presente política de privacidade, através dos seguintes pontos de contacto:

- Encarregado de Proteção de Dados (DPO): dpo@noytrall.com

## 2. Finalidades do Tratamento e Fundamento Jurídico

Os dados pessoais recolhidos através do Website e da App destinam-se a permitir informar, dar resposta ou seguimento aos pedidos do utilizador, sendo tratados para as seguintes finalidades:

- Elaborar, dirigir e consultar os pedidos de informação e/ou reclamações;
- Contratar serviços e/ou equipamentos;
- Solicitar o contacto relacionado com algum serviço ou equipamento;
- Realizar avaliações de impacto ambiental;
- Aderir a parcerias promovidas pela Noytrall em conjunto com os seus parceiros comerciais;
- Participar em atividades da app Noytrall no âmbito do programa de gamificação;
- Participar em campanhas e passatempos no âmbito das vantagens e benefícios;
- Comunicação de leituras registadas pelos equipamentos de medição instalados no local de consumo (vulgo contador).

O tratamento de dados pessoais é necessário para efetuar as operações acima referidas no contexto da execução do contrato entre o utilizador e a Noytrall, ou, de diligências pré-contratuais a pedido do utilizador.

A adesão a parcerias promovidas pela Noytrall, quer através do Website quer da Web App, poderá requerer a partilha e transmissão dos seus dados pessoais junto dos parceiros comerciais da Noytrall. A Noytrall apenas irá partilhar os seus dados pessoais com os referidos parceiros na medida do estritamente necessário e, sempre que aplicável, após recolha do seu consentimento prévio e aceitação dos termos e condições de adesão específicos da parceria, disponíveis no momento da recolha de dados pessoais para esta finalidade.

O titular dos dados pode optar pela sua inclusão ou não no programa de gamificação. Caso opte for fazer parte deste programa, os dados recolhidos serão necessários para a manutenção do mesmo, para participação nos níveis do programa de gamificação e acesso às experiências, bem como para a sua participação nas vantagens e benefícios concedidos. A escolha de participação no programa de gamificação é totalmente livre. Caso deseje participar, recolheremos e trataremos dados referentes aos seus consumos e efeitos ambientais dos mesmos, bem como dados sobre a sua participação nas atividades e propostas do programa de gamificação. Os dados recolhidos são necessários à sua participação no programa de gamificação, porquanto possibilitam a participação efetiva no programa.

Sendo um programa interativo, a sua ativação de notificações push permite-nos o envio de mensagens eletrónicas relacionadas com o programa de gamificação. Não desejando receber comunicações push, poderá optar pelo não envio inicial das mesmas ou cancelar esse envio aquando da receção de uma qualquer comunicação, podendo, no entanto, perder o acesso a algumas das funcionalidades do programa de gamificação.

Caso não tenha aderido ao programa de gamificação, os dados necessários à participação no mesmo não serão objeto de tratamento, mantendo-se apenas os dados necessários aos demais serviços que haja contratado ou subscrito.

## 3. Destinatários

Os dados pessoais do utilizador poderão ser comunicados a prestadores de serviços idóneos contratados pela Noytrall, os quais tratarão os dados exclusivamente para as finalidades estabelecidas pela Noytrall, e, em observância das instruções por esta emitidas, cumprindo rigorosamente as normas legais sobre proteção de dados pessoais, segurança da informação e demais normas aplicáveis nos termos de um acordo escrito celebrado entre as partes.

Os dados pessoais do utilizador poderão ser também comunicados a parceiros comerciais da Noytrall para efeitos de adesão a uma parceria, a pedido do utilizador e após recolha de consentimento para esta finalidade.

A Noytrall pode ainda comunicar os dados pessoais do utilizador a terceiros quando a transmissão seja efetuada no âmbito do cumprimento de uma obrigação legal, de uma deliberação da Comissão Nacional de Proteção de Dados ou de outra autoridade de controlo relevante, ou de uma ordem judicial. Podem ainda ser comunicados para qualquer outra finalidade legítima prevista na lei.

## 4. Transferências Internacionais de Dados

A Noytrall procurará assegurar que o tratamento de dados pessoais acontece inteiramente no Espaço Económico Europeu.

Excecionalmente e quando estritamente necessário para a prossecução das finalidades de tratamento descritas nesta Política, a Noytrall poderá transferir os dados pessoais recolhidos para países terceiros ou organizações internacionais para os quais exista uma decisão de adequação da Comissão Europeia ou sujeitos às salvaguardas adequadas previstas na lei, como por exemplo a celebração de cláusulas contratuais-tipo para a transferência de dados pessoais (nos termos do artigo 46.º do RGPD). Quando transfira dados para países terceiros ou organizações internacionais com base em salvaguardas adequadas, a Noytrall assegurará as medidas suplementares para garantir que os dados pessoais gozam de um nível de proteção essencialmente equivalente ao existente na União Europeia.

Adicionalmente, os dados recolhidos durante a sua navegação no nosso website, nomeadamente através de cookies e outras tecnologias similares, podem igualmente ser transferidos para fora do EEE se der o seu consentimento para a colocação de determinadas categorias de cookies (ex. cookies analíticos ou de publicidade). Estes cookies são colocados por entidades terceiras que podem transferir os seus dados para países que não têm uma decisão de adequação da Comissão Europeia e que não oferecem um nível de proteção de dados pessoais essencialmente equivalente ao da União Europeia (ex. Estados Unidos da América), como é o caso do serviço de análise e otimização web “Google Analytics”, fornecido pela Google Ireland Limited. A título de exemplo, a partilha de dados com prestadores de serviços fora do EEE (como é o caso da Google LLC) poderá implicar um acesso desproporcional aos seus dados por parte das autoridades governamentais e de serviços de inteligência do país importador dos dados ou a impossibilidade de exercer de forma eficaz os seus direitos. Para obter mais informação sobre a utilização destes cookies consulte a nossa Política de Cookies.

Caso o Titular dos dados pretenda obter mais informação sobre os tratamentos de dados pessoais efetuados fora do EEE, incluindo sobre as salvaguardas adotadas na transferência dos dados, poderá entrar em contacto com os responsáveis pelo tratamento para os contactos fornecidos nesta Política.

## 5. Prazo de Conservação

A Noytrall conservará os dados pessoais dos utilizadores pelo período de tempo necessário à realização das finalidades para as quais foram recolhidos. No caso de existir um contrato entre a Noytrall e o utilizador, os dados serão conservados durante o prazo de vigência do contrato acrescido dos prazos de prescrição e caducidade dos direitos dele decorrentes, salvo se a Noytrall for obrigada a mantê-los por prazo mais longo por força da lei.

A Noytrall poderá ainda manter os dados para além desses períodos para fins estatísticos, devendo para este efeito anonimizá-los.

## 6. Direitos dos Utilizadores

O utilizador tem o direito de solicitar à Noytrall o acesso aos dados pessoais que lhe digam respeito, bem como a sua retificação ou o seu apagamento. Tem ainda o direito à limitação do tratamento no que disser respeito ao utilizador, ou o direito de se opor ao tratamento, bem como o direito à portabilidade dos dados, nos termos das leis que regem o tratamento de dados pessoais.

O direito de eliminação ficará condicionado nos casos de dados pessoais que são necessários ao cumprimento de obrigações legais.

Para exercício dos direitos acima referidos, o utilizador poderá contactar o responsável pelo tratamento de dados através dos endereços indicados no ponto n.º1 da presente política. O utilizador também tem o direito de apresentar uma reclamação à Comissão Nacional de Proteção de Dados, se entender que os seus direitos sobre os seus dados pessoais foram infringidos.

Para qualquer questão relacionada com a presente política de privacidade, o utilizador poderá ainda contactar o encarregado de proteção de dados (DPO) da Noytrall, cujo endereço de correio eletrónico é dpo@noytrall.com.

## 7. Medidas de Segurança

A Noytrall desenvolve os seus melhores esforços para proteger os dados pessoais dos utilizadores contra acessos não autorizados através da Internet. Para o efeito utiliza sistemas de segurança, regras e outros procedimentos, de modo a garantir a proteção dos dados pessoais, bem como a prevenir o acesso não autorizado aos dados, o uso impróprio, a sua divulgação, perda ou destruição.

É, no entanto, da responsabilidade dos utilizadores, garantir e assegurar que o dispositivo utilizado para aceder ao Website e à Web App se encontra adequadamente protegido contra softwares nocivos, vírus informáticos e worms. Os utilizadores deverão estar cientes que, sem a adoção de medidas de segurança adequadas (por exemplo, a configuração segura do programa de navegação, software antivírus atualizado, software de barreira de segurança e a não utilização de software de origem duvidosa), o risco de os dados pessoais e passwords serem acedidos por terceiros não autorizados, é agravado.

Adicionalmente, caso detete algum defeito, bug ou anomalia no Website ou na App, o utilizador deverá comunicar de imediato a sua existência à Noytrall e não procurar através dos referidos aceder a informação comercial, dados pessoais de outros utilizadores, alterar as características do Website ou da Web App ou, de qualquer outra maneira, explorar o defeito, bug ou anomalia para utilizar os canais digitais de forma irregular, ilícita ou diferente da desenhada pela Noytrall.

## 8. Partilha de Notícias

Através do Website e da Web App, o Utilizador pode partilhar notícias relevantes nas várias plataformas de redes sociais, nomeadamente: Facebook, LinkedIn e X. A Noytrall não se pode responsabilizar pelo tratamento dos seus dados pessoais que seja levado a cabo por estas plataformas na sequência da sua partilha. Deste modo, o utilizador deverá ler com cuidado os Termos e Condições e Políticas de Privacidade destas plataformas antes de partilhar notícias.

## 9. Cookies

O Website e a App utilizam cookies. Para saber mais visite a nossa Política de Cookies.

## 10. Alterações à Política de Privacidade

A Noytrall poderá alterar a presente Política de Privacidade a todo o momento. Quando essas alterações ocorram, serão divulgadas através do Website e/ou da Web App e/ou comunicadas aos utilizadores através dos contactos disponibilizados.
`,
};
