import React from "react";
import { Col, Grid, Row } from "rsuite";
import { ReactComponent as DashboardIcon } from "../../../../assets/icons/dashboard.svg";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import SimpleDateRangePicker from "../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import usePeriodState from "../../../../hooks/usePeriodState";
import CheckInCheckOut from "../checkInCheckOut";
import ConsumptionCards from "./consumptionCards";
import OccupancyCard from "./occupancyCard";

const buildTransKey = (key: string) => `pages.overview.sections.summary.${key}`;

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const Live: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const [period, setPeriod] = usePeriodState();

  const [colBreakpoints, consumptionColBreakpoints] = isMobile
    ? [
        { xs: 24, sm: 24 },
        { xs: 24, sm: 24 },
      ]
    : [
        { sm: 12, xxl: 8 },
        { sm: 8, xxl: 4 },
      ];

  return (
    <div>
      <SecondaryTopBar>
        <Flex row middle gap={8}>
          {dataTypeToggle}
          <SimpleDateRangePicker
            value={period}
            onChange={setPeriod}
            style={{ paddingLeft: "8px" }}
          />
        </Flex>
      </SecondaryTopBar>
      <PageSection
        icon={DashboardIcon}
        title={trans(buildTransKey("title"))}
        description={trans(buildTransKey("description"))}
      >
        <div>
          <Grid fluid>
            <Row gutter={16}>
              <Col {...colBreakpoints}>
                <CheckInCheckOut period={period} />
              </Col>
              <Col {...colBreakpoints}>
                <OccupancyCard period={period} />
              </Col>
            </Row>
            <ConsumptionCards
              colBreakpoints={consumptionColBreakpoints}
              period={period}
            />
          </Grid>
        </div>
      </PageSection>
    </div>
  );
};

export default Live;
