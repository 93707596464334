import React, { useEffect, useState } from "react";
import useWindowSizeState from "../WindowSize/hooks/useWindowSizeState";
import { nContentDimensions } from "./interfaces";

const ContentDimensionsStateContext = React.createContext<
  nContentDimensions.tStateData | undefined
>(undefined);
ContentDimensionsStateContext.displayName = "ContentDimensionsStateContext";

const ContentDimensionsContextProvider: React.FC<
  nContentDimensions.iContextProps
> = ({ children }) => {
  const { isMobile } = useWindowSizeState();
  const [state, setState] = useState<nContentDimensions.tStateData>({
    PageSection: {
      title: 24,
      description: 14,
      icon: 24,
      descriptionLineHeight: 18,
    },
    cardSegment: { mainValue: 36, mainValueUnit: 16, label: 12 },
    segmentation: { label: 14, count: 12 },
  });

  useEffect(() => {
    if (isMobile) {
      setState({
        PageSection: {
          title: 18,
          description: 12,
          icon: 18,
          descriptionLineHeight: 14,
        },
        cardSegment: { mainValue: 24, mainValueUnit: 14, label: 12 },
        segmentation: { label: 12, count: 10 },
      });
    }
  }, [isMobile]);

  return (
    <ContentDimensionsStateContext.Provider value={state}>
      {children}
    </ContentDimensionsStateContext.Provider>
  );
};

export { ContentDimensionsContextProvider, ContentDimensionsStateContext };
