import React, { Fragment, useContext } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import WithContextProvider from "../../../hoc/withProvider";
import SustainabilityContextProvider, {
  SustainabilityContext,
} from "./context";
import ManualData from "./manualData";
import SensorData from "./sensorData";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";

export type tBenchmarkSizes = {
  titleSize: number;
  titleIconSize: number;
  typeSize: number;
  typeIconSize: number;
  mainValueSize: number;
  mainValueUnitSize: number;
  mainValuePadding: number;
  benchmarkSize: number;
};

interface iSustainabilityWrappedProps {}

const SustainabilityWrapped: React.FC<iSustainabilityWrappedProps> = () => {
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const context = useContext(SustainabilityContext);
  const dataTypeState = useDataTypeToggle();
  const { isMobile } = useWindowSizeState();

  const benchmarkSizes: tBenchmarkSizes = isMobile
    ? {
        titleSize: 18,
        titleIconSize: 22,
        typeSize: 12,
        typeIconSize: 22,
        mainValueSize: 30,
        mainValueUnitSize: 14,
        mainValuePadding: 20,
        benchmarkSize: 14,
      }
    : {
        titleSize: 20,
        titleIconSize: 24,
        typeSize: 12,
        typeIconSize: 24,
        mainValueSize: 36,
        mainValueUnitSize: 16,
        mainValuePadding: 40,
        benchmarkSize: 16,
      };

  if (!activeSubscriptionIsEnterprise)
    return <ManualData {...{ benchmarkSizes }} dataTypeToggle={null} />;

  const toggle = (
    <DataTypeToggle
      {...{ dataTypeState }}
      loading={
        dataTypeState.isLoading ||
        Object.values(context.state.dataTypeToggleDisabled).some((v) => v)
      }
    />
  );

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataTypeLive)
    return <SensorData {...{ benchmarkSizes }} dataTypeToggle={toggle} />;
  return <ManualData {...{ benchmarkSizes }} dataTypeToggle={toggle} />;
};

const Sustainability: React.FC = () => {
  return (
    <>
      <TopBar page={"general.sustainability"} />
      <IpValidatorGuard>
        <Fragment>
          <SustainabilityWrapped />
          <PageBottomPadding />
        </Fragment>
      </IpValidatorGuard>
    </>
  );
};

export default WithContextProvider(
  SustainabilityContextProvider,
  Sustainability
);
