/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";

type LayoutType = "total" | "-" | "pager" | "|" | "limit" | "skip";

const usePagination = ({
  total: _total,
  prev: _prev = true,
  next: _next = true,
  first: _first = true,
  last: _last = true,
  limit: _limit,
  activePage: _activePage = 1,
  maxButtons: _maxButtons = 7,
}: {
  total: number;
  prev?: boolean;
  next?: boolean;
  first?: boolean;
  last?: boolean;
  limit: number;
  activePage?: number;
  maxButtons?: number;
}) => {
  const [prev, setPrev] = useState(_prev);
  const [next, setNext] = useState(_next);
  const [first, setFirst] = useState(_first);
  const [last, setLast] = useState(_last);
  const [total, setTotal] = useState(_total);
  const [limit, setLimit] = useState(_limit);
  const [activePage, setActivePage] = useState(_activePage);
  const [maxButtons, setMaxButtons] = useState(_maxButtons);

  return {
    prev,
    next,
    first,
    last,
    total,
    limit,
    activePage,
    maxButtons,
    setTotal,
    setLimit,
    setActivePage,
  };
};

export default usePagination;
