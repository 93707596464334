import { find, toNumber, uniqueId } from "lodash";
import moment from "moment";
import { useEffect, useReducer } from "react";
import {
  Button,
  CheckPicker,
  DateRangePicker,
  IconButton,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Tooltip,
  useToaster,
  Whisper,
} from "rsuite";
import { ReactComponent as AddIcon } from "../../../assets/icons/add.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../assets/icons/arrow_forward.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as StadiaControllerIcon } from "../../../assets/icons/stadia_controller.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSection from "../../../components/PageSection";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { tHotelAwardId, tHotelSpaceId } from "../../../models/hotel";
import {
  HOTEL_CHALLENGE_TYPES,
  tHotelChallenge,
  tHotelChallengeId,
  tHotelChallengeType,
} from "../../../models/hotel/challenges";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { getAwardDescription } from "../../../utils/hotels/awards";
import { COLORS } from "../../../utils/colors";
import { triDevPrd } from "../../../utils/environment";
import { notification } from "../../../utils/notifications";

type tData = {
  type: "" | tHotelChallengeType;
  spaces: tHotelSpaceId[];
  period: [Date, Date] | null;
  awards: { awardId: tHotelAwardId | null; count: number; key: string }[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    type: "",
    spaces: [],
    period: [moment().toDate(), moment().add(1, "day").toDate()],
    awards: [],
  },
  errors: {
    type: "",
    spaces: "",
    period: "",
    awards: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

export type tDataOnSubmit = Pick<
  tHotelChallenge,
  "type" | "from" | "to" | "target" | "awards"
> &
  Partial<Pick<tHotelChallenge, "_id">>;

interface iCreateEditChallengeModalWrappedProps {
  onClose(): void;
  challengeId?: tHotelChallengeId;
  requestStatus: nRequestStatus.tStatus;
  requestError: string | null;
  onRequest(award: tDataOnSubmit): void;
}

const CreateEditChallengeModalWrapped: React.FC<
  iCreateEditChallengeModalWrappedProps
> = ({ onClose, challengeId, requestStatus, onRequest, requestError }) => {
  const toaster = useToaster();
  const { trans, language } = useLocalizationState();
  const { hotel } = useHotelState();

  const availableAwards = hotel.awards.filter((a) => a.active && !a.removed);

  useEffect(() => {
    if (challengeId) {
      const challenge = find(hotel.challenges, (c) => c._id === challengeId);

      if (!challenge) {
        toaster.push(
          notification(
            "error",
            trans(
              "pages.challenges.modals.update_challenge.notifications.challenge_not_found"
            )
          )
        );
        onClose();
        return;
      }

      const { awards, from, target, to, type } = challenge;

      dispatch({
        type: "data",
        data: {
          awards: awards.map(({ awardId, count }) => ({
            awardId,
            count,
            key: uniqueId(),
          })),
          period: [moment(from).toDate(), moment(to).toDate()],
          type,
          spaces: target.spaces,
        },
      });
    }
  }, [challengeId, hotel.challenges, onClose, toaster, trans]);

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleConfirm = () => {
    const { type, period, awards, spaces } = state.data;

    const errors: Partial<tErrors> = {};

    if (type === "") {
      errors["type"] = trans(
        "pages.challenges.modals.create_update_challenge.errors.no_type"
      );
    }

    if (period === null) {
      errors["period"] = trans(
        "pages.challenges.modals.create_update_challenge.errors.no_period"
      );
    }

    const awardsParsed = awards
      .filter(({ awardId, count }) => {
        return awardId && toNumber(count) > 0;
      })
      .map(({ awardId, count }) => {
        const award = find(availableAwards, (a) => a._id === awardId);
        return {
          awardId: awardId!,
          count,
          availableFrom: moment().toDate(),
          availableUntil: moment()
            .add(award?.validityAfterAwarding, "milliseconds")
            .toDate(),
        };
      });

    if (awardsParsed.length === 0) {
      errors["awards"] = trans(
        "pages.challenges.modals.create_update_challenge.errors.no_awards"
      );
    }

    if (Object.keys(errors).length > 0)
      return dispatch({ type: "errors", errors });

    onRequest({
      ...(challengeId ? { _id: challengeId } : {}),
      type: type as tHotelChallengeType,
      from: period![0],
      to: period![1],
      target: { spaces, spaceAggregates: [] },
      awards: awardsParsed,
    });
  };

  const onChange = (key: Extract<keyof tData, "type">) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  const onChangeSpaces = (value: tHotelSpaceId[]) => {
    dispatch({ type: "key value", key: "spaces", value });
  };

  const isLoading = requestStatus === REQUEST_STATUS.PENDING;

  return (
    <Flex column gap={32}>
      <PageSection
        title={{
          text: challengeId
            ? trans("pages.challenges.modals.update_challenge.title")
            : trans("pages.challenges.modals.create_challenge.title"),
        }}
        icon={{ Element: StadiaControllerIcon }}
      />
      <Flex column gap={24}>
        <InputWrapper
          style={{ width: "50%" }}
          label={trans("general.type")}
          error={state.errors.type}
        >
          <SelectPicker
            value={state.data.type}
            onChange={onChange("type")}
            data={HOTEL_CHALLENGE_TYPES.map((t) => ({ label: t, value: t }))}
            searchable={false}
            placeholder={trans(
              "pages.challenges.modals.create_update_challenge.inputs.type.placeholder"
            )}
            disabled={isLoading}
          />
        </InputWrapper>
        <Flex row gap={8}>
          <InputWrapper
            style={{ width: "50%" }}
            label={trans("general.target")}
            error={state.errors.spaces}
          >
            <CheckPicker
              value={state.data.spaces}
              onChange={onChangeSpaces}
              data={hotel.spaces.map((s) => ({ label: s.name, value: s._id }))}
              searchable={false}
              placeholder={trans(
                "pages.challenges.modals.create_update_challenge.inputs.target.placeholder"
              )}
              disabled={isLoading}
            />
          </InputWrapper>
        </Flex>
        <InputWrapper
          label={trans(
            "pages.challenges.modals.create_update_challenge.inputs.period.label"
          )}
          error={state.errors.period}
          style={{ width: "50%" }}
        >
          <DateRangePicker
            cleanable={false}
            disabled={isLoading}
            value={state.data.period}
            onChange={(value) => {
              dispatch({ type: "key value", key: "period", value });
            }}
          />
        </InputWrapper>
        <InputWrapper
          error={state.errors.awards}
          label={trans("general.awards")}
        >
          {state.data.awards.length > 0 && (
            <Flex column gap={8}>
              {state.data.awards.map(({ awardId, count, key }, i) => {
                return (
                  <Flex row gap={16} key={key} middle>
                    <SelectPicker
                      disabled={isLoading}
                      placement={"topStart"}
                      menuMaxHeight={200}
                      style={{ flexBasis: triDevPrd("50%", "85%") }}
                      value={awardId}
                      onChange={(value) => {
                        const awards = state.data.awards;
                        awards[i] = { ...state.data.awards[i], awardId: value };
                        dispatch({
                          type: "key value",
                          key: "awards",
                          value: awards,
                        });
                      }}
                      data={availableAwards.map((a) => {
                        const description = getAwardDescription(a, language);
                        return {
                          label: (
                            <Whisper
                              trigger="hover"
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  {description?.longDescription}
                                </Tooltip>
                              }
                            >
                              <div>{description?.shortDescription}</div>
                            </Whisper>
                          ),
                          value: a._id,
                        };
                      })}
                    />
                    <InputNumber
                      disabled={isLoading}
                      style={{ flexBasis: "10%" }}
                      value={count}
                      min={1}
                      onChange={(value) => {
                        const awards = state.data.awards;
                        awards[i] = {
                          ...state.data.awards[i],
                          count: value ? toNumber(value) : 1,
                        };
                        dispatch({
                          type: "key value",
                          key: "awards",
                          value: awards,
                        });
                      }}
                    />
                    <IconButton
                      disabled={isLoading}
                      circle
                      style={{
                        flexBasis: "5%",
                      }}
                      icon={
                        <Icon
                          Element={DeleteIcon}
                          fill={COLORS.error}
                          size={18}
                        />
                      }
                      onClick={() => {
                        const awards = state.data.awards.filter(
                          (a) => a.key !== key
                        );
                        dispatch({
                          type: "key value",
                          key: "awards",
                          value: awards,
                        });
                      }}
                    />
                  </Flex>
                );
              })}
            </Flex>
          )}
          <Button
            disabled={isLoading}
            style={{ width: "fit-content" }}
            className="button-gray"
            onClick={() =>
              dispatch({
                type: "key value",
                key: "awards",
                value: [
                  ...state.data.awards,
                  { awardId: "", count: 1, key: uniqueId() },
                ],
              })
            }
            appearance="default"
          >
            {trans(
              "pages.challenges.modals.create_update_challenge.add_award_button.label"
            )}
          </Button>
        </InputWrapper>
      </Flex>
      <Modal2ActionButtons
        onClose={onClose}
        onConfirm={handleConfirm}
        disabled={isLoading}
        isLoading={isLoading}
        {...{
          ...(challengeId
            ? {
                confirmLabel: trans(
                  "pages.challenges.modals.update_challenge.confirm_label"
                ),
                confirmIcon: ArrowForwardIcon,
              }
            : {
                confirmLabel: trans(
                  "pages.challenges.modals.create_challenge.confirm_label"
                ),
                confirmIcon: AddIcon,
              }),
        }}
      />
    </Flex>
  );
};

interface iProps
  extends Omit<ModalProps, "onClose">,
    iCreateEditChallengeModalWrappedProps {}

const CreateEditChallengeModal: React.FC<iProps> = ({
  onClose,
  requestStatus,
  requestError,
  onRequest,
  challengeId,
  ...props
}) => {
  return (
    <Modal
      size="md"
      id="CREATE.EDIT.CHALLENGE.MODAL"
      overflow={false}
      {...{ ...props, onClose, keyboard: false, backdrop: "static" }}
    >
      <CreateEditChallengeModalWrapped
        {...{ onClose, challengeId, requestStatus, requestError, onRequest }}
      />
    </Modal>
  );
};

export default CreateEditChallengeModal;
