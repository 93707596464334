import { Tooltip, Whisper } from "rsuite";
import { COLORS } from "../../../../../utils/colors";
import Flex, { iFlexProps } from "../../../../Flex";
import { iTextProps } from "../../../../Text";
import InterTag from "../../../../Text/Inter";
import { baseTextfunction } from "../../utils";
import BaseCell from "../BaseCell";

interface iProps {
  rowData?: Record<string, any>;
  dataKey: string;
  rowIndex?: number;
  textProps?: (
    rowData: any,
    dataKey: string,
    rowIndex: number
  ) => Omit<iTextProps, "text">;
  descriptionProps?: (
    rowData: any,
    dataKey: string,
    rowIndex: number
  ) => Omit<iTextProps, "text">;
  textFunction?(rowData: any, dataKey: string, rowIndex: number): any;
  descriptionFunction?(rowData: any, dataKey: string, rowIndex: number): any;
  containerProps?: Omit<iFlexProps, "children">;
  tooltipDisplay?: boolean;
  tooltipTextFunction?(rowData: any, dataKey: string, rowIndex: number): any;
  [key: string]: any;
}

const SimpleTextCell = ({
  textProps,
  containerProps = {},
  textFunction = baseTextfunction,
  descriptionFunction,
  descriptionProps,
  tooltipDisplay,
  tooltipTextFunction,
  ...props
}: iProps) => {
  const { rowData, dataKey, rowIndex } = props;

  const text = textFunction(rowData, dataKey, rowIndex!);

  const child = (
    <Flex
      column
      gap={4}
      {...{
        ...containerProps,
        style: { ...containerProps.style },
      }}
    >
      <InterTag
        {...{
          text,
          size: 12,
          color: COLORS.secondary,
          ...(textProps ? textProps(rowData, dataKey, rowIndex!) : {}),
        }}
      />
      {descriptionFunction && (
        <InterTag
          {...{
            text: descriptionFunction(rowData, dataKey, rowIndex!),
            size: 12,
            color: COLORS.gray,
            ...(descriptionProps
              ? descriptionProps(rowData, dataKey, rowIndex!)
              : {}),
          }}
        />
      )}
    </Flex>
  );

  return (
    <BaseCell {...{ ...props }}>
      {tooltipDisplay ? (
        <Whisper
          placement="topStart"
          trigger="hover"
          speaker={
            <Tooltip>
              {tooltipTextFunction
                ? tooltipTextFunction(rowData, dataKey, rowIndex!)
                : text}
            </Tooltip>
          }
        >
          {child}
        </Whisper>
      ) : (
        child
      )}
    </BaseCell>
  );
};

export default SimpleTextCell;
