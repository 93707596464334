import React from "react";
import { Modal, ModalProps } from "rsuite";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import Flex from "../../Flex";
import Modal2ActionButtons from "../../Buttons/modal2ActionButtons";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import { COLORS } from "../../../utils/colors";
import { toTextProps } from "../../Text/utils";

interface iConfirmModalWrappedProps
  extends Pick<iProps, "onClose" | "onConfirm" | "description"> {}

const ConfirmModalWrapped: React.FC<iConfirmModalWrappedProps> = ({
  onClose,
  onConfirm,
  description,
}) => {
  const { trans } = useLocalizationState();
  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={20}>
        <InterTag
          {...{
            size: 14,
            color: COLORS.secondary,
            ...toTextProps(description),
          }}
        />
        <Modal2ActionButtons
          confirmLabel={trans("Confirm")}
          onClose={onClose}
          onConfirm={onConfirm}
          closeLabel={trans("Cancel")}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onConfirm(): void;
  description: iTextProps | string;
}

const ConfirmModal: React.FC<iProps> = ({
  onClose,
  onConfirm,
  description,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...props }}>
      <ConfirmModalWrapped {...{ onClose, onConfirm, description }} />
    </Modal>
  );
};

export default ConfirmModal;
