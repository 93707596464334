import { pick } from "lodash";
import useGetRequest from "../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../hooks/useEffectSafe";
import {
  DATA_TYPE_TOGGLE_ARCHIVES,
  DATA_TYPE_TOGGLE_LIVE,
  DATA_TYPE_TOGGLE_LS,
  tDataTypeToggle,
} from "../../utils/dataTypeToggle";

export const useDataTypeToggle = () => {
  const dataTypeState = useGetRequest<{ dataType: tDataTypeToggle | null }>({
    dataType: null,
  });

  useEffectSafe(() => {
    setTimeout(() => {
      const get = () => {
        const stored = localStorage.getItem(DATA_TYPE_TOGGLE_LS);
        switch (stored) {
          case DATA_TYPE_TOGGLE_ARCHIVES:
            return DATA_TYPE_TOGGLE_ARCHIVES;
          case DATA_TYPE_TOGGLE_LIVE:
            return DATA_TYPE_TOGGLE_LIVE;
        }
        return DATA_TYPE_TOGGLE_ARCHIVES;
      };
      const dataType = get();
      dataTypeState.resolve({ dataType });
    }, 200);
  }, []);

  const setDataType = (dataType: tDataTypeToggle) => {
    dataTypeState.resolve({ dataType });
    localStorage.setItem(DATA_TYPE_TOGGLE_LS, dataType);
  };

  return {
    dataType: dataTypeState.data.dataType,
    dataTypeLive: dataTypeState.data.dataType === DATA_TYPE_TOGGLE_LIVE,
    dataTypeArchive: dataTypeState.data.dataType === DATA_TYPE_TOGGLE_ARCHIVES,
    setDataType,
    ...pick(dataTypeState, ["isLoading", "isResolved"]),
  };
};
