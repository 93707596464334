import moment from "moment";
import { useState } from "react";
import { DEFAULT_RANGE } from "../utils/rsuite/dateRange";

const usePeriodState = (initialPeriod: [Date, Date] = DEFAULT_RANGE) => {
  const [period, setPeriod] = useState<[Date, Date]>(
    initialPeriod.map((p) => moment(p).toDate()) as [Date, Date]
  );

  return [period, setPeriod] as [
    period: [Date, Date],
    setPeriod: React.Dispatch<React.SetStateAction<[Date, Date]>>
  ];
};

export default usePeriodState;
