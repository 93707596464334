import React from "react";
import { ButtonProps } from "rsuite";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "../../utils/colors";
import SimpleButton from "../RsuiteWrapper/SimpleButton";

interface iProps {
  onClick: () => void;
}

const AddManualDataButton: React.FC<iProps> = ({ onClick }) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();

  const [size, fontSize, paddingVertical]: [
    ButtonProps["size"],
    number,
    number
  ] = isMobile ? ["sm", 12, 6] : ["md", 14, 8];

  return (
    <SimpleButton
      style={{
        paddingTop: `${paddingVertical}px`,
        paddingBottom: `${paddingVertical}px`,
      }}
      appearance="primary"
      size={size}
      text={{
        text: trans("components.manual_data.global_add_entry.label"),
        color: COLORS.white,
        size: fontSize,
      }}
      onClick={onClick}
    />
  );
};

export default AddManualDataButton;
