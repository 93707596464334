import React from "react";
import { ProfileDispatchContext } from "../";
import { tHotelId } from "../../../models/hotel";
import { tManager } from "../../../models/manager";
import { nProfile } from "../interfaces";

const useProfileDispatch = () => {
  const dispatch = React.useContext(ProfileDispatchContext);

  if (!dispatch)
    throw Error("useProfileDispatch must be used within ProfileStateContext");

  const setProfile = (profile: nProfile.tStateData["profile"]) => {
    dispatch({ type: "set profile", profile });
  };

  const updateProfile = (profile: Partial<tManager>) => {
    dispatch({ type: "update profile", profile });
  };

  const setActiveHotel = (
    activeHotel: tHotelId,
    onlyLocalStorage?: boolean
  ) => {
    dispatch({
      type: "update profile",
      profile: { activeHotel },
      onlyLocalStorage,
    });
  };

  return { setProfile, updateProfile, setActiveHotel };
};

export default useProfileDispatch;
