import { groupBy, has, round, sortBy, toInteger, toNumber, uniq } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown } from "rsuite";
import { ReactComponent as ArrowDropDownIcon } from "../../../../assets/icons/arrow_drop_down.svg";
import { ReactComponent as EnergySavingIcon } from "../../../../assets/icons/energy_program_saving.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import { ReactComponent as InsightsIcon } from "../../../../assets/icons/insights.svg";
import indicatorsPlaceholder from "../../../../assets/placeholders/indicators.png";
import Flex from "../../../../components/Flex";
import ApexChart from "../../../../components/Graphs/Apex/Chart";
import Icon from "../../../../components/Icons/Icon";
import PageSection from "../../../../components/PageSection";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../../components/Text";
import InterTag from "../../../../components/Text/Inter";
import { toTextProps } from "../../../../components/Text/utils";
import UnlockPageSection from "../../../../components/UnlockPageSection";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { tIconElement } from "../../../../interfaces/icon";
import { tHotelManualData } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { generateGraphCategories } from "../../../../utils/graphs";
import { thousandsSeparation } from "../../../../utils/numbers";
import { NOYTRALL_0 } from "../../../../utils/others";

const buildTransKey = (key: string) =>
  `pages.sustainability.sections.indicators_evolution.${key}`;

type tDataAdapter = "total" | "guest" | "accommodation";

interface iIndicatorsWrappedProps extends iProps {}

const IndicatorsWrapped: React.FC<iIndicatorsWrappedProps> = ({
  conversionFactorsRequest,
}) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const { manualDataOrdered } = useHotelManualDataState();
  const [selectedData, setSelectedData] = useState(0);
  const [dataAdapter, setDataAdapter] = useState<tDataAdapter>("total");
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const selectedYearsRef = useRef<number[]>([]);

  const manualDataByYear: Record<number, tHotelManualData[]> = useMemo(() => {
    return groupBy(manualDataOrdered, (mdo) => moment(mdo.from).year());
  }, [manualDataOrdered]);

  useEffect(() => {
    setSelectedYears([
      ...sortBy(
        Object.keys(manualDataByYear).map((y) => toNumber(y)) as number[]
      ),
    ]);
  }, [manualDataByYear]);

  useEffect(() => {
    selectedYearsRef.current = selectedYears;
  }, [selectedYears]);

  const co2_kg_list: number[] = [];
  const co2_kg_byYear: Record<number, number[]> = {};
  let totalCO2_kg = 0;

  const electricity_kWh_list: number[] = [];
  const electricity_kWh_byYear: Record<number, number[]> = {};
  let totalElectricity_kWh = 0;

  const water_m3_list: number[] = [];
  const water_m3_byYear: Record<number, number[]> = {};
  let totalWater_m3 = 0;

  const fuels_kWh_list: number[] = [];
  const fuels_kWh_byYear: Record<number, number[]> = {};
  let totalFuels_kWh = 0;

  const {
    electricity_kWh_to_kgCO2e,
    water_m3_to_kgCO2e,
    natural_gas_kWh_to_kgCO2e,
  } = conversionFactorsRequest.data;

  if (conversionFactorsRequest.isResolved) {
    manualDataOrdered.forEach((md, i) => {
      const { electricity, naturalGas, laundry, water, from } = md;
      const year = moment(from).year();

      const month = moment(from).month();

      const electricity_kWh =
        (electricity?.totalKWh || 0) +
        (laundry?.outsourcedElectricityConsumptionKWh || 0);
      electricity_kWh_list.push(electricity_kWh);

      const water_m3 =
        (water?.totalM3 || 0) + (laundry?.outsourcedWaterConsumptionM3 || 0);
      water_m3_list.push(water_m3);

      const fuels_kWh = naturalGas?.totalKWh || 0;
      fuels_kWh_list.push(fuels_kWh);

      const co2_kg =
        electricity_kWh * electricity_kWh_to_kgCO2e +
        water_m3 * water_m3_to_kgCO2e +
        fuels_kWh * natural_gas_kWh_to_kgCO2e;
      co2_kg_list.push(co2_kg);

      if (selectedYears.includes(year)) {
        totalCO2_kg += co2_kg;
        totalElectricity_kWh += electricity_kWh;
        totalWater_m3 += water_m3;
        totalFuels_kWh += fuels_kWh;
      }

      if (!has(electricity_kWh_byYear, year))
        electricity_kWh_byYear[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      electricity_kWh_byYear[year][month] = electricity_kWh;

      if (!has(water_m3_byYear, year))
        water_m3_byYear[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      water_m3_byYear[year][month] = water_m3;

      if (!has(fuels_kWh_byYear, year))
        fuels_kWh_byYear[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      fuels_kWh_byYear[year][month] = fuels_kWh;

      if (!has(co2_kg_byYear, year))
        co2_kg_byYear[year] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      co2_kg_byYear[year][month] = co2_kg;
    });
  }

  const mainInfo: {
    key: string;
    label: iTextProps | string;
    value: number;
    unit: string;
    byYear: Record<number, number[]>;
    converter: nUnitConverter.tFunction;
    color: string;
  }[] = [
    {
      key: "co2",
      label: {
        text: trans("general.co2_emissions"),
        asHTML: true,
      },
      value: totalCO2_kg,
      unit: "kg",
      converter: convertMassUnit,
      color: COLORS.emissions,
      byYear: co2_kg_byYear,
    },
    {
      key: "electricity",
      label: trans("general.measures_.electricity"),
      value: totalElectricity_kWh,
      unit: "kWh",
      converter: convertEnergyUnit,
      color: COLORS.energy,
      byYear: electricity_kWh_byYear,
    },
    {
      key: "water",
      label: trans("general.measures_.water"),
      value: totalWater_m3,
      unit: "m3",
      converter: convertVolumeUnit,
      color: COLORS.water,
      byYear: water_m3_byYear,
    },
    {
      key: "fuels",
      label: trans("general.fuels"),
      value: totalFuels_kWh,
      unit: "kWh",
      converter: convertEnergyUnit,
      color: COLORS.fuels,
      byYear: fuels_kWh_byYear,
    },
  ];

  const [mainValueSize, mainValueUnitSize] = isMobile ? [26, 14] : [36, 16];

  const renderDataSelections = () => {
    if (conversionFactorsRequest.isLoading) {
      return mainInfo.map(({ label, key }, i) => {
        let labelColor = COLORS.gray_400;

        if (selectedData === i) {
          labelColor = COLORS.primary;
        }

        const textProps = toTextProps(label);

        return (
          <Flex
            column
            gap={6}
            basis={20}
            key={key}
            className="hover-darken-white-bg"
            style={{ padding: "8px", borderRadius: "8px" }}
          >
            <InterTag color={labelColor} size={12} {...{ ...textProps }} />
            <SkeletonText width={100} height={mainValueSize} />
          </Flex>
        );
      });
    }
    if (conversionFactorsRequest.isResolved) {
      return mainInfo.map(({ label, unit, value, converter, key }, i) => {
        let labelColor = COLORS.gray_400;
        let valueColor = COLORS.secondary;

        if (selectedData === i) {
          labelColor = COLORS.primary;
          valueColor = COLORS.primary;
        }

        const converted = converter(value, {
          from: unit,
          forceUnit: null,
        });

        const textProps = toTextProps(label);

        return (
          <Flex
            key={key}
            basis={14}
            onClick={() => {
              if (selectedData !== i) setSelectedData(i);
            }}
            column
            gap={6}
            className="hover-darken-white-bg"
            style={{ padding: "8px", borderRadius: "8px", cursor: "pointer" }}
          >
            <InterTag color={labelColor} size={12} {...{ ...textProps }} />
            <Flex row gap={4} bottom>
              <InterTag
                size={mainValueSize}
                color={valueColor}
                text={thousandsSeparation(round(converted.value, 2))}
              />
              <InterTag
                size={mainValueUnitSize}
                color={valueColor}
                text={converted.unitInHTML}
                asHTML
              />
            </Flex>
          </Flex>
        );
      });
    }
  };

  const renderGraph = () => {
    if (conversionFactorsRequest.isLoading) {
      return <SkeletonText width={"100%"} height={311} />;
    }

    if (conversionFactorsRequest.isResolved) {
      const { byYear } = mainInfo[selectedData];

      const categories = generateGraphCategories(
        "month",
        1,
        moment().startOf("year"),
        moment().endOf("year")
      );

      const valuesAdapter =
        dataAdapter === "accommodation"
          ? (values: number[]) =>
              values.map((v, i) => {
                return manualDataOrdered[i].occupancy?.numberOfStays
                  ? v / manualDataOrdered[i].occupancy!.numberOfStays
                  : 0;
              })
          : dataAdapter === "guest"
          ? (values: number[]) =>
              values.map((v, i) =>
                manualDataOrdered[i].occupancy?.numberOfGuests
                  ? v / manualDataOrdered[i].occupancy!.numberOfGuests
                  : 0
              )
          : (values: number[]) => values;

      const series = Object.keys(manualDataByYear).map((year) => {
        return {
          name: `${year}`,
          data: valuesAdapter(byYear[toInteger(year)]),
        };
      });

      return (
        <ApexChart
          type="bar"
          {...{
            series,
            colors: [
              COLORS.primary_900,
              COLORS.primary_600,
              COLORS.primary_400,
              COLORS.primary_200,
            ],
            yaxis: {
              labels: {
                show: true,
                formatter(val: any, opts: any) {
                  return `${
                    val === 0 ? NOYTRALL_0 : thousandsSeparation(round(val, 2))
                  }`;
                },
              },
            },
            xaxis: {
              categories,
              labels: {
                rotate: 0,
                show: true,
                formatter(value: any, timestamp: any, opts: any) {
                  return moment(value).format("MMM");
                },
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              x: {
                formatter(val: any, opts: any) {
                  const data = manualDataOrdered[opts.dataPointIndex];
                  return `${moment(data.from).format("DD/MMM/YYYY")} - ${moment(
                    data.to
                  ).format("DD/MMM/YYYY")}`;
                },
              },
            },
            legend: { show: true, showForSingleSeries: true },
            chart: {
              events: {
                legendClick(chart, seriesIndex, options) {
                  if (seriesIndex !== undefined) {
                    const year = toNumber(
                      options.globals.seriesNames[seriesIndex]
                    );
                    if (selectedYearsRef.current.includes(year)) {
                      setSelectedYears((prev) => [
                        ...prev.filter((y) => y !== year),
                      ]);
                    } else {
                      setSelectedYears((prev) => sortBy(uniq([...prev, year])));
                    }
                  }
                },
              },
            },
          }}
        />
      );
    }
  };

  if (isMobile)
    return (
      <Flex className="card-m" column>
        <Flex row gap={16} wrap>
          {renderDataSelections()}
        </Flex>
      </Flex>
    );

  return (
    <Flex className="card-m" column>
      <Flex column>
        <Flex right>
          <Dropdown
            placement="leftStart"
            renderToggle={(props, ref) => (
              <Flex
                row
                middle
                gap={4}
                style={{
                  cursor: "pointer",
                  padding: "4px",
                  borderRadius: "8px",
                }}
                className="hover-darken-white-bg"
                {...{ ...props }}
                ref={ref}
              >
                <Icon
                  Element={
                    dataAdapter === "accommodation"
                      ? HotelIcon
                      : dataAdapter === "guest"
                      ? GroupIcon
                      : EnergySavingIcon
                  }
                  fill={COLORS.primary}
                  size={24}
                />
                <InterTag
                  size={16}
                  color={COLORS.secondary}
                  text={
                    dataAdapter === "accommodation"
                      ? trans("Accommodation")
                      : dataAdapter === "guest"
                      ? trans("Guest")
                      : trans("Total")
                  }
                />
                <Icon
                  Element={ArrowDropDownIcon}
                  fill={COLORS.secondary}
                  size={24}
                />
              </Flex>
            )}
          >
            {(
              [
                {
                  label: trans("general.total"),
                  key: "total",
                  icon: EnergySavingIcon,
                },
                {
                  label: trans("general.accommodation"),
                  key: "accommodation",
                  icon: HotelIcon,
                },
                {
                  label: trans("general.guest"),
                  key: "guest",
                  icon: GroupIcon,
                },
              ] as {
                label: string;
                key: tDataAdapter;
                icon: tIconElement;
              }[]
            ).map(({ key, label, icon }) => {
              return (
                <Dropdown.Item
                  key={key}
                  onClick={() => {
                    setDataAdapter(key);
                  }}
                >
                  <Flex row gap={8} middle>
                    <Icon
                      {...{ fill: COLORS.secondary, size: 20, Element: icon }}
                    />
                    {label}
                  </Flex>
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        </Flex>
        <Flex row gap={40}>
          {renderDataSelections()}
        </Flex>
      </Flex>
      {renderGraph()}
    </Flex>
  );
};

interface iProps {
  conversionFactorsRequest: ReturnType<typeof useGetDefaultConverionFactors>;
}

const Indicators: React.FC<iProps> = ({ conversionFactorsRequest }) => {
  const { trans } = useLocalizationState();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans(buildTransKey("title"))}
        description={trans(buildTransKey("description"))}
        icon={InsightsIcon}
      />
      {activeSubscriptionIsStarter ? (
        <UnlockPageSection
          style={{ paddingBottom: "180px" }}
          image={indicatorsPlaceholder}
          title={trans(buildTransKey("unlock.title"))}
          description={[0].map((i) =>
            trans(buildTransKey(`unlock.description[${i}]`))
          )}
        />
      ) : (
        <IndicatorsWrapped {...{ conversionFactorsRequest }} />
      )}
    </Flex>
  );
};

export default Indicators;
