export type tLanguage =
  | "Afar"
  | "Abkhazian"
  | "Afrikaans"
  | "Akan"
  | "Amharic"
  | "Aragonese"
  | "Arabic"
  | "Assamese"
  | "Avar"
  | "Aymara"
  | "Azerbaijani"
  | "Bashkir"
  | "Belarusian"
  | "Bulgarian"
  | "Bihari"
  | "Bislama"
  | "Bambara"
  | "Bengali"
  | "Tibetan"
  | "Breton"
  | "Bosnian"
  | "Catalan"
  | "Chechen"
  | "Chamorro"
  | "Corsican"
  | "Cree"
  | "Czech"
  | "Old Church Slavonic / Old Bulgarian"
  | "Chuvash"
  | "Welsh"
  | "Danish"
  | "German"
  | "Divehi"
  | "Dzongkha"
  | "Ewe"
  | "Greek"
  | "English"
  | "Esperanto"
  | "Spanish"
  | "Estonian"
  | "Basque"
  | "Persian"
  | "Peul"
  | "Finnish"
  | "Fijian"
  | "Faroese"
  | "French"
  | "West Frisian"
  | "Irish"
  | "Scottish Gaelic"
  | "Galician"
  | "Guarani"
  | "Gujarati"
  | "Manx"
  | "Hausa"
  | "Hebrew"
  | "Hindi"
  | "Hiri Motu"
  | "Croatian"
  | "Haitian"
  | "Hungarian"
  | "Armenian"
  | "Herero"
  | "Interlingua"
  | "Indonesian"
  | "Interlingue"
  | "Igbo"
  | "Sichuan Yi"
  | "Inupiak"
  | "Ido"
  | "Icelandic"
  | "Italian"
  | "Inuktitut"
  | "Japanese"
  | "Javanese"
  | "Georgian"
  | "Kongo"
  | "Kikuyu"
  | "Kuanyama"
  | "Kazakh"
  | "Greenlandic"
  | "Cambodian"
  | "Kannada"
  | "Korean"
  | "Kanuri"
  | "Kashmiri"
  | "Kurdish"
  | "Komi"
  | "Cornish"
  | "Kirghiz"
  | "Latin"
  | "Luxembourgish"
  | "Ganda"
  | "Limburgian"
  | "Lingala"
  | "Laotian"
  | "Lithuanian"
  | "Latvian"
  | "Malagasy"
  | "Marshallese"
  | "Maori"
  | "Macedonian"
  | "Malayalam"
  | "Mongolian"
  | "Moldovan"
  | "Marathi"
  | "Malay"
  | "Maltese"
  | "Burmese"
  | "Nauruan"
  | "North Ndebele"
  | "Nepali"
  | "Ndonga"
  | "Dutch"
  | "Norwegian Nynorsk"
  | "Norwegian"
  | "South Ndebele"
  | "Navajo"
  | "Chichewa"
  | "Occitan"
  | "Ojibwa"
  | "Oromo"
  | "Oriya"
  | "Ossetian"
  | "Punjabi"
  | "Pali"
  | "Polish"
  | "Pashto"
  | "Portuguese"
  | "Quechua"
  | "Raeto Romance"
  | "Kirundi"
  | "Romanian"
  | "Russian"
  | "Rwandi"
  | "Sanskrit"
  | "Sardinian"
  | "Sindhi"
  | "Sango"
  | "Serbo-Croatian"
  | "Sinhalese"
  | "Slovak"
  | "Slovenian"
  | "Samoan"
  | "Shona"
  | "Somalia"
  | "Albanian"
  | "Serbian"
  | "Swati"
  | "Southern Sotho"
  | "Sundanese"
  | "Swedish"
  | "Swahili"
  | "Tamil"
  | "Telugu"
  | "Tajik"
  | "Thai"
  | "Tigrinya"
  | "Turkmen"
  | "Tagalog"
  | "Tswana"
  | "Tonga"
  | "Turkish"
  | "Tsonga"
  | "Tatar"
  | "Twi"
  | "Tahitian"
  | "Uyghur"
  | "Ukrainian"
  | "Urdu"
  | "Venda"
  | "Vietnamese"
  | "Volapük"
  | "Walloon"
  | "Wolof"
  | "Xhosa"
  | "Yiddish"
  | "Yoruba"
  | "Zhuang"
  | "Chinese"
  | "Zulu";

export type tLanguageCode =
  | "aa"
  | "ab"
  | "af"
  | "ak"
  | "am"
  | "an"
  | "ar"
  | "as"
  | "av"
  | "ay"
  | "az"
  | "ba"
  | "be"
  | "bg"
  | "bh"
  | "bi"
  | "bm"
  | "bn"
  | "bo"
  | "br"
  | "bs"
  | "ca"
  | "ce"
  | "ch"
  | "co"
  | "cr"
  | "cs"
  | "cu"
  | "cv"
  | "cy"
  | "da"
  | "de"
  | "dv"
  | "dz"
  | "ee"
  | "el"
  | "en"
  | "eo"
  | "es"
  | "et"
  | "eu"
  | "fa"
  | "ff"
  | "fi"
  | "fj"
  | "fo"
  | "fr"
  | "fy"
  | "ga"
  | "gd"
  | "gl"
  | "gn"
  | "gu"
  | "gv"
  | "ha"
  | "he"
  | "hi"
  | "ho"
  | "hr"
  | "ht"
  | "hu"
  | "hy"
  | "hz"
  | "ia"
  | "id"
  | "ie"
  | "ig"
  | "ii"
  | "ik"
  | "io"
  | "is"
  | "it"
  | "iu"
  | "ja"
  | "jv"
  | "ka"
  | "kg"
  | "ki"
  | "kj"
  | "kk"
  | "kl"
  | "km"
  | "kn"
  | "ko"
  | "kr"
  | "ks"
  | "ku"
  | "kv"
  | "kw"
  | "ky"
  | "la"
  | "lb"
  | "lg"
  | "li"
  | "ln"
  | "lo"
  | "lt"
  | "lv"
  | "mg"
  | "mh"
  | "mi"
  | "mk"
  | "ml"
  | "mn"
  | "mo"
  | "mr"
  | "ms"
  | "mt"
  | "my"
  | "na"
  | "nd"
  | "ne"
  | "ng"
  | "nl"
  | "nn"
  | "no"
  | "nr"
  | "nv"
  | "ny"
  | "oc"
  | "oj"
  | "om"
  | "or"
  | "os"
  | "pa"
  | "pi"
  | "pl"
  | "ps"
  | "pt"
  | "qu"
  | "rm"
  | "rn"
  | "ro"
  | "ru"
  | "rw"
  | "sa"
  | "sc"
  | "sd"
  | "sg"
  | "sh"
  | "si"
  | "sk"
  | "sl"
  | "sm"
  | "sn"
  | "so"
  | "sq"
  | "sr"
  | "ss"
  | "st"
  | "su"
  | "sv"
  | "sw"
  | "ta"
  | "te"
  | "tg"
  | "th"
  | "ti"
  | "tk"
  | "tl"
  | "tn"
  | "to"
  | "tr"
  | "ts"
  | "tt"
  | "tw"
  | "ty"
  | "ug"
  | "uk"
  | "ur"
  | "ve"
  | "vi"
  | "vo"
  | "wa"
  | "wo"
  | "xh"
  | "yi"
  | "yo"
  | "za"
  | "zh"
  | "zu";

export type tLangaugeItem = { language: tLanguage; code: tLanguageCode };

export const LANGAUGES = [
  { language: "Afar", code: "aa" },
  { language: "Abkhazian", code: "ab" },
  { language: "Afrikaans", code: "af" },
  { language: "Akan", code: "ak" },
  { language: "Amharic", code: "am" },
  { language: "Aragonese", code: "an" },
  { language: "Arabic", code: "ar" },
  { language: "Assamese", code: "as" },
  { language: "Avar", code: "av" },
  { language: "Aymara", code: "ay" },
  { language: "Azerbaijani", code: "az" },
  { language: "Bashkir", code: "ba" },
  { language: "Belarusian", code: "be" },
  { language: "Bulgarian", code: "bg" },
  { language: "Bihari", code: "bh" },
  { language: "Bislama", code: "bi" },
  { language: "Bambara", code: "bm" },
  { language: "Bengali", code: "bn" },
  { language: "Tibetan", code: "bo" },
  { language: "Breton", code: "br" },
  { language: "Bosnian", code: "bs" },
  { language: "Catalan", code: "ca" },
  { language: "Chechen", code: "ce" },
  { language: "Chamorro", code: "ch" },
  { language: "Corsican", code: "co" },
  { language: "Cree", code: "cr" },
  { language: "Czech", code: "cs" },
  { language: "Old Church Slavonic / Old Bulgarian", code: "cu" },
  { language: "Chuvash", code: "cv" },
  { language: "Welsh", code: "cy" },
  { language: "Danish", code: "da" },
  { language: "German", code: "de" },
  { language: "Divehi", code: "dv" },
  { language: "Dzongkha", code: "dz" },
  { language: "Ewe", code: "ee" },
  { language: "Greek", code: "el" },
  { language: "English", code: "en" },
  { language: "Esperanto", code: "eo" },
  { language: "Spanish", code: "es" },
  { language: "Estonian", code: "et" },
  { language: "Basque", code: "eu" },
  { language: "Persian", code: "fa" },
  { language: "Peul", code: "ff" },
  { language: "Finnish", code: "fi" },
  { language: "Fijian", code: "fj" },
  { language: "Faroese", code: "fo" },
  { language: "French", code: "fr" },
  { language: "West Frisian", code: "fy" },
  { language: "Irish", code: "ga" },
  { language: "Scottish Gaelic", code: "gd" },
  { language: "Galician", code: "gl" },
  { language: "Guarani", code: "gn" },
  { language: "Gujarati", code: "gu" },
  { language: "Manx", code: "gv" },
  { language: "Hausa", code: "ha" },
  { language: "Hebrew", code: "he" },
  { language: "Hindi", code: "hi" },
  { language: "Hiri Motu", code: "ho" },
  { language: "Croatian", code: "hr" },
  { language: "Haitian", code: "ht" },
  { language: "Hungarian", code: "hu" },
  { language: "Armenian", code: "hy" },
  { language: "Herero", code: "hz" },
  { language: "Interlingua", code: "ia" },
  { language: "Indonesian", code: "id" },
  { language: "Interlingue", code: "ie" },
  { language: "Igbo", code: "ig" },
  { language: "Sichuan Yi", code: "ii" },
  { language: "Inupiak", code: "ik" },
  { language: "Ido", code: "io" },
  { language: "Icelandic", code: "is" },
  { language: "Italian", code: "it" },
  { language: "Inuktitut", code: "iu" },
  { language: "Japanese", code: "ja" },
  { language: "Javanese", code: "jv" },
  { language: "Georgian", code: "ka" },
  { language: "Kongo", code: "kg" },
  { language: "Kikuyu", code: "ki" },
  { language: "Kuanyama", code: "kj" },
  { language: "Kazakh", code: "kk" },
  { language: "Greenlandic", code: "kl" },
  { language: "Cambodian", code: "km" },
  { language: "Kannada", code: "kn" },
  { language: "Korean", code: "ko" },
  { language: "Kanuri", code: "kr" },
  { language: "Kashmiri", code: "ks" },
  { language: "Kurdish", code: "ku" },
  { language: "Komi", code: "kv" },
  { language: "Cornish", code: "kw" },
  { language: "Kirghiz", code: "ky" },
  { language: "Latin", code: "la" },
  { language: "Luxembourgish", code: "lb" },
  { language: "Ganda", code: "lg" },
  { language: "Limburgian", code: "li" },
  { language: "Lingala", code: "ln" },
  { language: "Laotian", code: "lo" },
  { language: "Lithuanian", code: "lt" },
  { language: "Latvian", code: "lv" },
  { language: "Malagasy", code: "mg" },
  { language: "Marshallese", code: "mh" },
  { language: "Maori", code: "mi" },
  { language: "Macedonian", code: "mk" },
  { language: "Malayalam", code: "ml" },
  { language: "Mongolian", code: "mn" },
  { language: "Moldovan", code: "mo" },
  { language: "Marathi", code: "mr" },
  { language: "Malay", code: "ms" },
  { language: "Maltese", code: "mt" },
  { language: "Burmese", code: "my" },
  { language: "Nauruan", code: "na" },
  { language: "North Ndebele", code: "nd" },
  { language: "Nepali", code: "ne" },
  { language: "Ndonga", code: "ng" },
  { language: "Dutch", code: "nl" },
  { language: "Norwegian Nynorsk", code: "nn" },
  { language: "Norwegian", code: "no" },
  { language: "South Ndebele", code: "nr" },
  { language: "Navajo", code: "nv" },
  { language: "Chichewa", code: "ny" },
  { language: "Occitan", code: "oc" },
  { language: "Ojibwa", code: "oj" },
  { language: "Oromo", code: "om" },
  { language: "Oriya", code: "or" },
  { language: "Ossetian", code: "os" },
  { language: "Punjabi", code: "pa" },
  { language: "Pali", code: "pi" },
  { language: "Polish", code: "pl" },
  { language: "Pashto", code: "ps" },
  { language: "Portuguese", code: "pt" },
  { language: "Quechua", code: "qu" },
  { language: "Raeto Romance", code: "rm" },
  { language: "Kirundi", code: "rn" },
  { language: "Romanian", code: "ro" },
  { language: "Russian", code: "ru" },
  { language: "Rwandi", code: "rw" },
  { language: "Sanskrit", code: "sa" },
  { language: "Sardinian", code: "sc" },
  { language: "Sindhi", code: "sd" },
  { language: "Sango", code: "sg" },
  { language: "Serbo-Croatian", code: "sh" },
  { language: "Sinhalese", code: "si" },
  { language: "Slovak", code: "sk" },
  { language: "Slovenian", code: "sl" },
  { language: "Samoan", code: "sm" },
  { language: "Shona", code: "sn" },
  { language: "Somalia", code: "so" },
  { language: "Albanian", code: "sq" },
  { language: "Serbian", code: "sr" },
  { language: "Swati", code: "ss" },
  { language: "Southern Sotho", code: "st" },
  { language: "Sundanese", code: "su" },
  { language: "Swedish", code: "sv" },
  { language: "Swahili", code: "sw" },
  { language: "Tamil", code: "ta" },
  { language: "Telugu", code: "te" },
  { language: "Tajik", code: "tg" },
  { language: "Thai", code: "th" },
  { language: "Tigrinya", code: "ti" },
  { language: "Turkmen", code: "tk" },
  { language: "Tagalog", code: "tl" },
  { language: "Tswana", code: "tn" },
  { language: "Tonga", code: "to" },
  { language: "Turkish", code: "tr" },
  { language: "Tsonga", code: "ts" },
  { language: "Tatar", code: "tt" },
  { language: "Twi", code: "tw" },
  { language: "Tahitian", code: "ty" },
  { language: "Uyghur", code: "ug" },
  { language: "Ukrainian", code: "uk" },
  { language: "Urdu", code: "ur" },
  { language: "Venda", code: "ve" },
  { language: "Vietnamese", code: "vi" },
  { language: "Volapük", code: "vo" },
  { language: "Walloon", code: "wa" },
  { language: "Wolof", code: "wo" },
  { language: "Xhosa", code: "xh" },
  { language: "Yiddish", code: "yi" },
  { language: "Yoruba", code: "yo" },
  { language: "Zhuang", code: "za" },
  { language: "Chinese", code: "zh" },
  { language: "Zulu", code: "zu" },
];
