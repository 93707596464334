import { first, last, max, round } from "lodash";
import moment, { MomentInput } from "moment";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ButtonGroup, ButtonProps, Col, Grid, Row } from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg";
import { ReactComponent as EcoIcon } from "../../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as GroupIcon } from "../../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../../assets/icons/hotel.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../../../assets/icons/meeting_room.svg";
import { ReactComponent as ModeHeatIcon } from "../../../../../assets/icons/mode_heat.svg";
import { ReactComponent as ReadinessScoreIcon } from "../../../../../assets/icons/readiness_score.svg";
import { ReactComponent as RecyclingIcon } from "../../../../../assets/icons/recycling.svg";
import { ReactComponent as WaterDropIcon } from "../../../../../assets/icons/water_drop.svg";
import StraightComparison from "../../../../../components/Comparions/straightComparison";
import Flex from "../../../../../components/Flex";
import Icon from "../../../../../components/Icons/Icon";
import ManualDataModal, {
  iManualDataModalProps,
} from "../../../../../components/Modals/ManualDataModal";
import PageSection from "../../../../../components/PageSection";
import SimpleButton from "../../../../../components/RsuiteWrapper/SimpleButton";
import SimpleDateRangePicker from "../../../../../components/RsuiteWrapper/SimpleDateRangePicker";
import SkeletonText from "../../../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../../../components/Text";
import InterTag from "../../../../../components/Text/Inter";
import { toTextProps } from "../../../../../components/Text/utils";
import useHotelManualDataState from "../../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useHotelSubscriptionState from "../../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../../context/WindowSize/hooks/useWindowSizeState";
import { useGetBenchmarkData } from "../../../../../hooks/useGetBenchmarkData";
import useGetDefaultConverionFactors from "../../../../../hooks/useGetDefaultConverionFactors";
import { tIcon } from "../../../../../interfaces/icon";
import { tHotelManualData } from "../../../../../models/hotel";
import { COLORS } from "../../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../../utils/convertUnits/interfaces";
import { thousandsSeparation } from "../../../../../utils/numbers";
import { renderRequestedData } from "../../../../../utils/render";
import { SustainabilityContext } from "../../context";
import { tBenchmarkSizes } from "../../sustainability";

interface iMobileSlidesProps {
  perAccommodation: JSX.Element;
  perGuest: JSX.Element;
  energyPerArea: JSX.Element;
  recycling: JSX.Element;
  renewableEnergy: JSX.Element;
}

const MobileSlides: React.FC<iMobileSlidesProps> = ({
  energyPerArea,
  recycling,
  renewableEnergy,
  perAccommodation,
  perGuest,
}) => {
  const { trans } = useLocalizationState();
  const [index, setIndex] = useState(0);

  const segments = [
    {
      key: "accommodation",
      transKey: "general.accommodations",
      jsx: perAccommodation,
    },
    { key: "guests", transKey: "general.guests", jsx: perGuest },
    {
      key: "more",
      transKey: "general.more",
      jsx: (
        <Flex column gap={8}>
          {energyPerArea}
          {renewableEnergy}
          {recycling}
        </Flex>
      ),
    },
  ];

  const { jsx } = segments[index];

  return (
    <Flex column gap={8}>
      <ButtonGroup>
        {segments.map(({ key, transKey }, i) => {
          const [appearance, color, onClick]: [
            ButtonProps["appearance"],
            string,
            undefined | (() => void)
          ] =
            index === i
              ? ["primary", COLORS.white, undefined]
              : ["ghost", COLORS.primary, () => setIndex(i)];
          return (
            <SimpleButton
              {...{ key, appearance, onClick }}
              size="sm"
              text={{ text: trans(transKey), color }}
            />
          );
        })}
      </ButtonGroup>
      {jsx}
    </Flex>
  );
};

interface iBenchmarkWrapped2Props extends iProps {
  manualDataIndex: number;
}

const BenchmarkWrapped2: React.FC<iBenchmarkWrapped2Props> = ({
  manualDataIndex,
  conversionFactorsRequest,
  benchmarkSizes,
}) => {
  const { isMobile, isDesktop } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const [manualDataModal, setManualDataModal] = useState<{
    open: boolean;
    manualDataId?: tHotelManualData["_id"];
    initialStep?: iManualDataModalProps["initialStep"];
  }>({
    open: false,
    manualDataId: undefined,
    initialStep: undefined,
  });
  const benchmarkRequest = useGetBenchmarkData();
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const { hotel, getHotelDimensionsInTimeframe } = useHotelManualDataState();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  const manualData = hotel.manualData[manualDataIndex];

  const hotelDimensions = getHotelDimensionsInTimeframe(
    (moment(manualData.from).valueOf() + moment(manualData.to).valueOf()) / 2
  );

  useEffect(() => {
    setDataTypeToggleDisabled({ benchmarkRequest: benchmarkRequest.isLoading });
  }, [benchmarkRequest.isLoading, setDataTypeToggleDisabled]);

  const {
    titleSize,
    titleIconSize,
    typeSize,
    typeIconSize,
    mainValueSize,
    mainValueUnitSize,
    mainValuePadding,
    benchmarkSize,
  } = benchmarkSizes;

  const renderCell = (
    icon: tIcon,
    label: string | iTextProps,
    main: { value: number; unit: string; loading?: boolean },
    homologue: { value: number; unit: string },
    converter: nUnitConverter.tFunction,
    benchmark?: { value: number; unit: string; loading?: boolean }
  ) => {
    const renderMainValue = () => {
      if (main.loading)
        return <SkeletonText width={100} height={mainValueSize} />;
      const converted = converter(main.value, {
        from: main.unit,
        forceUnit: null,
      });
      return (
        <Flex row gap={4} bottom>
          <InterTag
            size={mainValueSize}
            color={COLORS.secondary}
            text={thousandsSeparation(round(converted.value, 2).toFixed(2))}
          />
          <InterTag
            size={mainValueUnitSize}
            color={COLORS.secondary}
            text={converted.unitInHTML}
            asHTML
          />
        </Flex>
      );
    };

    const renderBenchmark = () => {
      if (!benchmark) return null;

      if (benchmarkRequest.isLoading)
        return <SkeletonText height={benchmarkSize} width={50} />;

      if (benchmarkRequest.isResolved) {
        const converted = converter(main.value, {
          from: main.unit,
          forceUnit: null,
        });
        const benchmarkConverted = converter(benchmark.value, {
          from: benchmark.unit,
          forceUnit: converted.unit,
        });

        return (
          <InterTag
            size={benchmarkSize}
            color={COLORS.secondary}
            text={`${thousandsSeparation(
              round(benchmarkConverted.value, 2).toFixed(2)
            )} ${benchmarkConverted.unitInHTML}`}
            asHTML
          />
        );
      }
    };
    const renderBenchmarkComparison = () => {
      if (!benchmark) return null;

      if (benchmarkRequest.isLoading || benchmark.loading)
        return <SkeletonText height={mainValueSize} width={100} />;

      if (benchmarkRequest.isResolved)
        return (
          <StraightComparison
            valueSize={mainValueSize}
            unitSize={mainValueUnitSize}
            value={main.value}
            baseValue={benchmark.value}
          />
        );
    };

    return (
      <Flex gap={10} column color={COLORS.white}>
        <Flex row gap={4} middle>
          <Icon {...{ size: typeIconSize, ...icon }} />
          <InterTag
            size={typeSize}
            color={COLORS.gray}
            {...{ ...toTextProps(label) }}
          />
        </Flex>
        <Flex row>
          <Flex one left>
            <div style={{ paddingLeft: `${mainValuePadding}px` }}>
              {renderMainValue()}
            </div>
          </Flex>
          <Flex one right>
            <div style={{ paddingRight: `${mainValuePadding}px` }}>
              {renderBenchmarkComparison()}
            </div>
          </Flex>
        </Flex>
        <Flex row>
          <Flex one>
            <div></div>
          </Flex>
          <Flex one right>
            <div style={{ paddingRight: `${mainValuePadding}px` }}>
              {renderBenchmark()}
            </div>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderDivider = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_200,
        }}
      />
    );
  };

  const realElectricity_kWh =
    (manualData.electricity?.totalKWh || 0) +
    (manualData.laundry?.outsourcedElectricityConsumptionKWh || 0);

  const realWater_m3 =
    (manualData.water?.totalM3 || 0) +
    (manualData.laundry?.outsourcedWaterConsumptionM3 || 0);

  const realFuel_kWh = manualData.naturalGas?.totalKWh || 0;
  const realWaste_kg = manualData.waste
    ? manualData.waste.glass.totalKg +
      manualData.waste.mixed.totalKg +
      manualData.waste.organic.totalKg +
      manualData.waste.paper.totalKg +
      manualData.waste.plastic.totalKg
    : 0;
  const realRecyclableWaste_kg = manualData.waste
    ? manualData.waste.glass.totalKg +
      manualData.waste.paper.totalKg +
      manualData.waste.plastic.totalKg
    : 0;

  const realCO2_kg =
    realElectricity_kWh *
      conversionFactorsRequest.data.electricity_kWh_to_kgCO2e +
    realWater_m3 * conversionFactorsRequest.data.water_m3_to_kgCO2e +
    realFuel_kWh * conversionFactorsRequest.data.natural_gas_kWh_to_kgCO2e;

  const energyPerAreaCard = (
    <Flex column gap={16} basis={50} className="card-sm">
      <Flex row gap={4} middle>
        <Icon
          Element={MeetingRoomIcon}
          size={titleIconSize}
          fill={COLORS.secondary}
        />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.energyPerArea.title"
          )}
          asHTML
        />
      </Flex>
      {hotelDimensions && hotelDimensions.totalAreaM2 > 0 ? (
        <>
          {(() => {
            const value = realElectricity_kWh / hotelDimensions.totalAreaM2;
            const converted = convertEnergyUnit(value, { from: "kWh" });

            return (
              <Flex row>
                <Flex one left>
                  <Flex
                    row
                    bottom
                    gap={4}
                    style={{ paddingLeft: `${mainValuePadding}px` }}
                  >
                    <InterTag
                      size={mainValueSize}
                      color={COLORS.secondary}
                      text={thousandsSeparation(round(converted.value, 2))}
                    />
                    <InterTag
                      size={mainValueUnitSize}
                      color={COLORS.secondary}
                      text={`${converted.unitInHTML}/m<sup>2</sup>`}
                      asHTML
                    />
                  </Flex>
                </Flex>
                {benchmarkRequest.isLoading ? (
                  <SkeletonText width={150} height={mainValueSize} />
                ) : (
                  <Flex
                    one
                    right
                    style={{ paddingRight: `${mainValuePadding}px` }}
                  >
                    <StraightComparison
                      valueSize={mainValueSize}
                      unitSize={mainValueUnitSize}
                      baseValue={benchmarkRequest.data.perM2.electricity_kWh}
                      value={value}
                    />
                  </Flex>
                )}
              </Flex>
            );
          })()}
          <Flex row between>
            <Flex one left>
              <div style={{ paddingLeft: `${mainValuePadding}px` }}></div>
            </Flex>
            <Flex one right>
              <div style={{ paddingRight: `${mainValuePadding}px` }}>
                {renderRequestedData(benchmarkRequest.status, {
                  pending: () => (
                    <SkeletonText width={90} height={benchmarkSize} />
                  ),
                  resolved: () => (
                    <InterTag
                      size={benchmarkSize}
                      color={COLORS.secondary}
                      text={`${thousandsSeparation(
                        benchmarkRequest.data.perM2.electricity_kWh
                      )} kWh/m<sup>2</sup>`}
                      asHTML
                    />
                  ),
                })}
              </div>
            </Flex>
          </Flex>
        </>
      ) : (
        <InterTag
          hoverUnderline
          size={14}
          color={COLORS.primary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.energyPerArea.noData"
          )}
          onClick={() =>
            setManualDataModal({
              open: true,
              manualDataId: manualData._id,
              initialStep: "space",
            })
          }
        />
      )}
    </Flex>
  );

  const renewableEnergyValue = manualData.electricity?.isElectricityGreen
    ? 100
    : 0;

  const renewableEnergyCard = (
    <Flex column gap={16} basis={50} className="card-sm">
      <Flex row gap={4} middle>
        <Icon
          Element={ElectricBoltIcon}
          size={titleIconSize}
          fill={COLORS.primary}
        />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.renewableEnergy.title"
          )}
          asHTML
        />
      </Flex>
      <Flex row>
        <Flex one left>
          <Flex
            row
            gap={4}
            bottom
            style={{ paddingLeft: `${mainValuePadding}px` }}
          >
            <InterTag
              size={mainValueSize}
              text={renewableEnergyValue}
              color={COLORS.secondary}
            />
            <InterTag
              size={mainValueUnitSize}
              text={"%"}
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
        <Flex one right>
          <div style={{ paddingRight: `${mainValuePadding}px` }}>
            <StraightComparison
              valueSize={mainValueSize}
              unitSize={mainValueUnitSize}
              moreIsGood
              value={renewableEnergyValue}
              baseValue={100}
            />
          </div>
        </Flex>
      </Flex>
      <Flex row gap={40}>
        <Flex one left>
          <div style={{ paddingLeft: `${mainValuePadding}px` }}></div>
        </Flex>
        <Flex one right>
          <div style={{ paddingRight: `${mainValuePadding}px` }}>
            <InterTag
              size={benchmarkSize}
              color={COLORS.secondary}
              text={trans("100 %")}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );

  const recyclingCard = (
    <Flex column gap={16} basis={50} className="card-sm">
      <Flex row gap={4} middle>
        <Icon
          Element={RecyclingIcon}
          size={titleIconSize}
          fill={COLORS.primary}
        />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.recycledWaste.title"
          )}
          asHTML
        />
      </Flex>
      <Flex row>
        <Flex one left>
          <Flex
            row
            gap={4}
            bottom
            style={{ paddingLeft: `${mainValuePadding}px` }}
          >
            <InterTag
              size={mainValueSize}
              text={
                realWaste_kg
                  ? round((realRecyclableWaste_kg / realWaste_kg) * 100, 2)
                  : 0
              }
              color={COLORS.secondary}
            />
            <InterTag
              size={mainValueUnitSize}
              text={"kg"}
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
        <Flex row right>
          <div style={{ paddingRight: `${mainValuePadding}px` }}>
            <StraightComparison
              valueSize={mainValueSize}
              unitSize={mainValueUnitSize}
              moreIsGood
              value={
                realWaste_kg
                  ? round((realRecyclableWaste_kg / realWaste_kg) * 100, 2)
                  : 0
              }
              baseValue={84}
            />
          </div>
        </Flex>
      </Flex>
      <Flex row>
        <Flex one left>
          <div style={{ paddingLeft: `${mainValuePadding}px` }}></div>
        </Flex>
        <Flex one right>
          <div style={{ paddingRight: `${mainValuePadding}px` }}>
            <InterTag
              size={benchmarkSize}
              color={COLORS.secondary}
              text={trans("84 %")}
            />
          </div>
        </Flex>
      </Flex>
    </Flex>
  );

  const onlyAvailableOnBusiness = (icon: tIcon, label: string) => {
    return (
      <Flex column gap={16}>
        <Flex row gap={4} middle>
          <Icon size={24} fill={COLORS.secondary} {...{ ...icon }} />
          <InterTag size={12} color={COLORS.gray} text={label} asHTML />
        </Flex>
        <InterTag text={trans("general.business only")} />
      </Flex>
    );
  };

  const perAccommodationCard = (
    <Flex one column className="card-sm" gap={16}>
      <Flex row gap={4} middle>
        <Icon
          Element={HotelIcon}
          fill={COLORS.secondary}
          size={titleIconSize}
        />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={`${trans(
            "pages.sustainability.sections.indicators.cards.accommodation.title"
          )} (${manualData.occupancy?.numberOfStays || 0})`}
        />
      </Flex>
      {manualData.occupancy?.numberOfStays ? (
        <>
          {renderCell(
            { Element: EcoIcon, fill: COLORS.emissions },
            { text: trans("general.co2_emissions"), asHTML: true },
            {
              value: realCO2_kg / manualData.occupancy.numberOfStays,
              unit: "kg",
              loading: conversionFactorsRequest.isLoading,
            },
            { value: 0, unit: "" },
            convertMassUnit,
            {
              value: benchmarkRequest.data.perAccommodation.co2_kg,
              unit: "kg",
              loading: conversionFactorsRequest.isLoading,
            }
          )}
          {renderDivider()}
          {renderCell(
            { Element: ElectricBoltIcon, fill: COLORS.energy },
            trans("general.measures_.electricity"),
            {
              value: realElectricity_kWh / manualData.occupancy.numberOfStays,
              unit: "kWh",
            },
            { value: 0, unit: "" },
            convertEnergyUnit,
            {
              value: benchmarkRequest.data.perAccommodation.electricity_kWh,
              unit: "kWh",
            }
          )}
          {renderDivider()}
          {renderCell(
            { Element: WaterDropIcon, fill: COLORS.water },
            trans("general.measures_.water"),
            {
              value: realWater_m3 / manualData.occupancy.numberOfStays,
              unit: "m3",
            },
            { value: 0, unit: "" },
            convertVolumeUnit,
            {
              value: benchmarkRequest.data.perAccommodation.water_m3,
              unit: "m3",
            }
          )}
          {renderDivider()}
          {activeSubscriptionIsStarter
            ? onlyAvailableOnBusiness(
                { Element: ModeHeatIcon, fill: COLORS.fuels },
                trans("general.fuels")
              )
            : renderCell(
                { Element: ModeHeatIcon, fill: COLORS.fuels },
                trans("general.fuels"),
                {
                  value: realFuel_kWh / manualData.occupancy.numberOfStays,
                  unit: "kWh",
                },
                { value: 0, unit: "" },
                convertEnergyUnit,
                {
                  value: benchmarkRequest.data.perAccommodation.fuels_kWh,
                  unit: "kWh",
                }
              )}
          {renderDivider()}
          {activeSubscriptionIsStarter
            ? onlyAvailableOnBusiness(
                { Element: DeleteIcon, fill: COLORS.waste },
                trans("general.waste")
              )
            : renderCell(
                { Element: DeleteIcon, fill: COLORS.waste },
                trans("general.waste"),
                {
                  value: realWaste_kg / manualData.occupancy.numberOfStays,
                  unit: "kg",
                },
                { value: 0, unit: "" },
                convertMassUnit,
                {
                  value: benchmarkRequest.data.perAccommodation.waste_kg,
                  unit: "kg",
                }
              )}
        </>
      ) : null}
    </Flex>
  );

  const perGuestCard = (
    <Flex
      one
      column
      color={COLORS.white}
      style={{ padding: 16, borderRadius: "8px" }}
      gap={16}
    >
      <Flex row gap={4} middle>
        <Icon
          Element={GroupIcon}
          fill={COLORS.secondary}
          size={titleIconSize}
        />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={`${trans(
            "pages.sustainability.sections.indicators.cards.guest.title"
          )} (${manualData.occupancy?.numberOfGuests || 0})`}
        />
      </Flex>
      {manualData.occupancy?.numberOfGuests ? (
        <>
          {renderCell(
            { Element: EcoIcon, fill: COLORS.emissions },
            { text: trans("general.co2_emissions"), asHTML: true },
            {
              value: realCO2_kg / manualData.occupancy.numberOfGuests,
              unit: "kg",
              loading: conversionFactorsRequest.isLoading,
            },
            { value: 0, unit: "" },
            convertMassUnit,
            {
              value: benchmarkRequest.data.perGuest.co2_kg,
              unit: "kg",
              loading: conversionFactorsRequest.isLoading,
            }
          )}
          {renderDivider()}
          {renderCell(
            { Element: ElectricBoltIcon, fill: COLORS.energy },
            trans("general.measures_.electricity"),
            {
              value: realElectricity_kWh / manualData.occupancy.numberOfGuests,
              unit: "kWh",
            },
            { value: 0, unit: "" },
            convertEnergyUnit,
            {
              value: benchmarkRequest.data.perGuest.electricity_kWh,
              unit: "kWh",
            }
          )}
          {renderDivider()}
          {renderCell(
            { Element: WaterDropIcon, fill: COLORS.water },
            trans("general.measures_.water"),
            {
              value: realWater_m3 / manualData.occupancy.numberOfGuests,
              unit: "m3",
            },
            { value: 0, unit: "" },
            convertVolumeUnit,
            {
              value: benchmarkRequest.data.perGuest.water_m3,
              unit: "m3",
            }
          )}
          {renderDivider()}
          {activeSubscriptionIsStarter
            ? onlyAvailableOnBusiness(
                { Element: ModeHeatIcon, fill: COLORS.fuels },
                trans("general.fuels")
              )
            : renderCell(
                { Element: ModeHeatIcon, fill: COLORS.fuels },
                trans("general.fuels"),
                {
                  value: realFuel_kWh / manualData.occupancy.numberOfGuests,
                  unit: "kWh",
                },
                { value: 0, unit: "" },
                convertEnergyUnit,
                {
                  value: benchmarkRequest.data.perGuest.fuels_kWh,
                  unit: "kWh",
                }
              )}
          {renderDivider()}
          {activeSubscriptionIsStarter
            ? onlyAvailableOnBusiness(
                { Element: DeleteIcon, fill: COLORS.waste },
                trans("general.waste")
              )
            : renderCell(
                { Element: DeleteIcon, fill: COLORS.waste },
                trans("general.waste"),
                {
                  value: realWaste_kg / manualData.occupancy.numberOfGuests,
                  unit: "kg",
                },
                { value: 0, unit: "" },
                convertMassUnit,
                {
                  value: benchmarkRequest.data.perGuest.waste_kg,
                  unit: "kg",
                }
              )}
        </>
      ) : null}
    </Flex>
  );

  const mainCards = (
    <Fragment>
      {perAccommodationCard}
      {perGuestCard}
    </Fragment>
  );

  const mainRender = () => {
    if (isMobile)
      return (
        <MobileSlides
          perAccommodation={perAccommodationCard}
          perGuest={perGuestCard}
          energyPerArea={energyPerAreaCard}
          recycling={recyclingCard}
          renewableEnergy={renewableEnergyCard}
        />
      );

    if (isDesktop)
      return (
        <Flex row gap={16}>
          {mainCards}
          <Flex one column gap={16}>
            <div>{energyPerAreaCard}</div>
            <div>{renewableEnergyCard}</div>
            <div>{recyclingCard}</div>
          </Flex>
        </Flex>
      );

    return (
      <Flex column gap={8}>
        <Flex row gap={16}>
          {mainCards}
        </Flex>
        <div>
          <Grid fluid>
            <Row gutter={16}>
              <Col xs={12}>{energyPerAreaCard}</Col>
              <Col xs={12}>{renewableEnergyCard}</Col>
            </Row>
            <Row gutter={16}>
              <Col xs={12}>{recyclingCard}</Col>
            </Row>
          </Grid>
        </div>
      </Flex>
    );
  };

  return (
    <>
      <ManualDataModal
        open={manualDataModal.open}
        onClose={() => {
          setManualDataModal({ open: false });
        }}
        manualDataId={manualDataModal.manualDataId}
        initialStep={manualDataModal.initialStep}
      />
      {mainRender()}
    </>
  );
};
interface iBenchmarkWrappedProps extends iProps {}

const BenchmarkWrapped: React.FC<iBenchmarkWrappedProps> = ({
  conversionFactorsRequest,
  benchmarkSizes,
}) => {
  const {
    hotel: { manualData },
    manualDataOrdered,
  } = useHotelManualDataState();
  const [manualDataIndex, setManualDataIndex] = useState(0);

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  const availablePeriods = useMemo(() => {
    const periods: [MomentInput, MomentInput][] = [];

    for (const { from, to } of manualDataOrdered) {
      if (periods.length) {
        if (
          moment(last(periods)![1]).add(1, "day").format("DD/MM/YYYY") ===
          moment(from).format("DD/MM/YYYY")
        ) {
          periods[periods.length - 1][1] = to;
          continue;
        }
      }

      periods.push([from, to]);
    }

    return periods;
  }, [manualDataOrdered]);
  return (
    <Flex column gap={16}>
      <SimpleDateRangePicker
        {...{
          ...(manualDataOrdered.length
            ? {
                limitEndYear: max([
                  moment(last(manualDataOrdered)!.to).year() -
                    moment().year() +
                    1,
                  0,
                ]),
                limitStartYear:
                  moment().year() -
                  moment(first(manualDataOrdered)!.from).year() +
                  1,
              }
            : {}),
        }}
        showOneCalendar
        oneTap
        hoverRange={"month"}
        shouldDisableDate={(date, selectedDate, selectedDone, target) => {
          for (const [from, to] of availablePeriods) {
            if (
              moment(date).isSameOrAfter(moment(from)) &&
              moment(date).isSameOrBefore(moment(to))
            ) {
              return false;
            }
          }
          return true;
        }}
        onChange={(range) => {
          if (range) {
            for (const [i, { from, to }] of manualData.entries()) {
              if (
                moment(range[0])
                  .startOf("day")
                  .isSame(moment(from).startOf("day")) &&
                moment(range[1]).endOf("day").isSame(moment(to).endOf("day"))
              ) {
                setManualDataIndex(i);
              }
            }
          }
        }}
        value={[selectedManualData.from, selectedManualData.to]}
        ranges={[]}
        cleanable={false}
      />
      <BenchmarkWrapped2
        {...{
          manualDataIndex,
          conversionFactorsRequest,
          benchmarkSizes,
        }}
      />
    </Flex>
  );
};

interface iProps {
  conversionFactorsRequest: ReturnType<typeof useGetDefaultConverionFactors>;
  benchmarkSizes: tBenchmarkSizes;
}

const Benchmark: React.FC<iProps> = ({
  conversionFactorsRequest,
  benchmarkSizes,
}) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSection
        icon={ReadinessScoreIcon}
        title={trans("pages.sustainability.sections.indicators.title")}
        description={trans(
          "pages.sustainability.sections.indicators.description"
        )}
      />
      <BenchmarkWrapped {...{ conversionFactorsRequest, benchmarkSizes }} />
    </Flex>
  );
};

export default Benchmark;
