import { COLORS, hexLuminance } from "./colors";

export const GRAPH_COLORS_WATER = [
  hexLuminance(COLORS.water, -0.5),
  hexLuminance(COLORS.water, -0.25),
  COLORS.water,
  hexLuminance(COLORS.water, 0.5),
  hexLuminance(COLORS.water, 0.8),
  hexLuminance(COLORS.water, 1),
];
