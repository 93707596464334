import { forwardRef } from "react";
import TextTag, { iTextProps } from "..";

export interface iInterTagProps extends iTextProps {}

const InterTag = forwardRef<HTMLParagraphElement, iInterTagProps>(
  (props, ref) => {
    return <TextTag {...props} ref={ref} font="inter" />;
  }
);

export default InterTag;
