import { first, last, max, sortBy } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useMemo } from "react";
import { tHotelManualData } from "../../../models/hotel";
import SimpleDateRangePicker from "../SimpleDateRangePicker";

interface iProps {
  setPeriod: React.Dispatch<React.SetStateAction<[Date, Date]>>;
  period: [Date, Date];
  manualData: tHotelManualData[];
}

const SimpleDateRangePickerForManualDataPeriods: React.FC<iProps> = ({
  manualData,
  setPeriod,
  period,
}) => {
  const manualDataOrdered = useMemo(() => {
    return sortBy(manualData, (md) => moment(md.from).valueOf());
  }, [manualData]);

  const availablePeriods = useMemo(() => {
    const periods: [MomentInput, MomentInput][] = [];

    for (const { from, to } of manualDataOrdered) {
      if (periods.length) {
        if (
          moment(last(periods)![1]).add(1, "day").format("DD/MM/YYYY") ===
          moment(from).format("DD/MM/YYYY")
        ) {
          periods[periods.length - 1][1] = to;
          continue;
        }
      }

      periods.push([from, to]);
    }

    return periods;
  }, [manualDataOrdered]);

  return (
    <SimpleDateRangePicker
      {...{
        ...(manualDataOrdered.length
          ? {
              limitEndYear: max([
                moment(last(manualDataOrdered)!.to).year() -
                  moment().year() +
                  1,
                0,
              ]),
              limitStartYear:
                moment().year() -
                moment(first(manualDataOrdered)!.from).year() +
                1,
            }
          : {}),
      }}
      showOneCalendar
      oneTap
      hoverRange={"month"}
      shouldDisableDate={(date, selectedDate, selectedDone, target) => {
        for (const [from, to] of availablePeriods) {
          if (
            moment(date).isSameOrAfter(moment(from)) &&
            moment(date).isSameOrBefore(moment(to))
          ) {
            return false;
          }
        }
        return true;
      }}
      onChange={(range) => {
        setPeriod(range);
      }}
      value={period}
      ranges={[]}
      cleanable={false}
    />
  );
};

export default SimpleDateRangePickerForManualDataPeriods;
