import React from "react";
import { Pagination, PaginationProps } from "rsuite";

interface iSimplePaginationProps extends PaginationProps {
  setLimit(limit: number): void;
  setActivePage(activePage: number): void;
}

const SimplePagination: React.FC<iSimplePaginationProps> = ({
  prev,
  next,
  first,
  last,
  total,
  limit,
  setLimit,
  activePage,
  setActivePage,
  maxButtons,
  ellipsis,
  boundaryLinks,
  size = "xs",
  layout = ["limit", "|", "pager"],
  limitOptions = [10, 20, 50, 100],
}) => {
  return (
    <Pagination
      prev={prev}
      next={next}
      first={first}
      last={last}
      total={total}
      layout={layout}
      size={size}
      ellipsis={ellipsis}
      boundaryLinks={boundaryLinks}
      limit={limit}
      limitOptions={limitOptions}
      maxButtons={maxButtons}
      activePage={activePage}
      onChangePage={setActivePage}
      onChangeLimit={setLimit}
    />
  );
};

export default SimplePagination;
