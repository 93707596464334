import { find } from "lodash";
import { tHotelAward, tHotelAwardType } from "../../models/hotel";
import { tLanguageCode } from "../languages";
import { DEFAULT_LANGAUGE } from "../../context/Localization/utils";
import { ReactComponent as HikingIcon } from "../../assets/icons/hiking.svg";
import { ReactComponent as RestaurantIcon } from "../../assets/icons/restaurant.svg";
import { ReactComponent as HotelIcon } from "../../assets/icons/hotel.svg";
import { ReactComponent as AttractionsIcon } from "../../assets/icons/attractions.svg";
import { ReactComponent as ShoppingBasketIcon } from "../../assets/icons/shopping_basket.svg";
import { ReactComponent as WorkIcon } from "../../assets/icons/work.svg";
import { ReactComponent as SpaIcon } from "../../assets/icons/spa.svg";
import { ReactComponent as TheaterComedyIcon } from "../../assets/icons/theater_comedy.svg";

export const getAwardDescription = (
  award: tHotelAward,
  language: tLanguageCode | string
): { shortDescription: string; longDescription: string } | undefined => {
  return (
    find(award.descriptions, (d) => d.language === language) ||
    find(award.descriptions, (d) => d.language === DEFAULT_LANGAUGE) ||
    award.descriptions[0]
  );
};

export const getAwardTypeInfo = (
  type: tHotelAwardType
): {
  transKey: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >;
} => {
  switch (type) {
    case "Adventure":
      return {
        transKey: "general.award_types.adventure",
        icon: HikingIcon,
      };
    case "Food & Drinks":
      return {
        transKey: "general.award_types.food_and_drinks",
        icon: RestaurantIcon,
      };
    case "Hospitality":
      return {
        transKey: "general.award_types.hospitality",
        icon: HotelIcon,
      };
    case "Leisure":
      return {
        transKey: "general.award_types.leisure",
        icon: AttractionsIcon,
      };
    case "Products":
      return {
        transKey: "general.award_types.products",
        icon: ShoppingBasketIcon,
      };
    case "Services":
      return { transKey: "general.award_types.services", icon: WorkIcon };
    case "Wellness":
      return { transKey: "general.award_types.wellness", icon: SpaIcon };
    case "Cultural":
      return {
        transKey: "general.award_types.cultural",
        icon: TheaterComedyIcon,
      };
  }
};
