import { find, groupBy, has, orderBy, sortBy, toInteger } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useCallback, useMemo } from "react";
import { HotelStateContext } from "../..";
import { WASTE_TYPES } from "../../../../interfaces/manualData";
import { tHotelManualData } from "../../../../models/hotel";
import useHotelState from "./useHotelState";

const useHotelManualDataState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error(
      "useHotelManualDataState must be used within HotelStateContext"
    );

  const { hotel, hotelId } = useHotelState();

  const manualDataOrdered = useMemo(() => {
    return orderBy(
      hotel.manualData.map((md) => ({
        ...md,
        from: moment(md.from).valueOf(),
        to: moment(md.to).valueOf(),
      })),
      ["from", "to"],
      ["asc", "asc"]
    );
  }, [hotel]);

  const getManualDataWithoutZeroValues = useCallback(
    (
      types: (keyof Pick<
        tHotelManualData,
        | "biomass"
        | "butane"
        | "diesel"
        | "electricity"
        | "ethanol"
        | "gasoline"
        | "laundry"
        | "water"
        | "waste"
        | "naturalGas"
        | "propane"
      >)[]
    ) => {
      const filterFunc = (entry: tHotelManualData) => {
        for (const type of types) {
          switch (type) {
            case "naturalGas":
              if (entry.naturalGas?.totalKWh || entry.naturalGas?.totalM3)
                return true;
              break;
            case "biomass":
            case "propane":
            case "butane":
              if (entry[type]?.totalKWh || entry[type]?.totalKg) return true;
              break;

            case "diesel":
            case "ethanol":
            case "gasoline":
              if (entry[type]?.totalKWh || entry[type]?.totalLiters)
                return true;
              break;

            case "electricity":
              if (entry.electricity?.totalKWh) return true;
              break;

            case "water":
              if (entry.water?.totalM3) return true;
              break;

            case "waste":
              if (
                WASTE_TYPES.map((et) => {
                  const elem = entry.waste;
                  if (elem) {
                    return elem[et].totalKg;
                  }
                  return null;
                }).filter((v) => v).length > 0
              )
                return true;
              break;

            case "laundry":
              if (entry.laundry?.kgOutsourced) return true;
              break;
          }
        }
        return false;
      };

      return hotel.manualData.filter((i) => filterFunc(i));
    },
    [hotel.manualData]
  );

  const getManualDataOrderedWithoutZeroValues = useCallback(
    (
      types: (keyof Pick<
        tHotelManualData,
        | "biomass"
        | "butane"
        | "diesel"
        | "electricity"
        | "ethanol"
        | "gasoline"
        | "laundry"
        | "water"
        | "waste"
        | "naturalGas"
        | "propane"
      >)[],
      order: "asc" | "desc" = "asc"
    ) => {
      const noZeros = getManualDataWithoutZeroValues(types);

      const sortFunc =
        order === "asc"
          ? (date: MomentInput) => moment(date).valueOf()
          : (date: MomentInput) => -moment(date).valueOf();

      return sortBy(noZeros, (e) => sortFunc(e.from));
    },
    [getManualDataWithoutZeroValues]
  );

  const manualDataByYear: Record<string, tHotelManualData[]> = useMemo(() => {
    return groupBy(manualDataOrdered, (mdo) => moment(mdo.from).year());
  }, [manualDataOrdered]);

  const getManualDataByYearWithoutZeroValueYears = useCallback(
    (
      types: (keyof Pick<
        tHotelManualData,
        | "biomass"
        | "butane"
        | "diesel"
        | "electricity"
        | "ethanol"
        | "gasoline"
        | "laundry"
        | "water"
        | "waste"
        | "naturalGas"
        | "propane"
      >)[]
    ) => {
      const noZeros = getManualDataWithoutZeroValues(types);

      const obj = groupBy(noZeros, (i) => moment(i.from).year());

      Object.keys(obj).forEach(
        (year) =>
          (obj[year] = sortBy(obj[year], (e) => moment(e.from).valueOf()))
      );

      return obj;
    },
    [getManualDataWithoutZeroValues]
  );

  const measuresTrackedInManualData = useMemo(() => {
    const measuresTracked = {
      electricity: false,
      water: false,
      fuels: false,
      waste: false,
      laundry: false,
    };
    for (const {
      electricity,
      water,
      naturalGas,
      butane,
      biomass,
      diesel,
      ethanol,
      gasoline,
      laundry,
      propane,
      waste,
    } of manualDataOrdered) {
      if (electricity) {
        measuresTracked.electricity = true;
      }
      if (water) {
        measuresTracked.water = true;
      }
      if (
        naturalGas ||
        butane ||
        biomass ||
        diesel ||
        ethanol ||
        gasoline ||
        propane
      ) {
        measuresTracked.fuels = true;
      }
      if (waste) {
        measuresTracked.waste = true;
      }
      if (laundry) {
        measuresTracked.laundry = true;
      }
      if (Object.values(measuresTracked).filter((v) => !v).length === 0) {
        break;
      }
    }
    return measuresTracked;
  }, [manualDataOrdered]);

  const getHotelDimensionsInTimeframe = useCallback(
    (date: MomentInput) => {
      let i = 0;
      for (; i < manualDataOrdered.length; i++) {
        const entry = manualDataOrdered[i];
        if (!moment(date).isBetween(moment(entry.from), moment(entry.to)))
          continue;

        let dimensions = entry.space;
        if (dimensions) return dimensions;

        let j = i - 1;
        while (!dimensions && j >= 0) {
          dimensions = manualDataOrdered[j].space;
          j--;
        }
        if (dimensions) return dimensions;
        j = i + 1;
        while (!dimensions && j < manualDataOrdered.length) {
          dimensions = manualDataOrdered[j].space;
          j++;
        }
        if (dimensions) return dimensions;
        break;
      }

      i = manualDataOrdered.length - 1;
      for (; i >= 0; i--) {
        if (
          manualDataOrdered[i].space &&
          manualDataOrdered[i].space!.totalAreaM2
        )
          return manualDataOrdered[i].space;
      }

      return undefined;
    },
    [manualDataOrdered]
  );
  const getManualDataEntryWithDimensionsInTimeframe = useCallback(
    (date: MomentInput) => {
      let i = 0;
      for (; i < manualDataOrdered.length; i++) {
        const entry = manualDataOrdered[i];
        if (!moment(date).isBetween(moment(entry.from), moment(entry.to)))
          continue;

        let dimensions = entry.space;
        if (dimensions) return { ...entry };

        let j = i - 1;
        while (j >= 0) {
          dimensions = manualDataOrdered[j].space;
          if (dimensions) return { ...manualDataOrdered[j] };
          j--;
        }
        j = i + 1;
        while (j < manualDataOrdered.length) {
          dimensions = manualDataOrdered[j].space;
          if (dimensions) return { ...manualDataOrdered[j] };
          j++;
        }
        break;
      }

      i = manualDataOrdered.length - 1;
      for (; i >= 0; i--) {
        if (
          manualDataOrdered[i].space &&
          manualDataOrdered[i].space!.totalAreaM2
        ) {
          return { ...manualDataOrdered[i] };
        }
      }

      return undefined;
    },
    [manualDataOrdered]
  );

  const findHomologueManualDataEntry = useCallback(
    (entry: tHotelManualData) => {
      const homologueEntry = find(
        hotel.manualData,
        (md) =>
          moment(md.from).isSame(moment(entry.from).subtract(1, "year")) &&
          moment(md.to).isSame(moment(entry.to).subtract(1, "year"))
      );
      return homologueEntry;
    },
    [hotel.manualData]
  );

  const findPreviousAvailableYearManualDataEntry = useCallback(
    (entry: tHotelManualData, property?: keyof tHotelManualData) => {
      const entryMoment = moment(
        (moment(entry.from).valueOf() + moment(entry.to).valueOf()) / 2
      );
      const entryYear = entryMoment.year();
      const entryMonth = entryMoment.month();

      const yearsDescendingOrdered = sortBy(
        Object.keys(manualDataByYear).map((y) => toInteger(y)),
        (y) => -y
      );

      if (property) {
        for (const year of yearsDescendingOrdered) {
          if (year >= entryYear) continue;

          const monthEntry = find(
            manualDataByYear[year],
            (md) =>
              moment(
                (moment(md.from).valueOf() + moment(md.to).valueOf()) / 2
              ).month() === entryMonth
          );

          if (monthEntry) {
            if (has(monthEntry, property)) return monthEntry;
            continue;
          }
        }
      } else {
        for (const year of yearsDescendingOrdered) {
          if (year >= entryYear) continue;

          const monthEntry = find(
            manualDataByYear[year],
            (md) =>
              moment(
                (moment(md.from).valueOf() + moment(md.to).valueOf()) / 2
              ).month() === entryMonth
          );

          if (monthEntry) return monthEntry;
        }
      }

      return undefined;
    },
    [manualDataByYear]
  );

  return {
    hotel,
    hotelId,
    manualData: hotel.manualData,
    getManualDataWithoutZeroValues,
    manualDataOrdered,
    getManualDataOrderedWithoutZeroValues,
    getHotelDimensionsInTimeframe,
    measuresTrackedInManualData,
    manualDataByYear,
    getManualDataEntryWithDimensionsInTimeframe,
    findHomologueManualDataEntry,
    findPreviousAvailableYearManualDataEntry,
    getManualDataByYearWithoutZeroValueYears,
  };
};

export default useHotelManualDataState;
