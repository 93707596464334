import { createContext, useCallback, useState } from "react";

type tState = {
  dataTypeToggleDisabled: Record<
    "occupancyRequest" | "homologueOccupancyRequest" | "hotel",
    boolean
  >;
};

const initialState: tState = {
  dataTypeToggleDisabled: {
    homologueOccupancyRequest: false,
    occupancyRequest: false,
    hotel: false,
  },
};

const OccupancyContext = createContext<{
  state: tState;
  setDataTypeToggleDisabled: (
    arg: Partial<tState["dataTypeToggleDisabled"]>
  ) => void;
}>({ state: { ...initialState }, setDataTypeToggleDisabled(arg) {} });

const OccupancyContextProvider: React.FC<{ children: any }> = ({
  children,
}) => {
  const [state, setState] = useState({ ...initialState });

  const setDataTypeToggleDisabled = useCallback(
    (arg: Partial<tState["dataTypeToggleDisabled"]>) => {
      setState((prev) => ({
        ...prev,
        dataTypeToggleDisabled: { ...prev.dataTypeToggleDisabled, ...arg },
      }));
    },
    []
  );

  return (
    <OccupancyContext.Provider value={{ state, setDataTypeToggleDisabled }}>
      {children}
    </OccupancyContext.Provider>
  );
};

export { OccupancyContext };

export default OccupancyContextProvider;
