import React from "react";
import { WindowSizeStateContext } from "../";

const useWindowSizeState = () => {
  const state = React.useContext(WindowSizeStateContext);

  if (!state)
    throw Error(
      "useWindowSizeState must be used within WindowSizeStateContext"
    );

  const {
    data: { isLaptop, isMobile, isTablet, isDesktop },
  } = state;

  return { isLaptop, isMobile, isTablet, isDesktop };
};

export default useWindowSizeState;
