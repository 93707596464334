import React, { PropsWithChildren } from "react";
import { IconButton } from "rsuite";
import { ReactComponent as DoneAllIcon } from "../../assets/icons/done_all.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import { COLORS } from "../../utils/colors";

interface iProps {
  onSave(event: React.SyntheticEvent): void;
  onCancel(event: React.SyntheticEvent): void;
  hasChanges: boolean;
}

const SaveWrapper: React.FC<PropsWithChildren<iProps>> = ({
  children,
  hasChanges,
  onCancel,
  onSave,
}) => {
  return (
    <Flex style={{ position: "relative" }}>
      {children}
      {hasChanges && (
        <div style={{ position: "absolute", bottom: -30, right: 0 }}>
          <Flex row gap={4}>
            <IconButton
              size="xs"
              icon={
                <Icon Element={CloseIcon} fill={COLORS.secondary} size={16} />
              }
              onClick={onCancel}
            />
            <IconButton
              size="xs"
              icon={
                <Icon Element={DoneAllIcon} fill={COLORS.secondary} size={16} />
              }
              onClick={onSave}
            />
          </Flex>
        </div>
      )}
    </Flex>
  );
};

export default SaveWrapper;
