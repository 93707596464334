import axios from "axios";
import React, { useCallback } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { IconButton, Loader } from "rsuite";
import { ReactComponent as AddPhotoAlternateIcon } from "../../../assets/icons/add_photo_alternate.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as MoreVertIcon } from "../../../assets/icons/more_vert.svg";
import { ReactComponent as OpenInNewIcon } from "../../../assets/icons/open_in_new.svg";
import { ReactComponent as PhotoIcon } from "../../../assets/icons/photo.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageSection from "../../../components/PageSection";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelSubscriptionState, {
  MAX_NUMBER_OF_PHOTOS_GUEST_APP,
} from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { openNewTab } from "../../../utils/others";

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

interface iPhotoGalleryWrappedProps extends iProps {}

const PhotoGalleryWrapped: React.FC<iPhotoGalleryWrappedProps> = ({
  canWrite,
}) => {
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { hasMaxGuestAppImages, activeSubscriptionType } =
    useHotelSubscriptionState();
  const { updateHotel } = useHotelDispatch();
  const deleteRequest = useDeleteRequest();
  const postRequest = usePostRequest();

  const onDrop = useCallback(
    (
      acceptedFiles: File[],
      fileRejections: FileRejection[],
      event: DropEvent
    ) => {
      if (!canWrite) return;

      if (acceptedFiles.length) {
        postRequest.pending();

        const formData = new FormData();

        acceptedFiles.forEach((file) => {
          formData.append(file.name, file);
        });
        axios
          .post(
            `${apiAddress(false)}/v2/hotels/${hotelId}/guest-app/images`,
            formData
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;
            updateHotel(hotelId, hotel);
            postRequest.resolve();
          })
          .catch((err) => {
            const error = getErrorMessage(err, trans);
            deleteRequest.reject(error, true);
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    onDrop,
    disabled:
      postRequest.isLoading || deleteRequest.isLoading || hasMaxGuestAppImages,
  });

  const renderImages = () => {
    const thumbs = hotel.guestApp.images.map((image) => (
      <Flex
        center
        middle
        color={COLORS.gray_300}
        style={{
          borderRadius: 8,
          width: 272,
          height: 272,
          overflow: "hidden",
          position: "relative",
        }}
        key={image}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <div style={{ position: "absolute", right: 8, top: 8 }}>
          <SimpleWhisperPopoverDropdown
            placement="leftStart"
            options={[
              {
                key: "open",
                label: trans(
                  "pages.hotel_profile.sections.photos.image_options.open"
                ),
                icon: OpenInNewIcon,
                onClick: async () => {
                  openNewTab(image);
                },
              },
              {
                key: "remove",
                label: { text: trans("general.remove"), color: COLORS.error },
                icon: { Element: DeleteIcon, fill: COLORS.error },
                onClick() {
                  deleteRequest.pending();
                  axios
                    .delete(
                      `${apiAddress(
                        false
                      )}/v2/hotels/${hotelId}/guest-app/images`,
                      { data: { imageKey: image } }
                    )
                    .then((res) => {
                      const {
                        data: { hotel },
                      } = res;
                      updateHotel(hotelId, hotel);
                      deleteRequest.resolve();
                    })
                    .catch((err) => {
                      const error = getErrorMessage(err, trans);
                      deleteRequest.reject(error, true);
                    });
                },
              },
            ]}
          >
            <IconButton size="xs" circle style={{ padding: 2 }}>
              <Icon Element={MoreVertIcon} fill={COLORS.secondary} size={24} />
            </IconButton>
          </SimpleWhisperPopoverDropdown>
        </div>
        <img src={image} style={img} alt="img" />
      </Flex>
    ));

    return (
      <Flex
        {...{
          ...getRootProps(),
          style: {
            border: `1px dashed ${COLORS.gray_300}`,
            // borderRadius: "8px",
            padding: "16px 16px",
          },
        }}
      >
        <input {...getInputProps()} />
        {(() => {
          const renderThumbnails = () => {
            const list: JSX.Element[] = [];

            for (
              let i = thumbs.length;
              i < MAX_NUMBER_OF_PHOTOS_GUEST_APP[activeSubscriptionType];
              i++
            ) {
              list.push(
                <Flex
                  key={i}
                  middle
                  center
                  style={{
                    border: `1px dashed ${COLORS.gray_400}`,
                    height: "272px",
                    width: "272px",
                  }}
                >
                  <Icon
                    Element={AddPhotoAlternateIcon}
                    size={48}
                    color={COLORS.gray_400}
                  />
                </Flex>
              );
            }

            return list;
          };

          return (
            <Flex row wrap middle center gap={24}>
              {thumbs}
              {renderThumbnails()}
            </Flex>
          );
        })()}
      </Flex>
    );
  };

  const renderNoInputImages = () => {
    const thumbs = hotel.guestApp.images.map((image) => (
      <Flex
        center
        middle
        color={COLORS.gray_300}
        style={{
          borderRadius: 8,
          width: 272,
          height: 272,
          overflow: "hidden",
          position: "relative",
        }}
        key={image}
      >
        <img src={image} style={img} alt="img" />
      </Flex>
    ));

    return (
      <div
        {...{
          style: {
            border: `1px dashed ${COLORS.gray_300}`,
            padding: "16px 16px",
            width: "fit-content",
          },
        }}
      >
        {(() => {
          return (
            <Flex row wrap gap={24}>
              {thumbs}
            </Flex>
          );
        })()}
      </div>
    );
  };

  const renderLoader = () => {
    if (!postRequest.isLoading && !deleteRequest.isLoading) return null;

    return (
      <>
        <div
          style={{
            position: "absolute",
            backgroundColor: COLORS.secondary,
            opacity: 0.2,
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 1,
          }}
        ></div>
        <Flex
          center
          middle
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            zIndex: 2,
          }}
        >
          <Loader size={"lg"} />
        </Flex>
      </>
    );
  };

  return (
    <Flex column gap={16}>
      <Flex column gap={8} style={{ position: "relative" }}>
        {canWrite ? renderImages() : renderNoInputImages()}
        {renderLoader()}
        {canWrite && (
          <InterTag
            size={12}
            color={COLORS.gray_400}
            text={trans(
              "pages.hotel_profile.sections.photos.number_of_photos",
              {
                parameters: [
                  hotel.guestApp.images.length,
                  MAX_NUMBER_OF_PHOTOS_GUEST_APP[activeSubscriptionType],
                ],
              }
            )}
          />
        )}
      </Flex>
      {canWrite && (
        <Flex column gap={8}>
          {[0, 1, 2, 3].map((index) => (
            <InterTag
              wrap
              key={index}
              text={trans(
                `pages.hotel_profile.sections.photos.upload_description[${index}]`
              )}
              color={COLORS.gray_400}
              size={14}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};

interface iProps {
  canWrite: boolean;
}

const PhotoGallery: React.FC<iProps> = (props) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.hotel_profile.sections.photos.title")}
        icon={PhotoIcon}
        description={trans("pages.hotel_profile.sections.photos.description")}
      />
      <PhotoGalleryWrapped {...props} />
    </Flex>
  );
};

export default PhotoGallery;
