import React from "react";
import Flex from "../../../../components/Flex";
import Graph from "./graph";
import List from "./list";
import PageSection from "../../../../components/PageSection";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";

interface iProps {}

const Vehicles: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={40}>
      <Flex column gap={16}>
        <PageSection
          title={trans(
            "pages.fleet.archive.segments.vehicles.sections.overview.title"
          )}
          description={trans(
            "pages.fleet.archive.segments.vehicles.sections.overview.description"
          )}
          icon={QueryStatsIcon}
        />
        <Graph />
      </Flex>
      <Flex column gap={16}>
        <PageSection
          title={trans(
            "pages.fleet.archive.segments.vehicles.sections.list.title"
          )}
          description={trans(
            "pages.fleet.archive.segments.vehicles.sections.list.description"
          )}
          icon={DirectionsCarIcon}
        />
        <List />
      </Flex>
    </Flex>
  );
};

export default Vehicles;
