import { max, sortBy } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useReducer } from "react";
import {
  Button,
  CheckTreePicker,
  DatePicker,
  Drawer,
  DrawerProps,
  RangeSlider,
} from "rsuite";
import { ReactComponent as FilterIcon } from "../../../assets/icons/filter_list.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

type tStateData = {
  numberOfDays: { values: [number, number]; limits: [number, number] };
  numberOfGuests: { values: [number, number]; limits: [number, number] };
  rooms: { values: string[] };
  checkInFrom: Date | null | undefined;
  checkInTo: Date | null | undefined;
  checkOutFrom: Date | null | undefined;
  checkOutTo: Date | null | undefined;
  periodFrom: Date | null | undefined;
  periodTo: Date | null | undefined;
};

type tState = {
  data: tStateData;
};

type tAction =
  | { type: "set data"; data: tStateData }
  | { type: "set rooms value"; rooms: string[] }
  | { type: "set number of guests value"; numberOfGuests: [number, number] }
  | {
      type: "set date property";
      key: keyof Pick<
        tStateData,
        | "checkInFrom"
        | "checkInTo"
        | "checkOutFrom"
        | "checkOutTo"
        | "periodFrom"
        | "periodTo"
      >;
      value: Date | null | undefined;
    }
  | { type: "set numberOfDays value"; numberOfDays: [number, number] };

const initialState: tState = {
  data: {
    numberOfDays: { values: [1, 10], limits: [1, 10] },
    numberOfGuests: { values: [1, 10], limits: [1, 10] },
    rooms: { values: [] },
    checkInFrom: null,
    checkInTo: null,
    checkOutFrom: null,
    checkOutTo: null,
    periodFrom: null,
    periodTo: null,
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "set data": {
      return { ...state, data: action.data };
    }
    case "set rooms value": {
      const { rooms: values } = action;
      return {
        ...state,
        data: { ...state.data, rooms: { ...state.data.rooms, values } },
      };
    }
    case "set number of guests value": {
      const { numberOfGuests: values } = action;
      return {
        ...state,
        data: {
          ...state.data,
          numberOfGuests: { ...state.data.numberOfGuests, values },
        },
      };
    }
    case "set numberOfDays value": {
      const { numberOfDays: values } = action;
      return {
        ...state,
        data: {
          ...state.data,
          numberOfDays: { ...state.data.numberOfDays, values },
        },
      };
    }
    case "set date property": {
      const { key, value } = action;
      return { ...state, data: { ...state.data, [key]: value } };
    }
    default:
      return { ...state };
  }
};

const FiltersDrawerWrapped: React.FC<iFiltersDrawerProps> = ({
  onClose,
  onConfirm,
  initialValues,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const {
      numberOfGuests,
      numberOfDays,
      rooms,
      checkInFrom,
      checkInTo,
      checkOutFrom,
      checkOutTo,
      periodFrom,
      periodTo,
    } = initialValues;

    dispatch({
      type: "set data",
      data: {
        rooms: { values: [...rooms] },
        numberOfDays: {
          values: [...numberOfDays],
          limits: [1, max([10, numberOfDays[1]]) as number],
        },
        numberOfGuests: {
          values: [...numberOfGuests],
          limits: [1, max([10, numberOfGuests[1]]) as number],
        },
        checkInFrom,
        checkInTo,
        checkOutFrom,
        checkOutTo,
        periodFrom,
        periodTo,
      },
    });
  }, [initialValues]);

  const {
    hotel: { spaces },
  } = useHotelState();
  const { trans } = useLocalizationState();

  const {
    data: {
      rooms: { values: roomValues },
      numberOfGuests: { values: numberOfGuests, limits: numberOfGuestsLimits },
      numberOfDays: { values: numberOfDays, limits: periodLimits },
      checkInFrom,
      checkInTo,
      checkOutFrom,
      checkOutTo,
      periodFrom,
      periodTo,
    },
  } = state;

  const setRoomValues = (rooms: string[]) => {
    dispatch({ type: "set rooms value", rooms });
  };
  const setNumberOfGuestsValues = (numberOfGuests: [number, number]) => {
    dispatch({ type: "set number of guests value", numberOfGuests });
  };
  const setPeriodValues = (numberOfDays: [number, number]) => {
    dispatch({ type: "set numberOfDays value", numberOfDays });
  };

  const handleConfirm = () => {
    onConfirm(
      roomValues,
      numberOfGuests,
      numberOfDays,
      checkInFrom,
      checkInTo,
      checkOutFrom,
      checkOutTo,
      periodFrom,
      periodTo
    );
  };

  const changesMade = (() => {
    if (sortBy(initialValues.rooms).join("|") !== sortBy(roomValues).join("|"))
      return true;
    if (numberOfDays[0] !== initialValues.numberOfDays[0]) return true;
    if (numberOfDays[1] !== initialValues.numberOfDays[1]) return true;
    if (numberOfGuests[0] !== initialValues.numberOfGuests[0]) return true;
    if (numberOfGuests[1] !== initialValues.numberOfGuests[1]) return true;
    if (checkInFrom !== initialValues.checkInFrom) return true;
    if (checkInTo !== initialValues.checkInTo) return true;
    if (checkOutFrom !== initialValues.checkOutFrom) return true;
    if (checkOutTo !== initialValues.checkOutTo) return true;
    if (periodFrom !== initialValues.periodFrom) return true;
    if (periodTo !== initialValues.periodTo) return true;
    return false;
  })();
  const handleSetCheckInOut =
    (
      key: keyof Pick<
        tStateData,
        | "checkInFrom"
        | "checkInTo"
        | "checkOutFrom"
        | "checkOutTo"
        | "periodFrom"
        | "periodTo"
      >
    ) =>
    (value: Date | null | undefined) => {
      dispatch({ type: "set date property", key, value });
    };

  return (
    <Fragment>
      <Drawer.Header>
        <Flex row middle gap={8}>
          <InterTag
            text={trans("general.filters")}
            color={COLORS.secondary}
            size={18}
          />
          <Icon Element={FilterIcon} size={20} fill={COLORS.secondary} />
        </Flex>
        <Drawer.Actions>
          <Button onClick={onClose}>{trans("general.cancel")}</Button>
          <Button
            onClick={handleConfirm}
            appearance="primary"
            disabled={!changesMade}
          >
            {trans("general.confirm")}
          </Button>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <Flex column gap={20}>
          <Flex column gap={16}>
            <InterTag size={16} text={trans("general.rooms")} />

            <CheckTreePicker
              value={roomValues}
              onChange={(value) => {
                if (value.length) setRoomValues([...(value as string[])]);
              }}
              style={{ width: "100%" }}
              data={spaces.map((s) => ({ value: s._id, label: s.name }))}
            />
          </Flex>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid var(--color-gray-400)",
            }}
          />
          <Flex column>
            <Flex column gap={16}>
              <InterTag size={16} text={trans("general.number_of_guests")} />
              <RangeSlider
                max={numberOfGuestsLimits[1]}
                min={numberOfGuestsLimits[0]}
                progress
                graduated
                value={numberOfGuests}
                onChange={(value) => {
                  setNumberOfGuestsValues(value);
                }}
                renderMark={(mark) => {
                  return (
                    <span
                      style={{
                        color: COLORS.secondary,
                        fontSize: "10px",
                      }}
                    >
                      {mark}
                    </span>
                  );
                }}
              />
            </Flex>
          </Flex>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid var(--color-gray-400)",
            }}
          />
          <Flex column>
            <Flex column gap={16}>
              <InterTag size={16} text={trans("general.number_of_nights")} />
              <RangeSlider
                max={periodLimits[1]}
                min={periodLimits[0]}
                progress
                graduated
                value={numberOfDays}
                onChange={(value) => {
                  setPeriodValues(value);
                }}
                renderMark={(mark) => {
                  return (
                    <span
                      style={{
                        color: COLORS.secondary,
                        fontSize: "10px",
                      }}
                    >
                      {mark}
                    </span>
                  );
                }}
              />
            </Flex>
          </Flex>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid var(--color-gray-400)",
            }}
          />
          <Flex column gap={16}>
            <InterTag size={16} text={trans("general.check-in")} />
            <Flex row gap={8}>
              <Flex column gap={4}>
                <InterTag size={10} text={trans("general.from_date")} />
                <DatePicker
                  value={checkInFrom}
                  placement="topStart"
                  format="dd/MM/yyyy"
                  onSelect={handleSetCheckInOut("checkInFrom")}
                  onChange={handleSetCheckInOut("checkInFrom")}
                  shouldDisableDate={(date: Date) => {
                    if (checkInTo === null || checkInTo === undefined)
                      return false;
                    return moment(date)
                      .startOf("day")
                      .isAfter(moment(checkInTo).startOf("day"));
                  }}
                />
              </Flex>
              <Flex column gap={4}>
                <InterTag size={10} text={trans("general.to_date")} />
                <DatePicker
                  value={checkInTo}
                  placement="topEnd"
                  format="dd/MM/yyyy"
                  onSelect={handleSetCheckInOut("checkInTo")}
                  onChange={handleSetCheckInOut("checkInTo")}
                  shouldDisableDate={(date: Date) => {
                    if (checkInFrom === null || checkInFrom === undefined)
                      return false;
                    return moment(date)
                      .startOf("day")
                      .isBefore(moment(checkInFrom).startOf("day"));
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid var(--color-gray-400)",
            }}
          />
          <Flex column gap={16}>
            <InterTag size={16} text={trans("general.check-out")} />
            <Flex row gap={8}>
              <Flex column gap={4}>
                <InterTag size={10} text={trans("general.from_date")} />
                <DatePicker
                  value={checkOutFrom}
                  placement="topStart"
                  format="dd/MM/yyyy"
                  onSelect={handleSetCheckInOut("checkOutFrom")}
                  onChange={handleSetCheckInOut("checkOutFrom")}
                  shouldDisableDate={(date: Date) => {
                    if (checkOutTo === null || checkOutTo === undefined)
                      return false;
                    return moment(date)
                      .startOf("day")
                      .isAfter(moment(checkOutTo).startOf("day"));
                  }}
                />
              </Flex>
              <Flex column gap={4}>
                <InterTag size={10} text={trans("general.to_date")} />
                <DatePicker
                  value={checkOutTo}
                  placement="topEnd"
                  format="dd/MM/yyyy"
                  onSelect={handleSetCheckInOut("checkOutTo")}
                  onChange={handleSetCheckInOut("checkOutTo")}
                  shouldDisableDate={(date: Date) => {
                    if (checkOutFrom === null || checkOutFrom === undefined)
                      return false;
                    return moment(date)
                      .startOf("day")
                      .isBefore(moment(checkOutFrom).startOf("day"));
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
          <div
            style={{
              width: "100%",
              borderTop: "1px solid var(--color-gray-400)",
            }}
          />
          <Flex column gap={16}>
            <InterTag size={16} text={trans("Period")} />
            <Flex row gap={8}>
              <Flex column gap={4}>
                <InterTag size={10} text={trans("general.from_date")} />
                <DatePicker
                  value={periodFrom}
                  placement="topStart"
                  format="dd/MM/yyyy"
                  onSelect={handleSetCheckInOut("periodFrom")}
                  onChange={handleSetCheckInOut("periodFrom")}
                  shouldDisableDate={(date: Date) => {
                    if (periodTo === null || periodTo === undefined)
                      return false;
                    return moment(date)
                      .startOf("day")
                      .isAfter(moment(periodTo).startOf("day"));
                  }}
                />
              </Flex>
              <Flex column gap={4}>
                <InterTag size={10} text={trans("general.to_date")} />
                <DatePicker
                  value={periodTo}
                  placement="topEnd"
                  format="dd/MM/yyyy"
                  onSelect={handleSetCheckInOut("periodTo")}
                  onChange={handleSetCheckInOut("periodTo")}
                  shouldDisableDate={(date: Date) => {
                    if (periodFrom === null || periodFrom === undefined)
                      return false;
                    return moment(date)
                      .startOf("day")
                      .isBefore(moment(periodFrom).startOf("day"));
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Drawer.Body>
    </Fragment>
  );
};

interface iFiltersDrawerProps extends DrawerProps {
  open: boolean;
  onClose(): void;
  onConfirm(
    rooms: string[],
    numberOfGuests: [number, number],
    numberOfDays: [number, number],
    checkInFrom: Date | null | undefined,
    checkInTo: Date | null | undefined,
    checkOutFrom: Date | null | undefined,
    checkOutTo: Date | null | undefined,
    periodFrom: Date | null | undefined,
    periodTo: Date | null | undefined
  ): void;
  initialValues: {
    rooms: string[];
    numberOfGuests: [number, number];
    numberOfDays: [number, number];
    checkInFrom: Date | null | undefined;
    checkInTo: Date | null | undefined;
    checkOutFrom: Date | null | undefined;
    checkOutTo: Date | null | undefined;
    periodFrom: Date | null | undefined;
    periodTo: Date | null | undefined;
  };
}

const FiltersDrawer: React.FC<iFiltersDrawerProps> = ({
  open,
  onClose,
  ...props
}) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      placement="right"
      size={"xs"}
      className="drawer-secondary-bg"
    >
      {open && <FiltersDrawerWrapped {...{ open, onClose, ...props }} />}
    </Drawer>
  );
};

export default FiltersDrawer;
