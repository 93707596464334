import React, { useEffect, useState } from "react";
import { Button, Modal, ModalProps, SelectPicker } from "rsuite";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import Flex from "../../Flex";
import InputWrapper from "../../InputWrapper";
import InterTag from "../../Text/Inter";
import { COLORS } from "../../../utils/colors";

interface iModalSelectWrappedProps
  extends Pick<
    iModalSelectProps,
    "onClose" | "options" | "onConfirm" | "label" | "error" | "beforeConfirm"
  > {}

const ModalSelectWrapped: React.FC<iModalSelectWrappedProps> = ({
  onClose,
  onConfirm,
  beforeConfirm,
  options,
  label,
  error,
}) => {
  const [state, setState] = useState<{
    value: string | null;
    error: string | null | undefined;
  }>({ value: null, error: null });
  const { trans } = useLocalizationState();

  useEffect(() => {
    setState((prev) => ({ ...prev, error }));
  }, [error]);

  const handleConfirm = () => {
    const error = beforeConfirm(state.value);
    if (error) setState((prev) => ({ ...prev, error }));
    else onConfirm(state.value);
  };

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={20}>
          <InputWrapper label={label} error={state.error}>
            <SelectPicker
              data={options}
              onChange={(value) =>
                setState((prev) => ({ ...prev, error: null, value }))
              }
              value={state.value}
            />
          </InputWrapper>
          <Flex row gap={8} center>
            <Button appearance="subtle" onClick={onClose}>
              <InterTag text={trans("Cancel")} size={14} color={COLORS.error} />
            </Button>
            <Button appearance="primary" onClick={handleConfirm}>
              <InterTag
                text={trans("Confirm")}
                size={14}
                color={COLORS.white}
              />
            </Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </>
  );
};

interface iModalSelectProps extends ModalProps {
  onClose(): void;
  onConfirm(value: string | null): void;
  beforeConfirm(value: string | null): string | null;

  options: { label: string; value: string }[];
  label: string;
  error?: string;
}

const ModalSelect: React.FC<iModalSelectProps> = ({
  onClose,
  options,
  onConfirm,
  beforeConfirm,
  label,
  error,
  ...props
}) => {
  return (
    <Modal {...{ onClose, size: "xs", ...props }}>
      <ModalSelectWrapped
        {...{ onClose, onConfirm, beforeConfirm, options, label, error }}
      />
    </Modal>
  );
};

export default ModalSelect;
