export const COLORS = {
  primary_50: "#f1faf9",
  primary_100: "#e4f6f5",
  primary_200: "#85dfd7",
  primary_300: "#5dcfc6",
  primary_400: "#40bfb4",
  primary_500: "#2dada2",
  primary_600: "#249c92",
  primary_700: "#1c8a81",
  primary_800: "#1a7870",
  primary_900: "#19605a",

  white: "#ffffff",
  light_white: "#f0f0f0",

  gray_0: "#f8f8f9",
  gray_50: "#f7f9fa",
  gray_100: "#f1f3f5",
  gray_200: "#e6e8eb",
  gray_300: "#d2d5d9",
  gray_400: "#bec3c7",
  gray_500: "#a3a9af",
  gray_600: "#848c94",
  gray_700: "#5c6670",
  gray_800: "#333e48",
  gray_900: "#10171c",

  secondary: "",
  primary: "",

  water: "#2f80ed",
  cold: "#56ccf2",
  hot: "#eb5757",
  gray: "#bdbdbd",
  emissions: "#1dda25",
  energy: "#fbce43",
  waste: "#944a0a",
  gas: "#828282",
  fuels: "",

  esg_environment: "#139019",
  esg_social: "#ff9808",
  esg_governance: "#6fbbdb",
  esg_supply_chain: "#a13978",

  success: "#4caf50",
  warning: "#ffb300",
  error: "#f44336",
  info: "#2196f3",

  app_background: "",
};

Object.assign(COLORS, {
  secondary: COLORS.gray_800,
  primary: COLORS.primary_400,
  app_background: COLORS.gray_100,
  fuels: COLORS.gas,
});

export const hex2hexA = (hex: string, transparencyPercentage: number) => {
  var decimalValue = Math.round((transparencyPercentage * 255) / 100);
  var hexValue;
  if (transparencyPercentage < 7) {
    hexValue = "0" + decimalValue.toString(16).toUpperCase();
  } else {
    hexValue = decimalValue.toString(16).toUpperCase();
  }
  return `${hex}${hexValue}`;
};

export function convertHexToRgbA(hexVal: string) {
  let ret: any;

  // If the hex value is valid.
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexVal)) {
    // Getting the content after '#',
    // eg. 'ffffff' in case of '#ffffff'
    ret = hexVal.slice(1);

    // Splitting each character
    ret = ret.split("");

    // Checking if the length is 3
    // then make that 6
    if (ret.length === 3) {
      let ar = [];
      ar.push(ret[0]);
      ar.push(ret[0]);
      ar.push(ret[1]);
      ar.push(ret[1]);
      ar.push(ret[2]);
      ar.push(ret[2]);
      ret = ar;
    }

    // Starts with '0x'(in hexadecimal)
    ret = "0x" + ret.join("");

    // Converting the first 2 characters
    // from hexadecimal to r value
    let r = (ret >> 16) & 255;

    // Converting the second 2 characters
    // to hexadecimal to g value
    let g = (ret >> 8) & 255;

    // Converting the last 2 characters
    // to hexadecimal to b value
    let b = ret & 255;

    // Appending all of them to make
    // the RGBA value
    return "rgba(" + [r, g, b].join(",") + ",1)";
  }
}

export function rgbToHex(r: number, g: number, b: number) {
  function componentToHex(c: any) {
    var hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function hexLuminance(hexCode: string, lum: number) {
  // validate hex string
  let hex = String(hexCode).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var updatedHex = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substring(i * 2, i * 2 + 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    updatedHex += ("00" + c).substring(c.length);
  }

  return updatedHex;
}

export type tColorName = keyof typeof COLORS;
export type tColor = string;
