import { orderBy, sum } from "lodash";
import moment from "moment";
import React, { useMemo } from "react";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import { FUEL_TYPES } from "../../../../interfaces/manualData";
import ShowcasingWrapped2, { tTableItem } from "./wrapped2";

interface iShowcasingWrappedProps {
  outletContainerWidth: number;
}

const ShowcasingWrapped: React.FC<iShowcasingWrappedProps> = ({
  outletContainerWidth,
}) => {
  const { manualData } = useHotelManualDataState();
  const tableData: tTableItem[] = useMemo(() => {
    return orderBy(
      manualData
        .map((item) => {
          const { from, to, _id } = item;
          const totalKWh = sum(
            FUEL_TYPES.map((key) => item[key]?.totalKWh || 0)
          );
          const totalPrice = sum(
            FUEL_TYPES.map((key) => item[key]?.price || 0)
          );

          return {
            from,
            to,
            _id,
            totalKWh,
            totalPrice,
            sorter: moment(from).valueOf(),
            homologueId: `${moment(from).format("YYYY/MM")}`,
          };
        })
        .filter((item) => item.totalKWh || item.totalPrice),
      ["sorter"],
      ["desc"]
    ).map((item, index) => ({ ...item, index }));
  }, [manualData]);

  return <ShowcasingWrapped2 {...{ outletContainerWidth, tableData }} />;
};

export default ShowcasingWrapped;
