import axios, { AxiosResponse } from "axios";
import { find, min, orderBy } from "lodash";
import moment, { MomentInput } from "moment";
import React from "react";
import { Table } from "rsuite";
import useHotelState from "../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../hooks/useEffectSafe";
import { tConservationLabsEvent } from "../../models/conservationLabs";
import { tHotelSpace, tHotelSpaceId } from "../../models/hotel";
import { tMeasure } from "../../models/measures";
import { constructApiAddress } from "../../utils/apiCall";
import { getErrorMessage } from "../../utils/httpResponses/others";
import { getMeasureInfo } from "../../utils/measures";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../utils/tables";
import SimpleTableWrapper from "../RsuiteWrapper/SimpleTable";
import SimpleDateCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleTextCell from "../RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../RsuiteWrapper/SimpleTable/SimpleHeaderCell";

const buildTransKey = (key: string) =>
  `components.conservation_labs.events.${key}`;

type tTableItem = {
  space: tHotelSpace;
  measureLabel: tMeasure;
} & tConservationLabsEvent;

interface iProps {
  from: MomentInput;
  to: MomentInput;
  spaces?: tHotelSpaceId[];
}

const ConservationLabsEvents: React.FC<iProps> = ({ from, to, spaces }) => {
  const { trans } = useLocalizationState();
  const { hotelId, hotel } = useHotelState();
  const request = useGetRequest<{ events: tTableItem[] }>({
    events: [],
  });

  useEffectSafe(() => {
    request.pending();
    axios
      .get(
        constructApiAddress(`/v2/hotels/${hotelId}/conservation-labs/events`),
        {
          params: {
            from: moment(from).toISOString(),
            to: moment(to).toISOString(),
            spaces: spaces,
          },
        }
      )
      .then((res: AxiosResponse<{ events: tConservationLabsEvent[] }>) => {
        const {
          data: { events },
        } = res;

        const parsedEvents = events
          .map((event) => {
            const conservationLabsSensor = find(
              hotel.conservationLabs.sensors,
              (s) => s._id === event.device_id
            );
            if (!conservationLabsSensor)
              return { space: undefined, measureLabel: "", ...event };

            const space = find(
              hotel.spaces,
              (s) => s._id === conservationLabsSensor?.spaceId
            );
            if (!space) return { space: undefined, measureLabel: "", ...event };

            const measureInfo = getMeasureInfo(
              conservationLabsSensor.measure,
              trans
            );

            return {
              space,
              measureLabel: measureInfo.label,
              sorter: moment(event.event_begin).valueOf(),
              ...event,
            };
          })
          .filter((e) => e.space) as tTableItem[];

        const orderedEvents = orderBy(parsedEvents, ["sorter"], ["asc"]);

        request.resolve({ events: orderedEvents });
      })
      .catch((err) => {
        const error = getErrorMessage(err);
        request.reject(error, true);
      });
  }, [hotelId, hotel.spaces, from, to, spaces]);

  const tableHeight =
    ((min([7, request.data.events.length]) as number) || 1) *
      TABLE_ROW_HEIGHT.M +
    TABLE_HEADER_HEIGHT;

  return (
    <SimpleTableWrapper
      data={request.data.events}
      loading={request.isLoading}
      height={tableHeight}
      rowHeight={TABLE_ROW_HEIGHT.M}
      headerHeight={TABLE_HEADER_HEIGHT}
    >
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans("general.space")} />
        <SimpleTextCell dataKey="space.name" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell name={trans("general.measure")} />
        <SimpleTextCell dataKey="measureLabel" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={trans(buildTransKey("table.headers.event_date"))}
        />
        <SimpleDateCell showDiff={false} dataKey="event_date" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={trans(buildTransKey("table.headers.event_begin"))}
        />
        <SimpleDateCell
          showDiff={false}
          dataKey="event_begin"
          formatString="HH:mm:ss"
        />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={trans(buildTransKey("table.headers.event_end"))}
        />
        <SimpleDateCell
          showDiff={false}
          dataKey="event_end"
          formatString="HH:mm:ss"
        />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={trans(buildTransKey("table.headers.day_part"))}
        />
        <SimpleTextCell dataKey="event_day_part" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={trans(buildTransKey("table.headers.event_duration"))}
        />
        <SimpleTextCell dataKey="event_duration" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={`${trans(buildTransKey("table.headers.usage"))} (L)`}
        />
        <SimpleTextCell dataKey="event_usage" />
      </Table.Column>
      <Table.Column flexGrow={1}>
        <SimpleHeaderCell
          name={`${trans(buildTransKey("table.headers.source"))}`}
        />
        <SimpleTextCell dataKey="event_source" />
      </Table.Column>
    </SimpleTableWrapper>
  );
};

export default ConservationLabsEvents;
