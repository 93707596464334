import React from "react";

interface iProps {
  size?: number;
}

const PageBottomPadding: React.FC<iProps> = ({ size = 60 }) => {
  return <div style={{ height: `${size}px` }} />;
};

export default PageBottomPadding;
