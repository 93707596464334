import React from "react";
import { Tooltip, Whisper, WhisperProps } from "rsuite";

interface iProps extends Omit<WhisperProps, "speaker"> {
  tooltip: any;
  children: any;
  id: string;
}

const SimpleWhisperTooltip: React.FC<iProps> = ({
  children,
  tooltip,
  id,
  ...props
}) => {
  const tooltipSpeaker = <Tooltip>{tooltip}</Tooltip>;
  return (
    <Whisper
      controlId={id}
      trigger="hover"
      speaker={tooltipSpeaker}
      {...{ ...props }}
    >
      {children}
    </Whisper>
  );
};

export default SimpleWhisperTooltip;
