import React, { useContext, useEffect, useState } from "react";
import { ButtonGroup, ButtonProps } from "rsuite";
import { ReactComponent as ReadinessScoreIcon } from "../../../../../assets/icons/readiness_score.svg";
import Flex from "../../../../../components/Flex";
import PageSection from "../../../../../components/PageSection";
import SimpleButton from "../../../../../components/RsuiteWrapper/SimpleButton";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../../context/WindowSize/hooks/useWindowSizeState";
import nUseGetMeasures from "../../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { useGetBenchmarkData } from "../../../../../hooks/useGetBenchmarkData";
import { COLORS } from "../../../../../utils/colors";
import { SustainabilityContext } from "../../context";
import PerAccommodation from "./perAccommodations";
import PerGuest from "./perGuest";
import { tBenchmarkSizes } from "../../sustainability";

interface iMobileSlidesProps {
  perAccommodation: JSX.Element;
  perGuest: JSX.Element;
}

const MobileSlides: React.FC<iMobileSlidesProps> = ({
  perAccommodation,
  perGuest,
}) => {
  const { trans } = useLocalizationState();
  const [index, setIndex] = useState(0);

  const segments = [
    {
      key: "accommodation",
      transKey: "general.accommodations",
      jsx: perAccommodation,
    },
    { key: "guests", transKey: "general.guests", jsx: perGuest },
  ];

  const { jsx } = segments[index];

  return (
    <Flex column gap={8}>
      <ButtonGroup>
        {segments.map(({ key, transKey }, i) => {
          const [appearance, color, onClick]: [
            ButtonProps["appearance"],
            string,
            undefined | (() => void)
          ] =
            index === i
              ? ["primary", COLORS.white, undefined]
              : ["ghost", COLORS.primary, () => setIndex(i)];
          return (
            <SimpleButton
              {...{ key, appearance, onClick }}
              size="sm"
              text={{ text: trans(transKey), color }}
            />
          );
        })}
      </ButtonGroup>
      {jsx}
    </Flex>
  );
};

interface iBenchmarkWrappedProps extends iProps {}

const BenchmarkWrapped: React.FC<iBenchmarkWrappedProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
  benchmarkSizes,
}) => {
  const { isMobile } = useWindowSizeState();
  const benchmarkRequest = useGetBenchmarkData({ doRequest: !noData });
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);

  useEffect(() => {
    setDataTypeToggleDisabled({ benchmarkRequest: benchmarkRequest.isLoading });
  }, [benchmarkRequest.isLoading, setDataTypeToggleDisabled]);

  const perAccommodation = (
    <PerAccommodation
      {...{
        benchmarkRequest,
        homologueMeasuresRequest,
        measuresRequest,
        period,
        benchmarkSizes,
      }}
    />
  );
  const perGuest = (
    <PerGuest
      {...{
        benchmarkRequest,
        homologueMeasuresRequest,
        measuresRequest,
        noData,
        period,
        benchmarkSizes,
      }}
    />
  );

  if (isMobile) return <MobileSlides {...{ perAccommodation, perGuest }} />;

  return (
    <Flex row gap={16} between>
      {perAccommodation}
      {perGuest}
    </Flex>
  );
};

interface iProps {
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  period: [Date, Date];
  noData: boolean;
  benchmarkSizes: tBenchmarkSizes;
}

const Benchmark: React.FC<iProps> = ({
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
  benchmarkSizes,
}) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        icon={ReadinessScoreIcon}
        title={trans("pages.sustainability.sections.indicators.title")}
        description={trans(
          "pages.sustainability.sections.indicators.description"
        )}
      />
      <BenchmarkWrapped
        {...{
          homologueMeasuresRequest,
          measuresRequest,
          period,
          noData,
          benchmarkSizes,
        }}
      />
    </Flex>
  );
};

export default Benchmark;
