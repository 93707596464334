import { forwardRef } from "react";
import TextTag, { iTextProps } from "..";

export interface iDMSeriffTagProps extends iTextProps {}

const DMSeriffTag = forwardRef<HTMLParagraphElement, iDMSeriffTagProps>(
  (props, ref) => {
    return <TextTag {...props} ref={ref} font="dm-seriff" />;
  }
);

export default DMSeriffTag;
