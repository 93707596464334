import React, { useState } from "react";
import SimpleAddButton from "../RsuiteWrapper/SimpleButton/buttons/addButton";
import AddItemModal from "./addItemModal";

interface iProps {}

const AddButton: React.FC<iProps> = () => {
  const [modalState, setModalState] = useState({ open: false });

  return (
    <>
      <AddItemModal
        open={modalState.open}
        onClose={() => setModalState({ open: false })}
      />
      <SimpleAddButton
        onClick={() => {
          setModalState({ open: true });
        }}
      />
    </>
  );
};

export default AddButton;
