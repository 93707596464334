import axios from "axios";
import React, { Fragment, useState } from "react";
import { Checkbox, Input, InputGroup, useToaster } from "rsuite";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PrivacyNoticeModal from "../../../components/Modals/PrivacyNoticeModal";
import TermsOfServiceModal from "../../../components/Modals/TermsOfService";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useApiRequest from "../../../hooks/apiRequests/useApiRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { tRegisterState2 } from "./register";

type tDataKeys = keyof Pick<tRegisterState2["data"], "email" | "termsAccepted">;

interface iProps {
  state: Pick<tRegisterState2["data"], tDataKeys>;
  errors: Pick<tRegisterState2["errors"], tDataKeys>;
  onChange(key: tDataKeys, value: any): void;
  setErrors(errors: Partial<Pick<tRegisterState2["errors"], tDataKeys>>): void;
  onConfirm(): void;
  isLaptop: boolean;
}

const Step1: React.FC<iProps> = ({
  state,
  errors,
  onChange,
  setErrors,
  onConfirm,
  isLaptop,
}) => {
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const verifyRequest = useApiRequest();
  const [privacyNoticeModal, setPrivacyNoticeModal] = useState({ open: false });
  const [termsOfServiceModal, setTermsOfServiceModal] = useState({
    open: false,
  });

  const onClickSignUp = () => {
    const errorsObj: Partial<typeof errors> = {};
    if (!state.termsAccepted) {
      errorsObj.termsAccepted = trans(
        "pages.register.errors.terms_not_accepted"
      );
    }

    if (!state.email.length) {
      errorsObj.email = trans("pages.register.errors.no_email");
    } else if (
      !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(state.email)
    ) {
      errorsObj.email = trans("pages.register.errors.invalid_email");
    }
    if (Object.keys(errorsObj).length) {
      return setErrors(errorsObj);
    }

    verifyRequest.pending();
    axios
      .get(`${apiAddress(false)}/v2/managers/verify-email`, {
        params: { email: state.email },
      })
      .then((res) => {
        verifyRequest.resolve();
        onConfirm();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        verifyRequest.reject(error);
        toaster.push(notification("error", error), {
          placement: "topEnd",
        });
      });
  };

  const renderForm = () => (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onClickSignUp();
      }}
    >
      <Flex column gap={4}>
        <Flex gap={4} className="width-100" column>
          <InputWrapper
            required
            label={trans("general.email")}
            error={errors.email}
          >
            <InputGroup disabled={verifyRequest.isLoading}>
              <InputGroup.Addon className="bg-white">
                <Icon
                  Element={MailIcon}
                  width={24}
                  height={24}
                  fill={COLORS.secondary}
                />
              </InputGroup.Addon>
              <Input
                type="email"
                name="email"
                className="bg-white"
                value={state.email}
                onChange={(value) => onChange("email", value)}
                // type="text"
                placeholder={trans("general.placeholders.email")}
                size="lg"
              />
            </InputGroup>
          </InputWrapper>
        </Flex>
        <Flex column gap={0}>
          <Flex row gap={8} middle>
            <Checkbox
              disabled={verifyRequest.isLoading}
              checked={state.termsAccepted}
              onChange={(_, checked) => {
                onChange("termsAccepted", checked);
              }}
            />
            <Flex wrap row gap={4} style={{ marginTop: "4px" }}>
              <InterTag
                text={trans("pages.register.checkbox[0]")}
                color={COLORS.gray_400}
                size={12}
                onClick={() => onChange("termsAccepted", !state.termsAccepted)}
              />
              <InterTag
                text={trans("pages.register.checkbox[1]")}
                color={COLORS.primary}
                size={12}
                onClick={() => {
                  if (!verifyRequest.isLoading)
                    setTermsOfServiceModal({ open: true });
                }}
                hoverUnderline
              />
              <InterTag
                text={trans("pages.register.checkbox[2]")}
                color={COLORS.gray_400}
                size={12}
                onClick={() => onChange("termsAccepted", !state.termsAccepted)}
              />
              <InterTag
                text={trans("pages.register.checkbox[3]")}
                color={COLORS.primary}
                size={12}
                onClick={() => {
                  if (!verifyRequest.isLoading)
                    setPrivacyNoticeModal({ open: true });
                }}
                hoverUnderline
              />
            </Flex>
          </Flex>
          {errors.termsAccepted.length > 0 && (
            <InterTag
              wrap
              text={errors.termsAccepted}
              color={COLORS.error}
              size={10}
            />
          )}
        </Flex>
      </Flex>
      <input type="submit" hidden />
    </form>
  );

  const renderGreetings = (size: number) => (
    <Flex gap={8} column middle>
      <InterTag
        wrap
        bold
        text={trans("pages.register.greetings[0]")}
        size={size}
        color={COLORS.secondary}
      />
      <InterTag
        style={{ textAlign: "center" }}
        bold
        wrap
        text={trans("pages.register.greetings[1]")}
        size={size}
        color={COLORS.secondary}
      />
    </Flex>
  );

  const renderButton = () => (
    <SimpleButton
      text={{
        text: trans("general.signup"),
        color: COLORS.white,
        bold: true,
        size: 20,
      }}
      appearance="primary"
      onClick={onClickSignUp}
      loading={verifyRequest.isLoading}
    />
  );

  const mainRender = () => {
    if (isLaptop)
      return (
        <Flex column gap={20}>
          {renderGreetings(32)}
          <div className="width-100" style={{ marginTop: "32px" }}>
            {renderForm()}
          </div>
          {renderButton()}
        </Flex>
      );
    return (
      <Flex column gap={40}>
        {renderGreetings(24)}
        <div style={{ padding: "0 8px" }}>{renderForm()}</div>
        <Flex column style={{ padding: "0 8px" }}>
          {renderButton()}
        </Flex>
      </Flex>
    );
  };

  return (
    <Fragment>
      <PrivacyNoticeModal
        open={privacyNoticeModal.open}
        language="en"
        onClose={() => setPrivacyNoticeModal({ open: false })}
      />
      <TermsOfServiceModal
        open={termsOfServiceModal.open}
        language="en"
        onClose={() => setTermsOfServiceModal({ open: false })}
      />
      {mainRender()}
    </Fragment>
  );
};

export default Step1;
