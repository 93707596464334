import React from "react";
import { ContentDimensionsStateContext } from "../";

const useContentDimensionsState = () => {
  const state = React.useContext(ContentDimensionsStateContext);

  if (!state)
    throw Error(
      "useContentDimensionsState must be used within ContentDimensionsStateContext"
    );

  return { ...state };
};

export default useContentDimensionsState;
