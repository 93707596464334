import { useCallback, useState } from "react";
import { toaster } from "rsuite";
import { nRequestStatus, tDeleteRequest } from "../../interfaces/apiCalls";
import {
  REQUEST_STATUS,
  setRequestStatePending,
  setRequestStateResolved,
} from "../../utils/apiCall";
import { notification } from "../../utils/notifications";

export type tUseDeleteRequest = {
  error: string | null;
  status: nRequestStatus.tStatus;
  reject(error: string, toast?: boolean): void;
  resolve(): void;
  pending(): void;
  isIdle: boolean;
  isLoading: boolean;
  isResolved: boolean;
  isRejected: boolean;
  isFinal: boolean;
};

function useDeleteRequest(
  {
    status = REQUEST_STATUS.IDLE,
    error = null,
  }: {
    status?: nRequestStatus.tStatus;
    error?: string | null;
  } = { status: REQUEST_STATUS.IDLE, error: null }
): tUseDeleteRequest {
  const [state, setState] = useState<tDeleteRequest>({ status, error });

  const reject = useCallback((error: string, toast: boolean = false) => {
    if (toast && error) {
      toaster.push(notification("error", error));
    }
    setState((prev) => ({
      ...prev,
      error,
      status: REQUEST_STATUS.REJECTED,
    }));
  }, []);
  const resolve = () => setRequestStateResolved(setState);
  const pending = () => setRequestStatePending(setState);

  const isIdle = state.status === REQUEST_STATUS.IDLE;
  const isLoading = state.status === REQUEST_STATUS.PENDING;
  const isResolved = state.status === REQUEST_STATUS.RESOLVED;
  const isRejected = state.status === REQUEST_STATUS.REJECTED;
  const isFinal = isRejected || isResolved;

  return {
    error: state.error,
    status: state.status,
    reject,
    resolve,
    pending,
    isIdle,
    isLoading,
    isResolved,
    isRejected,
    isFinal,
  };
}

export default useDeleteRequest;
