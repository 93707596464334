import axios from "axios";
import { chunk } from "lodash";
import React, { useCallback } from "react";
import { Col, Grid, Loader, Row, Tooltip, Whisper } from "rsuite";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import { tGuest } from "../../../../models/guest";
import { tReservation } from "../../../../models/reservation";
import { apiAddress } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";

type tLocalGuest = Pick<
  tGuest,
  "_id" | "email" | "name" | "images" | "username"
>;

interface iProps {
  reservationId: tReservation["_id"];
  mainGuest?: tReservation["mainGuest"];
  additionalGuests?: tReservation["additionalGuests"];
}

const GuestInformation: React.FC<iProps> = ({
  reservationId,
  mainGuest,
  additionalGuests,
}) => {
  const getRequest = useGetRequest<{
    mainGuest: tLocalGuest | null;
    additionalGuests: tLocalGuest[];
  }>({ mainGuest: null, additionalGuests: [] });
  const { trans } = useLocalizationState();

  const load = useCallback(() => {
    getRequest.pending();
    axios
      .get(`${apiAddress(false)}/v2/stays/${reservationId}/guests`)
      .then((res) => {
        const {
          data: { mainGuest, additionalGuests },
        } = res;
        getRequest.resolve({ mainGuest, additionalGuests });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        getRequest.reject(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId, trans]);

  useEffectSafe(() => {
    load();
  }, [load]);

  const mainGuestCard = () => {
    return guestCard(trans("general.guest_main"), getRequest.data.mainGuest);
  };

  const additionalGuestCard = (x: number, y: number, rowSize: number) => {
    const guest = getRequest.data.additionalGuests[x + y * rowSize - 1];

    return guestCard("", guest);
  };

  const guestCard = (label: string, guest: tLocalGuest | null) => {
    if (!guest) return;

    const { name, username, email } = guest;

    const displayText = name || username;

    return (
      <Flex className="card-m" column gap={8}>
        <Flex between className="width-100">
          <InterTag text={label} />
        </Flex>
        <Flex column between gap={4}>
          <Whisper
            trigger="hover"
            placement="topStart"
            speaker={<Tooltip>{displayText}</Tooltip>}
          >
            <InterTag
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineHeight: "36px",
              }}
              text={displayText}
              color={COLORS.secondary}
              size={30}
            />
          </Whisper>
          <InterTag text={email} color={COLORS.primary} size={16} />
        </Flex>
      </Flex>
    );
  };

  const chunkSize = 2;

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("pages.reservation.sections.guest_information.title")}
        icon={GroupIcon}
      />
      <div>
        {getRequest.isLoading ? (
          <Flex center middle>
            <Loader size="sm" />
          </Flex>
        ) : getRequest.data.mainGuest ||
          getRequest.data.additionalGuests.length ? (
          <Grid fluid>
            {mainGuest &&
              chunk([mainGuest, ...(additionalGuests || [])], chunkSize).map(
                (batch, i) => (
                  <Row key={i} gutter={16}>
                    {batch.map((elem, k) => (
                      <Col key={k} sm={12}>
                        {i === 0 && k === 0
                          ? mainGuestCard()
                          : additionalGuestCard(i, k, chunkSize)}
                      </Col>
                    ))}
                  </Row>
                )
              )}
          </Grid>
        ) : (
          <Flex>
            <InterTag
              text={
                getRequest.error ||
                trans(
                  "pages.reservation.sections.guest_information.errors.no_information"
                )
              }
              color={COLORS.hot}
            />
          </Flex>
        )}
      </div>
    </Flex>
  );
};

export default GuestInformation;
