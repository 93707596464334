import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Grid, Row } from "rsuite";
import { ReactComponent as DashboardIcon } from "../../../../assets/icons/dashboard.svg";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import SimpleDateRangePickerForManualDataPeriods2 from "../../../../components/RsuiteWrapper/SimpleDateRangePickerForManualDataPeriods2";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import CheckInCheckOut from "../checkInCheckOut";
import ConsumptionCards from "./consumptionCards";
import OccupancyCard from "./occupancyCard";

const buildTransKey = (key: string) => `pages.overview.sections.summary.${key}`;

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const Archives: React.FC<iProps> = ({ dataTypeToggle }) => {
  const { trans } = useLocalizationState();
  const { isMobile } = useWindowSizeState();
  const {
    activeSubscriptionIsStarter,
    hotel: { manualData },
  } = useHotelSubscriptionState();
  const [manualDataIndex, setManualDataIndex] = useState(manualData.length - 1);

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  const [colBreakpoints] = isMobile
    ? [{ xs: 24, sm: 24 }]
    : [{ sm: 12, xxl: 8 }];

  return (
    <div>
      <SecondaryTopBar>
        <Flex row middle gap={8}>
          {dataTypeToggle}
          <SimpleDateRangePickerForManualDataPeriods2
            {...{ manualDataIndex, setManualDataIndex }}
          />
        </Flex>
      </SecondaryTopBar>
      <PageSection
        icon={DashboardIcon}
        title={trans(buildTransKey("title"))}
        description={trans(buildTransKey("description"))}
      >
        <div>
          <Grid fluid>
            <Row gutter={16}>
              {!activeSubscriptionIsStarter && (
                <Col {...colBreakpoints}>
                  <CheckInCheckOut
                    period={[
                      moment(selectedManualData.from).toDate(),
                      moment(selectedManualData.to).toDate(),
                    ]}
                  />
                </Col>
              )}
              <Col {...colBreakpoints}>
                <OccupancyCard manualDataEntry={selectedManualData} />
              </Col>
            </Row>
            <ConsumptionCards manualDataEntry={selectedManualData} />
          </Grid>
        </div>
      </PageSection>
    </div>
  );
};

export default Archives;
