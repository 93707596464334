import { forwardRef } from "react";
import { Table } from "rsuite";

interface iBaseCellProps {
  [key: string]: any;
}

const BaseCell = forwardRef<any, iBaseCellProps>((props, ref) => {
  const { children, rowData, onDoubleClick, dataKey, rowIndex, ...rest } =
    props;
  return (
    <Table.Cell
      ref={ref}
      onDoubleClick={() =>
        onDoubleClick && onDoubleClick(rowData, dataKey, rowIndex)
      }
      {...{
        dataKey,
        rowData,
        rowIndex,
        ...rest,
        style: {
          ...rest.style,
          ...(onDoubleClick ? { cursor: "pointer" } : {}),
        },
      }}
    >
      {children}
    </Table.Cell>
  );
});

export default BaseCell;
