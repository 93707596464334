import React, { forwardRef } from "react";

export interface iFlexProps {
  row?: boolean;
  column?: boolean;

  // Horizontal Alignment
  left?: boolean;
  center?: boolean;
  right?: boolean;
  between?: boolean;
  around?: boolean;
  evenly?: boolean;

  // Vertical Alignment
  stretch?: boolean;
  top?: boolean;
  middle?: boolean;
  bottom?: boolean;
  baseline?: boolean;

  gap?: number;
  basis?: number;
  grow?: number;
  shrink?: number;
  one?: boolean;

  wrap?: boolean;

  color?: string;
  style?: React.CSSProperties;
  className?: string;

  onClick?(e?: any): void;

  id?: string;
  children: React.ReactNode;
  [key: string]: any;
}

const Flex = forwardRef<HTMLDivElement, iFlexProps>(
  (
    {
      row,
      column,

      left,
      center,
      right,
      between,
      around,
      evenly,

      stretch = true,
      top,
      middle,
      bottom,
      baseline,

      gap,
      basis,
      grow,
      shrink,
      one,

      wrap,

      color,
      style,
      className: classname,

      onClick,

      id,
      children,
      ...rest
    },
    ref
  ) => {
    const styles: React.CSSProperties = { display: "flex" };

    if (row) styles.flexDirection = "row";
    if (column) styles.flexDirection = "column";

    if (left) styles.justifyContent = "flex-start";
    if (center) styles.justifyContent = "center";
    if (right) styles.justifyContent = "flex-end";
    if (between) styles.justifyContent = "space-between";
    if (around) styles.justifyContent = "space-around";
    if (evenly) styles.justifyContent = "space-evenly";

    if (stretch) styles.alignItems = "stretch";
    if (top) styles.alignItems = "flex-start";
    if (bottom) styles.alignItems = "flex-end";
    if (middle) styles.alignItems = "center";
    if (baseline) styles.alignItems = "baseline";

    if (grow) styles.flexGrow = grow;
    if (shrink) styles.flexShrink = shrink;
    if (basis) styles.flexBasis = `${basis}%`;
    if (one) styles.flex = "1";

    if (wrap) styles.flexWrap = "wrap";

    if (color) styles.backgroundColor = color;

    const className = `${classname ? ` ${classname}` : ""}`;

    return (
      <div
        ref={ref}
        className={className}
        id={id}
        style={{ ...styles, ...(gap ? { gap: `${gap}px` } : {}), ...style }}
        onClick={onClick}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

export default Flex;
