import React from "react";
import { Loader, Panel, PanelGroup } from "rsuite";
import { ReactComponent as ApartmentIcon } from "../../../assets/icons/apartment.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/icons/logout.svg";
import { ReactComponent as PaymentsIcon } from "../../../assets/icons/payments.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/icons/security.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as SettingsAccountBoxIcon } from "../../../assets/icons/settings_account_box.svg";
import { ReactComponent as NoytrallSymbol } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSection from "../../../components/PageSection";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import AccommodationSettings from "./accommodation";
import BillingSettings from "./billing";
import GeneralSettings from "./general";
import PersonalSettings from "./personal";
import SecuritySettins from "./security";
import SessionSettings from "./session";
import styles from "./styles.module.css";

const Settings: React.FC = () => {
  const { trans } = useLocalizationState();
  const {
    hotel: { ipValidated },
    hotelIsLoaded,
  } = useHotelState();

  const renderPanel = (
    title: string,
    description: string,
    icon: tIconElement,
    Component: React.FC,
    id: string,
    loading: boolean
  ) => {
    return (
      <Panel
        id={id}
        key={id}
        defaultExpanded
        header={
          <PageSection
            style={{ width: "100%" }}
            title={title}
            description={description}
            icon={icon}
          />
        }
      >
        {loading ? (
          <Flex middle center>
            <Loader />
          </Flex>
        ) : (
          <div className={styles["settings-panel"]}>
            <Component />
          </div>
        )}
      </Panel>
    );
  };

  const panels: {
    transKeyword: string;
    icon: tIconElement;
    Component: React.FC;
    id: string;
    loading: boolean;
    hide?: boolean;
  }[] = [
    {
      transKeyword: "general",
      icon: SettingsIcon,
      Component: GeneralSettings,
      id: "settings-general",
      loading: false,
    },
    {
      transKeyword: "personal",
      icon: SettingsAccountBoxIcon,
      Component: PersonalSettings,
      id: "settings-personal",
      loading: false,
    },
    {
      transKeyword: "accommodation",
      icon: ApartmentIcon,
      Component: AccommodationSettings,
      id: "settings-accommodation",
      hide: !ipValidated,
      loading: !hotelIsLoaded,
    },
    {
      transKeyword: "billing",
      icon: PaymentsIcon,
      Component: BillingSettings,
      id: "settings-billing",
      hide: !ipValidated,
      loading: !hotelIsLoaded,
    },
    {
      transKeyword: "security",
      icon: SecurityIcon,
      Component: SecuritySettins,
      id: "settings-security",
      hide: !ipValidated,
      loading: !hotelIsLoaded,
    },
    {
      transKeyword: "session",
      icon: LogoutIcon,
      Component: SessionSettings,
      id: "settings-session",
      loading: false,
    },
  ];

  return (
    <div className={styles.settings}>
      <TopBar page="general.settings" hideSettingsButton />
      <Flex column gap={40}>
        <PanelGroup accordion bordered={false}>
          {panels
            .filter((p) => !p.hide)
            .map(({ Component, icon, id, transKeyword, loading }) => {
              return renderPanel(
                trans(`pages.settings.panels.${transKeyword}.title`),
                trans(`pages.settings.panels.${transKeyword}.description`),
                icon,
                Component,
                id,
                loading
              );
            })}
        </PanelGroup>
        <Flex row gap={16} middle>
          <Icon Element={NoytrallSymbol} height={32} width={23.95} />
          <Flex column gap={4}>
            <InterTag
              text={trans("noytrall technologies")}
              size={12}
              color={COLORS.secondary}
            />
            <InterTag
              text={`${trans("general.version_x", { parameters: ["1.0.1"] })}`}
              size={12}
              color={COLORS.secondary}
            />
          </Flex>
        </Flex>
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

export default Settings;
