import { chunk } from "lodash";

export const thousandsSeparation = (number: number | string) => {
  try {
    if (number === "Infinity" || number === Infinity) return number;

    const [integer, decimal] = `${number}`.split(".");
    const thousands = chunk(integer.split("").reverse(), 3)
      .map((a) => a.join(""))
      .join(",")
      .split("")
      .reverse()
      .join("");

    if (decimal) return `${thousands}.${decimal}`;
    return thousands;
  } catch (err: any) {
    return number;
  }
};
