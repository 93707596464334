import tHotel from "../models/hotel";

const STRIPE_PK_TEST =
  "pk_test_51OrR4nBS9t446aUMFiyo3OWwkX3aIEPQEPFc51mDhfxEb1We1lOQVOCVPP7gBIKXuKNPDjxKzKKUuGVZpCoE8QXt00LeOYwBNQ";

const STRIPE_PK_LIVE =
  "pk_live_51OrR4nBS9t446aUMZJxnHPGMg18BoIMCjpjno6f5i3i8mXuIi4SGhlF4rO0stG6xbWc0n0JSpqdWL0uDVjZfjzPi00nQIdmOOm";

export const STRIPE_ENV: "live" | "test" | string = "live";

export const [HOTEL_STRIPE_PROPERTY, STRIPE_PK]: [
  keyof Pick<tHotel, "stripeLive" | "stripeTest">,
  string
] =
  STRIPE_ENV === "live"
    ? ["stripeLive", STRIPE_PK_LIVE]
    : ["stripeTest", STRIPE_PK_TEST];
