import { max } from "lodash";

export const add2Arrays = (arr1: number[], arr2: number[]): number[] => {
  const list: number[] = [];

  const upper = max([arr1.length, arr2.length]) || 0;
  for (let i = 0; i < upper; i++) {
    list[i] = (arr1[i] || 0) + (arr2[i] || 0);
  }

  return list;
};

export function compareArrays<T>(...arrays: T[][]) {
  for (let i = 1; i < arrays.length; i++) {
    const array1 = arrays[i - 1].sort();
    const array2 = arrays[i].sort();

    if (array1.length !== array2.length) return false;

    for (let j = 0; j < array1.length; j++) {
      if (array1[j] !== array2[j]) return false;
    }
  }
  return true;
}
