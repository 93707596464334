import React from "react";
import { ReactComponent as ApprovalDelegationIcon } from "../../../assets/icons/approval_delegation.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import { ReactComponent as VolunteerActivismIcon } from "../../../assets/icons/volunteer_activism.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageSection from "../../../components/PageSection";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";

const AwardsStatisticsWrapped: React.FC = () => {
  const { trans } = useLocalizationState();
  const {
    hotel: { awards },
  } = useHotelState();

  const [totalGiven, totalUsed] = awards
    .map((a) => [a.timesAwarded, a.timesUsed])
    .reduce(
      (acc, value) => {
        return [acc[0] + value[0], acc[1] + value[1]];
      },
      [0, 0]
    );

  const info = [
    {
      label: "pages.awards.sections.statistics.cards.given.title",
      Icon: VolunteerActivismIcon,
      value: totalGiven,
    },
    {
      label: "pages.awards.sections.statistics.cards.used.title",
      Icon: ApprovalDelegationIcon,
      value: totalUsed,
    },
  ];

  return (
    <Flex row gap={24}>
      {info.map(({ label, Icon: Element, value }) => (
        <Flex basis={22} className="card-m" column gap={22} key={label}>
          <Flex row between>
            <InterTag size={20} color={COLORS.secondary} text={trans(label)} />
            <Icon size={24} fill={COLORS.secondary} Element={Element} />
          </Flex>
          <InterTag text={value} color={COLORS.secondary} size={36} />
        </Flex>
      ))}
    </Flex>
  );
};

const AwardsStatistics: React.FC = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={{ text: trans("pages.awards.sections.statistics.title") }}
        description={{
          text: trans("pages.awards.sections.statistics.description"),
        }}
        icon={{ Element: QueryStatsIcon }}
      />
      <AwardsStatisticsWrapped />
    </Flex>
  );
};

export default AwardsStatistics;
