import React, { PropsWithChildren } from "react";
import { TableProps } from "rsuite";
import { Table } from "rsuite-table";
import { RowDataType } from "rsuite/esm/Table";

interface iProps<T = any> extends TableProps<RowDataType<T>, string> {}

const SimpleTableWrapper: React.FC<PropsWithChildren<iProps>> = ({
  children,
  ...props
}) => {
  return (
    <div className="table-wrapper">
      <Table {...props}>{children}</Table>
    </div>
  );
};

export default SimpleTableWrapper;
