import React, { useContext, useEffect, useState } from "react";
import AddManualDataButton from "../../../../components/Buttons/addManualDataButton";
import Flex from "../../../../components/Flex";
import GettingStarted, {
  GettingStartedFinalModal,
} from "../../../../components/GettingStarted";
import ManualDataModal from "../../../../components/Modals/ManualDataModal";
import RoadmapTable from "../../../../components/RoadmapTable";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import useProfilePermissions from "../../../../hooks/useProfilePermissions";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { SustainabilityContext } from "../context";
import { tBenchmarkSizes } from "../sustainability";
import Benchmark from "./benchmark";
import Indicators from "./indicators";

interface iProps {
  dataTypeToggle: any;
  benchmarkSizes: tBenchmarkSizes;
}

const ManualData: React.FC<iProps> = ({ dataTypeToggle, benchmarkSizes }) => {
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const [manualDataModal, setManualDataModal] = useState(false);
  const profilePermissions = useProfilePermissions();
  const {
    hotel: { manualData },
  } = useHotelManualDataState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: manualData.length > 0,
    initialStatus: manualData.length
      ? REQUEST_STATUS.PENDING
      : REQUEST_STATUS.IDLE,
  });

  const [gettingStartedFinalModal, setGettingStartedFinalModal] =
    useState(false);

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <>
      <GettingStartedFinalModal
        open={gettingStartedFinalModal}
        onClose={() => setGettingStartedFinalModal(false)}
      />
      <ManualDataModal
        open={manualDataModal}
        onClose={() => {
          setManualDataModal(false);
        }}
      />
      {manualData.length === 0 ? (
        <GettingStarted
          startModalOpen={true}
          onInsert={() => setGettingStartedFinalModal(true)}
        />
      ) : (
        <div>
          <SecondaryTopBar>
            <Flex row gap={8} middle>
              {dataTypeToggle && <Flex>{dataTypeToggle}</Flex>}
              {profilePermissions.writeManualData && (
                <AddManualDataButton onClick={() => setManualDataModal(true)} />
              )}
            </Flex>
          </SecondaryTopBar>
          <Flex column gap={40}>
            <Benchmark {...{ conversionFactorsRequest, benchmarkSizes }} />
            <Indicators {...{ conversionFactorsRequest }} />
            <RoadmapTable />
          </Flex>
        </div>
      )}
    </>
  );
};

export default ManualData;
