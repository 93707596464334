import { max, min, round } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useState } from "react";
import { ReactComponent as ArrowDropDownIcon } from "../../../../assets/icons/arrow_drop_down.svg";
import { ReactComponent as DonutLargeIcon } from "../../../../assets/icons/donut_large.svg";
import { ReactComponent as EqualizerIcon } from "../../../../assets/icons/equalizer.svg";
import { ReactComponent as KeyVisualizerIcon } from "../../../../assets/icons/key_visualizer.svg";
import { ReactComponent as LineAxisIcon } from "../../../../assets/icons/line_axis.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import ApexChart from "../../../../components/Graphs/Apex/Chart";
import Icon from "../../../../components/Icons/Icon";
import { toIconProps } from "../../../../components/Icons/Icon/utils";
import PageSection from "../../../../components/PageSection";
import SimpleWhisperPopoverDropdown from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../components/Text/Inter";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import { tUseGetRequest } from "../../../../hooks/apiRequests/useGetRequest";
import { tIconElement } from "../../../../interfaces/icon";
import { tBinUnitSingular } from "../../../../interfaces/sensorData";
import { COLORS, hex2hexA } from "../../../../utils/colors";
import { generateGraphCategories } from "../../../../utils/graphs";
import { tData } from "./";

interface iOccupancyHeatmapWrappedProps
  extends Pick<
    iProps,
    | "occupancyRequest"
    | "homologueOccupancyRequest"
    | "binUnit"
    | "binValue"
    | "from"
    | "to"
  > {}

const OccupancyHeatmapWrapped: React.FC<iOccupancyHeatmapWrappedProps> = ({
  occupancyRequest,
  homologueOccupancyRequest,
  binUnit,
  binValue,
  from,
  to,
}) => {
  const { trans } = useLocalizationState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);
  const [selectedGraphType, setSelectedGraphType] = useState(0);

  const graphTypeOptions: { key: string; label: string; icon: tIconElement }[] =
    [
      {
        key: "heatmap",
        label: trans("general.graph_types.heatmap"),
        icon: DonutLargeIcon,
      },
      {
        key: "line",
        label: trans("general.graph_types.line"),
        icon: LineAxisIcon,
      },
      {
        key: "bar",
        label: trans("general.graph_types.bar"),
        icon: EqualizerIcon,
      },
    ];

  const segments: {
    label: string;
    key: keyof tData["perUnitOfTime"];
    unit: string;
    graphMax?: number;
    graphMin?: number;
  }[] = [
    { label: trans("general.guests"), key: "guests", unit: "" },
    { label: trans("general.stays"), key: "stays", unit: "" },
    {
      label: trans("general.occupancy"),
      key: "occupancyRate",
      unit: "%",
      graphMax: 100,
      graphMin: 0,
    },
  ];

  const selectedSegment = segments[selectedSegmentIndex];

  const minHeatmapValue =
    min(occupancyRequest.data.perUnitOfTime[selectedSegment.key]) || 1;

  const maxHeatmapValue =
    selectedSegment.graphMax ||
    max(occupancyRequest.data.perUnitOfTime[selectedSegment.key]);

  const renderSegments = () => {
    return segments.map(({ label, key, unit }, i) => {
      let labelColor = COLORS.gray_400;
      let valueColor = COLORS.secondary;

      if (selectedSegmentIndex === i) {
        labelColor = COLORS.primary;
        valueColor = COLORS.primary;
      }

      return (
        <Flex
          basis={10}
          column
          key={key}
          onClick={() => {
            if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
          }}
          style={{ cursor: "pointer", padding: "8px", borderRadius: "4px" }}
          className="hover-darken-white-bg"
          gap={4}
        >
          <InterTag size={12} text={trans(label)} color={labelColor} />
          <Flex column gap={4}>
            {occupancyRequest.isLoading ? (
              <>
                <SkeletonText width={"80%"} height={36} />
                <SkeletonText width={"80%"} height={16} />
              </>
            ) : occupancyRequest.isResolved ? (
              <>
                <Flex row gap={4} bottom>
                  <InterTag
                    size={36}
                    text={round(occupancyRequest.data.total[key], 1)}
                    color={valueColor}
                  />
                  {unit && (
                    <InterTag text={unit} color={valueColor} size={16} />
                  )}
                </Flex>
                {homologueOccupancyRequest.isLoading ? (
                  <SkeletonText width={"80%"} height={16} />
                ) : homologueOccupancyRequest.isResolved ? (
                  <TrendingComparison
                    baseValue={homologueOccupancyRequest.data.total[key]}
                    value={occupancyRequest.data.total[key]}
                  />
                ) : null}
              </>
            ) : null}
          </Flex>
        </Flex>
      );
    });
  };

  const graph = () => {
    if (occupancyRequest.isLoading)
      return <SkeletonText width={"100%"} height={288} />;

    const renderHeatmap = () => {
      const data = occupancyRequest.data.perUnitOfTime[selectedSegment.key];

      const diffOfDays =
        moment(to).endOf("day").diff(moment(from).startOf("day"), "days") + 1;

      const categories: number[] = (() => {
        const createArray = (numberOfElements: number) => {
          const array: number[] = [];
          for (let i = 0; i < numberOfElements; i++) {
            array.push(i);
          }
          return array;
        };

        if (diffOfDays <= 31) return createArray(7);
        return createArray(31);
      })();

      const startDate = moment(from);

      const series: { name: string; data: { x: string; y: number }[] }[] = [];
      if (diffOfDays <= 31) {
        series.push({
          name: moment(startDate).startOf("week").format("DD/MM/YYYY"),
          data: [],
        });

        for (let i = 0; i < moment(startDate).weekday(); i++) {
          series[0].data.push({ x: "", y: 0 });
        }

        for (let j = 0; j < diffOfDays; j++) {
          if (series[0].data.length === 7)
            series.unshift({
              name: moment(startDate)
                .add(series.length, "weeks")
                .startOf("week")
                .format("DD/MM/YYYY"),
              data: [],
            });
          series[0].data.push({ x: "", y: data[j] });
        }
      } else {
        series.push({
          name: moment(startDate).startOf("month").format("MM/YYYY"),
          data: [],
        });

        for (let i = 1; i < moment(startDate).date(); i++) {
          series[0].data.push({ x: "", y: 0 });
        }

        for (let j = 0; j < diffOfDays; j++) {
          const numberOfDaysInMonth = moment(startDate)
            .add(series.length - 1, "months")
            .endOf("month")
            .date();
          if (series[0].data.length === numberOfDaysInMonth) {
            const newElem = {
              name: moment(startDate)
                .add(series.length, "months")
                .startOf("month")
                .format("MM/YYYY"),
              data: [],
            };
            series.unshift(newElem);
          }
          series[0].data.push({ x: "", y: data[j] });
        }
      }

      return (
        <Flex column gap={0}>
          <ApexChart
            key={"heatmap"}
            {...{
              type: "heatmap",
              series,
              yaxis: {
                labels: {
                  show: true,
                  formatter(val: any, opts: any) {
                    return val;
                  },
                },
              },
              colors: [COLORS.primary],
              xaxis: {
                categories,
                labels: {
                  show: true,
                  formatter(value: any, timestamp: any, opts: any) {
                    if (diffOfDays <= 31)
                      return moment()
                        .startOf("week")
                        .add(value, "days")
                        .format("dd");

                    return value + 1;
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              tooltip: {
                enabled: true,
                followCursor: true,
                x: {
                  show: true,
                  formatter(val: any, opts: any) {
                    // const date = categories[val - 1];
                    return "";
                    // return moment(date).format("DD MMM YYYY HH:mm");
                  },
                },
                y: {
                  title: {
                    formatter(seriesName: string) {
                      return seriesName;
                    },
                  },
                },
              },
              legend: {
                show: false,
                position: "bottom",
                showForSingleSeries: true,
                markers: {
                  customHTML() {
                    return `
                <div style="display: flex; flex-direction: row; gap: 4px">
                <div>
                ${minHeatmapValue}
                </div>
                <div style="width: 50%; height: 20px; background-image: linear-gradient(to right, ${hex2hexA(
                  COLORS.primary_500,
                  0
                )}, ${hex2hexA(COLORS.primary_500, 100)});"/>
                <div>
                ${maxHeatmapValue}
                    </div>
                    </div>
                    `;
                  },
                  width: 100,
                  height: 20,
                  radius: 2,
                  fillColors: [COLORS.white],
                  strokeColor: COLORS.primary_100,
                  strokeWidth: 1,
                },
                formatter(legendName, opts) {
                  return "";
                },
              },
              plotOptions: {
                heatmap: {
                  enableShades: true,
                  shadeIntensity: 1,
                  useFillColorAsStroke: false,
                  radius: 2,
                  colorScale: {
                    min: 0,
                    max: 100,
                    ranges: [
                      {
                        from: minHeatmapValue,
                        to: maxHeatmapValue,
                        color: COLORS.primary_500,
                      },
                    ],
                  },
                },
              },
            }}
          />
          <Flex row gap={8} middle center>
            <InterTag
              size={12}
              color={COLORS.secondary}
              text={`${0}${selectedSegment.unit || ""}`}
            />
            <div
              style={{
                backgroundImage: ` linear-gradient(to right, ${hex2hexA(
                  COLORS.primary_500,
                  0
                )}, ${hex2hexA(COLORS.primary_500, 100)})`,
                width: "100px",
                height: "15px",
                borderRadius: "2px",
                border: `1px solid ${COLORS.primary_50}`,
              }}
            />
            <InterTag
              size={12}
              color={COLORS.secondary}
              text={`${maxHeatmapValue}${selectedSegment.unit || ""}`}
            />
          </Flex>
        </Flex>
      );
    };
    const renderLineBar = (type: "line" | "bar") => {
      const categories = generateGraphCategories(binUnit, binValue, from, to);

      return (
        <ApexChart
          {...{
            type,
            series: [
              {
                name: "",
                data: occupancyRequest.data.perUnitOfTime[selectedSegment.key],
                color: COLORS.primary,
              },
            ],
            stroke: { width: 2 },
            yaxis: {
              labels: {
                show: true,
                formatter(val: any, opts: any) {
                  return `${round(val, 2)}${
                    selectedSegment.unit ? ` ${selectedSegment.unit}` : ""
                  }`;
                },
              },
            },
            xaxis: {
              categories,
              tickAmount: 5,
              labels: {
                rotate: 0,
                show: true,
                formatter(value: string, timestamp?: number, opts?: any) {
                  return moment(value).format("DD/MM/YYYY");
                },
              },
            },
            tooltip: {
              enabled: true,
              followCursor: true,
              x: {
                formatter(val: any, opts: any) {
                  const date = categories[opts.dataPointIndex];
                  return moment(date).format("DD/MMM/YYYY");
                },
              },
            },
            legend: {
              show: false,
            },
          }}
        />
      );
    };

    switch (graphTypeOptions[selectedGraphType].key) {
      case "heatmap":
        return renderHeatmap();
      case "line":
        return renderLineBar("line");
      case "bar":
        return renderLineBar("bar");
      default:
        return null;
    }
  };

  return (
    <Flex className="card-m" column>
      <Flex column gap={0}>
        <Flex row right>
          <SimpleWhisperPopoverDropdown
            options={graphTypeOptions.map(({ key, icon, label }, i) => ({
              key,
              icon,
              label,
              onClick() {
                setSelectedGraphType(i);
              },
            }))}
          >
            <Flex
              row
              gap={4}
              middle
              className="hover-darken-white-bg"
              style={{ padding: "4px", borderRadius: "8px", cursor: "pointer" }}
            >
              <Icon
                {...{
                  ...toIconProps(graphTypeOptions[selectedGraphType].icon),
                  fill: COLORS.primary,
                  size: 24,
                }}
              />
              <InterTag
                size={16}
                color={COLORS.secondary}
                text={graphTypeOptions[selectedGraphType].label}
              />
              <Icon
                Element={ArrowDropDownIcon}
                size={24}
                fill={COLORS.secondary}
              />
            </Flex>
          </SimpleWhisperPopoverDropdown>
        </Flex>
        <Flex row gap={20}>
          {renderSegments()}
        </Flex>
      </Flex>
      {graph()}
    </Flex>
  );
};

interface iProps {
  occupancyRequest: tUseGetRequest<tData>;
  homologueOccupancyRequest: tUseGetRequest<tData>;
  binUnit: tBinUnitSingular;
  binValue: number;
  from: MomentInput;
  to: MomentInput;
}

const OccupancyHeatmap: React.FC<iProps> = ({
  occupancyRequest,
  homologueOccupancyRequest,
  binUnit,
  binValue,
  from,
  to,
}) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        icon={{ Element: KeyVisualizerIcon }}
        title={{ text: trans("pages.occupancy.live.sections.heatmap.title") }}
        description={{
          text: trans("pages.occupancy.live.sections.heatmap.description"),
        }}
      />
      <OccupancyHeatmapWrapped
        {...{
          occupancyRequest,
          homologueOccupancyRequest,
          binUnit,
          binValue,
          from,
          to,
        }}
      />
    </Flex>
  );
};

export default OccupancyHeatmap;
