import axios from "axios";
import { min } from "lodash";
import React, { useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as ApprovalDelegationsIcon } from "../../../assets/icons/approval_delegation.svg";
import { ReactComponent as AvgPaceIcon } from "../../../assets/icons/avg_pace.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as HotelIcon } from "../../../assets/icons/hotel.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings.svg";
import { ReactComponent as TargetIcon } from "../../../assets/icons/target.svg";
import { ReactComponent as ToggleOffIcon } from "../../../assets/icons/toggle_off.svg";
import { ReactComponent as ToggleOnIcon } from "../../../assets/icons/toggle_on.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Flex from "../../../components/Flex";
import PageSection from "../../../components/PageSection";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleAwardsCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleAwardsCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tHotelLoyaltyProgramItem } from "../../../models/hotel";
import { apiAddress, constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import CreateEditLoyaltyProgramItem from "./createEditLoyaltyProgramItem";

type tTableItem = tHotelLoyaltyProgramItem;

interface iLoyaltyProgramWrappedProps {}

const LoyaltyProgramWrapped: React.FC<iLoyaltyProgramWrappedProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const deleteRequest = useDeleteRequest();
  const putRequest = usePutRequest();

  const [modal, setModal] = useState<
    { open: false } | { open: true; programId: tHotelLoyaltyProgramItem["_id"] }
  >({ open: false });

  const handleUpdateLoyaltyItem = (
    elem: tHotelLoyaltyProgramItem,
    body: Partial<
      Pick<tHotelLoyaltyProgramItem, "status" | "awards" | "category" | "goal">
    >
  ) => {
    putRequest.pending();
    axios
      .put(`${apiAddress(true)}/v2/hotels/${hotelId}/loyalty/${elem._id}`, body)
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        toaster.success(
          trans(
            "pages.loyalty.sections.loyalty_program.notifications.status_updated"
          )
        );
        updateHotel(hotelId, hotel);
        putRequest.resolve();
      })
      .catch((err) => {
        const {
          data: { hotel },
        } = err.response;
        const error = getErrorMessage(err, trans);

        if (hotel) {
          updateHotel(hotelId, hotel);
        } else {
          toaster.error(error);
        }
        putRequest.reject(error);
      });
  };

  const options = (
    dataKey: string,
    tableItem: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    options.push({
      key: "edit",
      icon: SettingsIcon,
      label: trans("general.configure"),
      onClick() {
        setModal({ open: true, programId: tableItem._id });
      },
    });

    switch (tableItem.status) {
      case "inactive":
        options.push({
          key: "toggle status",
          label: { text: trans("general.activate"), color: COLORS.info },
          icon: { Element: ToggleOnIcon, fill: COLORS.info },
          onClick() {
            handleUpdateLoyaltyItem(tableItem, { status: "active" });
          },
        });
        break;
      case "active":
        options.push({
          key: "toggle status",
          label: { text: trans("general.deactivate"), color: COLORS.info },
          icon: { Element: ToggleOffIcon, fill: COLORS.info },
          onClick() {
            handleUpdateLoyaltyItem(tableItem, { status: "inactive" });
          },
        });
        break;

      default:
        break;
    }

    options.push({
      key: "delete",
      icon: { Element: DeleteIcon, fill: COLORS.error },
      label: { text: trans("general.remove"), color: COLORS.error },
      onClick() {
        deleteRequest.pending();
        axios
          .delete(
            constructApiAddress(
              `/v2/hotels/${hotelId}/loyalty/${tableItem._id}`,
              true
            )
          )
          .then((res) => {
            const {
              data: { hotel },
            } = res;

            updateHotel(hotelId, hotel);
            deleteRequest.resolve();
            toaster.success(trans("general.removed_successfully"));
          })
          .catch((err) => {
            const {
              data: { hotel },
            } = err.response;
            const error = getErrorMessage(err, trans);
            if (hotel) {
              updateHotel(hotelId, hotel);
            } else {
              toaster.error(error);
            }
            deleteRequest.reject(error);
          });
      },
    });

    return options;
  };

  const tableData: tTableItem[] = hotel.loyaltyProgram;

  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight =
    (min([4, tableData.length || 1]) as number) * rowHeight + headerHeight;

  return (
    <>
      {modal.open && (
        <CreateEditLoyaltyProgramItem
          programId={modal.programId}
          open={true}
          onClose={() => setModal({ open: false })}
        />
      )}
      <div className="table-wrapper">
        <Table
          id={"TABLE.LOYALTY"}
          height={tableHeight}
          data={tableData}
          rowHeight={rowHeight}
          headerHeight={headerHeight}
          loading={deleteRequest.isLoading || putRequest.isLoading}
        >
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell icon={TargetIcon} name={trans("general.goal")} />
            <BaseCell>
              {(rowData: tTableItem) => {
                const { goal, category } = rowData;

                const text =
                  goal > 1
                    ? trans(
                        category === "nights"
                          ? "pages.loyalty.sections.loyalty_program.table.columns.goal.guests_achieve_x_nights"
                          : "pages.loyalty.sections.loyalty_program.table.columns.goal.guests_achieve_x_stays",
                        { parameters: [goal] }
                      )
                    : trans(
                        category === "nights"
                          ? "pages.loyalty.sections.loyalty_program.table.columns.goal.guests_achieve_1_night"
                          : "pages.loyalty.sections.loyalty_program.table.columns.goal.guests_achieve_1_stay",
                        { parameters: [goal] }
                      );

                return (
                  <InterTag size={12} color={COLORS.secondary} text={text} />
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={VerifiedIcon}
              name={trans("general.awards")}
            />
            <SimpleAwardsCell dataKey="awards" />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={AvgPaceIcon}
              name={trans("general.status")}
            />
            <SimpleTextCell
              dataKey="status"
              textProps={(rowData: tTableItem) => ({
                color:
                  COLORS[rowData.status === "active" ? "emissions" : "error"],
              })}
              textFunction={(rowData) => {
                switch (rowData.status) {
                  case "active":
                    return trans("general.active");
                  case "inactive":
                    return trans("general.inactive");
                }
                return "";
              }}
            />
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={ApprovalDelegationsIcon}
              name={trans("general.awarded")}
            />
            <SimpleTextCell dataKey="timesAwarded" />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

const AddButton: React.FC = () => {
  const [modal, setModal] = useState(false);
  return (
    <>
      <CreateEditLoyaltyProgramItem
        open={modal}
        onClose={() => setModal(false)}
      />
      <SimpleAddButton onClick={() => setModal(true)} />
    </>
  );
};

interface iProps {}

const LoyaltyProgram: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <>
      <Flex column gap={16}>
        <Flex row bottom between>
          <PageSection
            icon={HotelIcon}
            title={trans("Stay Program")}
            description={trans(
              "Celebrate guest loyalty with perks for repeated visits."
            )}
          />
          <AddButton />
        </Flex>
        <LoyaltyProgramWrapped />
      </Flex>
    </>
  );
};

export default LoyaltyProgram;
