import { indexOf, round, sum, toInteger } from "lodash";
import moment, { MomentInput } from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Grid, InputNumber, Row, SelectPicker } from "rsuite";
import useHotelState from "../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../hooks/apiRequests/useGetMeasures";
import { nRequestStatus } from "../../interfaces/apiCalls";
import { tIcon } from "../../interfaces/icon";
import { tBinUnitSingular } from "../../interfaces/sensorData";
import { tHotelSpaceId } from "../../models/hotel";
import { tMeasure, tMeasureTotal } from "../../models/measures";
import { COLORS } from "../../utils/colors";
import {
  convertMassUnit,
  DEFAULT_MASS_MEASURE_UNIT,
} from "../../utils/convertUnits/";
import { nUnitConverter } from "../../utils/convertUnits/interfaces";
import { generateGraphCategories, graphTypeInfo } from "../../utils/graphs";
import { getMeasureInfo } from "../../utils/measures";
import { thousandsSeparation } from "../../utils/numbers";
import { NOYTRALL_0 } from "../../utils/others";
import ReloadButton from "../Buttons/ReloadButton";
import Flex from "../Flex";
import ApexChart, { iApexChartProps } from "../Graphs/Apex/Chart";
import Icon from "../Icons/Icon";
import { toIconProps } from "../Icons/Icon/utils";
import SimpleButton from "../RsuiteWrapper/SimpleButton";
import SimpleWhisperPopoverDropdown from "../RsuiteWrapper/SimpleWhisperPopoverDropdown";
import SaveWrapper from "../SaveWrapper";
import SkeletonText from "../Skeleton/SkeletonText";
import InterTag from "../Text/Inter";

export interface iMeasureConsumptionProps {
  mainMeasureKey: tMeasureTotal;
  specificMeasures: {
    measureKey: tMeasure;
  }[];
  converter: nUnitConverter.tFunction;
  from: MomentInput;
  to: MomentInput;
  binUnit: tBinUnitSingular;
  binValue: number;
  mainUnit: string;
  spaces?: tHotelSpaceId[];
  measuresRequestStatusTracker?(status: nRequestStatus.tStatus): void;
}

const MeasureConsumption: React.FC<iMeasureConsumptionProps> = ({
  converter,
  from: initialFrom,
  mainMeasureKey,
  specificMeasures,
  to: initialTo,
  binUnit: initialBinUnit,
  binValue: initialBinValue,
  mainUnit,
  spaces,
  measuresRequestStatusTracker,
}) => {
  const { hotel } = useHotelState();
  const { trans } = useLocalizationState();
  const graphRef = useRef<any>();
  const [graphType, setGraphType] =
    useState<Extract<iApexChartProps["type"], "line" | "bar">>("line");
  const [selectedSegment, setSelectedSegment] = useState(0);
  const [selectedMeasures, setSelectedMeasures] = useState<number[]>(
    [mainMeasureKey, ...specificMeasures].map((e, i) => i)
  );
  const [periodState, setPeriodState] = useState(() => ({
    from: initialFrom,
    to: initialTo,
    unit: initialBinUnit,
    value: initialBinValue,
  }));
  const [periodState2, setPeriodState2] = useState(() => ({
    from: initialFrom,
    to: initialTo,
    unit: initialBinUnit,
    value: initialBinValue,
  }));
  const allMeasureRequest = useGetMeasures(hotel, {
    from: moment(periodState.from).toISOString(),
    to: moment(periodState.to).toISOString(),
    binUnit: periodState.unit,
    binValue: periodState.value,
    measures: [
      mainMeasureKey,
      ...specificMeasures.map(({ measureKey }) => measureKey),
    ],
    spaces,
  });

  useEffect(() => {
    setPeriodState((prev) => ({ ...prev, from: initialFrom, to: initialTo }));
    setPeriodState2((prev) => ({ ...prev, from: initialFrom, to: initialTo }));
  }, [initialFrom, initialTo]);

  useEffect(() => {
    measuresRequestStatusTracker &&
      measuresRequestStatusTracker(allMeasureRequest.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMeasureRequest.status]);

  const dataSeries = useMemo(() => {
    const dataSeries: Record<
      "measurements" | "costs" | "co2",
      { name: string; data: number[]; color: string }[]
    > = { measurements: [], costs: [], co2: [] };

    if (allMeasureRequest.isResolved) {
      const d = allMeasureRequest.data.grouped[mainMeasureKey];
      const { color } = getMeasureInfo(mainMeasureKey);
      dataSeries.measurements.push({
        name: trans("general.total"),
        data: d?.measurements || [],
        color: color,
      });
      dataSeries.costs.push({
        name: trans("general.total"),
        data: d?.costs || [],
        color: color,
      });
      dataSeries.co2.push({
        name: trans("general.total"),
        data: d?.co2 || [],
        color: color,
      });
      specificMeasures.forEach(({ measureKey }) => {
        const d = allMeasureRequest.data.grouped[measureKey];
        const measureInfo = getMeasureInfo(measureKey, trans);

        dataSeries.measurements.push({
          name: measureInfo.label,
          data: d?.measurements || [],
          color: measureInfo.color,
        });
        dataSeries.costs.push({
          name: measureInfo.label,
          data: d?.costs || [],
          color: measureInfo.color,
        });
        dataSeries.co2.push({
          name: measureInfo.label,
          data: d?.co2 || [],
          color: measureInfo.color,
        });
      });
    } else {
      const { color } = getMeasureInfo(mainMeasureKey);
      dataSeries.measurements.push({
        name: trans("general.total"),
        data: [],
        color: color,
      });
      dataSeries.costs.push({
        name: trans("general.total"),
        data: [],
        color: color,
      });
      dataSeries.co2.push({
        name: trans("general.total"),
        data: [],
        color: color,
      });
      specificMeasures.forEach(({ measureKey }) => {
        const measureInfo = getMeasureInfo(measureKey, trans);

        dataSeries.measurements.push({
          name: measureInfo.label,
          data: [],
          color: measureInfo.color,
        });
        dataSeries.costs.push({
          name: measureInfo.label,
          data: [],
          color: measureInfo.color,
        });
        dataSeries.co2.push({
          name: measureInfo.label,
          data: [],
          color: measureInfo.color,
        });
      });
    }

    return dataSeries;
  }, [
    allMeasureRequest.data.grouped,
    allMeasureRequest.isResolved,
    mainMeasureKey,
    specificMeasures,
    trans,
  ]);

  const summedConvertedData = (() => {
    if (selectedMeasures.includes(0)) {
      return {
        consumption: converter(
          sum(dataSeries.measurements.slice(0, 1).flatMap((s) => s.data) || [])
        ),
        costs: {
          value: sum(dataSeries.costs.slice(0, 1).flatMap((s) => s.data) || []),
          unit: "€",
        },
        footprint: convertMassUnit(
          sum(dataSeries.co2.slice(0, 1).flatMap((s) => s.data) || [])
        ),
      };
    } else {
      return {
        consumption: converter(
          sum(
            dataSeries.measurements
              .slice(1)
              .filter((e, i) => selectedMeasures.includes(i + 1))
              .flatMap((series) => series.data)
          )
        ),
        costs: {
          value: sum(
            dataSeries.costs
              .slice(1)
              .filter((e, i) => selectedMeasures.includes(i + 1))
              .flatMap((series) => series.data)
          ),
          unit: "€",
        },
        footprint: convertMassUnit(
          sum(
            dataSeries.co2
              .slice(1)
              .filter((e, i) => selectedMeasures.includes(i + 1))
              .flatMap((series) => series.data)
          )
        ),
      };
    }
  })();

  const renderSegments = () => {
    const graphInfo = graphTypeInfo(graphType);
    return (
      <Flex row between>
        <Flex one row gap={12}>
          {[
            {
              label: "general.consumption",
              value: round(summedConvertedData.consumption.value, 2),
              unit: summedConvertedData.consumption.unit,
            },
            {
              label: "general.co2_emissions",
              value: round(summedConvertedData.footprint.value, 2),
              unit: summedConvertedData.footprint.unit,
            },
            {
              label: "general.costs",
              value: round(summedConvertedData.costs.value, 2),
              unit: summedConvertedData.costs.unit,
            },
          ].map(({ label, value, unit }, i) => {
            let labelColor = COLORS.gray;
            let mainColor = COLORS.secondary;
            const loading = allMeasureRequest.isLoading;

            if (selectedSegment === i) {
              labelColor = COLORS.primary;
              mainColor = COLORS.primary;
            }

            return (
              <Flex
                key={i}
                column
                gap={8}
                basis={15}
                style={{
                  cursor: "pointer",
                  padding: "6px",
                  borderRadius: "8px",
                }}
                className="hover-darken-white-bg"
                onClick={() => {
                  if (selectedSegment !== i) setSelectedSegment(i);
                }}
              >
                <InterTag
                  color={labelColor}
                  text={trans(label)}
                  size={12}
                  asHTML
                />
                {loading ? (
                  <Flex>
                    <SkeletonText width={"70%"} height="36" />
                  </Flex>
                ) : (
                  <Flex row gap={4} bottom>
                    <InterTag text={value} size={36} color={mainColor} />
                    <InterTag text={unit} asHTML size={16} color={mainColor} />
                  </Flex>
                )}
              </Flex>
            );
          })}
        </Flex>
        <Flex bottom row gap={8}>
          <Flex column gap={4}>
            <InterTag size={12} text={trans("group data by")} />
            <SaveWrapper
              hasChanges={
                periodState.unit !== periodState2.unit ||
                periodState.value !== periodState2.value
              }
              onSave={() => {
                setPeriodState((prev) => ({
                  ...prev,
                  unit: periodState2.unit,
                  value: periodState2.value,
                }));
              }}
              onCancel={() =>
                setPeriodState2((prev) => ({
                  ...prev,
                  unit: periodState.unit,
                  value: periodState.value,
                }))
              }
            >
              <Flex row gap={8}>
                <InputNumber
                  value={periodState2.value}
                  onChange={(value) =>
                    setPeriodState2((prev) => ({
                      ...prev,
                      value: value ? toInteger(value) : 1,
                    }))
                  }
                  style={{ width: "60px" }}
                  min={1}
                  disabled={allMeasureRequest.isLoading}
                />
                <SelectPicker
                  disabled={allMeasureRequest.isLoading}
                  style={{ width: "100px", zIndex: 0 }}
                  searchable={false}
                  cleanable={false}
                  value={periodState2.unit}
                  onChange={(value) =>
                    setPeriodState2((prev) => ({
                      ...prev,
                      unit: value as tBinUnitSingular,
                    }))
                  }
                  data={["hour", "day", "week", "month", "year"].map((key) => ({
                    value: key,
                    label: trans(
                      `general.bin_units.${key}.${
                        periodState2.value > 1 ? `plural` : `singular`
                      }`
                    ),
                  }))}
                />
              </Flex>
            </SaveWrapper>
          </Flex>
          <Flex row gap={8} middle>
            <SimpleWhisperPopoverDropdown
              placement="bottom"
              options={(["line", "bar"] as (typeof graphType)[]).map((key) => ({
                key,
                label: trans(graphTypeInfo(key).transKey),
                onClick: () => {
                  setGraphType(key);
                },
                icon: toIconProps(graphTypeInfo(key).icon),
              }))}
            >
              <SimpleButton
                icon={{
                  size: 16,
                  fill: COLORS.secondary,
                  ...toIconProps(graphInfo.icon),
                }}
                // text={{ text: trans(graphInfo.transKey), size: 14 }}
              />
            </SimpleWhisperPopoverDropdown>
            <ReloadButton
              onClick={allMeasureRequest.redoRequest}
              on={allMeasureRequest.isLoading}
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const cards = () => {
    const mainMeasureInfo = getMeasureInfo(mainMeasureKey, trans);

    const convertedMainMeasure =
      selectedSegment === 0
        ? converter(
            sum(
              allMeasureRequest.data.grouped[mainMeasureKey]?.measurements || []
            )
          )
        : selectedSegment === 1
        ? convertMassUnit(
            sum(allMeasureRequest.data.grouped[mainMeasureKey]?.co2 || [])
          )
        : {
            value: sum(
              allMeasureRequest.data.grouped[mainMeasureKey]?.costs || []
            ),
            unitInHTML: "€",
          };

    const list: {
      label: string;
      icon: tIcon;
      value: number;
      unit: string;
      color: string;
      loading: boolean;
    }[] = [
      {
        loading: !allMeasureRequest.isFinal,
        label: trans("general.total"),
        icon: mainMeasureInfo.icon,
        value: convertedMainMeasure.value,
        unit: convertedMainMeasure.unitInHTML,
        color: mainMeasureInfo.color,
      },
    ];

    specificMeasures.forEach(({ measureKey }) => {
      const info = getMeasureInfo(measureKey, trans);

      const converted =
        selectedSegment === 0
          ? converter(
              sum(
                allMeasureRequest.data.grouped[measureKey]?.measurements || []
              )
            )
          : selectedSegment === 1
          ? convertMassUnit(
              sum(allMeasureRequest.data.grouped[measureKey]?.co2 || [])
            )
          : {
              value: sum(
                allMeasureRequest.data.grouped[measureKey]?.costs || []
              ),
              unitInHTML: "€",
            };

      list.push({
        loading: !allMeasureRequest.isFinal,
        label: info.label,
        icon: info.icon,
        value: converted.value,
        unit: converted.unitInHTML,
        color: info.color,
      });
    });

    return list.map(({ label, icon, unit, value, color, loading }, i) => {
      return (
        <div
          key={label}
          style={{
            cursor: "pointer",
            borderRadius: "8px",
            padding: "8px",
            backgroundColor: selectedMeasures.includes(i)
              ? color
              : COLORS.white,
          }}
          onClick={() => {
            if (selectedMeasures.includes(i)) {
              if (selectedMeasures.length > 1) {
                if (graphRef.current)
                  graphRef.current.chart.toggleSeries(label);
                setSelectedMeasures((prev) => [...prev.filter((v) => v !== i)]);
              }
            } else {
              if (graphRef.current) graphRef.current.chart.toggleSeries(label);
              setSelectedMeasures((prev) => [...prev, i]);
            }
          }}
        >
          <Flex
            column
            gap={16}
            color={COLORS.white}
            style={{ borderRadius: "8px", padding: "12px" }}
          >
            <Flex row left gap={8} middle>
              <Icon size={20} {...{ ...icon }} />
              <InterTag size={16} text={label} />
            </Flex>
            {loading ? (
              <Flex>
                <SkeletonText style={{ width: "50%", height: "36px" }} />
              </Flex>
            ) : (
              <Flex row gap={4} bottom>
                <InterTag
                  size={36}
                  text={thousandsSeparation(round(value, 2).toFixed(2))}
                />
                <InterTag size={16} text={unit} asHTML />
              </Flex>
            )}
          </Flex>
        </div>
      );
    });
  };

  const graph = useCallback(() => {
    const categories = generateGraphCategories(
      periodState.unit,
      periodState.value,
      periodState.from,
      periodState.to
    );

    const [series, unit] = (() => {
      if (selectedSegment === 1)
        return [dataSeries.co2, DEFAULT_MASS_MEASURE_UNIT];
      if (selectedSegment === 2) return [dataSeries.costs, "€"];
      return [dataSeries.measurements, mainUnit];
    })();

    const [formatter, tickAmount] = (() => {
      if (periodState.unit === "day")
        return [(date: MomentInput) => moment(date).format("DD/MM/YYYY"), 5];
      if (periodState.unit === "hour")
        return [
          (date: MomentInput) => {
            const index = indexOf(categories, date);
            if (index % 24 === 0) return moment(date).format("DD/MM HH:mm");
            return "";
          },
          undefined,
        ];
      return [(date: MomentInput) => moment(date).format("DD/MM/YYYY"), 5];
    })();

    return (
      <ApexChart
        {...{
          ref: graphRef,
          type: categories.length === 1 ? "bar" : graphType,
          series,
          stroke: { width: 2 },
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${val === 0 ? NOYTRALL_0 : round(val, 2)} ${unit}`;
              },
            },
          },
          xaxis: {
            categories,
            type: "category",
            tickAmount,
            labels: {
              rotate: 0,
              show: true,
              formatter,
            },
          },
          tooltip: {
            enabled: true,
            followCursor: false,
            x: {
              formatter(val: any, opts: any) {
                const date = categories[opts.dataPointIndex];
                return moment(date).format("DD/MM/YYYY HH:mm");
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            showForSingleSeries: true,
            onItemClick: {
              toggleDataSeries: false,
            },
          },
          chart: {
            // stacked: true,
            events: {
              mounted(chart, options) {
                if (graphRef.current)
                  [mainMeasureKey, ...specificMeasures].forEach((e, i) => {
                    if (selectedMeasures.includes(i)) {
                      graphRef.current.chart.showSeries(
                        options.globals.seriesNames[i]
                      );
                    } else {
                      graphRef.current.chart.hideSeries(
                        options.globals.seriesNames[i]
                      );
                    }
                  });
              },
              legendClick(chart, seriesIndex, options) {
                if (seriesIndex !== undefined) {
                  if (graphRef.current) {
                    if (selectedMeasures.includes(seriesIndex)) {
                      if (selectedMeasures.length > 1) {
                        graphRef.current.chart.toggleSeries(
                          options.globals.seriesNames[seriesIndex]
                        );
                        setSelectedMeasures((prev) => [
                          ...prev.filter((v) => v !== seriesIndex),
                        ]);
                      }
                    } else {
                      graphRef.current.chart.toggleSeries(
                        options.globals.seriesNames[seriesIndex]
                      );
                      setSelectedMeasures((prev) => [...prev, seriesIndex]);
                    }
                  }
                }
              },
            },
          },
        }}
      />
    );
  }, [
    periodState.unit,
    periodState.value,
    periodState.from,
    periodState.to,
    selectedSegment,
    dataSeries.co2,
    dataSeries.costs,
    dataSeries.measurements,
    mainUnit,
    mainMeasureKey,
    specificMeasures,
    selectedMeasures,
    graphType,
  ]);

  return (
    <Flex column>
      <Flex
        style={{ padding: "24px 24px 0 24px", borderRadius: "8px" }}
        color={COLORS.white}
        column
        gap={20}
      >
        {renderSegments()}
        {allMeasureRequest.isResolved ? (
          graph()
        ) : (
          <div style={{ paddingBottom: "24px" }}>
            <SkeletonText width={"100%"} height={285} />
          </div>
        )}
      </Flex>
      <div>
        <Grid fluid>
          {
            <Row gutter={16} style={{ marginTop: "16px" }}>
              {cards().map((elem, k) => (
                <Col key={k} sm={8} xxl={6}>
                  {elem}
                </Col>
              ))}
            </Row>
          }
        </Grid>
      </div>
    </Flex>
  );
};

export default MeasureConsumption;
