import React from "react";
import { Navigate } from "react-router-dom";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSettingsState from "../../../context/Hotel/hooks/hotelState/useHotelSettingsState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import EditDescription from "./editDescription";
import EditName from "./editName";
import PhotoGallery from "./photoGallery";

interface iHotelProfileMainProps {
  canWrite: boolean;
}

const HotelProfileMain: React.FC<iHotelProfileMainProps> = ({ canWrite }) => {
  return (
    <div>
      <Flex column gap={40}>
        <EditName {...{ canWrite }} />
        <EditDescription {...{ canWrite }} />
        <PhotoGallery {...{ canWrite }} />
      </Flex>
      <PageBottomPadding />
    </div>
  );
};

const HotelProfileGuarded: React.FC = () => {
  const profilePermissions = useProfilePermissions();

  return (
    <>
      <TopBar page="pages.hotel_profile.title" />
      {profilePermissions.readGuestAppData ? (
        <IpValidatorGuard>
          <HotelProfileMain canWrite={profilePermissions.writeGuestAppData} />
        </IpValidatorGuard>
      ) : (
        <NoPermissions />
      )}
    </>
  );
};

const HotelProfile: React.FC = () => {
  const { isSettingOn } = useHotelSettingsState();

  if (!isSettingOn("pages.guestAppProfile.show")) return <Navigate to="/" />;

  return <HotelProfileGuarded />;
};

export default HotelProfile;
