import React from "react";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import IpNotPermitted from "../../Pages/IpNotPermitted";

interface iProps {
  children: any;
}

const IpValidatorGuard: React.FC<iProps> = ({ children }) => {
  const {
    hotel: { ipValidated },
    hotel,
    hotelLoading,
  } = useHotelState();

  if (hotelLoading || !hotel._id.length) return null;

  if (!ipValidated) {
    return <IpNotPermitted />;
  }

  return <>{children}</>;
};

export default IpValidatorGuard;
