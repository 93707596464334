import axios from "axios";
import { chunk, find, split, toUpper } from "lodash";
import React, { useEffect, useMemo, useReducer } from "react";
import { Input, Modal, ModalProps } from "rsuite";
import { ReactComponent as CarTagIcon } from "../../../../assets/icons/car_tag.svg";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../../components/Flex";
import InputWrapper from "../../../../components/InputWrapper";
import PageSection from "../../../../components/PageSection";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useApiRequest from "../../../../hooks/apiRequests/useApiRequest";
import { tHotelFleet } from "../../../../models/hotel";
import { apiAddress } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";

type tData = {
  licensePlate: string;
  vehicleMake: string;
  vehicleModel: string;
  vehicleFuelType: string;
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    licensePlate: "",
    vehicleFuelType: "",
    vehicleMake: "",
    vehicleModel: "",
  },
  errors: {
    licensePlate: "",
    vehicleFuelType: "",
    vehicleMake: "",
    vehicleModel: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iVehicleModalWrappedProps
  extends Pick<iProps, "onClose" | "fleetId"> {}

const VehicleModalWrapped: React.FC<iVehicleModalWrappedProps> = ({
  onClose,
  fleetId,
}) => {
  const { trans } = useLocalizationState();
  const { hotelId, hotel } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const request = useApiRequest();

  useEffect(() => {
    if (fleetId) {
      const fleetRecord = find(hotel.fleet, (f) => f._id === fleetId);
      if (fleetRecord) {
        const {
          licensePlate,
          vehicle: { fuelType, make, model },
        } = fleetRecord;
        dispatch({
          type: "data",
          data: {
            licensePlate,
            vehicleFuelType: fuelType,
            vehicleMake: make,
            vehicleModel: model,
          },
        });
      }
    }
  }, [fleetId, hotel.fleet]);

  const [title, confirmLabel] = useMemo(() => {
    if (fleetId)
      return [
        trans(
          "pages.fleet.archive.segments.vehicles.modals.update_vehicle.title"
        ),
        trans("general.update"),
      ];
    return [
      trans("pages.fleet.archive.segments.vehicles.modals.add_vehicle.title"),
      trans("general.insert"),
    ];
  }, [fleetId, trans]);

  const handleClose = () => {
    onClose();
  };
  const handleConfirm = () => {
    request.pending();
    const data: Pick<tHotelFleet, "licensePlate" | "vehicle"> = {
      licensePlate: state.data.licensePlate,
      vehicle: {
        make: state.data.vehicleMake,
        model: state.data.vehicleModel,
        fuelType: state.data.vehicleFuelType,
      },
    };
    (fleetId
      ? axios.put(
          `${apiAddress(false)}/v2/hotels/${hotelId}/fleet/${fleetId}`,
          data
        )
      : axios.post(`${apiAddress(false)}/v2/hotels/${hotelId}/fleet/`, data)
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        handleClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
      });
  };

  const handleChangeLicensePlate = (value: string) => {
    const chunks = chunk(
      split(value, "").filter((c) => /[a-zA-Z0-9]/.test(c)),
      2
    )
      .map((s) => s.join(""))
      .join("-");
    let parsed = toUpper(chunks).slice(0, 8);

    dispatch({ type: "key value", key: "licensePlate", value: parsed });
  };

  const handleChange = (key: keyof tData) => (value: string) => {
    dispatch({ type: "key value", key, value });
  };

  const confirmButtonDisabled = false;

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={20}>
        <PageSection title={title} icon={DirectionsCarIcon} />
        <InputWrapper label={trans("License Plate")} icon={CarTagIcon}>
          <Input
            disabled={request.isLoading}
            value={state.data.licensePlate}
            onChange={handleChangeLicensePlate}
          />
        </InputWrapper>
        <InputWrapper label={trans("general.vehicle")} icon={DirectionsCarIcon}>
          <Flex row gap={8} style={{ paddingLeft: "8px" }}>
            <InputWrapper
              label={{
                text: trans("general.vehicle_make"),
                size: 12,
                bold: false,
              }}
            >
              <Input
                disabled={request.isLoading}
                value={state.data.vehicleMake}
                onChange={handleChange("vehicleMake")}
              />
            </InputWrapper>
            <InputWrapper
              label={{
                text: trans("general.vehicle_model"),
                size: 12,
                bold: false,
              }}
            >
              <Input
                disabled={request.isLoading}
                value={state.data.vehicleModel}
                onChange={handleChange("vehicleModel")}
              />
            </InputWrapper>
            <InputWrapper
              label={{
                text: trans("general.fuel_type"),
                size: 12,
                bold: false,
              }}
            >
              <Input
                disabled={request.isLoading}
                value={state.data.vehicleFuelType}
                onChange={handleChange("vehicleFuelType")}
              />
            </InputWrapper>
          </Flex>
        </InputWrapper>
        <Modal2ActionButtons
          isLoading={request.isLoading}
          confirmButtonDisabled={confirmButtonDisabled}
          confirmLabel={confirmLabel}
          onClose={handleClose}
          onConfirm={handleConfirm}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  fleetId?: tHotelFleet["_id"];
}

const VehicleModal: React.FC<iProps> = ({ onClose, fleetId, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...props }}>
      {props.open && <VehicleModalWrapped {...{ onClose, fleetId }} />}
    </Modal>
  );
};

export default VehicleModal;
