import axios from "axios";
import { findIndex, floor, min, pick } from "lodash";
import moment from "moment";
import React, {
  Fragment,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Table } from "rsuite";
import { ReactComponent as BreakfastInBedAmico } from "../../../assets/breakfast from bed-amico 2.svg";
import { ReactComponent as ContentCopyIcon } from "../../../assets/icons/content_copy.svg";
import { ReactComponent as PeriodIcon } from "../../../assets/icons/date_range.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as CheckOutIcon } from "../../../assets/icons/event_leaving.svg";
import { ReactComponent as CheckInIcon } from "../../../assets/icons/event_upcoming.svg";
import { ReactComponent as GroupIcon } from "../../../assets/icons/group.svg";
import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import { ReactComponent as MeetingRoomIcon } from "../../../assets/icons/meeting_room.svg";
import { ReactComponent as PersonIcon } from "../../../assets/icons/person.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import { ReactComponent as VisibilityIcon } from "../../../assets/icons/visibility.svg";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import Icon from "../../../components/Icons/Icon";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import PageSection from "../../../components/PageSection";
import SimpleFilterButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/filterButton";
import SimplePagination, {
  usePagination,
} from "../../../components/RsuiteWrapper/SimplePagination";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import Segmentation from "../../../components/Segmentation";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useDeleteRequest from "../../../hooks/apiRequests/useDeleteRequest";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import usePutRequest from "../../../hooks/apiRequests/usePutRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import useScreenSize from "../../../hooks/useScreenSize";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { tGuest } from "../../../models/guest";
import { tHotelAwardId } from "../../../models/hotel";
import { tReservation, tReservationId } from "../../../models/reservation";
import { apiAddress, LOCAL_NODE_SERVER_V2 } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { copyToClipboard } from "../../../utils/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import AddReservation from "./addReservation";
import AwardGuestModal from "./awardGuestModal";
import FiltersDrawer from "./filtersDrawer";
import Unlock from "./unlock";

const LS_DATA_FILTERS = "__r-filters__";

type tTopLevelFilter = {
  key: "upcoming" | "canceled" | "ongoing" | "past";
  label: string;
};

type tSorterKey = "startAt" | "endAt" | "numberOfGuests" | "numberOfDays";
type tSorterDirection = "asc" | "desc";

type tStateData = {
  filters: {
    rooms: string[];
    numberOfGuests: [number, number];
    numberOfDays: [number, number];
    checkInFrom: Date | null | undefined;
    checkInTo: Date | null | undefined;
    checkOutFrom: Date | null | undefined;
    checkOutTo: Date | null | undefined;
    periodFrom: Date | null | undefined;
    periodTo: Date | null | undefined;
  };
  sorters: {
    key: tSorterKey;
    direction: tSorterDirection;
  } | null;
};

type tState = {
  data: tStateData;
  initialized: boolean;
};

type tAction =
  | { type: "set default filters"; filters: Partial<tStateData["filters"]> }
  | { type: "set filters"; filters: tStateData["filters"] }
  | { type: "set sorters"; sorters: tStateData["sorters"] }
  | { type: "set data"; data: tStateData };

const initialState: tState = {
  data: {
    filters: {
      rooms: [],
      numberOfGuests: [1, 10],
      numberOfDays: [1, 10],
      checkInFrom: null,
      checkInTo: null,
      checkOutFrom: null,
      checkOutTo: null,
      periodFrom: null,
      periodTo: null,
    },
    sorters: { key: "startAt", direction: "desc" },
  },
  initialized: false,
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      return { ...state, data };
    }
    case "set default filters": {
      const { filters } = action;
      return {
        ...state,
        initialized: true,
        data: {
          ...state.data,
          filters: { ...state.data.filters, ...filters },
        },
      };
    }
    case "set filters": {
      const { filters } = action;
      localStorage.setItem(LS_DATA_FILTERS, JSON.stringify(filters));
      return { ...state, data: { ...state.data, filters } };
    }
    case "set sorters": {
      const { sorters } = action;
      return { ...state, data: { ...state.data, sorters } };
    }
    default:
      return { ...state };
  }
};

interface iProps {
  outletContainerWidth: number;
}

const ReservationsWrapped: React.FC<iProps> = ({ outletContainerWidth }) => {
  const {
    hotelId,
    hotel: { spaces },
    findSpace,
  } = useHotelSpacesState();
  const navigate = useNavigate();
  const toaster = useSimpleToaster();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [awardGuestModal, setAwardGuestModal] = useState<
    | {
        open: true;
        reservationId: tReservationId;
      }
    | { open: false }
  >({
    open: false,
  });
  const awardGuestRequest = usePutRequest();
  const deleteReservationRequest = useDeleteRequest();
  const profilePermissions = useProfilePermissions();

  const spacesStringified = JSON.stringify(spaces);
  useEffect(() => {
    dispatch({
      type: "set default filters",
      filters: { rooms: spaces.map(({ _id }) => _id) },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spacesStringified]);

  const reservationsRequest = useGetRequest<{
    list: tReservation[];
    counts: Record<tTopLevelFilter["key"], number | null>;
  }>({
    list: [],
    counts: { upcoming: null, canceled: null, ongoing: null, past: null },
  });

  const [filtersDrawer, setFiltersDrawer] = useState<{ open: boolean }>({
    open: false,
  });
  const [topLevelFilterIndex, setTopLevelFilterIndex] = useState<number>(1);

  const guestsRequest = useGetRequest<{
    list: tGuest[];
  }>({ list: [] });

  const { setTotal, setActivePage, setLimit, ...paginationProps } =
    usePagination({
      total: 0,
      limit: 10,
      activePage: 1,
    });
  const activePageRef = useRef(paginationProps.activePage);
  const pageLimitRef = useRef(paginationProps.limit);
  const [loadReservationsHelper, setLoadReservationsHelper] = useState(false);
  const loadReservationsHelperRef = useRef(!loadReservationsHelper);

  const { trans } = useLocalizationState();
  const tableRef = useRef<any>();

  const TOP_LEVEL_FILTERS: tTopLevelFilter[] = useMemo(() => {
    return [
      {
        key: "past",
        label: "pages.guests.table.segments.archive",
      },
      {
        key: "ongoing",
        label: "pages.guests.table.segments.ongoing",
      },
      {
        key: "upcoming",
        label: "pages.guests.table.segments.coming_up",
      },
    ];
  }, []);

  const onSelectTopLevelFilter = (key: string) => {
    if (!reservationsRequest.isLoading) {
      setTopLevelFilterIndex(
        findIndex(TOP_LEVEL_FILTERS, (f) => f.key === key)
      );
    }
  };

  const load = useMemo(() => {
    return (hotelId: string, page: number, pageSize: number) => {
      reservationsRequest.pending();

      const params: {
        sort: tStateData["sorters"];
        topLevelFilters: Pick<tTopLevelFilter, "key">[];
        filters: tStateData["filters"];
        page: number;
        pageSize: number;
      } = {
        sort: state.data.sorters,
        topLevelFilters: [
          pick(TOP_LEVEL_FILTERS[topLevelFilterIndex], ["key"]),
        ],
        filters: { ...state.data.filters },
        page,
        pageSize,
      };
      axios
        .get(`${apiAddress(false)}/v2/hotels/${hotelId}/stays`, {
          params,
        })
        .then((res) => {
          const {
            data: { reservations, counts },
          } = res;
          reservationsRequest.resolve({
            list: reservations,
            counts,
          });
          setTotal(counts[TOP_LEVEL_FILTERS[topLevelFilterIndex].key]);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          toaster.error(error);
          reservationsRequest.reject(error);
        });

      guestsRequest.pending();
      axios
        .get(`${apiAddress(false)}/v2/hotels/${hotelId}/stays/guests`, {
          params,
        })
        .then((res) => {
          const {
            data: { guests },
          } = res;
          guestsRequest.resolve({ list: guests });
        })
        .catch((err) => {
          guestsRequest.reject(getErrorMessage(err, trans));
        });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.data.sorters,
    state.data.filters,
    topLevelFilterIndex,
    setTotal,
    trans,
    toaster,
  ]);

  useEffectSafe(() => {
    if (hotelId && state.initialized) {
      if (
        activePageRef.current !== paginationProps.activePage ||
        pageLimitRef.current !== paginationProps.limit ||
        loadReservationsHelperRef.current !== loadReservationsHelper
      ) {
        activePageRef.current = paginationProps.activePage;
        pageLimitRef.current = paginationProps.limit;
        loadReservationsHelperRef.current = loadReservationsHelper;

        load(hotelId, paginationProps.activePage, paginationProps.limit);
      }
    }
  }, [
    hotelId,
    load,
    paginationProps.activePage,
    paginationProps.limit,
    loadReservationsHelper,
    state.initialized,
  ]);

  useEffectSafe(() => {
    if (activePageRef.current !== 1) {
      setActivePage(1);
    } else {
      setLoadReservationsHelper((prev) => !prev);
    }
  }, [topLevelFilterIndex, state.data, setActivePage]);

  const segmentation = () => {
    return (
      <Flex row between>
        <Flex grow={1}>
          <Segmentation
            appearance="subtle"
            active={TOP_LEVEL_FILTERS[topLevelFilterIndex].key}
            onSelect={onSelectTopLevelFilter}
            options={TOP_LEVEL_FILTERS.map((f) => {
              const { key, label } = f;
              return {
                key,
                label: { text: trans(label) },
                count: reservationsRequest.data.counts[key],
                disabled: reservationsRequest.isLoading,
              };
            })}
          />
        </Flex>
        <Flex
          column
          style={{
            borderBottom: `2px ${COLORS.gray} solid`,
          }}
        >
          <Flex row gap={8}>
            {profilePermissions.writeStays && (
              <AddReservation
                afterCreate={() => setLoadReservationsHelper((prev) => !prev)}
              />
            )}
            <SimpleFilterButton
              onClick={() =>
                setFiltersDrawer((prev) => ({ ...prev, open: true }))
              }
            />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const handleConfirmFilters = (
    rooms: string[],
    numberOfGuests: [number, number],
    numberOfDays: [number, number],
    checkInFrom: Date | null | undefined,
    checkInTo: Date | null | undefined,
    checkOutFrom: Date | null | undefined,
    checkOutTo: Date | null | undefined,
    periodFrom: Date | null | undefined,
    periodTo: Date | null | undefined
  ) => {
    dispatch({
      type: "set filters",
      filters: {
        rooms,
        numberOfGuests,
        numberOfDays,
        checkInFrom,
        checkInTo,
        checkOutFrom,
        checkOutTo,
        periodFrom,
        periodTo,
      },
    });
    setFiltersDrawer({ open: false });
  };

  const handleDeleteReservation = (reservationId: tReservationId) => {
    if (hotelId) {
      deleteReservationRequest.pending();
      axios
        .delete(
          `${apiAddress(false)}/v2/hotels/${hotelId}/stays/${reservationId}`
        )
        .then(() => {
          deleteReservationRequest.resolve();
          setLoadReservationsHelper((prev) => !prev);
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          deleteReservationRequest.reject(error);
          toaster.error(error);
        });
    }
  };

  const options = (dataKey: string, reservation: tReservation) => {
    const viewOption = (onClick: () => void) => {
      return {
        key: "view",
        label: trans("pages.guests.table.options.view"),
        onClick() {
          navigate(
            `/reservations/${reservation[dataKey as keyof tReservation]}`,
            { state: { reservation } }
          );
        },
        icon: VisibilityIcon,
        show: true,
      };
    };

    const awardGuestOption = {
      key: "award guest",
      label: trans("pages.guests.table.options.give_award"),
      onClick() {
        setAwardGuestModal({ open: true, reservationId: reservation._id });
      },
      icon: VerifiedIcon,
      // show: profilePermissions.giveAwardToStay,
    };

    const viewMainGuestOption = {
      key: "view guest",
      label: trans("pages.guests.table.options.view_guest"),
      icon: PersonIcon,
      onClick() {
        navigate(
          `/guests/${reservation.mainGuest._id || reservation.mainGuest.name}`,
          { state: { reservation } }
        );
      },
      show: reservation.mainGuest._id !== undefined,
    };

    const copyIdToClipboard = {
      key: "copy id to clipboard",
      label: trans("pages.guests.table.options.copy_id"),
      icon: ContentCopyIcon,
      onClick() {
        copyToClipboard(reservation._id);
      },
      show: apiAddress() === LOCAL_NODE_SERVER_V2,
    };

    const copyCodeToClipboard = {
      key: "copy code to clipboard",
      label: trans("pages.guests.table.options.copy_code"),
      icon: ContentCopyIcon,
      onClick() {
        copyToClipboard(reservation.code);
      },
    };

    const deleteReservation = {
      key: "delete",
      label: { text: trans("general.remove"), color: COLORS.hot },
      onClick() {
        handleDeleteReservation(reservation._id);
      },
      icon: { Element: DeleteIcon, fill: COLORS.hot },
    };

    switch (TOP_LEVEL_FILTERS[topLevelFilterIndex].key) {
      case "upcoming":
        return [
          viewOption(() => {}),
          awardGuestOption,
          viewMainGuestOption,
          copyIdToClipboard,
          copyCodeToClipboard,
          deleteReservation,
        ];
      case "ongoing":
        return [
          viewOption(() => {}),
          awardGuestOption,
          viewMainGuestOption,
          copyIdToClipboard,
          copyCodeToClipboard,
          deleteReservation,
        ];
      case "past":
        return [
          viewOption(() => {}),
          awardGuestOption,
          viewMainGuestOption,
          copyIdToClipboard,
          copyCodeToClipboard,
          deleteReservation,
        ];
      case "canceled":
        return [];
      default:
        return [];
    }
  };

  const handleSortColumn = (sortColumn: string, sortType?: string) => {
    if (sortColumn)
      dispatch({
        type: "set sorters",
        sorters: {
          key: sortColumn as tSorterKey,
          direction: sortType as tSorterDirection,
        },
      });
    else
      dispatch({
        type: "set sorters",
        sorters: null,
      });
  };

  const handleDoubleClick = (reservation: tReservation) => {
    navigate(`/reservations/${reservation._id}`, { state: { reservation } });
  };

  const confirmAwardGuest = (
    awards: { awardId: tHotelAwardId; count: number }[]
  ) => {
    if (awardGuestModal.open && awardGuestModal.reservationId) {
      awardGuestRequest.pending();
      axios
        .put(
          `${apiAddress(false)}/v2/hotels/${hotelId}/stays/${
            awardGuestModal.reservationId
          }/awards`,
          { awards }
        )
        .then((res) => {
          toaster.success(trans("pages.guests.confirmed_stay_awarded"));
          awardGuestRequest.resolve();
          setAwardGuestModal({ open: false });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          toaster.error(error);
          awardGuestRequest.reject(error);
        });
    }
  };

  const tableWidth = outletContainerWidth;
  const idWidth = floor(0.07 * tableWidth);
  const mainGuestWidth = floor(0.22 * tableWidth);
  const guestsWidth = floor(0.1 * tableWidth);
  const roomWidth = floor(0.1 * tableWidth);
  const checkInWidth = floor(0.15 * tableWidth);
  const checkOutWidth = floor(0.15 * tableWidth);
  const periodWidth = floor(0.1 * tableWidth);

  const tableIsLoading =
    deleteReservationRequest.isLoading || reservationsRequest.isLoading;

  const tableHeight = tableIsLoading
    ? TABLE_ROW_HEIGHT.M + TABLE_HEADER_HEIGHT
    : (min([10, reservationsRequest.data.list.length]) as number) *
        TABLE_ROW_HEIGHT.M +
      TABLE_HEADER_HEIGHT;

  return (
    <Fragment>
      <AwardGuestModal
        onClose={() => setAwardGuestModal({ open: false })}
        open={awardGuestModal.open}
        onConfirm={confirmAwardGuest}
        isLoading={awardGuestRequest.isLoading}
      />
      <FiltersDrawer
        initialValues={{ ...state.data.filters }}
        open={filtersDrawer.open}
        onClose={() => setFiltersDrawer((prev) => ({ ...prev, open: false }))}
        onConfirm={handleConfirmFilters}
      />

      <div>
        <div>{segmentation()}</div>
        <div className="table-wrapper" style={{ marginTop: "16px" }}>
          <Table
            onSortColumn={handleSortColumn}
            ref={tableRef}
            height={tableHeight}
            data={reservationsRequest.data.list}
            id={`RESERVATIONS.TABLE`}
            rowHeight={TABLE_ROW_HEIGHT.M}
            headerHeight={TABLE_HEADER_HEIGHT}
            loading={tableIsLoading}
            {...{
              ...(state.data.sorters
                ? {
                    sortColumn: state.data.sorters.key,
                    sortType: state.data.sorters.direction,
                  }
                : {}),
            }}
          >
            <Table.Column width={idWidth}>
              <SimpleHeaderCell icon={KeyIcon} name={trans("general.code")} />
              <SimpleTextCell
                onDoubleClick={handleDoubleClick}
                dataKey="code"
              />
            </Table.Column>
            <Table.Column width={mainGuestWidth}>
              <SimpleHeaderCell
                icon={PersonIcon}
                name={trans("general.main_guest")}
              />
              <BaseCell>
                {(rowData: tReservation, index: number) => {
                  const { mainGuest } = rowData;
                  const { name, _id, email } = mainGuest;

                  if (name || email) {
                    return (
                      <Flex column between gap={4}>
                        <InterTag
                          size={12}
                          color={COLORS.secondary}
                          text={name || "------------"}
                        />
                        <InterTag
                          size={10}
                          color={COLORS.gray}
                          text={email || " "}
                        />
                      </Flex>
                    );
                  }

                  if (_id) {
                    if (guestsRequest.isLoading) return <Loader size="xs" />;
                    if (guestsRequest.isResolved) {
                      const guest = guestsRequest.data.list[index];

                      if (!guest) return <Flex></Flex>;
                      return (
                        <Flex column between gap={4}>
                          <InterTag
                            size={12}
                            color={COLORS.secondary}
                            text={guest.name}
                          />
                          <InterTag
                            size={10}
                            color={COLORS.gray}
                            text={guest.email}
                          />
                        </Flex>
                      );
                    }
                  }

                  return null;
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column sortable width={guestsWidth}>
              <SimpleHeaderCell
                icon={GroupIcon}
                name={trans("general.guests")}
              />
              <SimpleTextCell
                onDoubleClick={handleDoubleClick}
                dataKey="numberOfGuests"
              />
            </Table.Column>
            <Table.Column width={roomWidth}>
              <SimpleHeaderCell
                icon={MeetingRoomIcon}
                name={trans("general.unit")}
              />
              <SimpleTextCell
                textProps={(rowData) => ({
                  hoverUnderline: true,
                  onClick: () => {
                    navigate(`/spaces/${rowData.room}`);
                  },
                })}
                onDoubleClick={handleDoubleClick}
                dataKey="room"
                tooltipDisplay
                textFunction={(rowData: tReservation) => {
                  const space = findSpace(rowData.room);
                  if (!space) return rowData.room;

                  return space?.name;
                }}
              />
            </Table.Column>
            <Table.Column sortable width={checkInWidth}>
              <SimpleHeaderCell
                icon={CheckInIcon}
                name={trans("general.check-in")}
              />
              <SimpleDateCell
                onDoubleClick={handleDoubleClick}
                dataKey="startAt"
              />
            </Table.Column>
            <Table.Column sortable width={checkOutWidth}>
              <SimpleHeaderCell
                icon={CheckOutIcon}
                name={trans("general.check-out")}
              />
              <SimpleDateCell
                onDoubleClick={handleDoubleClick}
                dataKey="endAt"
              />
            </Table.Column>
            <Table.Column sortable width={periodWidth}>
              <SimpleHeaderCell
                icon={{ Element: PeriodIcon }}
                name={trans("general.period")}
              />
              <BaseCell
                dataKey="numberOfDays"
                onDoubleClick={handleDoubleClick}
              >
                {(rowData: tReservation) => {
                  const numberOfNights = moment(rowData.endAt)
                    .endOf("day")
                    .diff(moment(rowData.startAt).startOf("day"), "days");
                  return (
                    <Flex column>
                      <InterTag
                        size={12}
                        color={COLORS.secondary}
                        text={trans(
                          numberOfNights === 1
                            ? "general.x_number_of_nights.singular"
                            : "general.x_number_of_nights.plural",
                          { parameters: [numberOfNights] }
                        )}
                      />
                    </Flex>
                  );
                }}
              </BaseCell>
            </Table.Column>
            <Table.Column flexGrow={1} align="right">
              <SimpleHeaderCell name={{ text: "" }} />
              <SimpleActionCell
                onDoubleClick={handleDoubleClick}
                options={options}
                dataKey="_id"
              />
            </Table.Column>
          </Table>
          {!reservationsRequest.isLoading &&
            reservationsRequest.data.list.length === 0 && (
              <Flex column middle gap={40}>
                <Icon Element={BreakfastInBedAmico} size={250} />
                <Flex column middle gap={16} style={{ margin: "0 70px" }}>
                  <InterTag
                    size={36}
                    text={trans("pages.guests.no_stays.title")}
                    color={COLORS.secondary}
                  />
                  {[
                    trans("pages.guests.no_stays.header_description"),
                    trans("pages.guests.no_stays.todo.description"),
                    ...[
                      "check_systems",
                      "check_later",
                      "add_stay",
                      "reach_out",
                    ].map(
                      (key) =>
                        `<strong>${trans(
                          `pages.guests.no_stays.todo.${key}.label`
                        )}</strong>: ${trans(
                          `pages.guests.no_stays.todo.${key}.description`
                        )}`
                    ),
                    trans("pages.guests.no_stays.footer_description"),
                  ].map((text) => (
                    <InterTag
                      key={text}
                      asHTML
                      size={16}
                      color={COLORS.secondary}
                      style={{
                        textWrap: "wrap",
                        textAlign: "center",
                        lineHeight: "22px",
                      }}
                      text={text}
                    />
                  ))}
                </Flex>
                <AddReservation
                  afterCreate={() => setLoadReservationsHelper((prev) => !prev)}
                />
              </Flex>
            )}
        </div>
        <Flex right style={{ marginTop: "16px" }}>
          <SimplePagination
            {...{ ...paginationProps, setActivePage, setLimit }}
            layout={["pager"]}
          />
        </Flex>
      </div>
    </Fragment>
  );
};

const ReservationsGuarded: React.FC = () => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();

  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!profilePermissions.readStays) {
    return <NoPermissions />;
  }

  if (!oc) return null;
  return (
    <Flex column gap={20}>
      <PageSection
        title={trans("pages.guests.table.title")}
        description={trans("pages.guests.table.description")}
        icon={GroupIcon}
      />
      <ReservationsWrapped outletContainerWidth={oc.width} />
      <PageBottomPadding />
    </Flex>
  );
};

const Reservations: React.FC = () => {
  return (
    <>
      <TopBar page="general.guests" />
      <IpValidatorGuard>
        <ReservationsGuarded />
      </IpValidatorGuard>
    </>
  );
};

export default Reservations;
