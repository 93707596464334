import { find } from "lodash";
import React from "react";
import { Tooltip, Whisper } from "rsuite";
import useHotelState from "../../../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import { tHotelAward, tHotelAwardId } from "../../../../../models/hotel";
import { getAwardDescription } from "../../../../../utils/hotels/awards";
import { COLORS } from "../../../../../utils/colors";
import InterTag from "../../../../Text/Inter";
import BaseCell from "../BaseCell";

interface iProps {
  [key: string]: any;
}

const SimpleAwardsCell: React.FC<iProps> = ({ ...props }) => {
  const { trans, language } = useLocalizationState();
  const { hotel } = useHotelState();
  const { rowData, dataKey } = props;

  const awardsFromObject: { awardId: tHotelAwardId; count: number }[] =
    rowData[dataKey];

  const awardsIds = awardsFromObject.map((a) => a.awardId);

  const awards: (tHotelAward & { count: number })[] = hotel.awards
    .filter((a) => awardsIds.includes(a._id))
    .map((a) => {
      const ca = find(awardsFromObject, (ca) => ca.awardId === a._id);
      return { ...a, count: ca?.count || 0 };
    });

  return (
    <BaseCell {...{ ...props }}>
      {(() => {
        if (awards.length === 0) {
          return (
            <InterTag
              size={12}
              text={trans("components.simple_award_cell.no_awards")}
              color={COLORS.secondary}
            />
          );
        }

        if (awards.length === 1) {
          const description = getAwardDescription(awards[0], language);
          return (
            <Whisper
              trigger="hover"
              placement="topStart"
              speaker={
                <Tooltip>{`(${awards[0].count}x) ${description?.longDescription}`}</Tooltip>
              }
            >
              <InterTag
                color={COLORS.secondary}
                size={12}
                text={`(${awards[0].count}x) ${description?.shortDescription}`}
                style={{ wordWrap: "break-all" }}
              />
            </Whisper>
          );
        }
        if (awards.length > 1)
          return (
            <Whisper
              placement="topStart"
              trigger={"hover"}
              speaker={
                <Tooltip>
                  {awards
                    .flatMap((a, i) => {
                      const description = getAwardDescription(a, language);
                      return [
                        <br key={`${i}.br`} />,
                        `(${a.count}x) ${description?.longDescription}`,
                      ];
                    })
                    .slice(1)}
                </Tooltip>
              }
            >
              <InterTag
                color={COLORS.secondary}
                size={12}
                text={trans("components.simple_award_cell.multiple_awards")}
              />
            </Whisper>
          );
      })()}
    </BaseCell>
  );
};

export default SimpleAwardsCell;
