import { isNumber } from "lodash";
import React, { useState } from "react";
import { Button, Toggle } from "rsuite";
import { ReactComponent as CheckBoxFilledIcon } from "../../../assets/icons/check_box_filled.svg";
import { ReactComponent as NoytrallSymbolIcon } from "../../../assets/noytrall_symbol.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import BusinessPaymentModal from "../../../components/Modals/BusinessPaymentModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tHotelSubscriptionModel } from "../../../models/hotel";
import { COLORS } from "../../../utils/colors";
type tBillingTimePeriod = "monthly" | "annually";

const SettingsBillingPlanWrapped: React.FC = () => {
  const { activeSubscriptionType, activeSubscriptionIsStarter } =
    useHotelSubscriptionState();
  const { trans } = useLocalizationState();
  const [billingTimePeriod, setBillingTimePeriod] =
    useState<tBillingTimePeriod>("monthly");
  const [paymentModal, setPaymentModal] = useState(false);

  const renderCards = () => {
    const list: {
      key: tHotelSubscriptionModel["type"];
      price: Record<tBillingTimePeriod, number | string>;
      priceDescription: Record<tBillingTimePeriod, string>;
      perksCheckColor: string;
      transKeyword: string;
      numberOfPerks: number;
    }[] = [
      {
        key: "starter",
        transKeyword: "starter",
        price: { monthly: 0, annually: 0 },
        priceDescription: {
          monthly: trans("general.free"),
          annually: trans("general.free"),
        },

        numberOfPerks: 3,
        perksCheckColor: COLORS.secondary,
      },
      {
        key: "business",
        transKeyword: "business",
        price: { monthly: 50, annually: 45 },
        priceDescription: {
          monthly: trans("pages.billing_plan.billed_monthly"),
          annually: trans("pages.billing_plan.per_month_billed_annually"),
        },

        numberOfPerks: 4,
        perksCheckColor: COLORS.primary,
      },
      {
        key: "enterprise",
        transKeyword: "enterprise",
        price: { monthly: trans("Custom"), annually: trans("Custom") },
        priceDescription: {
          monthly: trans("pages.billing_plan.billed_monthly"),
          annually: trans("pages.billing_plan.per_month_billed_annually"),
        },

        numberOfPerks: 5,
        perksCheckColor: COLORS.primary,
      },
    ];
    return list.map(
      ({
        key,
        perksCheckColor,
        price,
        priceDescription,
        numberOfPerks,
        transKeyword,
      }) => {
        const title = trans(`pages.billing_plan.plans.${transKeyword}.title`);
        const description = trans(
          `pages.billing_plan.plans.${transKeyword}.description`
        );

        const perks: string[] = [];
        for (let i = 0; i < numberOfPerks; i++) {
          perks.push(
            trans(`pages.billing_plan.plans.${transKeyword}.perks[${i}]`)
          );
        }

        const renderButton = () => {
          if (activeSubscriptionType === key)
            return (
              <Button
                appearance="ghost"
                style={{ width: 200, height: 56, fontSize: 20 }}
                disabled
              >
                <strong>{trans("general.plan_current")}</strong>
              </Button>
            );

          if (key === "enterprise")
            return (
              <Button
                onClick={() => {
                  window.open(
                    "https://supercal.io/miguelrolinhoclemente/noytrall",
                    "_blank",
                    "noreferrer"
                  );
                }}
                appearance="ghost"
                style={{ width: 200, height: 56, fontSize: 20 }}
              >
                <strong>{trans("general.contact_us")}</strong>
              </Button>
            );

          if (activeSubscriptionIsStarter && key === "business")
            return (
              <Button
                appearance="primary"
                style={{ width: 200, height: 56, fontSize: 20 }}
                onClick={() => setPaymentModal(true)}
              >
                <strong>{trans("general.upgrade_now")}</strong>
              </Button>
            );

          return null;
        };

        return (
          <Flex key={key} one column between gap={24} className="card-m">
            <Flex column gap={24}>
              <Flex column gap={8}>
                <Flex row gap={8} middle>
                  <Icon Element={NoytrallSymbolIcon} size={36} />
                  <InterTag
                    text={trans(title)}
                    size={24}
                    bold
                    color={COLORS.secondary}
                  />
                </Flex>
                <InterTag
                  text={trans(description)}
                  color={COLORS.gray}
                  size={16}
                  style={{ textWrap: "wrap", textAlign: "left" }}
                />
              </Flex>
              <Flex column gap={16} middle>
                <Flex row gap={3} bottom>
                  <InterTag
                    size={64}
                    text={price[billingTimePeriod]}
                    color={COLORS.secondary}
                  />
                  {isNumber(price[billingTimePeriod]) && (
                    <InterTag size={24} text={"€"} color={COLORS.secondary} />
                  )}
                </Flex>
                <InterTag
                  text={priceDescription[billingTimePeriod]}
                  size={16}
                  color={COLORS.gray}
                />
              </Flex>
              <Flex column gap={12}>
                {perks.map((perk) => (
                  <Flex row gap={16} middle key={perk}>
                    <div>
                      <Icon
                        Element={CheckBoxFilledIcon}
                        fill={perksCheckColor}
                        size={24}
                      />
                    </div>
                    <InterTag
                      size={16}
                      colro={COLORS.secondary}
                      text={trans(perk)}
                      style={{ textWrap: "wrap", lineHeight: "18px" }}
                    />
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex center middle>
              {renderButton()}
            </Flex>
          </Flex>
        );
      }
    );
  };

  return (
    <>
      <BusinessPaymentModal
        billingTimePeriod={billingTimePeriod}
        open={paymentModal}
        onClose={() => setPaymentModal(false)}
      />
      <Flex column gap={32}>
        <Flex column gap={10}>
          <InterTag
            size={36}
            color={COLORS.secondary}
            text={trans("pages.billing_plan.title")}
            style={{
              textWrap: "wrap",
              textAlign: "center",
              margin: "0 80px",
              fontWeight: 700,
            }}
          />
          <InterTag
            size={16}
            color={COLORS.secondary}
            text={trans("pages.billing_plan.description")}
            style={{ textWrap: "wrap", textAlign: "center" }}
          />
        </Flex>
        <Flex row gap={8} middle center>
          <InterTag
            size={14}
            color={COLORS.secondary}
            text={trans("pages.billing_plan.billed_monthly")}
          />
          <Toggle
            checked={billingTimePeriod === "annually"}
            onChange={(checked) =>
              setBillingTimePeriod(checked ? "annually" : "monthly")
            }
          />
          <InterTag
            size={14}
            color={COLORS.secondary}
            text={`${trans("pages.billing_plan.billed_annually")} (${trans(
              "general.x_%_off",
              { parameters: [10] }
            )})`}
          />
        </Flex>
        <Flex row gap={24}>
          {renderCards()}
        </Flex>
      </Flex>
    </>
  );
};

const SettingsBillingPlan: React.FC = () => {
  return (
    <div>
      <TopBar
        page={"general.plan"}
        parentPages={[
          { transKey: "general.settings", to: "/settings" },
          { transKey: "general.billing", to: "/settings#settings-billing" },
        ]}
        hideSettingsButton
      />

      <SettingsBillingPlanWrapped />
      <PageBottomPadding />
    </div>
  );
};

export default SettingsBillingPlan;
