import axios from "axios";
import React, { useState } from "react";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import useSimpleToaster from "../../../hooks/useSimpleToaster";
import { apiAddress } from "../../../utils/apiCall";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import CreateEditChallengeModal, {
  tDataOnSubmit,
} from "./createEditChallengeModal";

interface iProps {}

const AddChallenge: React.FC<iProps> = () => {
  const toaster = useSimpleToaster();
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [modal, setModal] = useState<{ open: boolean }>({ open: false });
  const postRequest = usePostRequest();

  const createChallenge = (data: Omit<tDataOnSubmit, "_id">) => {
    postRequest.pending();
    axios
      .post(`${apiAddress(false)}/v2/hotels/${hotelId}/challenges/`, data)
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        toaster.success(
          trans(
            "pages.challenges.sections.list.notifications.challenge_created"
          )
        );
        postRequest.resolve();
        setModal({ open: false });
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        toaster.error(error);
        postRequest.reject(error);
      });
  };

  return (
    <>
      <CreateEditChallengeModal
        onRequest={createChallenge}
        requestStatus={postRequest.status}
        requestError={postRequest.error}
        open={modal.open}
        onClose={() => setModal({ open: false })}
      />
      <SimpleAddButton onClick={() => setModal({ open: true })} />
    </>
  );
};

export default AddChallenge;
