import React, { Fragment, useState } from "react";
import SimpleAddButton from "../../../components/RsuiteWrapper/SimpleButton/buttons/addButton";
import SimpleWhisperPopoverDropdown from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import EntriesModal from "./entries/modal";
import VehicleModal from "./vehicles/modal";

interface iProps {}

const Add: React.FC<iProps> = () => {
  const [vehicleModal, setVehicleModal] = useState(false);
  const [entriesModal, setEntriesModal] = useState(false);
  const { trans } = useLocalizationState();

  const options = [
    {
      key: "vehicles",
      label: trans("general.vehicle"),
      onClick() {
        setVehicleModal(true);
      },
    },
    {
      key: "entries",
      label: trans("general.usage"),
      onClick() {
        setEntriesModal(true);
      },
    },
  ];

  return (
    <Fragment>
      <VehicleModal
        open={vehicleModal}
        onClose={() => setVehicleModal(false)}
      />
      <EntriesModal
        open={entriesModal}
        onClose={() => setEntriesModal(false)}
      />
      <SimpleWhisperPopoverDropdown options={options}>
        <SimpleAddButton />
      </SimpleWhisperPopoverDropdown>
    </Fragment>
  );
};

export default Add;
