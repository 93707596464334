import { TagPicker, TagPickerProps } from "rsuite";
import { PickerComponent } from "rsuite/esm/internals/Picker";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";

interface iProps extends TagPickerProps {}

const SimpleTagPicker: PickerComponent<iProps> = ({ ...props }) => {
  const { trans } = useLocalizationState();
  return (
    <TagPicker
      {...{ placeholder: trans("general.placeholders.select"), ...props }}
    />
  );
};

export default SimpleTagPicker;
