import { find } from "lodash";
import React, { useMemo } from "react";
import { HotelStateContext } from "../../";
import tHotel from "../../../../models/hotel";
import useHotelState from "./useHotelState";

const useHotelBillingState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelBillingState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const activeBillingInfo: tHotel["billing"][0] | undefined = useMemo(() => {
    return find(hotel.billing, (b) => b.active);
  }, [hotel.billing]);

  return {
    hotel,
    hotelId,
    activeBillingInfo,
  };
};

export default useHotelBillingState;
