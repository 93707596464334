import { round } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../../assets/icons/delete.svg";
import { ReactComponent as EcoIcon } from "../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as ModeHeatIcon } from "../../../../assets/icons/mode_heat.svg";
import { ReactComponent as WaterDropIcon } from "../../../../assets/icons/water_drop.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import Icon from "../../../../components/Icons/Icon";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../components/Text/Inter";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import useGetDefaultFuelsToEnergy from "../../../../hooks/useGetDefaultFuelsToEnergy";
import { tIcon } from "../../../../interfaces/icon";
import { tHotelManualData } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../utils/convertUnits";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { thousandsSeparation } from "../../../../utils/numbers";
import { renderRequestedData } from "../../../../utils/render";

type tSimpleCard = {
  key: string;
  to: string;
  label: string;
  icon: tIcon;
  value: number;
  homologueValue: number;
  unit: string;
  converter: nUnitConverter.tFunction;
};

interface iProps {
  manualDataEntry: tHotelManualData;
}

const ConsumptionCards: React.FC<iProps> = ({ manualDataEntry }) => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { findPreviousAvailableYearManualDataEntry } =
    useHotelManualDataState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: manualDataEntry !== undefined,
  });
  const fuelsToEnergyRequest = useGetDefaultFuelsToEnergy("PT", {
    doRequest: manualDataEntry !== undefined,
  });

  if (!manualDataEntry) return null;

  const homologueEntry = findPreviousAvailableYearManualDataEntry(
    manualDataEntry,
    "occupancy"
  );

  const [
    homologueTotalElectricity,
    homologueTotalWater,
    homologueTotalNaturalGasKWh,
    homologueTotalButaneKg,
    homologueTotalPropaneKg,
    homologueTotalBiomassKg,
    homologueTotalEthanolLiters,
    homologueTotalGasolineLiters,
    homologueTotalDieselLiters,
    homologueTotalWaste,
  ] = homologueEntry
    ? [
        (homologueEntry.electricity?.totalKWh || 0) +
          (homologueEntry.laundry?.isLaundryOutsourced &&
          homologueEntry.laundry?.outsourcedElectricityConsumptionIsKnown
            ? homologueEntry.laundry?.outsourcedElectricityConsumptionKWh || 0
            : 0),
        (homologueEntry.water?.totalM3 || 0) +
          (homologueEntry.laundry?.isLaundryOutsourced &&
          homologueEntry.laundry?.outsourcedWaterConsumptionM3
            ? homologueEntry.laundry?.outsourcedWaterConsumptionM3 || 0
            : 0),
        homologueEntry.naturalGas?.totalKWh || 0,
        homologueEntry.propane?.totalKg || 0,
        homologueEntry.butane?.totalKg || 0,
        homologueEntry.biomass?.totalKg || 0,
        homologueEntry.ethanol?.totalLiters || 0,
        homologueEntry.gasoline?.totalLiters || 0,
        homologueEntry.diesel?.totalLiters || 0,
        homologueEntry.waste
          ? homologueEntry.waste.glass.totalKg +
            homologueEntry.waste.mixed.totalKg +
            homologueEntry.waste.organic.totalKg +
            homologueEntry.waste.paper.totalKg +
            homologueEntry.waste.plastic.totalKg
          : 0,
      ]
    : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  const totalElectricity =
    (manualDataEntry.electricity?.totalKWh || 0) +
    (manualDataEntry.laundry?.isLaundryOutsourced &&
    manualDataEntry.laundry?.outsourcedElectricityConsumptionIsKnown
      ? manualDataEntry.laundry?.outsourcedElectricityConsumptionKWh || 0
      : 0);
  const totalWater =
    (manualDataEntry.water?.totalM3 || 0) +
    (manualDataEntry.laundry?.isLaundryOutsourced &&
    manualDataEntry.laundry?.outsourcedWaterConsumptionM3
      ? manualDataEntry.laundry?.outsourcedWaterConsumptionM3 || 0
      : 0);

  const totalNaturalGasKWh = manualDataEntry.naturalGas?.totalKWh || 0;
  const totalPropaneKg = manualDataEntry.propane?.totalKg || 0;
  const totalButaneKg = manualDataEntry.butane?.totalKg || 0;
  const totalBiomassKg = manualDataEntry.biomass?.totalKg || 0;
  const totalEthanolLiters = manualDataEntry.ethanol?.totalLiters || 0;
  const totalGasolineLiters = manualDataEntry.gasoline?.totalLiters || 0;
  const totalDieselLiters = manualDataEntry.diesel?.totalLiters || 0;

  const totalWaste = manualDataEntry.waste
    ? manualDataEntry.waste.glass.totalKg +
      manualDataEntry.waste.mixed.totalKg +
      manualDataEntry.waste.organic.totalKg +
      manualDataEntry.waste.paper.totalKg +
      manualDataEntry.waste.plastic.totalKg
    : 0;

  const listMain: tSimpleCard[] = [
    {
      key: "electricity",
      to: "/consumption/electricity",
      label: trans("general.measures_.electricity"),
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      value: totalElectricity,
      homologueValue: homologueTotalElectricity,
      unit: "kWh",
      converter: convertEnergyUnit,
    },
    {
      key: "water",
      to: "/consumption/water",
      label: trans("general.measures_.water"),
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      value: totalWater,
      homologueValue: homologueTotalWater,
      unit: "m3",
      converter: convertVolumeUnit,
    },
  ];

  const wasteCardInfo: tSimpleCard = {
    key: "waste",
    to: "/consumption/waste",
    label: trans("general.waste"),
    icon: { Element: DeleteIcon, fill: COLORS.waste },
    value: totalWaste,
    homologueValue: homologueTotalWaste,
    unit: "kg",
    converter: convertMassUnit,
  };

  const colProps = { xs: 24, sm: 12, md: 12, lg: 8, xxl: 6 };

  const renderSimpleCard = (info: tSimpleCard) => {
    const { converter, homologueValue, icon, key, label, to, unit, value } =
      info;
    const converted = converter(value, { from: unit, forceUnit: null });
    return (
      <Col key={key} {...{ ...colProps }}>
        <Flex
          style={{ cursor: "pointer" }}
          column
          className="card-m hover-box-shadow"
          gap={20}
          onClick={() => {
            navigate(to);
          }}
        >
          <Flex row gap={8} left middle>
            <Icon {...{ size: 24, ...icon }} />
            <InterTag text={label} size={20} color={COLORS.secondary} />
          </Flex>
          <Flex column middle gap={4}>
            <Flex row bottom gap={4}>
              <InterTag
                size={36}
                color={COLORS.secondary}
                text={thousandsSeparation(round(converted.value, 2))}
              />
              <InterTag
                size={16}
                color={COLORS.secondary}
                text={converted.unitInHTML}
                asHTML
              />
            </Flex>
            <TrendingComparison baseValue={homologueValue} value={value} />
          </Flex>
        </Flex>
      </Col>
    );
  };

  return (
    <Row gutter={16}>
      <Col {...{ ...colProps }}>
        <Flex
          style={{ cursor: "pointer" }}
          column
          className="card-m hover-box-shadow"
          gap={20}
          onClick={() => {
            navigate("/consumption");
          }}
        >
          <Flex row gap={8} left middle>
            <Icon
              {...{ size: 24, Element: EcoIcon, fill: COLORS.esg_environment }}
            />
            <InterTag
              text={trans("general.co2_emissions")}
              asHTML
              size={20}
              color={COLORS.secondary}
            />
          </Flex>
          <Flex column middle gap={4}>
            {renderRequestedData(conversionFactorsRequest.status, {
              pending: () => (
                <>
                  <SkeletonText width={100} height={36} />
                  <SkeletonText width={100} height={16} />
                </>
              ),
              resolved: () => {
                const {
                  data: {
                    electricity_kWh_to_kgCO2e,
                    water_m3_to_kgCO2e,
                    natural_gas_kWh_to_kgCO2e,
                    biomass_kg_to_kgCO2e,
                    butane_kg_to_kgCO2e,
                    diesel_L_to_kgCO2e,
                    ethanol_L_to_kgCO2e,
                    gasoline_L_to_kgCO2e,
                    propane_kg_to_kgCO2e,
                  },
                } = conversionFactorsRequest;
                const value =
                  totalElectricity * electricity_kWh_to_kgCO2e +
                  totalWater * water_m3_to_kgCO2e +
                  totalNaturalGasKWh * natural_gas_kWh_to_kgCO2e +
                  totalBiomassKg * biomass_kg_to_kgCO2e +
                  totalButaneKg * butane_kg_to_kgCO2e +
                  totalPropaneKg * propane_kg_to_kgCO2e +
                  totalDieselLiters * diesel_L_to_kgCO2e +
                  totalEthanolLiters * ethanol_L_to_kgCO2e +
                  totalGasolineLiters * gasoline_L_to_kgCO2e;
                const homologueValue =
                  homologueTotalElectricity * electricity_kWh_to_kgCO2e +
                  homologueTotalWater * water_m3_to_kgCO2e +
                  homologueTotalNaturalGasKWh * natural_gas_kWh_to_kgCO2e +
                  homologueTotalBiomassKg * biomass_kg_to_kgCO2e +
                  homologueTotalButaneKg * butane_kg_to_kgCO2e +
                  homologueTotalPropaneKg * propane_kg_to_kgCO2e +
                  homologueTotalDieselLiters * diesel_L_to_kgCO2e +
                  homologueTotalEthanolLiters * ethanol_L_to_kgCO2e +
                  homologueTotalGasolineLiters * gasoline_L_to_kgCO2e;
                const converted = convertMassUnit(value, {
                  from: "kg",
                  forceUnit: null,
                });

                return (
                  <>
                    <Flex row gap={4} bottom>
                      <InterTag
                        size={36}
                        color={COLORS.secondary}
                        text={thousandsSeparation(round(converted.value, 2))}
                      />
                      <InterTag
                        size={16}
                        color={COLORS.secondary}
                        text={converted.unitInHTML}
                        asHTML
                      />
                    </Flex>
                    <TrendingComparison
                      value={value}
                      baseValue={homologueValue}
                    />
                  </>
                );
              },
            })}
          </Flex>
        </Flex>
      </Col>
      {listMain.map((elem) => renderSimpleCard(elem))}
      <Col {...{ ...colProps }}>
        <Flex
          style={{ cursor: "pointer" }}
          column
          className="card-m hover-box-shadow"
          gap={20}
          onClick={() => {
            navigate("/consumption/fuels");
          }}
        >
          <Flex row gap={8} left middle>
            <Icon
              {...{ size: 24, Element: ModeHeatIcon, fill: COLORS.fuels }}
            />
            <InterTag
              text={trans("general.fuels")}
              asHTML
              size={20}
              color={COLORS.secondary}
            />
          </Flex>
          <Flex column middle gap={4}>
            {renderRequestedData(fuelsToEnergyRequest.status, {
              pending: () => (
                <>
                  <SkeletonText width={100} height={36} />
                  <SkeletonText width={100} height={16} />
                </>
              ),
              resolved: () => {
                const {
                  data: {
                    biomass_kg_to_kWh,
                    butane_kg_to_kWh,
                    diesel_L_to_kWh,
                    ethanol_L_to_kWh,
                    gasoline_L_to_kWh,
                    propane_kg_to_kWh,
                  },
                } = fuelsToEnergyRequest;

                const value =
                  totalNaturalGasKWh +
                  totalBiomassKg * biomass_kg_to_kWh +
                  totalButaneKg * butane_kg_to_kWh +
                  totalDieselLiters * diesel_L_to_kWh +
                  totalEthanolLiters * ethanol_L_to_kWh +
                  totalGasolineLiters * gasoline_L_to_kWh +
                  totalPropaneKg * propane_kg_to_kWh;
                const homologueValue =
                  homologueTotalNaturalGasKWh +
                  homologueTotalBiomassKg * biomass_kg_to_kWh +
                  homologueTotalButaneKg * butane_kg_to_kWh +
                  homologueTotalDieselLiters * diesel_L_to_kWh +
                  homologueTotalEthanolLiters * ethanol_L_to_kWh +
                  homologueTotalGasolineLiters * gasoline_L_to_kWh +
                  homologueTotalPropaneKg * propane_kg_to_kWh;

                const converted = convertEnergyUnit(value, {
                  from: "kWh",
                  forceUnit: null,
                });
                return (
                  <>
                    <Flex row gap={4} bottom>
                      <InterTag
                        size={36}
                        color={COLORS.secondary}
                        text={thousandsSeparation(round(converted.value, 2))}
                      />
                      <InterTag
                        size={16}
                        color={COLORS.secondary}
                        text={converted.unitInHTML}
                        asHTML
                      />
                    </Flex>
                    <TrendingComparison
                      value={value}
                      baseValue={homologueValue}
                    />
                  </>
                );
              },
              rejected: () => {
                return (
                  <InterTag
                    color={COLORS.error}
                    size={14}
                    text={fuelsToEnergyRequest.error}
                  />
                );
              },
            })}
          </Flex>
        </Flex>
      </Col>
      {renderSimpleCard(wasteCardInfo)}
    </Row>
  );
};

export default ConsumptionCards;
