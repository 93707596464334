import axios from "axios";
import { pick } from "lodash";
import React, { useEffect, useReducer } from "react";
import { Input, Modal, ModalProps } from "rsuite";
import { ReactComponent as ArrowForwardIcon } from "../../../../assets/icons/arrow_forward.svg";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../../components/Flex";
import InputWrapper from "../../../../components/InputWrapper";
import PageSection from "../../../../components/PageSection";
import useHotelBillingState from "../../../../context/Hotel/hooks/hotelState/useHotelBillingState";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import { tHotelBilling } from "../../../../models/hotel";
import { apiAddress } from "../../../../utils/apiCall";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";

type tData = Pick<
  tHotelBilling,
  "name" | "address" | "email" | "phone" | "taxId"
>;

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    address: "",
    email: "",
    name: "",
    phone: "",
    taxId: "",
  },
  errors: {
    address: "",
    email: "",
    name: "",
    phone: "",
    taxId: "",
  },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface EditBillingDetailsModalWrappedProps extends Pick<iProps, "onClose"> {}

const EditBillingDetailsModalWrapped: React.FC<
  EditBillingDetailsModalWrappedProps
> = ({ onClose }) => {
  const { activeBillingInfo, hotelId } = useHotelBillingState();
  const { updateHotel } = useHotelDispatch();
  const { trans } = useLocalizationState();
  const putRequest = usePutRequest();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (activeBillingInfo)
      dispatch({
        type: "data",
        data: {
          ...pick(activeBillingInfo, [
            "name",
            "address",
            "phone",
            "taxId",
            "email",
          ]),
        },
      });
  }, [activeBillingInfo]);

  const handleClose = () => {
    onClose();
  };

  const changesMade = () => {
    if (!activeBillingInfo)
      return (
        state.data.address.length ||
        state.data.email.length ||
        state.data.name.length ||
        state.data.taxId.length ||
        state.data.phone.length
      );

    if (state.data.name && activeBillingInfo.name !== state.data.name)
      return true;
    if (state.data.email && activeBillingInfo.email !== state.data.email)
      return true;
    if (state.data.address && activeBillingInfo.address !== state.data.address)
      return true;
    if (state.data.phone && activeBillingInfo.phone !== state.data.phone)
      return true;
    if (state.data.taxId && activeBillingInfo.taxId !== state.data.taxId)
      return true;

    return false;
  };

  const handleConfirm = () => {
    putRequest.pending();

    const { address, email, name, phone, taxId } = state.data;

    const body: Pick<
      tHotelBilling,
      "name" | "email" | "phone" | "address" | "taxId"
    > = { name, email, phone, address, taxId };

    axios
      .put(`${apiAddress(false)}/v2/hotels/${hotelId}/billing-info`, body)
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        putRequest.resolve();
        handleClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        putRequest.reject(error);
      });
  };

  const handleChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  const inputDisabled = putRequest.isLoading;

  const list: { label: string; key: keyof tData }[] = [
    { label: "general.name", key: "name" },
    { label: "general.email", key: "email" },
    { label: "general.address", key: "address" },
    { label: "general.phone_number", key: "phone" },
    { label: "general.tax_id", key: "taxId" },
  ];

  return (
    <Modal.Body className="modal-body-pb0">
      <Flex column gap={28}>
        <PageSection
          title={trans(
            "pages.settings.panels.billing.items.billing_details.modals.edit_billing_details.title"
          )}
        />
        <Flex column gap={16}>
          {list.map(({ key, label }) => (
            <InputWrapper key={key} label={trans(label)}>
              <Input
                disabled={inputDisabled}
                value={state.data[key]}
                onChange={handleChange(key)}
              />
            </InputWrapper>
          ))}
        </Flex>
        <Modal2ActionButtons
          confirmLabel={trans("general.update")}
          confirmIcon={ArrowForwardIcon}
          onClose={handleClose}
          onConfirm={handleConfirm}
          isLoading={putRequest.isLoading}
          confirmButtonDisabled={!changesMade()}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
}

const EditBillingDetailsModal: React.FC<iProps> = ({ onClose, ...props }) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, ...props, onClose }}>
      <EditBillingDetailsModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default EditBillingDetailsModal;
