import { MomentInput } from "moment";
import { calcNumberOfNights } from "../dates";

export const calcOccupancyRate = (
  numberOfUnits: number,
  numberOfStays: number,
  from: MomentInput,
  to: MomentInput
) => {
  return (
    ((numberOfStays || 0) / (numberOfUnits * calcNumberOfNights(from, to))) *
    100
  );
};
