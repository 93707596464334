import axios from "axios";
import { floor, has, min, pick, round, some, sum } from "lodash";
import moment, { MomentInput } from "moment";
import React from "react";
import { ReactComponent as RoomPreferencesIcon } from "../../../../assets/icons/room_preferences.svg";
import Flex from "../../../../components/Flex";
import PageSection from "../../../../components/PageSection";
import useHotelSpacesState from "../../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useHotelState from "../../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../../hooks/apiRequests/useGetRequest";
import usePostRequest from "../../../../hooks/apiRequests/usePostRequest";
import useEffectSafe from "../../../../hooks/useEffectSafe";
import useScreenSize from "../../../../hooks/useScreenSize";
import { tGetRequest } from "../../../../interfaces/apiCalls";
import {
  tHotelSpace,
  tHotelSpaceAggregate,
  tHotelSpaceId,
} from "../../../../models/hotel";
import { TE, tMeasureTotal, TW } from "../../../../models/measures";
import { apiAddress } from "../../../../utils/apiCall";
import { add2Arrays } from "../../../../utils/arrays";
import { COLORS } from "../../../../utils/colors";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import {
  getCO2AndCostsPerMeasure,
  groupDataBySpace,
} from "../../../../utils/measures";
import { tData } from "../spaceAggregate";
import Renderer, { tTableDataItem } from "./renderer";

const buildTransKey = (key: string) =>
  `pages.space_aggregate.sections.list.${key}`;

interface iListWrappedProps extends Pick<iProps, "spaceAggregate"> {
  outletContainerWidth: number;
  measuresRequest: tGetRequest<tData>;
}

const ListWrapped: React.FC<iListWrappedProps> = ({
  measuresRequest,
  outletContainerWidth,
  spaceAggregate,
}) => {
  const { trans } = useLocalizationState();
  const { findSpaces, hotelId } = useHotelSpacesState();
  const { updateHotel } = useHotelDispatch();

  const postRequest = usePostRequest();

  const dissociateSpace = (spaceId: tHotelSpaceId) => {
    postRequest.pending();
    axios
      .post(
        `${apiAddress(false)}/v2/hotels/${hotelId}/space-aggregates/${
          spaceAggregate._id
        }`
      )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        postRequest.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  const options = (dataKey: string, space: tHotelSpace) => {
    return [
      {
        key: "uncouple",
        label: {
          text: trans(buildTransKey("table.options.dissociate")),
          color: COLORS.error,
        },
        onClick() {
          dissociateSpace(space._id);
        },
      },
    ];
  };

  const tableData: tTableDataItem[] = findSpaces(spaceAggregate.spaces).map(
    (s) => {
      if (has(measuresRequest.data.bySpace, s._id)) {
        const te = round(
          sum(measuresRequest.data.bySpace[s._id].te?.values || []),
          1
        );
        const tw = round(
          sum(
            measuresRequest.data.bySpace[s._id].tw?.values || ([] as number[])
          ),
          1
        );
        const costs = round(sum(measuresRequest.data.bySpace[s._id].costs), 1);
        const co2 = round(sum(measuresRequest.data.bySpace[s._id].co2), 1);

        return {
          ...pick(s, ["_id", "name"]),
          waterIsMeasured: s.measures.tw.isMeasured,
          energyIsMeasured: s.measures.te.isMeasured,
          te,
          tw,
          co2,
          costs,
        };
      }
      return {
        ...pick(s, ["_id", "name"]),
        waterIsMeasured: s.measures.tw.isMeasured,
        energyIsMeasured: s.measures.te.isMeasured,
        te: 0,
        tw: 0,
        co2: 0,
        costs: 0,
      };
    }
  );

  const waterIsMeasured = some(tableData, (s) => s.waterIsMeasured);
  const energyIsMeasured = some(tableData, (s) => s.energyIsMeasured);
  const rowHeight = 60;
  const headerHeight = 50;
  const tableHeight =
    (min([5, tableData.length]) as number) * rowHeight + headerHeight;

  const spaceWidth = floor(0.17 * outletContainerWidth);
  const teWidth = floor(0.17 * outletContainerWidth);
  const twWidth = floor(0.17 * outletContainerWidth);
  const costsWidth = floor(0.17 * outletContainerWidth);
  const co2Width = floor(0.17 * outletContainerWidth);

  return (
    <div className="table-wrapper">
      <Renderer
        height={tableHeight}
        data={tableData}
        loading={postRequest.isLoading}
        dataStatus={measuresRequest.status}
        {...{
          rowHeight,
          headerHeight,
          options,
          waterIsMeasured,
          energyIsMeasured,
          spaceWidth,
          teWidth,
          twWidth,
          costsWidth,
          co2Width,
        }}
      />
    </div>
  );
};

interface iProps {
  spaceAggregate: tHotelSpaceAggregate;
  from: MomentInput;
  to: MomentInput;
  energyIsMeasured: boolean;
  waterIsMeasured: boolean;
}

const List: React.FC<iProps> = ({
  spaceAggregate,
  from,
  to,
  energyIsMeasured,
  waterIsMeasured,
}) => {
  const { trans } = useLocalizationState();
  const { outlet: oc } = useScreenSize();
  const { hotelId, hotel } = useHotelState();

  const measuresRequest = useGetRequest<tData>({
    grouped: {
      co2: [],
      costs: [],
      te: { co2: [], costs: [], values: [] },
      tw: { co2: [], costs: [], values: [] },
    },
    bySpace: {},
  });

  useEffectSafe(() => {
    const run = () => {
      const required_measures: tMeasureTotal[] = [];
      if (waterIsMeasured) required_measures.push(TW);
      if (energyIsMeasured) required_measures.push(TE);
      if (required_measures.length === 0) {
        measuresRequest.resolve();
        return;
      }
      axios
        .get(
          `${apiAddress(false)}/v2/hotels/${hotelId}/space-aggregates/${
            spaceAggregate._id
          }/consumption`,
          {
            params: {
              from: moment(from).toISOString(),
              to: moment(to).toISOString(),
              binUnit: "day",
              binValue: 1,
              measures: required_measures,
            },
          }
        )
        .then((res) => {
          const { dataBySpace } = res.data as {
            dataBySpace: Record<
              tHotelSpaceId,
              Partial<Record<tMeasureTotal, number[]>>
            >;
          };

          const grouped = (() => {
            const measuresGrouped = groupDataBySpace(dataBySpace);

            const grouped: tData["grouped"] = {
              co2: [],
              costs: [],
              te: {
                co2: [],
                costs: [],
                values: measuresGrouped.te || [],
              },
              tw: {
                co2: [],
                costs: [],
                values: measuresGrouped.tw || [],
              },
            };

            const co2AndCostsPerMeasure = getCO2AndCostsPerMeasure(
              hotel,
              measuresGrouped,
              from,
              to,
              "day",
              1
            );

            grouped.te = { ...grouped.te, ...co2AndCostsPerMeasure.te };
            grouped.tw = { ...grouped.tw, ...co2AndCostsPerMeasure.tw };
            grouped.co2 = add2Arrays(grouped.te.co2, grouped.tw.co2);
            grouped.costs = add2Arrays(grouped.te.costs, grouped.tw.costs);

            return grouped;
          })();

          const bySpace: tData["bySpace"] = Object.fromEntries(
            Object.entries(dataBySpace).map((entry) => {
              const [spaceId, measurements] = entry as [
                tHotelSpaceId,
                Partial<Record<tMeasureTotal, number[]>>
              ];

              const co2CostsPerMeasure = getCO2AndCostsPerMeasure(
                hotel,
                measurements,
                from,
                to,
                "day",
                1
              );

              return [
                spaceId,
                {
                  co2: add2Arrays(
                    co2CostsPerMeasure.te?.co2 || [],
                    co2CostsPerMeasure.tw?.co2 || []
                  ),
                  costs: add2Arrays(
                    co2CostsPerMeasure.te?.costs || [],
                    co2CostsPerMeasure.tw?.costs || []
                  ),
                  te: {
                    co2: [],
                    costs: [],
                    values: measurements.te || [],
                    ...co2CostsPerMeasure.te,
                  },
                  tw: {
                    co2: [],
                    costs: [],
                    values: measurements.tw || [],
                    ...co2CostsPerMeasure.tw,
                  },
                },
              ];
            })
          );

          measuresRequest.resolve({ bySpace, grouped });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          measuresRequest.reject(error);
        });
    };
    if (hotelId) run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    from,
    to,
    spaceAggregate._id,
    energyIsMeasured,
    trans,
    waterIsMeasured,
    hotelId,
  ]);

  if (!oc) return null;

  return (
    <Flex column gap={16}>
      <PageSection
        icon={{ Element: RoomPreferencesIcon, fill: COLORS.secondary }}
        title={{
          text: trans(`general.management_x`, {
            parameters: [
              trans(
                `general.space_categories_.short.${spaceAggregate.category}`
              ),
            ],
          }),
        }}
        description={{
          text: trans(buildTransKey("description")),
        }}
      />
      <ListWrapped
        {...{
          outletContainerWidth: oc.width,
          spaceAggregate,
          measuresRequest,
        }}
      />
    </Flex>
  );
};

export default List;
