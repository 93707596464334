import React from "react";
import TopBar from "../../../components/TopBar/PageNameTopBar";

const SettingsBillingInvoices: React.FC = () => {
  return (
    <div>
      <TopBar
        page="general.invoices"
        parentPages={[
          { transKey: "general.settings", to: "/settings" },
          { transKey: "general.billing", to: "/settings" },
        ]}
      />
    </div>
  );
};

export default SettingsBillingInvoices;
