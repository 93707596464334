import React, { useState } from "react";
import { Input, Modal, ModalProps } from "rsuite";
import { DEFAULT_MODAL_PROPS } from "../../../../utils/rsuite/modals";
import Modal2ActionButtons from "../../../../components/Buttons/modal2ActionButtons";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import Flex from "../../../../components/Flex";
import InterTag from "../../../../components/Text/Inter";
import InputWrapper from "../../../../components/InputWrapper";

interface iAddIpModalWrappedProps
  extends Pick<iProps, "onClose" | "onConfirm" | "title"> {}

const AddIpModalWrapped: React.FC<iAddIpModalWrappedProps> = ({
  onClose,
  onConfirm,
  title,
}) => {
  const { trans } = useLocalizationState();
  const [ip, setIp] = useState("");

  return (
    <Modal.Body>
      <Flex column gap={16}>
        <Flex middle center>
          <InterTag text={title} />
        </Flex>
        <InputWrapper
          label={trans(
            "pages.settings.panels.security.items.ip_restrictions.modals.add_ip.inputs.ip_address.label"
          )}
        >
          <Input value={ip} onChange={setIp} />
        </InputWrapper>
        <Modal2ActionButtons
          confirmLabel={trans("general.add")}
          onClose={onClose}
          onConfirm={() => onConfirm(ip)}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  onConfirm(ip: string): void;
  title: string;
}

const AddIpModal: React.FC<iProps> = ({
  onClose,
  onConfirm,
  title,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "xs", onClose, ...props }}>
      <AddIpModalWrapped {...{ onClose, onConfirm, title }} />
    </Modal>
  );
};

export default AddIpModal;
