import React, { useContext, useEffect, useState } from "react";
import AddManualDataButton from "../../../components/Buttons/addManualDataButton";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import HistoricDataConsumptionGraphSection from "../../../components/HistoricDataConsumptionGraphSection";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import RoadmapTable from "../../../components/RoadmapTable";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelManualDataState from "../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useGetDefaultConverionFactors from "../../../hooks/useGetDefaultConverionFactors";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { ConsumptionContext } from "../consumption/context";
import Showcasing from "./showcasing";
import Unlock from "./unlock";

interface iFuelsWrappedProps {}

const FuelsWrapped: React.FC<iFuelsWrappedProps> = () => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { measuresTrackedInManualData } = useHotelManualDataState();
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresTrackedInManualData.water,
    initialStatus: measuresTrackedInManualData.water
      ? REQUEST_STATUS.PENDING
      : REQUEST_STATUS.IDLE,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <Flex column gap={40}>
      <HistoricDataConsumptionGraphSection
        {...{ conversionFactorsRequest, measure: "fuels" }}
      />
      <Showcasing />
      <RoadmapTable filterByType="fuels" />
    </Flex>
  );
};

const FuelsGuarded: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();
  const [manualDataModal, setManualDataModal] = useState({ open: false });

  return (
    <>
      {activeSubscriptionIsStarter ? (
        <Unlock />
      ) : (
        <div>
          {profilePermissions.writeManualData && (
            <ManualDataModal
              open={manualDataModal.open}
              initialStep="naturalGas"
              singleStep
              onClose={(action) => {
                setManualDataModal({ open: false });
              }}
            />
          )}
          <SecondaryTopBar>
            <div>
              {profilePermissions.writeManualData && (
                <AddManualDataButton
                  onClick={() => setManualDataModal({ open: true })}
                />
              )}
            </div>
          </SecondaryTopBar>
          <FuelsWrapped />
        </div>
      )}
    </>
  );
};

const Fuels: React.FC = () => {
  return (
    <div>
      <TopBar
        page="general.fuels"
        parentPages={[{ transKey: "general.consumption", to: "/consumption" }]}
      />
      <IpValidatorGuard>
        <FuelsGuarded />
        <PageBottomPadding />
      </IpValidatorGuard>
    </div>
  );
};

export default Fuels;
