import axios, { AxiosResponse } from "axios";
import { findIndex, has } from "lodash";
import React, { useReducer } from "react";
import useEffectSafe from "../../hooks/useEffectSafe";
import useSimpleToaster from "../../hooks/useSimpleToaster";
import tHotel from "../../models/hotel";
import { apiAddress } from "../../utils/apiCall";
import { RUN_CONTEXT } from "../../utils/context";
import { getErrorMessage } from "../../utils/httpResponses/others";
import { sleep } from "../../utils/others";
import useAuthDispatch from "../Auth/hooks/useAuthDispatch";
import useProfileState from "../Profile/hooks/useProfileState";
import { nHotel } from "./interfaces";

const HotelDispatchContext = React.createContext<
  nHotel.tDispatchContext | undefined
>(undefined);
HotelDispatchContext.displayName = "HotelDispatchContext";
const HotelStateContext = React.createContext<nHotel.tStateContext | undefined>(
  undefined
);
HotelStateContext.displayName = "HotelStateContext";

const LS_DATA = "__h__";

const initialState: nHotel.tState = {
  data: {
    hotels: [],
    activeHotel: "",
  },
  status: "pending",
  error: null,
};

const reducer = (
  state: nHotel.tState,
  action: nHotel.tAction
): nHotel.tState => {
  try {
    switch (action.type) {
      case "set data": {
        const { data } = action;
        localStorage.setItem(LS_DATA, JSON.stringify(data));
        return { ...state, status: "resolved", error: null, data };
      }
      case "select hotel": {
        const { activeHotel } = action;
        const stored = localStorage.getItem(LS_DATA);

        if (!stored) {
          const data = { ...state.data, activeHotel };
          localStorage.setItem(LS_DATA, JSON.stringify(data));

          return { ...state, data };
        } else {
          const data = { ...state.data, ...JSON.parse(stored), activeHotel };
          return { ...state, data };
        }
      }
      case "set hotels": {
        const { hotels } = action;
        const data = { ...state.data, hotels };
        localStorage.setItem(LS_DATA, JSON.stringify(data));

        return { ...state, status: "resolved", error: null, data };
      }
      case "update hotel": {
        const { hotelId, hotel } = action;
        const index = findIndex(state.data.hotels, (h) => h._id === hotelId);
        if (index >= 0) {
          state.data.hotels[index] = { ...state.data.hotels[index], ...hotel };
          state.data.hotels = [...state.data.hotels];
        }
        localStorage.setItem(LS_DATA, JSON.stringify(state.data));

        return { ...state, status: "resolved", error: null };
      }
      case "resolved": {
        return { ...state, status: "resolved", error: null };
      }
      case "rejected": {
        const { error } = action;
        return { ...state, status: "rejected", error };
      }
      case "pending": {
        return { ...state, status: "pending", error: null };
      }
      default:
        return { ...state };
    }
  } catch (err) {
    return { ...state };
  }
};

const HotelContextProvider: React.FC<nHotel.iContextProps> = ({ children }) => {
  const toaster = useSimpleToaster();
  const [state, dispatch]: [nHotel.tState, React.Dispatch<nHotel.tAction>] =
    useReducer(reducer, initialState);
  const { logout } = useAuthDispatch();
  const {
    profile: { activeHotel },
    isResolved,
  } = useProfileState();

  useEffectSafe(() => {
    if (isResolved) {
      if (state.data.activeHotel !== activeHotel) {
        dispatch({ type: "select hotel", activeHotel });
      }
    }
  }, [activeHotel, state.data.activeHotel]);

  useEffectSafe(() => {
    async function main() {
      dispatch({ type: "pending" });

      let error: any = null;
      for (let tries = 0; tries < RUN_CONTEXT.HOTEL.max_tries; tries++) {
        try {
          const result: AxiosResponse<{
            hotels: (tHotel & { ipValidated: boolean })[];
          }> = await axios.get(
            `${apiAddress(false)}/v2/managers/manager/hotels`
          );
          const {
            data: { hotels },
          } = result;

          dispatch({ type: "set hotels", hotels });
          return;
        } catch (err: any) {
          error = err;
          if (has(err, "response.status")) {
            if (err.response.status === 403) {
              logout();
              break;
            } else if (err.response.status === 404) {
              if (err.response.data.error === "TOKEN_NOT_FOUND") {
                logout();
                break;
              }
            }
          }
          await sleep(3000);
        }
      }
      if (error) dispatch({ type: "rejected", error: getErrorMessage(error) });
    }

    if (
      window.location.pathname.includes("setup-password") ||
      window.location.pathname.includes("forgot-password")
    )
      logout();
    else {
      main();
    }
  }, [logout, toaster]);

  return (
    <HotelStateContext.Provider value={state}>
      <HotelDispatchContext.Provider value={dispatch}>
        {children}
      </HotelDispatchContext.Provider>
    </HotelStateContext.Provider>
  );
};

export { HotelContextProvider, HotelDispatchContext, HotelStateContext };
