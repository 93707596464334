import React, { PropsWithChildren } from "react";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { COLORS } from "../../utils/colors";

interface iProps {}

const SecondaryTopBar: React.FC<PropsWithChildren<iProps>> = ({ children }) => {
  const { isMobile } = useWindowSizeState();

  const [styles, containerHeight] = isMobile
    ? [{ top: 50 }, 20]
    : [{ top: 60 }, 50];

  return (
    <div
      style={{
        height: `${containerHeight}px`,
        width: "100%",
        backgroundColor: COLORS.app_background,
      }}
    >
      <div
        style={{
          position: "fixed",
          zIndex: 3,
          width: "100%",
          backgroundColor: COLORS.app_background,
          paddingBottom: "8px",
          ...styles,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default SecondaryTopBar;
