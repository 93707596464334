import { random } from "lodash";
import React, { useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import {
  Dropdown,
  Input,
  InputProps,
  Popover,
  Whisper,
  WhisperProps,
} from "rsuite";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tIcon, tIconElement } from "../../../interfaces/icon";
import { COLORS } from "../../../utils/colors";
import Flex from "../../Flex";
import Icon from "../../Icons/Icon";
import { toIconProps } from "../../Icons/Icon/utils";
import { iTextProps } from "../../Text";
import InterTag from "../../Text/Inter";
import { toTextProps } from "../../Text/utils";

export type tSimpleWhisperPopoverDropdownOptions = {
  key: string;
  label: string | iTextProps;
  onClick(value?: any): void;
  show?: boolean;
  icon?: tIcon | tIconElement;
  style?: React.CSSProperties;
  link?: LinkProps;
};

interface iProps extends Omit<WhisperProps, "speaker"> {
  options: tSimpleWhisperPopoverDropdownOptions[];
  useInput?: boolean;
  inputProps?: {
    onChange?(value: string): string;
  } & Partial<InputProps>;
  children: any;
}

const SimpleWhisperPopoverDropdown: React.FC<iProps> = ({
  children,
  options,
  useInput,
  inputProps,
  ...props
}) => {
  const [inputValue, setInputValue] = useState("");
  const { trans } = useLocalizationState();

  const onChangeMiddleware = (value: string) => {
    if (inputProps && inputProps.onChange) return inputProps.onChange(value);
    return value;
  };

  return (
    <Whisper
      placement="autoVerticalStart"
      trigger="click"
      {...props}
      speaker={(args: any, ref: any) =>
        renderMenu(
          {
            ...args,
            options,
            trans,
            useInput,
            inputProps: {
              value: inputValue,
              ...inputProps,
              onChange: (value: string) =>
                setInputValue(onChangeMiddleware(value)),
            },
          },
          ref
        )
      }
    >
      {children}
    </Whisper>
  );
};

const renderMenu = (
  {
    onClose,
    left,
    top,
    className,
    options,
    trans,
    useInput,
    inputProps,
  }: {
    options: iProps["options"];
    trans(value: string): string;
    useInput: boolean;
    inputProps: {
      value: string;
      onChange(value: string): void;
    };
    [key: string]: any;
  },
  ref: any
) => {
  const handleSelect = (eventKey: any) => {
    onClose();
  };
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu
        onSelect={handleSelect}
        style={{ paddingLeft: "8px", paddingRight: "8px" }}
      >
        {useInput && (
          <div style={{ marginBottom: "4px" }}>
            <Input
              size={"xs"}
              placeholder={trans("Type a value...")}
              {...{ ...inputProps }}
            />
          </div>
        )}
        {options
          .filter(({ show }) => show !== false)
          .map(({ key, label, icon, onClick, style, link }, i) => {
            return (
              <Dropdown.Item
                eventKey={i}
                key={`${key}.${random(0, 1, true)}`}
                onSelect={() => onClick(inputProps.value)}
                style={{ ...style }}
              >
                {link ? (
                  <Link to={link.to} state={link.state}>
                    <Flex row middle gap={8}>
                      {icon && (
                        <Icon
                          {...{
                            size: 16,
                            fill: COLORS.secondary,
                            ...toIconProps(icon),
                          }}
                        />
                      )}
                      <InterTag {...{ size: 12, ...toTextProps(label) }} />
                    </Flex>
                  </Link>
                ) : (
                  <Flex row middle gap={8}>
                    {icon && (
                      <Icon
                        {...{
                          size: 16,
                          fill: COLORS.secondary,
                          ...toIconProps(icon),
                        }}
                      />
                    )}
                    <InterTag {...{ size: 12, ...toTextProps(label) }} />
                  </Flex>
                )}
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Popover>
  );
};

export default SimpleWhisperPopoverDropdown;
