import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Input, InputGroup, Loader, useToaster } from "rsuite";
import { ReactComponent as PasswordIcon } from "../../../assets/icons/password.svg";
import { ReactComponent as VisibilityOnIcon } from "../../../assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../../assets/icons/visibility_off.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import NoAuthDesktopWrapper from "../../../components/Pages/NoAuthWrapper/noAuthDesktopWrapper";
import NoAuthMobileWrapper from "../../../components/Pages/NoAuthWrapper/noAuthMobileWrapper";
import SimpleButton from "../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../context/WindowSize/hooks/useWindowSizeState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { notification } from "../../../utils/notifications";
import { preventDefault } from "../../../utils/others";

interface iProps {}

const SetupPassword: React.FC<iProps> = () => {
  const { isTablet } = useWindowSizeState();
  const toaster = useToaster();
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const params = useParams<{ token: string }>();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [visibility, setVisibility] = useState(false);
  const [visibility2, setVisibility2] = useState(false);

  const postRequest = usePostRequest();

  const handleSubmit = () => {
    if (password1 !== password2) {
      setError(trans("pages.setup_password.errors.no_match"));
      return;
    }

    postRequest.pending();
    axios
      .post(`${apiAddress(false)}/v2/managers/setup-password/${params.token}`, {
        password1,
        password2,
      })
      .then(() => {
        toaster.push(
          notification(
            "success",
            trans("pages.setup_password.notifications.done")
          ),
          {
            placement: "topEnd",
          }
        );
        navigate("/login");
      })
      .catch((err) => {
        toaster.push(notification("error", getErrorMessage(err, trans)), {
          placement: "topEnd",
        });
      });
  };

  useEffect(() => {
    setError(null);
  }, [password1, password2]);

  const renderTitle = (size: number) => (
    <InterTag
      size={size}
      text={trans("pages.setup_password.title")}
      bold
      color={COLORS.secondary}
    />
  );

  const renderForm = () => (
    <form onSubmit={preventDefault(handleSubmit)}>
      <Flex column gap={20}>
        <InputWrapper label={trans("general.password")}>
          <InputGroup>
            <InputGroup.Addon>
              <Icon
                Element={PasswordIcon}
                width={24}
                height={24}
                fill={COLORS.secondary}
              />
            </InputGroup.Addon>
            <Input
              value={password1}
              onChange={setPassword1}
              type={visibility ? "text" : "password"}
              placeholder={trans("general.placeholders.password")}
              size="lg"
            />
            <InputGroup.Button
              className={`hover-gray-100 bg-white`}
              onClick={() => setVisibility((prev) => !prev)}
            >
              {visibility ? (
                <Icon
                  Element={VisibilityOnIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              ) : (
                <Icon
                  Element={VisibilityOffIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              )}
            </InputGroup.Button>
          </InputGroup>
        </InputWrapper>
        <InputWrapper
          error={error}
          label={trans("pages.setup_password.inputs.confirm_password.label")}
        >
          <InputGroup>
            <InputGroup.Addon>
              <Icon
                Element={PasswordIcon}
                width={24}
                height={24}
                fill={COLORS.secondary}
              />
            </InputGroup.Addon>
            <Input
              value={password2}
              onChange={setPassword2}
              type={visibility2 ? "text" : "password"}
              placeholder={trans("general.placeholders.password")}
              size="lg"
            />
            <InputGroup.Button
              className={`hover-gray-100 bg-white`}
              onClick={() => setVisibility2((prev) => !prev)}
            >
              {visibility2 ? (
                <Icon
                  Element={VisibilityOnIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              ) : (
                <Icon
                  Element={VisibilityOffIcon}
                  width={24}
                  height={24}
                  fill={COLORS.gray}
                />
              )}
            </InputGroup.Button>
          </InputGroup>
        </InputWrapper>
      </Flex>
      <input type="submit" hidden />
    </form>
  );

  const renderButton = () => (
    <SimpleButton
      loading={postRequest.isLoading}
      onClick={handleSubmit}
      appearance="primary"
      text={{
        text: trans("general.submit"),
        bold: true,
        size: 20,
        color: COLORS.white,
      }}
    />
  );

  const renderFooter = (color: string) => (
    <>
      <Flex row gap={4} center>
        <InterTag color={color} text={trans("pages.setup_password.signin")} />
        <InterTag
          color={COLORS.primary}
          text={trans("general.signin")}
          hoverUnderline
          onClick={() => {
            navigate("/login");
          }}
        />
      </Flex>
      <Flex row gap={4} center>
        <InterTag
          color={color}
          text={trans("pages.setup_password.signup[0]")}
        />
        <InterTag
          color={COLORS.primary}
          text={trans("pages.setup_password.signup[1]")}
          hoverUnderline
          onClick={() => {
            navigate("/register");
          }}
        />
        <InterTag
          color={color}
          text={trans("pages.setup_password.signup[2]")}
        />
      </Flex>
    </>
  );

  if (isTablet)
    return (
      <NoAuthDesktopWrapper>
        <Fragment>
          {postRequest.isLoading && <Loader center backdrop size="lg" />}
          <Flex column middle gap={16}>
            <Flex
              column
              center
              style={{
                backgroundColor: COLORS.light_white,
                borderRadius: "16px",
                padding: "64px",
                minWidth: "640px",
                maxWidth: "640px",
              }}
              gap={40}
            >
              {renderTitle(32)}
              {renderForm()}
              {renderButton()}
            </Flex>
            {renderFooter(COLORS.white)}
          </Flex>
        </Fragment>
      </NoAuthDesktopWrapper>
    );

  return (
    <NoAuthMobileWrapper>
      <Flex column style={{ marginTop: "80px", padding: "0 20px" }} gap={32}>
        {renderTitle(24)}
        <Flex column gap={32} style={{ padding: "0 8px" }}>
          {renderForm()}
          {renderButton()}
        </Flex>
        <Flex column gap={8}>
          {renderFooter(COLORS.secondary)}
        </Flex>
      </Flex>
    </NoAuthMobileWrapper>
  );
};

export default SetupPassword;
