import React from "react";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import NoPermissions from "../../../components/Pages/NoPermissions";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import AwardsList from "./list";
import AwardsStatistics from "./statistics";
import Unlock from "./unlock";

const AwardsWrapped: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();
  const profilePermissions = useProfilePermissions();
  if (activeSubscriptionIsStarter)
    return (
      <>
        <Unlock />
        <PageBottomPadding />
      </>
    );

  if (!profilePermissions.readAwards) {
    return <NoPermissions />;
  }

  return (
    <>
      <Flex column gap={40}>
        <AwardsStatistics />
        <AwardsList />
      </Flex>
      <PageBottomPadding />
    </>
  );
};

const Awards: React.FC = () => {
  return (
    <>
      <TopBar page="general.awards" />
      <IpValidatorGuard>
        <AwardsWrapped />
      </IpValidatorGuard>
    </>
  );
};

export default Awards;
