import { iFlexProps } from "../../../../Flex";
import SkeletonText from "../../../../Skeleton/SkeletonText";
import BaseCell from "../BaseCell";

interface iProps {
  rowData?: Record<string, any>;
  dataKey: string;
  containerProps?: Omit<iFlexProps, "children">;
  [key: string]: any;
}

const SimpleLoadingCell = ({ containerProps, ...props }: iProps) => {
  return (
    <BaseCell {...{ ...props }}>
      <SkeletonText width={30} height={12} />
    </BaseCell>
  );
};

export default SimpleLoadingCell;
