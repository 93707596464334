import { createIconFont } from "@rsuite/icons";
import { IconFontProps } from "@rsuite/icons/lib/createIconFont";

const IconFont = createIconFont({
  scriptUrl: "//at.alicdn.com/t/font_2144422_r174s9i1orl.js",
  commonProps: { style: { fontSize: 30, color: "#1675e0" } },
  onLoaded: () => {},
});

interface iSimpleReloaderProps extends Omit<IconFontProps, "spin" | "icon"> {
  size: number;
  on?: boolean;
  color?: string;
}

const SimpleReloader: React.FC<iSimpleReloaderProps> = ({
  size,
  on,
  color,
  ...rest
}) => {
  if (on)
    return (
      <IconFont
        spin
        icon="rs-iconreload"
        {...{
          ...rest,
          style: { color, fontSize: `${size}px` },
        }}
      />
    );
  return (
    <IconFont
      icon="rs-iconreload"
      {...{
        ...rest,
        style: { color, fontSize: `${size}px` },
      }}
    />
  );
};

export default SimpleReloader;
