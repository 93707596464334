import { isString } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../..";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  tMeasure,
} from "../../../../models/measures";
import useHotelState from "./useHotelState";

const useHotelMeasuresTrackedState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error(
      "useHotelMeasuresTrackedState must be used within HotelStateContext"
    );

  const { hotel, hotelId } = useHotelState();

  //** Returns measures being tracked in specific spaces
  // * If no spaceIds are provided, returns information about all spaces
  // */
  const getMeasuresTracked = useCallback(
    (_spaceIds?: string | string[]) => {
      const spaceNames = isString(_spaceIds) ? [_spaceIds] : _spaceIds;
      const spaces = hotel
        ? spaceNames
          ? hotel.spaces.filter((r) => spaceNames.includes(r._id))
          : hotel.spaces
        : [];

      let measureIsMeasured: Record<tMeasure, boolean> = {
        te: false,
        es: false,
        el: false,
        ac: false,
        wh: false,
        tw: false,
        cw: false,
        hw: false,
        gw: false,
        dw: false,
        ntw: false,
      };

      spaces.forEach((space) => {
        Object.keys(space.measures).forEach((key) => {
          const measureName = key as tMeasure;
          measureIsMeasured[measureName] ||=
            space.measures[measureName].isMeasured;
        });
      });

      if (
        !measureIsMeasured.te &&
        MEASURES_ELECTRICITY_SPECIFIC.filter((m) => measureIsMeasured[m])
          .length > 1
      )
        measureIsMeasured.te = true;

      if (
        !measureIsMeasured.tw &&
        MEASURES_WATER_SPECIFIC.filter((m) => measureIsMeasured[m]).length > 1
      )
        measureIsMeasured.tw = true;

      return measureIsMeasured;
    },
    [hotel]
  );

  return {
    hotel,
    hotelId,
    getMeasuresTracked,
  };
};

export default useHotelMeasuresTrackedState;
