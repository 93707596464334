import en from "./en";
import pt from "./pt";
import de from "./de";
import fr from "./fr";
import es from "./es";
import it from "./it";

const translation: Record<string, any> = {
  en,
  pt,
  de,
  fr,
  es,
  it,
};

export default translation;
