import moment, { MomentInput } from "moment";

export const getValidity = (
  date: MomentInput
): { years: number; months: number; days: number } => {
  const d = moment(date);

  const years = d.diff(moment(0), "years");

  if (years) {
    d.subtract(years, "years");
  }

  const months = d.diff(moment(0), "months");

  if (months) {
    d.subtract(months, "months");
  }

  const days = d.diff(moment(0), "days");

  return { years, months, days };
};

export const calcNumberOfNights = (
  from: MomentInput,
  to: MomentInput
): number => {
  return moment(to).endOf("day").diff(moment(from).startOf("day"), "days");
};
