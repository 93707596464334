import React from "react";
import { HotelStateContext } from "../../";

const useHotelsListState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelsListState must be used within HotelStateContext");

  const {
    data: { hotels },
  } = state;

  return { hotels };
};

export default useHotelsListState;
