import React from "react";
import { ProfileStateContext } from "../";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import { defaultManager } from "../../../utils/managers";

const useProfileState = () => {
  const state = React.useContext(ProfileStateContext);

  if (!state)
    throw Error("useProfileState must be used within ProfileStateContext");

  const { data, status } = state;

  const profile = data.profile || defaultManager;
  const profileLoaded = data.profile !== null;

  return {
    profileLoaded,
    profile,
    isResolved: status === REQUEST_STATUS.RESOLVED,
  };
};

export default useProfileState;
