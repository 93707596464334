import React from "react";
import RoadmapTable from "../../../components/RoadmapTable";
import TopBar from "../../../components/TopBar/PageNameTopBar";

interface iProps {}

const Roadmap: React.FC<iProps> = () => {
  return (
    <>
      <TopBar page="components.roadmap.title" />
      <RoadmapTable
        tableHeight={(rootOutletContainerHeight) =>
          rootOutletContainerHeight - 250
        }
      />
    </>
  );
};

export default Roadmap;
