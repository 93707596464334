import { at, isNull, replace, toString } from "lodash";
import React, { useCallback } from "react";
import { LocalizationStateContext } from "../";
import translation from "../translations";
import { DEFAULT_LANGAUGE, tTrans } from "../utils";

const useLocalizationState = () => {
  const language = React.useContext(LocalizationStateContext);

  if (!language)
    throw Error(
      "useLocalizationState must be used within LocalizationStateContext"
    );

  const trans: tTrans = useCallback(
    (
      key: string,
      {
        lang,
        parameters = [],
      }: { lang?: string; parameters?: (string | number)[] } = {
        parameters: [],
      }
    ): string => {
      try {
        const selectedLanguage = lang || language || DEFAULT_LANGAUGE || "en";
        // @ts-ignore
        let word: string | null =
          at(translation[selectedLanguage] || {}, key)[0] ||
          at(translation[DEFAULT_LANGAUGE] || {}, key)[0] ||
          at(translation["en"] || {}, key)[0] ||
          null;

        if (isNull(word) || !word) {
          for (let i = 0; i < parameters.length; i++) {
            const param = parameters[i];
            key = replace(key, `$${i}`, toString(param));
          }
          return key;
        }

        for (let i = 0; i < parameters.length; i++) {
          const param = parameters[i];
          word = replace(word, `$${i}`, toString(param));
        }
        return word;
      } catch (err) {
        return key;
      }
    },
    [language]
  );

  return { language, trans };
};

export default useLocalizationState;
