import axios from "axios";
import { MomentInput } from "moment";
import React, { useState } from "react";
import { Table } from "rsuite";
import { ReactComponent as CalendarClockIcon } from "../../../assets/icons/calendar_clock.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icons/category.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import { toIconProps } from "../../../components/Icons/Icon/utils";
import InputNumberModal from "../../../components/Modals/InputNumberModal";
import PageSection from "../../../components/PageSection";
import BaseCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleDateCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleDateCell";
import SimpleTextCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../components/Text/Inter";
import useHotelAwardsState from "../../../context/Hotel/hooks/hotelState/useHotelAwardsState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { tUseGetRequest } from "../../../hooks/apiRequests/useGetRequest";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tHotelAward } from "../../../models/hotel";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  getAwardDescription,
  getAwardTypeInfo,
} from "../../../utils/hotels/awards";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { TABLE_HEADER_HEIGHT, TABLE_ROW_HEIGHT } from "../../../utils/tables";
import { tRequest } from "./reservation";

const buildTransKey = (key: string) =>
  `pages.reservation.sections.awards.${key}`;

type tTableItem = {
  _id: string;
  award: tHotelAward;
  amount: number;
  amountUsed: number;
  validUntil: MomentInput;
};

interface AwardsTableProps extends Pick<iProps, "reservationRequest"> {}

const AwardsTable: React.FC<AwardsTableProps> = ({ reservationRequest }) => {
  const { trans, language } = useLocalizationState();
  const { updateHotel } = useHotelDispatch();
  const { findAward, hotelId } = useHotelAwardsState();
  const [modalState, setModalState] = useState({
    open: false,
    awardId: "",
    max: 1,
  });
  const postRequest = usePostRequest();

  const {
    data: { reservation },
  } = reservationRequest;

  const tableData: tTableItem[] = reservation.awards
    .map((a) => {
      const { _id, amount, amountUsed, validUntil } = a;
      return { _id, award: findAward(a.award), amount, amountUsed, validUntil };
    })
    .filter((a) => a.award !== undefined) as tTableItem[];

  const handleMarkAwardAsUsed = (awardId: string, amount: number) => {
    postRequest.pending();
    axios
      .post(
        `${apiAddress(false)}/v2/stays/${
          reservation._id
        }/awards/${awardId}/use`,
        { amount }
      )
      .then((res) => {
        const {
          data: { hotel, stay },
        } = res;
        updateHotel(hotelId, hotel);
        reservationRequest.resolve({
          reservation: { ...reservationRequest.data.reservation, ...stay },
          fromApi: true,
        });
        postRequest.resolve({}, trans("Award marked as used successfully"));
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        postRequest.reject(error, true);
      });
  };

  const options = (
    dataKey: string,
    award: tTableItem
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];

    if (award.amountUsed < award.amount)
      options.push({
        key: "use",
        label: trans(buildTransKey("table.options.mark_as_used.label")),
        icon: VerifiedIcon,
        onClick() {
          const available = award.amount - award.amountUsed;
          if (available > 1) {
            setModalState({
              open: true,
              awardId: award._id,
              max: available,
            });
          } else {
            handleMarkAwardAsUsed(award._id, 1);
          }
        },
      });

    return options;
  };

  return (
    <>
      <InputNumberModal
        open={modalState.open}
        onClose={() => setModalState({ open: false, awardId: "", max: 1 })}
        onConfirm={(value) => {
          handleMarkAwardAsUsed(modalState.awardId, value);
          setModalState({ open: false, awardId: "", max: 1 });
        }}
        description={trans("")}
        title={trans("Mark award as used")}
        min={1}
        max={modalState.max}
      />
      <div className="table-wrapper">
        <Table
          autoHeight
          loading={postRequest.isLoading}
          data={tableData}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
          renderEmpty={() => (
            <Flex center middle style={{ padding: "40px" }}>
              <InterTag
                text={trans(buildTransKey("table.empty.description"))}
                color={COLORS.secondary}
                size={14}
              />
            </Flex>
          )}
        >
          <Table.Column>
            <SimpleHeaderCell
              icon={CategoryIcon}
              name={trans("general.type")}
            />
            <BaseCell>
              {(rowData: tTableItem) => {
                const {
                  award: { type },
                } = rowData;
                const { icon, transKey } = getAwardTypeInfo(type);
                return (
                  <Flex
                    style={{
                      padding: "4px",
                      width: "fit-content",
                      borderRadius: "4px",
                    }}
                    middle
                    gap={4}
                  >
                    <Icon
                      {...{
                        fill: COLORS.secondary,
                        size: 14,
                        ...toIconProps(icon),
                      }}
                    />
                    <InterTag
                      size={12}
                      text={trans(transKey)}
                      color={COLORS.secondary}
                    />
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1}>
            <SimpleHeaderCell
              icon={VerifiedIcon}
              name={trans("general.award")}
            />
            <SimpleTextCell
              tooltipDisplay
              tooltipTextFunction={(rowData: tTableItem) =>
                `${
                  getAwardDescription(rowData.award, language)?.longDescription
                }`
              }
              dataKey="award.shortDescription"
              textFunction={(rowData: tTableItem) =>
                `${
                  getAwardDescription(rowData.award, language)?.shortDescription
                }`
              }
            />
          </Table.Column>
          <Table.Column>
            <SimpleHeaderCell name={trans("general.available")} />
            <SimpleTextCell
              textFunction={(item: tTableItem) =>
                `${item.amount - item.amountUsed}/${item.amount}`
              }
              dataKey="amount"
            />
          </Table.Column>
          <Table.Column>
            <SimpleHeaderCell
              icon={CalendarClockIcon}
              name={trans("general.validity")}
            />
            <SimpleDateCell dataKey="validUntil" />
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </>
  );
};

interface iProps {
  reservationRequest: tUseGetRequest<tRequest>;
}

const Awards: React.FC<iProps> = ({ reservationRequest }) => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={16}>
      <PageSection
        title={trans("general.awards")}
        description={trans(buildTransKey("description"))}
        icon={VerifiedIcon}
      />
      <AwardsTable reservationRequest={reservationRequest} />
    </Flex>
  );
};

export default Awards;
