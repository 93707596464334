import React, { PropsWithChildren } from "react";
import { Tooltip, Whisper } from "rsuite";
import { ReactComponent as HelpIcon } from "../../assets/icons/help.svg";
import useContentDimensionsState from "../../context/ContentDimensions/hooks/useContentDimensions";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { tIcon, tIconElement } from "../../interfaces/icon";
import { COLORS } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import { toIconProps } from "../Icons/Icon/utils";
import { iTextProps } from "../Text";
import InterTag from "../Text/Inter";
import { toTextProps } from "../Text/utils";

export interface iPageSectionProps {
  icon?: tIcon | tIconElement;
  title: string | iTextProps;
  description?: string | iTextProps;
  [key: string]: any;
}

const PageSectionTitle: React.FC<PropsWithChildren<iPageSectionProps>> = ({
  icon,
  title,
  description,
  children,
  ...rest
}) => {
  const { isMobile } = useWindowSizeState();
  const { PageSection } = useContentDimensionsState();

  const renderDescription = () => {
    if (!description || isMobile) return null;
    const descriptionProps = toTextProps(description);
    return (
      <InterTag
        {...{
          size: PageSection.description,
          color: COLORS.gray_600,
          wrap: true,
          ...descriptionProps,
          style: {
            ...descriptionProps.style,
            textAlign: "left",
            textWrap: "wrap",
            lineHeight: `${PageSection.descriptionLineHeight}px`,
          },
        }}
      />
    );
  };

  const renderTitle = () => {
    if (isMobile && description)
      return (
        <Whisper
          placement="bottomStart"
          speaker={<Tooltip>{toTextProps(description).text}</Tooltip>}
          trigger={"click"}
        >
          <Flex row gap={4} top>
            <Flex basis={95}>
              <InterTag
                {...{
                  wrap: true,
                  size: PageSection.title,
                  color: COLORS.secondary,
                  ...toTextProps(title),
                }}
              />
            </Flex>
            <Icon Element={HelpIcon} size={12} fill={COLORS.secondary} />
          </Flex>
        </Whisper>
      );

    return (
      <InterTag
        {...{
          wrap: true,
          size: PageSection.title,
          color: COLORS.secondary,
          ...toTextProps(title),
        }}
      />
    );
  };

  return (
    <Flex column gap={16}>
      <Flex column gap={8} style={{ ...rest.style }}>
        <Flex row middle gap={8}>
          {icon && (
            <Icon
              {...{
                fill: COLORS.secondary,
                size: PageSection.icon,
                ...toIconProps(icon),
              }}
            />
          )}
          {renderTitle()}
        </Flex>
        {description && renderDescription()}
      </Flex>
      {children}
    </Flex>
  );
};

export default React.memo(PageSectionTitle);
