import React from "react";
import Flex from "../../Flex";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { ReactComponent as DoNotEnterBro } from "../../../assets/bros/Do not enter sign-amico 1.svg";
import Icon from "../../Icons/Icon";
import InterTag from "../../Text/Inter";
import { COLORS } from "../../../utils/colors";

interface iProps {}

const IpNotPermitted: React.FC<iProps> = () => {
  const { trans } = useLocalizationState();

  return (
    <Flex column gap={28} middle>
      <Icon Element={DoNotEnterBro} size={250} />
      <Flex column gap={20} middle>
        {["Access Denied", "Your access has been restricted."].map((t) => (
          <InterTag size={36} text={trans(t)} color={COLORS.secondary} />
        ))}
      </Flex>
      <Flex column gap={16}>
        {[
          "Unfortunately, your current IP address is not authorized to access this platform. This may be due to security settings put in place to protect the system.",
          "If you believe this is an error or if your IP address should have access, please contact your account administrator or support team.",
          "For more information about our IP security policies, please reach out to your accoutn administrator.",
        ].map((t) => (
          <InterTag
            style={{ textWrap: "wrap", textAlign: "center" }}
            size={16}
            color={COLORS.secondary}
            text={trans(t)}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export default IpNotPermitted;
