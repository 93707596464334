import Markdown from "markdown-to-jsx";
import React from "react";
import { Modal, ModalProps } from "rsuite";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import { privacyPolicies } from "./localization";

interface iPrivacyNoticeModalWrappedProps extends Pick<iProps, "language"> {}

const PrivacyNoticeModalWrapped: React.FC<iPrivacyNoticeModalWrappedProps> = ({
  language,
}) => {
  return (
    <Modal.Body>
      <Markdown children={privacyPolicies[language]} />
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  language: keyof typeof privacyPolicies;
}

const PrivacyNoticeModal: React.FC<iProps> = ({
  onClose,
  language,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "md", onClose, ...props }}>
      <Modal.Header></Modal.Header>
      <PrivacyNoticeModalWrapped {...{ language }} />
    </Modal>
  );
};

export default PrivacyNoticeModal;
