import { at, round, sum } from "lodash";
import moment from "moment";
import { useState } from "react";
import { ReactComponent as InsightsIcon } from "../../../../assets/icons/insights.svg";
import { ReactComponent as TrendingDownIcon } from "../../../../assets/icons/trending_down.svg";
import { ReactComponent as TrendingUpIcon } from "../../../../assets/icons/trending_up.svg";
import indicatorsPlaceholder from "../../../../assets/placeholders/indicators.png";
import Flex from "../../../../components/Flex";
import ApexChart from "../../../../components/Graphs/Apex/Chart";
import Icon from "../../../../components/Icons/Icon";
import PageSection from "../../../../components/PageSection";
import SkeletonText from "../../../../components/Skeleton/SkeletonText";
import { iTextProps } from "../../../../components/Text";
import InterTag from "../../../../components/Text/Inter";
import { toTextProps } from "../../../../components/Text/utils";
import UnlockPageSection from "../../../../components/UnlockPageSection";
import useHotelMeasuresTrackedState from "../../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../../../context/WindowSize/hooks/useWindowSizeState";
import nUseGetMeasures from "../../../../hooks/apiRequests/useGetMeasures/interfaces";
import { tBinUnitSingular } from "../../../../interfaces/sensorData";
import { TE, TW } from "../../../../models/measures";
import { COLORS } from "../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
  FORCE_ENERGY_MEASURE_UNIT,
  FORCE_FOOTPRINT_MEASURE_UNIT,
  FORCE_WATER_MEASURE_UNIT,
} from "../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../utils/convertUnits/interfaces";
import { generateGraphCategories } from "../../../../utils/graphs";
import { calcVariation, getMeasureInfo } from "../../../../utils/measures";
import { thousandsSeparation } from "../../../../utils/numbers";
import { NOYTRALL_0 } from "../../../../utils/others";

interface iIndicatorsWrappedProps extends iIndicatorsProps {}

const IndicatorsWrapped: React.FC<iIndicatorsWrappedProps> = ({
  measuresRequest,
  homologueMeasuresRequest,
  period,
  binUnit,
  binValue,
}) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const { getMeasuresTracked } = useHotelMeasuresTrackedState();
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

  const trackedMeasures = getMeasuresTracked();

  const segments: {
    label: iTextProps | string;
    key: "te" | "tw" | "co2";
    converter: nUnitConverter.tFunction;
  }[] = [];

  if (trackedMeasures.te || trackedMeasures.tw) {
    segments.push({
      label: {
        text: trans("general.co2_emissions"),
        asHTML: true,
      },
      key: "co2",
      converter: convertMassUnit,
    });

    if (trackedMeasures.te)
      segments.push({
        label: trans("general.measures_.electricity"),
        key: "te",
        converter: convertEnergyUnit,
      });
    if (trackedMeasures.tw)
      segments.push({
        label: trans("general.measures_.water"),
        key: "tw",
        converter: convertVolumeUnit,
      });
  }

  if (segments.length === 0) return null;

  const [mainValueSize, mainValueUnitSize, homologueSize] = isMobile
    ? [26, 14, 14]
    : [36, 16, 16];

  const renderSegments = () => {
    return segments.map(({ label, key, converter }, i) => {
      let valueColor = COLORS.secondary;
      let labelColor = COLORS.gray_400;

      if (selectedSegmentIndex === i && !isMobile) {
        valueColor = COLORS.primary;
        labelColor = COLORS.primary;
      }

      return (
        <Flex
          key={key}
          column
          gap={8}
          basis={14}
          style={{
            ...(isMobile ? {} : { cursor: "pointer" }),
            padding: "6px",
            borderRadius: "8px",
          }}
          {...{
            ...(isMobile
              ? {}
              : {
                  onClick: () => {
                    if (selectedSegmentIndex !== i) setSelectedSegmentIndex(i);
                  },
                  className: "hover-darken-white-bg",
                }),
          }}
        >
          <InterTag
            size={12}
            color={labelColor}
            {...{ ...toTextProps(label) }}
          />
          <Flex column gap={8}>
            {measuresRequest.isLoading ? (
              <>
                <SkeletonText width={"70%"} height={mainValueSize} />
                <SkeletonText width={"70%"} height={homologueSize} />
              </>
            ) : measuresRequest.isResolved ? (
              (() => {
                const path =
                  key === "te"
                    ? "grouped.te.measurements"
                    : key === "tw"
                    ? "grouped.tw.measurements"
                    : "co2";

                const mainData = at(measuresRequest.data, path)[0] as number[];

                const converted = converter(sum(mainData));
                return (
                  <>
                    <Flex row gap={4} bottom>
                      <InterTag
                        color={valueColor}
                        size={mainValueSize}
                        text={thousandsSeparation(round(converted.value, 2))}
                      />
                      <InterTag
                        color={valueColor}
                        size={mainValueUnitSize}
                        text={converted.unitInHTML}
                        asHTML
                      />
                    </Flex>
                    {homologueMeasuresRequest.isLoading ? (
                      <SkeletonText width={"70%"} height={homologueSize} />
                    ) : (
                      (() => {
                        const homologueData = at(
                          homologueMeasuresRequest.data,
                          path
                        )[0] as number[];
                        const homologueConverted = converter(
                          sum(homologueData),
                          { forceUnit: converted.unit }
                        );

                        // return (
                        //   <TrendingComparison
                        //     value={converted.value}
                        //     baseValue={homologueConverted.value}
                        //   />
                        // );

                        const variation = calcVariation(
                          converted.value,
                          homologueConverted.value
                        );

                        if (variation === 0) return;
                        <InterTag
                          color={COLORS.gray_500}
                          text={"0 %"}
                          size={homologueSize}
                        />;

                        const [prefix, color, icon] = (() => {
                          if (converted.value > homologueConverted.value)
                            return ["", COLORS.error, TrendingUpIcon];
                          return ["-", COLORS.emissions, TrendingDownIcon];
                        })();

                        return (
                          <Flex row gap={4} middle basis={33}>
                            <InterTag
                              color={color}
                              text={`${prefix}${thousandsSeparation(
                                round(variation, 2)
                              )} %`}
                            />
                            <Icon
                              Element={icon}
                              fill={color}
                              size={homologueSize}
                            />
                          </Flex>
                        );
                      })()
                    )}
                  </>
                );
              })()
            ) : null}
          </Flex>
        </Flex>
      );
    });
  };

  const graph = () => {
    if (measuresRequest.isLoading)
      return <SkeletonText width={"100%"} height={288} />;

    const selectedSegment = segments[selectedSegmentIndex];

    const [path, color, converter, unit] = (() => {
      switch (selectedSegment.key) {
        case "co2":
          return [
            "co2",
            COLORS.esg_environment,
            convertMassUnit,
            FORCE_FOOTPRINT_MEASURE_UNIT,
          ];
        case "te":
          return [
            "grouped.te.measurements",
            getMeasureInfo(TE).color,
            convertEnergyUnit,
            FORCE_ENERGY_MEASURE_UNIT,
          ];
        case "tw":
          return [
            "grouped.tw.measurements",
            getMeasureInfo(TW).color,
            convertVolumeUnit,
            FORCE_WATER_MEASURE_UNIT,
          ];
      }
    })();

    const categories = generateGraphCategories(
      binUnit,
      binValue,
      period[0],
      period[1]
    );

    let type: "bar" | "line" = "line";

    const series: any[] = [];

    if (measuresRequest.isResolved) {
      const mainData = (
        (at(measuresRequest.data, path)[0] || []) as number[]
      ).map((v) => converter(v, { forceUnit: unit }).value);

      series.push({
        name: trans("general.period_main"),
        data: mainData,
        color,
      });
      type = mainData.length > 2 ? "line" : "bar";
    }
    if (homologueMeasuresRequest.isResolved) {
      const homologueData = (
        (at(homologueMeasuresRequest.data, path)[0] || []) as number[]
      ).map((v) => converter(v, { forceUnit: unit }).value);

      series.push({
        name: trans("general.period_homologue"),
        data: homologueData,
        color: COLORS.gray_500,
      });
      type = homologueData.length > 2 ? "line" : "bar";
    }

    return (
      <ApexChart
        {...{
          type,
          series,
          stroke: { width: 2 },
          yaxis: {
            labels: {
              show: true,
              formatter(val: any, opts: any) {
                return `${
                  val === 0 ? NOYTRALL_0 : thousandsSeparation(round(val, 2))
                } ${unit}`;
              },
            },
          },
          xaxis: {
            categories,
            tickAmount: 5,
            labels: {
              rotate: 0,
              show: true,
              formatter(value: string, timestamp?: number, opts?: any) {
                return moment(value).format("DD/MM/YYYY");
              },
            },
          },
          tooltip: {
            enabled: true,
            followCursor: true,
            x: {
              formatter(val: any, opts: any) {
                const date = categories[opts.dataPointIndex];
                return moment(date).format("DD MMM YYYY");
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            showForSingleSeries: true,
          },
        }}
      />
    );
  };

  return (
    <Flex className="card-m" column>
      <Flex row gap={16} wrap>
        {renderSegments()}
      </Flex>
      {!isMobile && graph()}
    </Flex>
  );
};

interface iIndicatorsProps {
  period: [Date, Date];
  binUnit: tBinUnitSingular;
  binValue: number;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
}

const Indicators: React.FC<iIndicatorsProps> = ({ ...props }) => {
  const { trans } = useLocalizationState();
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  return (
    <Flex column gap={16}>
      <PageSection
        icon={InsightsIcon}
        title={trans(
          "pages.sustainability.sections.indicators_evolution.title"
        )}
        description={trans(
          "pages.sustainability.sections.indicators_evolution.description"
        )}
      />
      {activeSubscriptionIsStarter ? (
        <UnlockPageSection
          style={{ paddingBottom: "180px" }}
          image={indicatorsPlaceholder}
          title={trans(
            "pages.sustainability.sections.indicators_evolution.unlock.title"
          )}
          description={[0].map((i) =>
            trans(
              `pages.sustainability.sections.indicators_evolution.unlock.description[${i}]`
            )
          )}
        />
      ) : (
        <IndicatorsWrapped {...{ ...props }} />
      )}
    </Flex>
  );
};

export default Indicators;
