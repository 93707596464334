import { floor, min, round } from "lodash";
import moment from "moment";
import React from "react";
import { Table } from "rsuite";
import { ReactComponent as DateRangeIcon } from "../../../../assets/icons/date_range.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/icons/group.svg";
import { ReactComponent as HotelIcon } from "../../../../assets/icons/hotel.svg";
import { ReactComponent as PercentIcon } from "../../../../assets/icons/percent.svg";
import TrendingComparison from "../../../../components/Comparions/trendingComparison";
import Flex from "../../../../components/Flex";
import BaseCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/BaseCell";
import SimpleActionCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleActionCell";
import SimpleTextCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleCells/SimpleTextCell";
import SimpleHeaderCell from "../../../../components/RsuiteWrapper/SimpleTable/SimpleHeaderCell";
import { tSimpleWhisperPopoverDropdownOptions } from "../../../../components/RsuiteWrapper/SimpleWhisperPopoverDropdown";
import InterTag from "../../../../components/Text/Inter";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useScreenSize from "../../../../hooks/useScreenSize";
import { tHotelManualData } from "../../../../models/hotel";
import { COLORS } from "../../../../utils/colors";
import { calcOccupancyRate } from "../../../../utils/hotels/occupancy";
import {
  TABLE_HEADER_HEIGHT,
  TABLE_ROW_HEIGHT,
} from "../../../../utils/tables";

type tTableItem = tHotelManualData & { index: number };

interface iListWrappedProps {
  outletContainerWidth: number;
}

const ListWrapped: React.FC<iListWrappedProps> = ({ outletContainerWidth }) => {
  const { trans } = useLocalizationState();
  const {
    manualDataOrdered,
    hotel: { accommodationNumberOfUnits },
  } = useHotelManualDataState();

  const options = (
    dataKey: string,
    award: tHotelManualData
  ): tSimpleWhisperPopoverDropdownOptions[] => {
    const options: tSimpleWhisperPopoverDropdownOptions[] = [];
    return options;
  };

  const data: tTableItem[] = manualDataOrdered.map((mdo, index) => ({
    ...mdo,
    index,
  }));

  const tableHeight =
    (min([5, manualDataOrdered.length]) as number) * TABLE_ROW_HEIGHT.M +
    TABLE_HEADER_HEIGHT;

  const periodWidth = floor(0.25 * outletContainerWidth);
  const guestsWidth = floor(0.2 * outletContainerWidth);
  const staysWidth = floor(0.2 * outletContainerWidth);
  const occupancyWidth = floor(0.2 * outletContainerWidth);

  return (
    <Flex column gap={16}>
      <div className="table-wrapper">
        <Table
          style={{ borderRadius: "8px" }}
          data={data}
          height={tableHeight}
          rowHeight={TABLE_ROW_HEIGHT.M}
          headerHeight={TABLE_HEADER_HEIGHT}
        >
          <Table.Column width={periodWidth}>
            <SimpleHeaderCell
              name={trans("general.period")}
              icon={DateRangeIcon}
            />
            <SimpleTextCell
              dataKey="from"
              textFunction={({ from, to }: tTableItem) =>
                trans("general.x_date_to_y_date", {
                  parameters: [from, to].map((d) =>
                    moment(d).format("DD MMM YYYY")
                  ),
                })
              }
            />
          </Table.Column>
          <Table.Column width={guestsWidth}>
            <SimpleHeaderCell name={trans("general.guests")} icon={GroupIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                const { occupancy, index } = item;

                if (!occupancy) return null;
                const { numberOfGuests } = occupancy;

                let comparison: any = null;
                if (index > 0) {
                  let previous: Partial<tHotelManualData> = {};
                  let i = index;
                  while (!previous.occupancy && i > 0) {
                    previous = data[i - 1];
                    i--;
                  }
                  if (previous.occupancy) {
                    comparison = (
                      <TrendingComparison
                        moreIsGood
                        size={12}
                        value={occupancy.numberOfGuests}
                        baseValue={previous.occupancy.numberOfGuests}
                      />
                    );
                  }
                }

                return (
                  <Flex style={{ width: "fit-content" }} column gap={2}>
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={numberOfGuests}
                    />
                    {comparison}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={staysWidth}>
            <SimpleHeaderCell name={trans("general.stays")} icon={HotelIcon} />
            <BaseCell>
              {(item: tTableItem) => {
                const { occupancy, index } = item;

                if (!occupancy) return null;
                const { numberOfStays } = occupancy;

                let comparison: any = null;
                if (index > 0) {
                  let previous: Partial<tHotelManualData> = {};
                  let i = index;
                  while (!previous.occupancy && i > 0) {
                    previous = data[i - 1];
                    i--;
                  }
                  if (previous.occupancy) {
                    comparison = (
                      <TrendingComparison
                        moreIsGood
                        size={12}
                        value={occupancy.numberOfStays}
                        baseValue={previous.occupancy.numberOfStays}
                      />
                    );
                  }
                }

                return (
                  <Flex style={{ width: "fit-content" }} column gap={2}>
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={numberOfStays}
                    />
                    {comparison}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column width={occupancyWidth}>
            <SimpleHeaderCell
              name={trans("general.occupancy_rate")}
              icon={PercentIcon}
            />
            <BaseCell>
              {(item: tTableItem) => {
                const { occupancy, index, from, to } = item;

                if (!occupancy) return null;

                const occupancyRate =
                  occupancy.occupancyRate ||
                  calcOccupancyRate(
                    accommodationNumberOfUnits,
                    occupancy.numberOfStays,
                    from,
                    to
                  );
                if (index === 0)
                  return (
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${round(occupancyRate, 2)} %`}
                    />
                  );

                const previous = data[index - 1];
                const previousOccupancyRate =
                  previous.occupancy?.occupancyRate ||
                  calcOccupancyRate(
                    accommodationNumberOfUnits,
                    previous.occupancy?.numberOfStays || 0,
                    previous.from,
                    previous.to
                  );
                const comparison = (
                  <TrendingComparison
                    moreIsGood
                    size={12}
                    value={occupancyRate}
                    baseValue={previousOccupancyRate}
                  />
                );

                return (
                  <Flex style={{ width: "fit-content" }} column gap={2}>
                    <InterTag
                      size={12}
                      color={COLORS.secondary}
                      text={`${round(occupancyRate, 2)} %`}
                    />
                    {comparison}
                  </Flex>
                );
              }}
            </BaseCell>
          </Table.Column>
          <Table.Column flexGrow={1} align="right">
            <SimpleHeaderCell name={{ text: "" }} />
            <SimpleActionCell options={options} dataKey="_id" />
          </Table.Column>
        </Table>
      </div>
    </Flex>
  );
};

interface iProps {}

const List: React.FC<iProps> = () => {
  const { outlet } = useScreenSize();

  if (!outlet) return null;

  return <ListWrapped outletContainerWidth={outlet.width} />;
};

export default List;
