import React from "react";
import { StepItemProps, Steps, StepsProps } from "rsuite";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";

interface iProps extends StepsProps {
  onClick(step: number): void;
}

const StepsHeader: React.FC<iProps> = ({ onClick, current }) => {
  const { trans } = useLocalizationState();

  const headerStepItems: (StepItemProps & {
    key: string;
    step: number;
  })[] = [
    {
      key: "period",
      title: trans("Timeframe"),
      step: 0,
    },
    {
      key: "space",
      title: trans("Accommodation"),
      step: 1,
    },
    {
      key: "occupancy",
      title: trans("Occupancy"),
      step: 2,
    },
    {
      key: "electricity",
      title: trans("Energy"),
      step: 3,
    },
    {
      key: "fuels",
      title: trans("Fuels"),
      step: 4,
    },
    {
      key: "water",
      title: trans("Water"),
      step: 5,
    },
    {
      key: "laundry",
      title: trans("Laundry"),
      step: 6,
    },
    {
      key: "waste",
      title: trans("Waste"),
      step: 7,
    },
  ];

  const steps: JSX.Element[] = headerStepItems.map(({ step, title }) => {
    const status = current === step ? "process" : "wait";
    return (
      <Steps.Item
        key={step}
        style={{
          cursor: "pointer",
        }}
        onClick={() => onClick(step)}
        {...{ status, title }}
      />
    );
  });

  return <Steps>{steps}</Steps>;
};

export default React.memo(StepsHeader);
