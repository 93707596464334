import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalProps } from "rsuite";
import { ReactComponent as HighFiveBro } from "../../assets/bros/high five hands-bro 2.svg";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as EnergyProgramSavingIcon } from "../../assets/icons/energy_program_saving.svg";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";
import { COLORS, hex2hexA } from "../../utils/colors";
import Flex from "../Flex";
import Icon from "../Icons/Icon";
import SimpleButton from "../RsuiteWrapper/SimpleButton";
import InterTag from "../Text/Inter";
import styles from "./styles.module.css";

const buildTransKey = (key: string) =>
  `components.getting_started.modals.final.${key}`;

interface iGettingStartedFinalModalWrappedProps
  extends Pick<iProps, "onClose"> {}

const GettingStartedFinalModalWrapped: React.FC<
  iGettingStartedFinalModalWrappedProps
> = ({ onClose }) => {
  const { isLaptop } = useWindowSizeState();
  const { trans } = useLocalizationState();
  const navigate = useNavigate();

  const [
    gotoContainerProps,
    gotoIconSize,
    gotoTextSize,
    contactContainerProps,
  ] = isLaptop
    ? [
        { row: true, gap: 16, center: true },
        40,
        20,
        { row: true, gap: 40, middle: true },
      ]
    : [{ column: true, gap: 8, left: true }, 24, 16, { column: true, gap: 8 }];

  const renderActionButtons = () => {
    return [
      {
        label: trans(buildTransKey("goto_dashboard")),
        Element: DashboardIcon,
        link: "/overview",
      },
      {
        label: trans(buildTransKey("check_scores")),
        Element: EnergyProgramSavingIcon,
        link: "/sustainability",
      },
    ].map(({ label, Element, link }) => {
      return (
        <Button
          key={link}
          className={styles["all-done-button"]}
          onClick={() => {
            navigate(link);
            onClose();
          }}
        >
          <Flex row gap={16} middle>
            <Flex
              center
              middle
              style={{
                borderRadius: "8px",
                backgroundColor: hex2hexA(COLORS.primary, 30),
                padding: "12px",
              }}
            >
              <Icon
                Element={Element}
                size={gotoIconSize}
                fill={COLORS.secondary}
              />
            </Flex>
            <InterTag
              text={label}
              size={gotoTextSize}
              color={COLORS.secondary}
            />
          </Flex>
        </Button>
      );
    });
  };

  return (
    <Modal.Body>
      <Flex column gap={16}>
        <Flex column gap={8}>
          <InterTag
            text={trans(buildTransKey("title"))}
            bold
            size={20}
            color={COLORS.secondary}
          />
          <InterTag
            style={{ textWrap: "wrap", lineHeight: "18px" }}
            text={trans(buildTransKey("description"))}
            size={14}
            color={COLORS.secondary}
          />
        </Flex>
        <Flex center>
          <Icon Element={HighFiveBro} size={250} />
        </Flex>
        <InterTag
          style={{ textWrap: "wrap", lineHeight: "18px" }}
          text={trans(buildTransKey("explore"))}
          size={14}
          color={COLORS.secondary}
        />
        <Flex {...gotoContainerProps}>{renderActionButtons()}</Flex>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: COLORS.gray_400,
          }}
        />
        <Flex {...contactContainerProps}>
          <div>
            <InterTag
              size={14}
              color={COLORS.secondary}
              style={{ textWrap: "wrap", lineHeight: "18px" }}
              text={trans(buildTransKey("need_help"))}
            />
          </div>
          <Flex column>
            <SimpleButton
              appearance="link"
              text={{
                text: trans(buildTransKey("contact_support")),
                size: 20,
                color: COLORS.primary,
              }}
            />
          </Flex>
        </Flex>
        <div
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: COLORS.gray_400,
          }}
        />
        <InterTag
          size={14}
          color={COLORS.secondary}
          style={{ textWrap: "wrap", lineHeight: "18px" }}
          text={trans(buildTransKey("thanks"))}
        />
      </Flex>
    </Modal.Body>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  onClose(): void;
}

const GettingStartedFinalModal: React.FC<iProps> = ({ onClose, ...rest }) => {
  const { isLaptop } = useWindowSizeState();
  return (
    <Modal
      {...{
        onClose,
        size: isLaptop ? "md" : "full",
        overflow: false,
        keyboard: false,
        backdrop: "static",
        ...rest,
      }}
    >
      <GettingStartedFinalModalWrapped {...{ onClose }} />
    </Modal>
  );
};

export default GettingStartedFinalModal;
