import { find } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../../";
import { tHotelAward } from "../../../../models/hotel";
import useHotelState from "./useHotelState";

const useHotelAwardsState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelAwardsState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const findAward = useCallback(
    (awardId: tHotelAward["_id"]): tHotelAward | undefined => {
      return find(hotel.awards, (a) => a._id === awardId);
    },
    [hotel.awards]
  );

  const listAwards = useCallback(
    (awardIds: tHotelAward["_id"][]) => {
      return awardIds
        .map((awardId) => findAward(awardId))
        .filter((a) => a !== undefined) as tHotelAward[];
    },
    [findAward]
  );

  return {
    hotel,
    hotelId,
    findAward,
    listAwards,
  };
};

export default useHotelAwardsState;
