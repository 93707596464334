import { at, round, sum } from "lodash";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { ReactComponent as EcoIcon } from "../../../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../../../assets/icons/electric_bolt.svg";
import { ReactComponent as GroupIcon } from "../../../../../assets/icons/group.svg";
import { ReactComponent as WaterDropIcon } from "../../../../../assets/icons/water_drop.svg";
import StraightComparison from "../../../../../components/Comparions/straightComparison";
import TrendingComparison from "../../../../../components/Comparions/trendingComparison";
import Flex from "../../../../../components/Flex";
import Icon from "../../../../../components/Icons/Icon";
import SkeletonText from "../../../../../components/Skeleton/SkeletonText";
import InterTag from "../../../../../components/Text/Inter";
import useLocalizationState from "../../../../../context/Localization/hooks/useLocalizationState";
import nUseGetMeasures from "../../../../../hooks/apiRequests/useGetMeasures/interfaces";
import {
  tBenchmarkData,
  useGetBenchmarkData,
} from "../../../../../hooks/useGetBenchmarkData";
import useGetNumberOfStaysAndGuests from "../../../../../hooks/useGetNumberOfStaysAndGuests";
import { tIcon } from "../../../../../interfaces/icon";
import { COLORS } from "../../../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../../../utils/convertUnits/";
import { nUnitConverter } from "../../../../../utils/convertUnits/interfaces";
import { calcNumberOfNights } from "../../../../../utils/dates";
import { thousandsSeparation } from "../../../../../utils/numbers";
import { SustainabilityContext } from "../../context";
import { tBenchmarkSizes } from "../../sustainability";

interface iProps {
  benchmarkRequest: ReturnType<typeof useGetBenchmarkData>;
  homologueMeasuresRequest: nUseGetMeasures.tFunctionReturn;
  measuresRequest: nUseGetMeasures.tFunctionReturn;
  period: [Date, Date];
  noData: boolean;
  benchmarkSizes: tBenchmarkSizes;
}

const PerGuest: React.FC<iProps> = ({
  benchmarkRequest,
  homologueMeasuresRequest,
  measuresRequest,
  period,
  noData,
  benchmarkSizes,
}) => {
  const { trans } = useLocalizationState();
  const { setDataTypeToggleDisabled } = useContext(SustainabilityContext);
  const guestsRequest = useGetNumberOfStaysAndGuests(
    {
      from: moment(period[0]).toISOString(),
      to: moment(period[1]).toISOString(),
    },
    { doRequest: !noData }
  );

  useEffect(() => {
    setDataTypeToggleDisabled({ guestsRequest: guestsRequest.isLoading });
  }, [guestsRequest.isLoading, setDataTypeToggleDisabled]);

  const {
    titleSize,
    titleIconSize,
    typeSize,
    typeIconSize,
    mainValueSize,
    mainValueUnitSize,
    mainValuePadding,
    benchmarkSize,
  } = benchmarkSizes;

  const numberOfNights = calcNumberOfNights(...period);

  const renderMainValue = (
    pathToMeasureValues: string,
    converter: nUnitConverter.tFunction
  ) => {
    if (measuresRequest.isLoading || guestsRequest.isLoading)
      return <SkeletonText width={100} height={mainValueSize} />;

    if (measuresRequest.isResolved && guestsRequest.isResolved) {
      const values = at(
        measuresRequest.data,
        pathToMeasureValues
      )[0] as number[];

      const converted = converter(
        sum(values) / guestsRequest.data.numberOfGuests / (numberOfNights || 1)
      );

      return (
        <Flex row bottom gap={4}>
          <InterTag
            size={mainValueSize}
            color={COLORS.secondary}
            text={thousandsSeparation(round(converted.value, 2))}
          />
          <InterTag
            size={mainValueUnitSize}
            color={COLORS.secondary}
            text={converted.unitInHTML}
            asHTML
          />
        </Flex>
      );
    }
    return null;
  };

  const renderHomologueComparison = (pathToMeasureValues: string) => {
    if (measuresRequest.isLoading || homologueMeasuresRequest.isLoading)
      return <SkeletonText width={50} height={benchmarkSize} />;

    if (measuresRequest.isResolved && homologueMeasuresRequest.isResolved) {
      const value = sum(
        at(measuresRequest.data, pathToMeasureValues)[0] as number[]
      );
      const homologueValue = sum(
        at(homologueMeasuresRequest.data, pathToMeasureValues)[0] as number[]
      );

      return (
        <TrendingComparison
          size={benchmarkSize}
          value={homologueValue}
          baseValue={value}
        />
      );
    }
    return null;
  };

  const renderBenchmarkComparison = (
    pathToBenchmarkValue: string,
    pathToMeasuresValue: string,
    converter: nUnitConverter.tFunction
  ) => {
    if (
      benchmarkRequest.isLoading ||
      measuresRequest.isLoading ||
      guestsRequest.isLoading
    )
      return <SkeletonText height={mainValueSize} width={100} />;

    if (
      benchmarkRequest.isResolved &&
      measuresRequest.isResolved &&
      guestsRequest.isResolved
    ) {
      const benchmarkValue = at(
        benchmarkRequest.data,
        pathToBenchmarkValue
      )[0] as unknown as number;

      const value =
        converter(
          sum(at(measuresRequest.data, pathToMeasuresValue)[0] as number[])
        ).value /
        guestsRequest.data.numberOfGuests /
        numberOfNights;

      return (
        <StraightComparison
          valueSize={mainValueSize}
          unitSize={mainValueUnitSize}
          baseValue={benchmarkValue}
          value={value}
        />
      );
    }
    return null;
  };

  const renderBenchmark = (
    pathToBenchmarkValue: `${keyof Pick<
      tBenchmarkData,
      "perGuest"
    >}.${keyof tBenchmarkData["perGuest"]}`,
    unit: string
  ) => {
    if (benchmarkRequest.isLoading)
      return <SkeletonText height={benchmarkSize} width={50} />;

    if (benchmarkRequest.isResolved) {
      // @ts-expect-error
      const value = at(
        benchmarkRequest.data,
        pathToBenchmarkValue
      )[0] as number;

      return (
        <Flex row gap={4} middle>
          <InterTag
            size={benchmarkSize}
            color={COLORS.secondary}
            text={thousandsSeparation(value)}
          />
          <InterTag
            size={benchmarkSize}
            color={COLORS.secondary}
            text={unit}
            asHTML
          />
        </Flex>
      );
    }
    return null;
  };

  const renderDivider = () => {
    return (
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: COLORS.gray_200,
        }}
      />
    );
  };

  const renderGuestsCell = (
    label: string,
    icon: tIcon,
    pathToMeasureValues: string,
    pathToBenchmarkValue: `${keyof Pick<
      tBenchmarkData,
      "perGuest"
    >}.${keyof tBenchmarkData["perGuest"]}`,
    benchmarkUnit: string,
    converter: nUnitConverter.tFunction
  ) => {
    return (
      <Flex gap={10} column color={COLORS.white}>
        <Flex row gap={4} middle>
          <Icon {...{ size: typeIconSize, ...icon }} />
          <InterTag size={typeSize} color={COLORS.gray} text={label} asHTML />
        </Flex>
        <Flex row between>
          <Flex one left>
            <div style={{ paddingLeft: `${mainValuePadding}px` }}>
              {renderMainValue(pathToMeasureValues, converter)}
            </div>
          </Flex>
          <Flex one right>
            <div style={{ paddingRight: `${mainValuePadding}px` }}>
              {renderBenchmarkComparison(
                pathToBenchmarkValue,
                pathToMeasureValues,
                converter
              )}
            </div>
          </Flex>
        </Flex>
        <Flex row between>
          <Flex one left>
            <div style={{ paddingLeft: `${mainValuePadding}px` }}>
              {renderHomologueComparison(pathToMeasureValues)}
            </div>
          </Flex>
          <Flex one right>
            <div style={{ paddingRight: `${mainValuePadding}px` }}>
              {renderBenchmark(pathToBenchmarkValue, benchmarkUnit)}
            </div>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex
      one
      column
      color={COLORS.white}
      style={{ padding: 16, borderRadius: "8px" }}
      gap={16}
    >
      <Flex row gap={8} middle>
        <Icon
          Element={GroupIcon}
          size={titleIconSize}
          fill={COLORS.secondary}
        />
        <InterTag
          size={titleSize}
          color={COLORS.secondary}
          text={trans(
            "pages.sustainability.sections.indicators.cards.guest.title"
          )}
        />
      </Flex>
      {renderGuestsCell(
        trans("general.co2_emissions"),
        {
          Element: EcoIcon,
          fill: COLORS.emissions,
        },
        "co2",
        "perGuest.co2_kg",
        "kg",
        convertMassUnit
      )}
      {renderDivider()}
      {renderGuestsCell(
        trans("general.measures_.electricity"),
        {
          Element: ElectricBoltIcon,
          fill: COLORS.energy,
        },
        "grouped.te.measurements",
        "perGuest.electricity_kWh",
        "kWh",
        convertEnergyUnit
      )}
      {renderDivider()}
      {renderGuestsCell(
        trans("general.measures_.water"),
        {
          Element: WaterDropIcon,
          fill: COLORS.water,
        },
        "grouped.tw.measurements",
        "perGuest.water_m3",
        "m<sup>3</sup>",
        convertVolumeUnit
      )}
    </Flex>
  );
};

export default PerGuest;
