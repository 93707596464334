import { tHotelAwardId, tHotelSpaceAggregateId, tHotelSpaceId } from ".";

export type tHotelChallengeType =
  | "Footprint Frenzy"
  | "Water Saving Heroes"
  | "Energy Boost";

export const HOTEL_CHALLENGE_TYPES: tHotelChallengeType[] = [
  "Footprint Frenzy",
  "Water Saving Heroes",
  "Energy Boost",
];

export type tHotelChallengeAudience = {
  spaces: tHotelSpaceId[];
  spaceAggregates: tHotelSpaceAggregateId[];
};

export type tHotelChallengeId = string;

export type tHotelChallenge = {
  _id: tHotelChallengeId;
  type: tHotelChallengeType;
  from: Date;
  to: Date;
  target: tHotelChallengeAudience;
  awards: {
    awardId: tHotelAwardId;
    count: number;
    availableFrom: Date;
    availableUntil: Date;
  }[];
  createdBy: string;
  timestamp: Date;
  winner: string[];
  removed: boolean;
};
