import { every, extend, pick } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../../";
import {
  tHotelStaffPermissions,
  tHotelStaffRole,
} from "../../../../models/hotel";
import { tManagerId } from "../../../../models/manager";
import useHotelState from "./useHotelState";

const useHotelRolesState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const listStaffMemberRoles = useCallback(
    (staffId: tManagerId) => {
      return hotel.staffRoles.filter((r) => r.staff.includes(staffId));
    },
    [hotel.staffRoles]
  );

  const staffMemberHasPermissions = (
    staffId: tManagerId,
    permissions: (keyof tHotelStaffRole["permissions"])[]
  ) => {
    const roles = listStaffMemberRoles(staffId);
    if (roles.length === 0) return false;

    const fullPermissions: Partial<tHotelStaffPermissions> = {};

    roles.forEach((r) => {
      extend(fullPermissions, r.permissions);
    });

    return every(
      Object.values(pick(fullPermissions, permissions)),
      (p) => p === true
    );
  };

  return {
    hotel,
    hotelId,
    listStaffMemberRoles,
    staffMemberHasPermissions,
  };
};

export default useHotelRolesState;
