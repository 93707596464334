import React, { useEffect, useReducer } from "react";
import { useWindowSize } from "usehooks-ts";
import { REQUEST_STATUS } from "../../utils/apiCall";
import { nWindowSize } from "./interfaces";

const WindowSizeDispatchContext = React.createContext<
  nWindowSize.tDispatchContext | undefined
>(undefined);
WindowSizeDispatchContext.displayName = "WindowSizeDispatchContext";
const WindowSizeStateContext = React.createContext<
  nWindowSize.tStateContext | undefined
>(undefined);
WindowSizeStateContext.displayName = "WindowSizeStateContext";

const LS_DATA = "";

const initialState: nWindowSize.tState = {
  data: {
    isLaptop: false,
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  },
  status: "idle",
  error: null,
};

const reducer = (
  state: nWindowSize.tState,
  action: nWindowSize.tAction
): nWindowSize.tState => {
  switch (action.type) {
    case "set data": {
      const { data } = action;
      localStorage.setItem(LS_DATA, JSON.stringify(data));
      return { ...state, data, status: "resolved", error: null };
    }
    case "resolved": {
      return { ...state, status: "resolved", error: null };
    }
    case "rejected": {
      const { error } = action;
      return { ...state, status: "rejected", error };
    }
    case "pending": {
      return { ...state, status: "pending", error: null };
    }
    default:
      return { ...state };
  }
};

const WindowSizeContextProvider: React.FC<nWindowSize.iContextProps> = ({
  children,
}) => {
  const { width } = useWindowSize();
  const [state, dispatch]: [
    nWindowSize.tState,
    React.Dispatch<nWindowSize.tAction>
  ] = useReducer(reducer, initialState);

  useEffect(() => {
    const isMobile = width < 768;
    const isTablet = width >= 768;
    const isLaptop = width >= 1024;
    const isDesktop = width >= 1720;

    if (
      isMobile !== state.data.isMobile ||
      isTablet !== state.data.isTablet ||
      isLaptop !== state.data.isLaptop ||
      isDesktop !== state.data.isDesktop
    ) {
      dispatch({
        type: "set data",
        data: { isMobile, isTablet, isLaptop, isDesktop },
      });
    }
  }, [
    state.data.isDesktop,
    state.data.isLaptop,
    state.data.isMobile,
    state.data.isTablet,
    width,
  ]);

  return (
    <WindowSizeStateContext.Provider value={state}>
      <WindowSizeDispatchContext.Provider value={dispatch}>
        {state.status === REQUEST_STATUS.RESOLVED && children}
      </WindowSizeDispatchContext.Provider>
    </WindowSizeStateContext.Provider>
  );
};

export {
  WindowSizeContextProvider,
  WindowSizeDispatchContext,
  WindowSizeStateContext,
};
