const WithContextProvider = (Provider: any, Component: any) => {
  return (props: any) => {
    return (
      <Provider>
        <Component {...props} />
      </Provider>
    );
  };
};

export default WithContextProvider;
