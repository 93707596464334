import React, { useEffect } from "react";
import useAuthDispatch from "../../../context/Auth/hooks/useAuthDispatch";

interface iProps {}

const GotoSetupPassword: React.FC<iProps> = () => {
  const { logout } = useAuthDispatch();

  useEffect(() => {
    logout();
  }, [logout]);

  return <></>;
};

export default GotoSetupPassword;
