export type tMeasureTW = "tw";
export type tMeasureHW = "hw";
export type tMeasureGW = "gw";
export type tMeasureCW = "cw";
export type tMeasureDW = "dw";
export type tMeasureNTW = "ntw";
export type tMeasureWater =
  | tMeasureTW
  | tMeasureHW
  | tMeasureCW
  | tMeasureGW
  | tMeasureDW
  | tMeasureNTW;
export type tMeasureWaterSpecific =
  | tMeasureHW
  | tMeasureCW
  | tMeasureGW
  | tMeasureDW
  | tMeasureNTW;
export type tMeasureWaterTotal = tMeasureTW;

export type tMeasureTE = "te";
export type tMeasureEL = "el";
export type tMeasureWH = "wh";
export type tMeasureES = "es";
export type tMeasureAC = "ac";
export type tMeasureEnergy =
  | tMeasureTE
  | tMeasureES
  | tMeasureEL
  | tMeasureAC
  | tMeasureWH;
export type tMeasureEnergySpecific =
  | tMeasureES
  | tMeasureEL
  | tMeasureAC
  | tMeasureWH;
export type tMeasureEnergyTotal = tMeasureTE;

export type tMeasure =
  | tMeasureTE
  | tMeasureTW
  | tMeasureCW
  | tMeasureHW
  | tMeasureDW
  | tMeasureNTW
  | tMeasureGW
  | tMeasureEL
  | tMeasureES
  | tMeasureWH
  | tMeasureAC;

export type tMeasureTotal = tMeasureTE | tMeasureTW;

export const TW: tMeasureTW = "tw";
export const HW: tMeasureHW = "hw";
export const GW: tMeasureGW = "gw";
export const CW: tMeasureCW = "cw";
export const DW: tMeasureDW = "dw";
export const NTW: tMeasureNTW = "ntw";

export const TE: tMeasureTE = "te";
export const ES: tMeasureES = "es";
export const EL: tMeasureEL = "el";
export const AC: tMeasureAC = "ac";
export const WH: tMeasureWH = "wh";

export const MEASURES: tMeasure[] = [
  TW,
  HW,
  GW,
  CW,
  DW,
  NTW,
  TE,
  ES,
  EL,
  AC,
  WH,
];

export const MEASURES_WATER: (tMeasureWaterTotal | tMeasureWaterSpecific)[] = [
  TW,
  HW,
  GW,
  CW,
  DW,
  NTW,
];
export const MEASURES_WATER_SPECIFIC: tMeasureWaterSpecific[] = [
  HW,
  GW,
  CW,
  DW,
  NTW,
];

export const MEASURES_ELECTRICITY: (
  | tMeasureEnergyTotal
  | tMeasureEnergySpecific
)[] = [TE, ES, EL, AC, WH];
export const MEASURES_ELECTRICITY_SPECIFIC: tMeasureEnergySpecific[] = [
  ES,
  EL,
  AC,
  WH,
];

export const MEASURES_TOTAL: tMeasureTotal[] = [TE, TW];
