import React, { useContext, useEffect } from "react";
import { ReactComponent as DirectionsCarIcon } from "../../../../assets/icons/directions_car.svg";
import { ReactComponent as LaundryIcon } from "../../../../assets/icons/local_laundry_service.svg";
import { ReactComponent as QueryStatsIcon } from "../../../../assets/icons/query_stats.svg";
import wasteConsumptionPlaceholder from "../../../../assets/placeholders/consumption-waste-data-placeholder.png";
import fleetPlaceholder from "../../../../assets/placeholders/fleet-placeholder.png";
import laundryPlaceholder from "../../../../assets/placeholders/laundry-placeholder.png";
import Flex from "../../../../components/Flex";
import HistoricDataConsumptionGraphSection from "../../../../components/HistoricDataConsumptionGraphSection";
import PageBottomPadding from "../../../../components/PageBottomPadding";
import PageSection, {
  iPageSectionProps,
} from "../../../../components/PageSection";
import SecondaryTopBar from "../../../../components/TopBar/SecondaryTopBar";
import UnlockPageSection, {
  iUnlockPageSectionProps,
} from "../../../../components/UnlockPageSection";
import useHotelManualDataState from "../../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import useGetDefaultConverionFactors from "../../../../hooks/useGetDefaultConverionFactors";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import FleetGraph from "../../fleet/vehicles/graph";
import LaundryGraph from "../../laundry/graph";
import WasteGraph from "../../waste/graph";
import { ConsumptionContext } from "../context";

const HistoricDataWrappedNonStarter: React.FC = () => {
  const { trans } = useLocalizationState();

  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  const list: {
    key: string;
    pageSectionProps: iPageSectionProps;
    Component: React.FC<any>;
    unlockProps: iUnlockPageSectionProps;
  }[] = [
    {
      key: "waste",
      pageSectionProps: {
        title: trans("pages.waste.archive.sections.overview.title"),
        icon: QueryStatsIcon,
        description: trans("pages.waste.archive.sections.overview.description"),
      },
      Component: WasteGraph,
      unlockProps: {
        image: wasteConsumptionPlaceholder,
        title: trans("pages.waste.unlock.title"),
        description: trans(
          "pages.waste.unlock.description"
        ) as unknown as string[],
      },
    },
    {
      key: "laundry",
      pageSectionProps: {
        title: trans("pages.consumption.archive.sections.laundry.title"),
        description: trans(
          "pages.consumption.archive.sections.laundry.description"
        ),
        icon: LaundryIcon,
      },
      Component: LaundryGraph,
      unlockProps: {
        image: laundryPlaceholder,
        title: trans("pages.laundry.unlock.title"),
        description: trans(
          "pages.laundry.unlock.description"
        ) as unknown as string[],
      },
    },
    {
      key: "fleet",
      pageSectionProps: {
        title: trans("pages.consumption.archive.sections.fleet.title"),
        description: trans(
          "pages.consumption.archive.sections.fleet.description"
        ),
        icon: DirectionsCarIcon,
      },
      Component: FleetGraph,
      unlockProps: {
        image: fleetPlaceholder,
        title: trans("pages.fleet.unlock.title"),
        description: trans(
          "pages.fleet.unlock.description"
        ) as unknown as string[],
      },
    },
  ];

  if (activeSubscriptionIsStarter)
    return (
      <>
        {list.map(({ key, pageSectionProps, unlockProps }) => (
          <Flex column gap={20} key={key}>
            <PageSection {...{ ...pageSectionProps }} />
            <UnlockPageSection {...{ ...unlockProps }} />
          </Flex>
        ))}
      </>
    );

  return (
    <>
      {list.map(({ Component, key, pageSectionProps }) => (
        <Flex column gap={20} key={key}>
          <PageSection {...{ ...pageSectionProps }} />
          <Component />
        </Flex>
      ))}
    </>
  );
};

const HistoricDataWithConversionFactors: React.FC = () => {
  const { setDataTypeToggleDisabled } = useContext(ConsumptionContext);
  const { measuresTrackedInManualData } = useHotelManualDataState();

  const measuresAreTracked =
    measuresTrackedInManualData.electricity ||
    measuresTrackedInManualData.water ||
    measuresTrackedInManualData.fuels;
  const conversionFactorsRequest = useGetDefaultConverionFactors("PT", {
    doRequest: measuresAreTracked,
    initialStatus: measuresAreTracked
      ? REQUEST_STATUS.PENDING
      : REQUEST_STATUS.IDLE,
  });

  useEffect(() => {
    setDataTypeToggleDisabled({
      conversionFactorsRequest: conversionFactorsRequest.isLoading,
    });
  }, [conversionFactorsRequest.isLoading, setDataTypeToggleDisabled]);

  return (
    <>
      {["electricity", "water", "fuels"].map((key) => (
        <HistoricDataConsumptionGraphSection
          {...{
            key,
            conversionFactorsRequest,
            measure: key as "electricity" | "water" | "fuels",
          }}
        />
      ))}
    </>
  );
};

interface iHistoricDataWrappedProps {
  dataTypeToggle: JSX.Element | null;
}

const HistoricDataWrapped: React.FC<iHistoricDataWrappedProps> = ({
  dataTypeToggle,
}) => {
  return (
    <div>
      <SecondaryTopBar>
        <Flex row gap={8}>
          {dataTypeToggle && <Flex middle>{dataTypeToggle}</Flex>}
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        <HistoricDataWithConversionFactors />
        <HistoricDataWrappedNonStarter />
      </Flex>
    </div>
  );
};

interface iProps {
  dataTypeToggle: JSX.Element | null;
}

const HistoricData: React.FC<iProps> = ({ dataTypeToggle }) => {
  return (
    <div>
      <HistoricDataWrapped {...{ dataTypeToggle }} />
      <PageBottomPadding />
    </div>
  );
};

export default HistoricData;
