export type tHotelAwardDescription = {
  language: string;
  shortDescription: string;
  longDescription: string;
};

export type tHotelAwardType =
  | "Adventure"
  | "Cultural"
  | "Food & Drinks"
  | "Hospitality"
  | "Leisure"
  | "Products"
  | "Services"
  | "Wellness";
export const HOTEL_AWARD_TYPES: tHotelAwardType[] = [
  "Adventure",
  "Cultural",
  "Food & Drinks",
  "Hospitality",
  "Leisure",
  "Products",
  "Services",
  "Wellness",
];

export type tHotelAwardId = string;
export type tHotelAward = {
  _id: tHotelAwardId;

  type: tHotelAwardType;

  descriptions: tHotelAwardDescription[];
  description: string;

  location: string;

  toBeUsedUntil: Date;
  validityAfterAwarding: number;

  discount: number;
  discountType: "percentage" | "nominal";

  images: string[];

  timesUsed: number;
  timesAwarded: number;

  active: boolean;
  removed: boolean;

  timestamp: Date;
  createdBy: string;
};
