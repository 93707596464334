import React from "react";

const ConsumableWrapped: React.FC = () => {
  return <div>Consumables</div>;
};

const Consumables: React.FC = () => {
  return <ConsumableWrapped />;
};

export default Consumables;
