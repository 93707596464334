import React from "react";
import { InputGroup, InputNumber } from "rsuite";
import { tState2 } from ".";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { nRequestStatus } from "../../../interfaces/apiCalls";
import { tHotel } from "../../../models/hotel";
import { REQUEST_STATUS } from "../../../utils/apiCall";
import Flex from "../../Flex";
import InputWrapper from "../../InputWrapper";

type tKeyofSpace = keyof tState2["data"]["space"];

interface iProps {
  status: nRequestStatus.tStatus;
  onChange(
    spaceProperty: keyof tState2["data"]["space"],
    value: number | string
  ): void;
  state: Required<tHotel["manualData"][0]>["space"];
  errors: tState2["errors"]["space"];
}

const Space: React.FC<iProps> = ({ onChange, state, errors, status }) => {
  const { trans } = useLocalizationState();

  const renderInputs = () => {
    const list: {
      label: string;
      description: string;
      placeholder: string;
      key: tKeyofSpace;
    }[] = [
      {
        key: "totalAreaM2",
        label: trans("Total Area"),
        description: trans(
          "Please provide the total indoor area of your accommodation. this includes all guest rooms, common spaces, and operational spaces."
        ),
        placeholder: trans("Hotel total area"),
      },
      {
        key: "guestRoomsAndCorridorsAreaM2",
        label: trans("Guest Rooms and Corridors Area"),
        description: trans(
          "Please provide the total area of guest rooms and corridors."
        ),
        placeholder: trans("Total area of guest rooms and corridors"),
      },
      {
        key: "meetingRoomsAreaM2",
        label: trans("Meeting Rooms Area"),
        description: trans("Please provide the total area of meeting rooms."),
        placeholder: trans("Total area of meeting rooms"),
      },
    ];

    return list.map(({ description, key, label, placeholder }) => {
      return (
        <InputWrapper {...{ label, description, error: errors[key], key }}>
          <div style={{ width: "50%" }}>
            <InputGroup>
              <InputNumber
                disabled={status === REQUEST_STATUS.PENDING}
                min={0}
                value={state[key]}
                placeholder={placeholder}
                onChange={(value) => {
                  if (value !== null) {
                    onChange(key, value);
                  }
                }}
              />
              <InputGroup.Addon>
                m<sup>2</sup>
              </InputGroup.Addon>
            </InputGroup>
          </div>
        </InputWrapper>
      );
    });
  };

  return (
    <Flex column gap={20}>
      <Flex column gap={20}>
        {renderInputs()}
      </Flex>
    </Flex>
  );
};

export default Space;
