import { first, last, max } from "lodash";
import moment, { MomentInput } from "moment";
import React, { useEffect, useMemo } from "react";
import useHotelManualDataState from "../../../context/Hotel/hooks/hotelState/useHotelManualDataState";
import SimpleDateRangePicker from "../SimpleDateRangePicker";

interface iProps {
  manualDataIndex: number;
  setManualDataIndex: React.Dispatch<React.SetStateAction<number>>;
}

const SimpleDateRangePickerForManualDataPeriods2: React.FC<iProps> = ({
  manualDataIndex,
  setManualDataIndex,
}) => {
  const {
    manualDataOrdered,
    hotel: { manualData },
  } = useHotelManualDataState();

  useEffect(() => {
    if (manualDataIndex + 1 > manualData.length) setManualDataIndex(0);
  }, [manualData.length, manualDataIndex, setManualDataIndex]);

  const selectedManualData = manualData[manualDataIndex];

  const availablePeriods = useMemo(() => {
    const periods: [MomentInput, MomentInput][] = [];

    for (const { from, to } of manualDataOrdered) {
      if (periods.length) {
        if (
          moment(last(periods)![1]).add(1, "day").format("DD/MM/YYYY") ===
          moment(from).format("DD/MM/YYYY")
        ) {
          periods[periods.length - 1][1] = to;
          continue;
        }
      }

      periods.push([from, to]);
    }

    return periods;
  }, [manualDataOrdered]);

  return (
    <SimpleDateRangePicker
      {...{
        ...(manualDataOrdered.length
          ? {
              limitEndYear: max([
                moment(last(manualDataOrdered)!.to).year() -
                  moment().year() +
                  1,
                0,
              ]),
              limitStartYear:
                moment().year() -
                moment(first(manualDataOrdered)!.from).year() +
                1,
            }
          : {}),
      }}
      showOneCalendar
      oneTap
      hoverRange={"month"}
      shouldDisableDate={(date, selectedDate, selectedDone, target) => {
        for (const [from, to] of availablePeriods) {
          if (
            moment(date).isSameOrAfter(moment(from)) &&
            moment(date).isSameOrBefore(moment(to))
          ) {
            return false;
          }
        }
        return true;
      }}
      onChange={(range) => {
        if (range) {
          for (const [i, { from, to }] of manualData.entries()) {
            if (
              moment(range[0])
                .startOf("day")
                .isSame(moment(from).startOf("day")) &&
              moment(range[1]).endOf("day").isSame(moment(to).endOf("day"))
            ) {
              setManualDataIndex(i);
            }
          }
        }
      }}
      value={[selectedManualData.from, selectedManualData.to]}
      ranges={[]}
      cleanable={false}
    />
  );
};

export default SimpleDateRangePickerForManualDataPeriods2;
