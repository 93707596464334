import { tHotelStaffPermissions } from "../../models/hotel";

export const STAFF_PERMISSIONS: tHotelStaffPermissions = {
  isAdmin: false,

  // Dashboard
  dashboardPage: false,

  // Readings
  readingsPage: false,

  // Challenges
  readChallenges: false,
  writeChallenges: false,

  // Awards
  readAwards: false,
  writeAwards: false,

  // Stay
  readStays: false,
  writeStays: false,
  giveAwardToStay: false,

  // Rooms
  roomsPage: false,

  // Room
  roomPage: false,

  // Space
  readSpaces: false,
  writeSpaces: false,

  // Space Aggregates
  readSpaceAggregates: false,
  writeSpaceAggregates: false,

  // Loyalty
  readLoyalty: false,
  writeLoyalty: false,
  loyaltyPage: false,
  createLoyaltyProgram: false,
  updateCreatedLoyaltyProgramStatus: false,
  updateCreatedLoyaltyProgramAwards: false,
  updateLoyaltyProgramStatus: false,
  updateLoyaltyProgramAwards: false,
  deleteLoyaltyProgram: false,
  deleteCreatedLoyaltyProgram: false,

  // Performance
  readPerformance: false,
  writePerformance: false,

  // Staff
  staffPage: false,

  // Guests
  guestPage: false,

  // Users
  readUsers: false,
  writeUsers: false,
  readUsersActivities: false,
  readUsersLastAccess: false,
  updateStaffMemberPassword: false,

  // Permissions
  permissionsPage: false,

  // Roles
  readRoles: false,
  writeRoles: false,
  readRolesPermissions: false,

  // Manual Data
  writeManualData: false,
  readManualData: false,

  canSetupHotel: false,

  canSelectSubscriptionPlan: false,

  ipRestrictions: false,

  // Guest-App
  readGuestAppData: false,
  writeGuestAppData: false,
};

export const STAFF_PERMISSIONS_KEYS: (keyof tHotelStaffPermissions)[] =
  Object.keys(STAFF_PERMISSIONS) as (keyof tHotelStaffPermissions)[];
