import React from "react";
import { StepItemProps, Steps, StepsProps } from "rsuite";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";

interface iProps extends StepsProps {
  onClickStep(step: number): void;
}

const StepsHeader: React.FC<iProps> = ({ current, onClickStep }) => {
  const { trans } = useLocalizationState();
  const steps: (StepItemProps & { key: string; step: number })[] = [
    {
      key: "email",
      title: trans("general.email"),
      step: 0,
    },
    {
      key: "personal",
      title: trans("general.yourself"),
      step: 1,
    },
    {
      key: "accommodation",
      title: trans("general.accommodation"),
      step: 2,
    },
  ];

  return (
    <Steps>
      {steps.map(({ key, step, title }) => {
        return (
          <Steps.Item
            {...{
              onClick: () => {
                if (current) {
                  if (step < current) onClickStep(step);
                }
              },
              style: {
                ...(current && step < current ? { cursor: "pointer" } : {}),
              },
              key,
              title,
              ...(current === step
                ? { status: "process" }
                : current
                ? step > current
                  ? { status: "wait" }
                  : { status: "finish" }
                : {}),
            }}
          />
        );
      })}
    </Steps>
  );
};

export default StepsHeader;
