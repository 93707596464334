import React from "react";
import { Toggle, ToggleProps } from "rsuite";
import useLocalizationState from "../../context/Localization/hooks/useLocalizationState";
import {
  DATA_TYPE_TOGGLE_ARCHIVES,
  DATA_TYPE_TOGGLE_LIVE,
} from "../../utils/dataTypeToggle";
import { useDataTypeToggle } from "./useDataTypeToggle";
import useWindowSizeState from "../../context/WindowSize/hooks/useWindowSizeState";

interface iProps extends ToggleProps {
  dataTypeState: ReturnType<typeof useDataTypeToggle>;
}

const DataTypeToggle: React.FC<iProps> = ({ dataTypeState, ...props }) => {
  const { isMobile } = useWindowSizeState();
  const { trans } = useLocalizationState();

  return (
    <Toggle
      size={isMobile ? "md" : "lg"}
      style={{ width: "fit-content" }}
      className={"toggle-data-type"}
      onChange={(checked) => {
        if (!dataTypeState.isLoading)
          dataTypeState.setDataType(
            checked ? DATA_TYPE_TOGGLE_LIVE : DATA_TYPE_TOGGLE_ARCHIVES
          );
      }}
      checked={dataTypeState.dataTypeLive}
      checkedChildren={trans("general.dataTypeToggle.live")}
      unCheckedChildren={trans("general.dataTypeToggle.archives")}
      {...{ ...props }}
    />
  );
};

export default DataTypeToggle;
