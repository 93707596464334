import React from "react";
import { Modal, ModalProps } from "rsuite";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";
import { termsOfService } from "./localization";
import Markdown from "markdown-to-jsx";

interface iTermsOfServiceModalWrappedProps extends Pick<iProps, "language"> {}

const TermsOfServiceModalWrapped: React.FC<
  iTermsOfServiceModalWrappedProps
> = ({ language }) => {
  return (
    <Modal.Body>
      <Markdown children={termsOfService[language]} />
    </Modal.Body>
  );
};

interface iProps extends ModalProps {
  onClose(): void;
  language: keyof typeof termsOfService;
}

const TermsOfServiceModal: React.FC<iProps> = ({
  onClose,
  language,
  ...props
}) => {
  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, size: "md", onClose, ...props }}>
      <Modal.Header></Modal.Header>
      <TermsOfServiceModalWrapped {...{ language }} />
    </Modal>
  );
};

export default TermsOfServiceModal;
