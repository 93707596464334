import React from "react";

const ReportsWrapped: React.FC = () => {
  return <div>REPORTS</div>;
};

const Reports: React.FC = () => {
  return <ReportsWrapped />;
};

export default Reports;