import { at, chunk, round, sum } from "lodash";
import React from "react";
import { Col, Grid, Row } from "rsuite";
import { ReactComponent as EcoIcon } from "../../../assets/icons/eco.svg";
import { ReactComponent as ElectricBoltIcon } from "../../../assets/icons/electric_bolt.svg";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import { ReactComponent as WaterDropIcon } from "../../../assets/icons/water_drop.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import PageSection from "../../../components/PageSection";
import SkeletonText from "../../../components/Skeleton/SkeletonText";
import InterTag from "../../../components/Text/Inter";
import useHotelMeasuresTrackedState from "../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetMeasures from "../../../hooks/apiRequests/useGetMeasures";
import { tIcon } from "../../../interfaces/icon";
import { TE, tMeasure, TW } from "../../../models/measures";
import { tReservation } from "../../../models/reservation";
import { COLORS } from "../../../utils/colors";
import {
  convertEnergyUnit,
  convertMassUnit,
  convertVolumeUnit,
} from "../../../utils/convertUnits/";
import { nUnitConverter } from "../../../utils/convertUnits/interfaces";

interface iProps extends iAverageConsumptionProps {}

const AverageConsumptionWrapped: React.FC<iProps> = ({ reservation }) => {
  const { trans } = useLocalizationState();
  const { getMeasuresTracked, hotel } = useHotelMeasuresTrackedState();

  const { room } = reservation;

  const tracked = getMeasuresTracked(room);

  const trackedMeasures: tMeasure[] = [];

  const cardsInfo: {
    measuresObjectPath:
      | "co2"
      | "grouped.te.measurements"
      | "grouped.tw.measurements";
    label: string;
    icon: tIcon;
    converter: nUnitConverter.tFunction;
  }[] = [
    {
      measuresObjectPath: "co2",
      label: "CO2",
      icon: { Element: EcoIcon, fill: COLORS.emissions },
      converter: convertMassUnit,
    },
  ];

  if (tracked.te) {
    trackedMeasures.push(TE);
    cardsInfo.push({
      measuresObjectPath: "grouped.te.measurements",
      label: "Energy",
      icon: { Element: ElectricBoltIcon, fill: COLORS.energy },
      converter: convertEnergyUnit,
    });
  }

  if (tracked.tw) {
    trackedMeasures.push(TW);
    cardsInfo.push({
      measuresObjectPath: "grouped.tw.measurements",
      label: "Water",
      icon: { Element: WaterDropIcon, fill: COLORS.water },
      converter: convertVolumeUnit,
    });
  }

  const measuresObject = useGetMeasures(hotel, {
    measures: trackedMeasures,
    binUnit: "day",
    binValue: 1,
    from: reservation.startAt,
    to: reservation.endAt,
    spaces: [room],
  });

  if (cardsInfo.length === 1) {
    return (
      <InterTag
        text={trans("No consumption being tracked. Is the space setup?")}
      />
    );
  }

  return (
    <div>
      <Grid fluid>
        {chunk(cardsInfo, 4).map((batch, i) => (
          <Row key={i} gutter={16}>
            {batch.map(({ label, icon, measuresObjectPath, converter }) => {
              const value = at(
                measuresObject.data,
                measuresObjectPath
              )[0] as number[];
              const converted = converter(sum(value));
              return (
                <Col key={label} sm={6}>
                  <Flex className="card-m" column gap={16}>
                    <Flex className="width-100" row between middle>
                      <InterTag
                        text={trans(label)}
                        size={16}
                        color={COLORS.secondary}
                      />
                      <Icon {...{ ...icon, size: 24 }} />
                    </Flex>
                    {measuresObject.isFinal ? (
                      measuresObject.isResolved ? (
                        <Flex row bottom gap={4}>
                          <InterTag
                            text={round(converted.value, 2)}
                            size={36}
                          />
                          <InterTag
                            size={20}
                            text={converted.unitInHTML}
                            asHTML
                          />
                        </Flex>
                      ) : null
                    ) : (
                      <SkeletonText width={"100%"} height={36} />
                    )}
                  </Flex>
                </Col>
              );
            })}
          </Row>
        ))}
      </Grid>
    </div>
  );
};

interface iAverageConsumptionProps {
  reservation: tReservation;
}

const AverageConsumption: React.FC<iAverageConsumptionProps> = ({
  reservation,
}) => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={16}>
      <PageSection icon={QueryStatsIcon} title={trans("general.consumption")} />
      <AverageConsumptionWrapped {...{ reservation }} />
    </Flex>
  );
};

export default AverageConsumption;
