import axios from "axios";
import { find, toNumber, uniqueId } from "lodash";
import React, { useEffect, useReducer } from "react";
import {
  Button,
  CheckTreePicker,
  CheckTreePickerProps,
  IconButton,
  InputGroup,
  InputNumber,
  Modal,
  ModalProps,
  SelectPicker,
  Toggle,
  Tooltip,
  Whisper,
} from "rsuite";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as VerifiedIcon } from "../../../assets/icons/verified.svg";
import Modal2ActionButtons from "../../../components/Buttons/modal2ActionButtons";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InputWrapper from "../../../components/InputWrapper";
import PageSection from "../../../components/PageSection";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelDispatch from "../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePostRequest from "../../../hooks/apiRequests/usePostRequest";
import { tWithKeyProperty } from "../../../interfaces/others";
import { tHotelPerformanceProgramItem } from "../../../models/hotel";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  TE,
  TW,
} from "../../../models/measures";
import { constructApiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import { triDevPrd } from "../../../utils/environment";
import { getAwardDescription } from "../../../utils/hotels/awards";
import { getErrorMessage } from "../../../utils/httpResponses/others";
import { getMeasureInfo } from "../../../utils/measures";
import { DEFAULT_MODAL_PROPS } from "../../../utils/rsuite/modals";

const buildTransKey = (key: string) =>
  `pages.loyalty.modals.performance_program.${key}`;

type tData = Omit<
  tHotelPerformanceProgramItem,
  "_id" | "timesAwarded" | "awardingHistory" | "awards"
> & {
  awards: tWithKeyProperty<tHotelPerformanceProgramItem["awards"][0]>[];
};

type tErrors = Record<keyof tData, string>;

type tState = {
  data: tData;
  errors: tErrors;
};

type tAction =
  | { type: "reset" }
  | { type: "errors"; errors: Partial<tErrors> }
  | { type: "data"; data: Partial<tData> }
  | {
      type: "awards";
      award: Pick<tData["awards"][0], "key"> &
        Partial<Omit<tData["awards"][0], "key">>;
    }
  | { type: "key value"; key: keyof tData; value: any };

const initialState: tState = {
  data: {
    awards: [],
    status: "active",
    measures: [],
    threshold: 0,
    type: "water",
  },
  errors: { awards: "", status: "", measures: "", threshold: "", type: "" },
};

const reducer = (state: tState, action: tAction): tState => {
  switch (action.type) {
    case "reset": {
      return { ...initialState };
    }
    case "errors": {
      const { errors } = action;
      return { ...state, errors: { ...state.errors, ...errors } };
    }
    case "data": {
      const { data } = action;
      return {
        ...state,
        errors: {
          ...state.errors,
          ...Object.fromEntries(Object.keys(data).map((key) => [key, ""])),
        },
        data: { ...state.data, ...data },
      };
    }
    case "awards": {
      const { award } = action;
      for (const [index, a] of Object.entries(state.data.awards)) {
        if (a.key === award.key) {
          state.data.awards[toNumber(index)] = { ...a, ...award };
          break;
        }
      }
      return {
        ...state,
        errors: { ...state.errors, awards: "" },
        data: { ...state.data, awards: [...state.data.awards] },
      };
    }
    case "key value": {
      const { key, value } = action;
      return {
        ...state,
        errors: { ...state.errors, [key]: "" },
        data: { ...state.data, [key]: value },
      };
    }
    default: {
      return { ...state };
    }
  }
};

interface iCreateEditPerformanceProgramWrappedProps
  extends Pick<iProps, "programId" | "onClose"> {}

const CreateEditPerformanceProgramWrapped: React.FC<
  iCreateEditPerformanceProgramWrappedProps
> = ({ onClose, programId }) => {
  const { trans, language } = useLocalizationState();
  const { hotel, hotelId } = useHotelState();
  const { updateHotel } = useHotelDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const request = usePostRequest();

  useEffect(() => {
    if (programId) {
      const program = find(
        hotel.performanceProgram,
        (lp) => lp._id === programId
      );

      if (!program) {
        onClose();
        return;
      }

      dispatch({
        type: "data",
        data: {
          awards: program.awards.map(({ awardId, count }) => ({
            awardId,
            count,
            key: uniqueId(),
          })),
          measures: program.measures,
          status: program.status,
          threshold: program.threshold,
          type: program.type,
        },
      });
    }
  }, [hotel.performanceProgram, onClose, programId]);

  const availableAwards = hotel.awards.filter((a) => a.active && !a.removed);

  const isLoading = request.isLoading;

  const handleConfirm = () => {
    request.pending();
    const awardsToSubmit: tHotelPerformanceProgramItem["awards"] =
      state.data.awards
        .filter((a) => a.count > 0 && a.awardId && a.awardId.length > 0)
        .map(({ awardId, count }) => ({ awardId, count }));

    const requestBody: Pick<
      tHotelPerformanceProgramItem,
      "awards" | "threshold" | "measures" | "type" | "status"
    > = {
      awards: awardsToSubmit,
      threshold: state.data.threshold,
      measures: state.data.measures,
      type: state.data.type,
      status: state.data.status,
    };
    (programId
      ? axios.patch(
          constructApiAddress(
            `/v2/hotels/${hotelId}/performance/${programId}`,
            true
          ),
          requestBody
        )
      : axios.post(
          constructApiAddress(`/v2/hotels/${hotelId}/performance`, true),
          requestBody
        )
    )
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        request.resolve();
        onClose();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error, true);
      });
  };

  const handleChange = (key: keyof tData) => (value: any) => {
    dispatch({ type: "key value", key, value });
  };

  const renderSelectMeasures = () => {
    const renderCheckTreePicker = (data: CheckTreePickerProps["data"]) => {
      return (
        <CheckTreePicker
          disabled={isLoading}
          searchable={false}
          value={state.data.measures}
          onChange={handleChange("measures")}
          defaultExpandAll
          data={data}
        />
      );
    };

    switch (state.data.type) {
      case "electricity":
        return renderCheckTreePicker([
          {
            label: trans(getMeasureInfo(TE).label),
            value: TE,
            children: MEASURES_ELECTRICITY_SPECIFIC.map((key) => {
              const { label } = getMeasureInfo(key);

              return {
                label: trans(label),
                value: key,
              };
            }),
          },
        ]);

      case "water":
        return renderCheckTreePicker([
          {
            label: trans(getMeasureInfo(TW).label),
            value: TW,
            children: MEASURES_WATER_SPECIFIC.map((key) => {
              const { label } = getMeasureInfo(key);

              return {
                label: trans(label),
                value: key,
              };
            }),
          },
        ]);

      case "footprint":
        return renderCheckTreePicker([
          {
            label: trans(getMeasureInfo(TE).label),
            value: TE,
            children: MEASURES_ELECTRICITY_SPECIFIC.map((key) => {
              const { label } = getMeasureInfo(key);

              return {
                label: trans(label),
                value: key,
              };
            }),
          },
          {
            label: trans(getMeasureInfo(TW).label),
            value: TW,
            children: MEASURES_WATER_SPECIFIC.map((key) => {
              const { label } = getMeasureInfo(key);

              return {
                label: trans(label),
                value: key,
              };
            }),
          },
        ]);

      default:
        return (
          <SelectPicker data={[]} disabled={isLoading || !state.data.type} />
        );
    }
  };
  const renderUnit = () => {
    switch (state.data.type) {
      case "water":
        return (
          <>
            m<sup>3</sup>
          </>
        );

      case "electricity":
        return <>kWh</>;

      case "footprint":
        return (
          <>
            CO<sub>2</sub> eq
          </>
        );
    }
  };

  return (
    <>
      <Flex column gap={12}>
        <Flex row gap={12}>
          <Flex basis={40} column>
            <InputWrapper required label={trans("general.type")}>
              <SelectPicker
                disabled={isLoading}
                searchable={false}
                cleanable={false}
                value={state.data.type}
                onChange={(value) => {
                  if (value)
                    dispatch({
                      type: "data",
                      data: {
                        type: value as tHotelPerformanceProgramItem["type"],
                        measures: [],
                      },
                    });
                }}
                data={[
                  { transKey: "general.measures_.water", key: "water" },
                  {
                    transKey: "general.measures_.electricity",
                    key: "electricity",
                  },
                  { transKey: "general.footprint", key: "footprint" },
                ].map(({ transKey, key }) => ({
                  value: key,
                  label: trans(transKey),
                }))}
              />
            </InputWrapper>
          </Flex>
          <Flex basis={40} column>
            <InputWrapper
              style={{ maxWidth: "300px" }}
              required
              label={trans("general.measures")}
            >
              {renderSelectMeasures()}
            </InputWrapper>
          </Flex>
        </Flex>
        <InputWrapper
          style={{ width: "50%" }}
          label={trans("general.threshold")}
        >
          <InputGroup>
            <InputNumber
              disabled={isLoading}
              min={0}
              value={state.data.threshold}
              onChange={handleChange("threshold")}
            />
            <InputGroup.Addon>{renderUnit()}</InputGroup.Addon>
          </InputGroup>
        </InputWrapper>
        <InputWrapper label={trans("general.status")}>
          <Toggle
            disabled={isLoading}
            style={{ width: "fit-content" }}
            checked={
              state.data.awards.length === 0
                ? false
                : state.data["status"] === "active"
            }
            onChange={
              state.data.awards.length === 0
                ? undefined
                : (checked) =>
                    dispatch({
                      type: "key value",
                      key: "status",
                      value: checked ? "active" : "inactive",
                    })
            }
            checkedChildren={trans("general.active")}
            unCheckedChildren={trans("general.inactive")}
            size="md"
          />
        </InputWrapper>
        <InputWrapper
          icon={VerifiedIcon}
          label={trans("general.awards")}
          error={state.errors["awards"]}
        >
          {state.data.awards.map(({ awardId, count, key }, i) => {
            return (
              <Flex row gap={16} key={key}>
                <SelectPicker
                  disabled={request.isLoading}
                  placement="right"
                  menuMaxHeight={200}
                  style={{ flexBasis: triDevPrd("50%", "85%") }}
                  value={awardId}
                  onChange={(value) => {
                    dispatch({
                      type: "awards",
                      award: { awardId: value || "", key },
                    });
                    const awards = state.data.awards;
                    awards[i] = {
                      ...state.data.awards[i],
                      awardId: value || "",
                    };
                    dispatch({
                      type: "key value",
                      key: "awards",
                      value: awards,
                    });
                  }}
                  data={availableAwards.map((a) => {
                    const description = getAwardDescription(a, language);
                    return {
                      label: (
                        <Whisper
                          trigger="hover"
                          placement="topStart"
                          speaker={
                            <Tooltip>{description?.longDescription}</Tooltip>
                          }
                        >
                          <div>{description?.shortDescription}</div>
                        </Whisper>
                      ),
                      value: a._id,
                    };
                  })}
                />
                <InputNumber
                  disabled={request.isLoading}
                  style={{ flexBasis: "10%" }}
                  value={count}
                  min={1}
                  onChange={(value) => {
                    dispatch({
                      type: "awards",
                      award: { awardId, count: toNumber(value) || 1, key },
                    });
                  }}
                />
                <IconButton
                  disabled={request.isLoading}
                  circle
                  icon={
                    <Icon
                      Element={DeleteIcon}
                      fill={request.isLoading ? COLORS.gray_200 : COLORS.error}
                      size={18}
                    />
                  }
                  onClick={() => {
                    const awards = state.data.awards.filter(
                      (a) => a.key !== key
                    );
                    dispatch({
                      type: "key value",
                      key: "awards",
                      value: awards,
                    });
                  }}
                />
              </Flex>
            );
          })}
          <Button
            disabled={request.isLoading}
            style={{ width: "fit-content" }}
            className="button-gray"
            onClick={() =>
              dispatch({
                type: "key value",
                key: "awards",
                value: [
                  ...state.data.awards,
                  { awardId: "", count: 1, key: uniqueId() },
                ],
              })
            }
            appearance="default"
          >
            {trans(buildTransKey("add_new_award"))}
          </Button>
        </InputWrapper>
        <Modal2ActionButtons
          onClose={onClose}
          onConfirm={handleConfirm}
          disabled={isLoading}
          isLoading={isLoading}
        />
      </Flex>
    </>
  );
};

interface iProps extends Omit<ModalProps, "onClose"> {
  programId?: tHotelPerformanceProgramItem["_id"];
  onClose(): void;
}

const CreateEditPerformanceProgram: React.FC<iProps> = ({
  programId,
  onClose,
  ...rest
}) => {
  const { trans } = useLocalizationState();

  return (
    <Modal {...{ ...DEFAULT_MODAL_PROPS, onClose, ...rest }}>
      <Modal.Header></Modal.Header>
      <Modal.Body className="modal-body-pb0">
        <Flex column gap={20}>
          <PageSection
            title={
              programId
                ? trans("general.update_x", {
                    parameters: [trans(buildTransKey("title"))],
                  })
                : trans("general.create_x", {
                    parameters: [trans(buildTransKey("title"))],
                  })
            }
          />
          <CreateEditPerformanceProgramWrapped {...{ onClose, programId }} />
        </Flex>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEditPerformanceProgram;
