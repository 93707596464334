import axios from "axios";
import { find, reverse } from "lodash";
import moment from "moment";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Loader, Timeline } from "rsuite";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import ReloadButton from "../../../components/Buttons/ReloadButton";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSection from "../../../components/PageSection";
import InterTag from "../../../components/Text/Inter";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useGetRequest from "../../../hooks/apiRequests/useGetRequest";
import useEffectSafe from "../../../hooks/useEffectSafe";
import useListStaff from "../../../hooks/useListStaff";
import { tHotelId } from "../../../models/hotel";
import { tHotelActionLog } from "../../../models/hotelActionLog";
import { apiAddress } from "../../../utils/apiCall";
import { COLORS } from "../../../utils/colors";
import {
  translateHotelActionsAction,
  translateHotelActionsHotelProperty,
} from "../../../utils/hotelActions";
import { getErrorMessage } from "../../../utils/httpResponses/others";

const ActivitiesHistoryWrapped: React.FC = () => {
  const navigate = useNavigate();
  const { trans } = useLocalizationState();
  const { hotelId } = useHotelState();
  const getActionsRequest = useGetRequest<{ actions: tHotelActionLog[] }>({
    actions: [],
  });
  const staffState = useListStaff();

  const runAction = useCallback(
    (hotelId: tHotelId) => {
      getActionsRequest.pending();
      axios
        .get(`${apiAddress(false)}/v2/hotels/${hotelId}/hotel-actions`)
        .then((res) => {
          const {
            data: { actions },
          } = res;
          getActionsRequest.resolve({ actions });
        })
        .catch((err) => {
          const error = getErrorMessage(err, trans);
          getActionsRequest.reject(error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trans]
  );

  useEffectSafe(() => {
    if (hotelId) runAction(hotelId);
  }, [hotelId, trans]);

  return (
    <Flex column gap={8} style={{ width: "60%" }} middle>
      {getActionsRequest.isLoading ? (
        <Loader
          size="lg"
          style={{
            marginBottom: "10px",
            marginTop: "5px",
          }}
        />
      ) : (
        <Flex column>
          <div style={{ marginLeft: "178px" }}>
            <ReloadButton onClick={() => runAction(hotelId)} />
          </div>
          {getActionsRequest.data.actions.length ? (
            <Timeline
              align="left"
              endless
              isItemActive={(index, totalITemsCount) => {
                return false;
              }}
              style={{ width: "fit-content" }}
            >
              {reverse(getActionsRequest.data.actions).map(
                ({
                  _id,
                  action,
                  managerId,
                  timestamp,
                  details: { hotelProperty, hotelPropertyId },
                }) => {
                  const staffMember = find(
                    staffState.data.staff,
                    (s) => s._id === managerId
                  );
                  return (
                    <Timeline.Item
                      key={_id}
                      time={moment(timestamp).format("MMMM DD, YYYY HH:mm")}
                      style={{ paddingBottom: "12px" }}
                    >
                      <Flex style={{ paddingTop: "3px" }} row gap={4}>
                        <InterTag
                          hoverUnderline
                          style={{ cursor: "pointer" }}
                          size={14}
                          color={COLORS.primary}
                          text={staffMember?.name}
                          onClick={() => {
                            navigate(`/users/${managerId}`);
                          }}
                        />
                        <InterTag
                          size={14}
                          color={COLORS.secondary}
                          text={translateHotelActionsAction(action, trans)}
                        />
                        <InterTag
                          hoverUnderline
                          style={{ cursor: "pointer" }}
                          size={14}
                          color={COLORS.primary}
                          text={translateHotelActionsHotelProperty(
                            hotelProperty,
                            trans
                          )}
                          onClick={() => {
                            switch (hotelProperty) {
                              case "awards":
                                return navigate(`/awards/${hotelPropertyId}`);
                              default:
                                break;
                            }
                          }}
                        />
                      </Flex>
                    </Timeline.Item>
                  );
                }
              )}
            </Timeline>
          ) : (
            <div>
              <InterTag
                text={trans(
                  "pages.activities.sections.activities.no_recent_activities"
                )}
                size={12}
                color={COLORS.secondary}
                style={{ marginLeft: "130px", marginTop: "10px" }}
              />
            </div>
          )}
        </Flex>
      )}
    </Flex>
  );
};

const ActivitiesHistory: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <div>
      <TopBar page="pages.activities.title" />
      <IpValidatorGuard>
        <Flex column gap={16}>
          <PageSection
            title={trans("pages.activities.sections.activities.title")}
            description={trans(
              "pages.activities.sections.activities.description"
            )}
            icon={{ Element: QueryStatsIcon }}
          />
          <ActivitiesHistoryWrapped />
        </Flex>
        <PageBottomPadding />
      </IpValidatorGuard>
    </div>
  );
};

export default ActivitiesHistory;
