import { useState } from "react";

const useClicker = (): [number, () => void] => {
  const [clicked, setClicked] = useState<number>(0);

  const click = () => {
    setClicked((prev) => prev + 1);
  };

  return [clicked, click];
};

export default useClicker;
