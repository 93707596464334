import React from "react";
import { Navigate, useParams } from "react-router-dom";
import Flex from "../../../components/Flex";
import MeasureConsumption from "../../../components/MeasureConsumption";
import PageBottomPadding from "../../../components/PageBottomPadding";
import SimpleDateRangePicker from "../../../components/RsuiteWrapper/SimpleDateRangePicker";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelMeasuresTrackedState from "../../../context/Hotel/hooks/hotelState/useHotelMeasuresTrackedState";
import useHotelSpacesState from "../../../context/Hotel/hooks/hotelState/useHotelSpacesState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import usePeriodState from "../../../hooks/usePeriodState";
import { tHotelSpaceAggregate, tHotelSpaceId } from "../../../models/hotel";
import {
  MEASURES_ELECTRICITY_SPECIFIC,
  MEASURES_WATER_SPECIFIC,
  TE,
  tMeasureEnergySpecific,
  tMeasureTE,
  tMeasureTW,
  tMeasureWaterSpecific,
  TW,
} from "../../../models/measures";
import {
  convertEnergyUnit,
  convertVolumeUnit,
  DEFAULT_ENERGY_MEASURE_UNIT,
  DEFAULT_WATER_MEASURE_UNIT,
} from "../../../utils/convertUnits";
import List from "./List";

export type tData = {
  grouped: Record<"co2" | "costs", number[]> &
    Record<
      tMeasureTE | tMeasureTW,
      Record<"co2" | "costs" | "values", number[]>
    >;
  bySpace: Record<
    tHotelSpaceId,
    Record<"co2" | "costs", number[]> &
      Partial<
        Record<
          tMeasureTE | tMeasureTW,
          Record<"co2" | "costs" | "values", number[]>
        >
      >
  >;
};

interface iSpaceAggregateWrappedProps {
  spaceAggregate: tHotelSpaceAggregate;
}

const SpaceAggregateWrapped: React.FC<iSpaceAggregateWrappedProps> = ({
  spaceAggregate,
}) => {
  const [period, setPeriod] = usePeriodState();
  const { getMeasuresTracked } = useHotelMeasuresTrackedState();

  const trackedMeasures = getMeasuresTracked(spaceAggregate.spaces);

  const electricitySpecificMeasures: {
    measureKey: tMeasureEnergySpecific;
  }[] = [];
  const waterSpecificMeasures: {
    measureKey: tMeasureWaterSpecific;
  }[] = [];

  if (trackedMeasures.te) {
    MEASURES_ELECTRICITY_SPECIFIC.forEach((m) => {
      if (trackedMeasures[m])
        electricitySpecificMeasures.push({ measureKey: m });
    });
  }
  if (trackedMeasures.tw) {
    MEASURES_WATER_SPECIFIC.forEach((m) => {
      if (trackedMeasures[m]) waterSpecificMeasures.push({ measureKey: m });
    });
  }

  return (
    <div>
      <SecondaryTopBar>
        <Flex>
          <SimpleDateRangePicker value={period} onChange={setPeriod} />
        </Flex>
      </SecondaryTopBar>
      <Flex column gap={40}>
        {trackedMeasures.te && (
          <MeasureConsumption
            mainMeasureKey={TE}
            specificMeasures={electricitySpecificMeasures}
            converter={convertEnergyUnit}
            binUnit={"day"}
            binValue={1}
            mainUnit={DEFAULT_ENERGY_MEASURE_UNIT}
            {...{
              spaces: spaceAggregate.spaces,
              from: period[0],
              to: period[1],
            }}
          />
        )}
        {trackedMeasures.tw && (
          <MeasureConsumption
            mainMeasureKey={TW}
            specificMeasures={waterSpecificMeasures}
            converter={convertVolumeUnit}
            binUnit={"day"}
            binValue={1}
            mainUnit={DEFAULT_WATER_MEASURE_UNIT}
            {...{
              spaces: spaceAggregate.spaces,
              from: period[0],
              to: period[1],
            }}
          />
        )}
        <List
          {...{
            spaceAggregate,
            energyIsMeasured: trackedMeasures.te,
            from: period[0],
            to: period[1],
            waterIsMeasured: trackedMeasures.tw,
          }}
        />
      </Flex>
    </div>
  );
};

const SpaceAggregate: React.FC = () => {
  const { spaceAggregateId } = useParams<{ spaceAggregateId: string }>();
  const { trans } = useLocalizationState();
  const { hotelId, findSpaceAggregate } = useHotelSpacesState();

  if (!spaceAggregateId) return <Navigate to="/spaces" />;

  if (!hotelId) return <Navigate to="/spaces" />;

  const spaceAggregate = findSpaceAggregate(spaceAggregateId);

  if (!spaceAggregate) return <Navigate to="/spaces" />;

  return (
    <div>
      <TopBar
        page={`${trans(
          `general.space_categories_.short.${spaceAggregate.category}`
        )}: ${spaceAggregate.name}`}
        parentPages={[{ transKey: "general.spaces", to: "/spaces" }]}
      />
      <SpaceAggregateWrapped {...{ spaceAggregate }} />
      <PageBottomPadding />
    </div>
  );
};

export default SpaceAggregate;
