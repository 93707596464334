import React, { useMemo } from "react";
import { ReactComponent as BuildYourHomeAmico } from "../../../assets/Build your home-amico 1.svg";
import Flex from "../../../components/Flex";
import Icon from "../../../components/Icons/Icon";
import InterTag from "../../../components/Text/Inter";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import { COLORS } from "../../../utils/colors";
import AddButton from "./addButton";
import { isArray } from "lodash";

const NoSpaces: React.FC<{
  category: "space" | "type" | "group" | "zone";
}> = ({ category }) => {
  const { trans } = useLocalizationState();

  const [
    title,
    firstDescription,
    addFirst,
    clickToAdd,
    afterCreateFirstDescription,
  ] = useMemo(() => {
    let keyword: "spaces" | "types" | "groups" | "zones";
    switch (category) {
      case "space":
        keyword = "spaces";
        break;
      case "type":
        keyword = "types";
        break;
      case "group":
        keyword = "groups";
        break;
      case "zone":
        keyword = "zones";
        break;
    }
    return [
      "title",
      "description",
      "add_first",
      "click_add",
      "after_create_first",
    ].map((t) => trans(`pages.spaces.tabs.${keyword}.no_content.${t}`));
  }, [category, trans]);

  return (
    <Flex column middle gap={40}>
      <Icon Element={BuildYourHomeAmico} size={250} />
      <Flex column middle gap={16} style={{ margin: "0 70px" }}>
        <InterTag size={36} text={trans(title)} color={COLORS.secondary} />
        {[
          trans(firstDescription),
          `<strong>${trans(
            "pages.spaces.no_content.organize.title"
          )}:</strong>`,

          `<strong>${trans(
            "general.space_categories_.extended.groups"
          )}:</strong> ${trans("pages.spaces.no_content.organize.groups")}`,
          `<strong>${trans(
            "general.space_categories_.extended.zones"
          )}:</strong> ${trans("pages.spaces.no_content.organize.zones")}.`,
          `<strong>${trans(
            "general.space_categories_.extended.types"
          )}:</strong> ${trans("pages.spaces.no_content.organize.types")}`,
          `<strong>${trans(addFirst)}:</strong> ${trans(clickToAdd)}.`,
          `<strong>${trans(
            "pages.spaces.no_content.need_help.label"
          )}</strong> ${trans(
            "pages.spaces.no_content.need_help.description"
          )}.`,
          trans(afterCreateFirstDescription),
          trans("pages.spaces.no_content.start_description"),
        ].map((text, i) =>
          isArray(text) ? (
            <Flex row gap={5}>
              {text.map((t) => (
                <InterTag
                  key={i}
                  asHTML
                  size={16}
                  color={COLORS.secondary}
                  style={{
                    textWrap: "wrap",
                    textAlign: "center",
                    lineHeight: "22px",
                  }}
                  text={t}
                />
              ))}
            </Flex>
          ) : (
            <InterTag
              key={i}
              asHTML
              size={16}
              color={COLORS.secondary}
              style={{
                textWrap: "wrap",
                textAlign: "center",
                lineHeight: "22px",
              }}
              text={text}
            />
          )
        )}
      </Flex>
      <AddButton placement="top" />
    </Flex>
  );
};

export default NoSpaces;
