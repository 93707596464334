import { every, extend, pick } from "lodash";
import React, { useCallback } from "react";
import { HotelStateContext } from "../..";
import {
  tHotelStaffPermissions,
  tHotelStaffRole,
} from "../../../../models/hotel";
import { tManagerId } from "../../../../models/manager";
import useHotelState from "./useHotelState";

const useHotelStaffState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelStaffState must be used within HotelStateContext");

  const { hotel, hotelId } = useHotelState();

  const listAccountStaffRoles = useCallback(
    (staffId: tManagerId) => {
      return hotel.staffRoles.filter((r) => r.staff.includes(staffId));
    },
    [hotel.staffRoles]
  );

  const listVisibleRoles = useCallback(
    (profileId: tManagerId) => {
      const roles = listAccountStaffRoles(profileId);
      const rolesIds = roles.map((r) => r._id);

      return hotel.staffRoles.filter(
        (sr) => !sr.isShadowRole || rolesIds.includes(sr._id)
      );
    },
    [listAccountStaffRoles, hotel.staffRoles]
  );

  const listVisibleStaff = useCallback(
    (profileId: tManagerId) => {
      const roles = listAccountStaffRoles(profileId);
      const rolesStaffIds = roles.flatMap((r) => r.staff);

      return hotel.staff.filter(
        (s) => !s.shadowAccount || rolesStaffIds.includes(s.staffId)
      );
    },
    [hotel.staff, listAccountStaffRoles]
  );

  const checkAccountsShareRoles = (
    member1: tManagerId,
    member2: tManagerId
  ) => {
    const roles1 = listAccountStaffRoles(member1);
    const roles2 = listAccountStaffRoles(member2);

    for (const r of roles1) {
      if (roles2.includes(r)) return true;
    }

    return false;
  };

  const staffMemberHasPermissions = (
    staffId: tManagerId,
    permissions: (keyof tHotelStaffRole["permissions"])[]
  ) => {
    const roles = listAccountStaffRoles(staffId);
    if (roles.length === 0) return false;

    const fullPermissions: Partial<tHotelStaffPermissions> = {};

    roles.forEach((r) => {
      extend(fullPermissions, r.permissions);
    });

    return every(
      Object.values(pick(fullPermissions, permissions)),
      (p) => p === true
    );
  };

  const isStaffMemberAdmin = (staffId: tManagerId) => {
    const roles = listAccountStaffRoles(staffId);

    for (const role of roles) {
      if (role.isAdmin) return true;
    }
    return false;
  };

  return {
    hotel,
    hotelId,

    isStaffMemberAdmin,
    listAccountStaffRoles,
    staffMemberHasPermissions,
    listVisibleRoles,
    listVisibleStaff,

    checkAccountsShareRoles,
  };
};

export default useHotelStaffState;
