import React from "react";
import { LocalizationDispatchContext, LS_DATA_LANGUAGE } from "../";

const useLocalizationDispatch = () => {
  const dispatch = React.useContext(LocalizationDispatchContext);

  if (!dispatch)
    throw Error(
      "useLocalizationDispatch must be used within LocalizationStateContext"
    );

  const setLanguage = (language: string) => {
    localStorage.setItem(LS_DATA_LANGUAGE, language);
    dispatch(language);
  };

  return { setLanguage };
};

export default useLocalizationDispatch;
