import { tHotelManualData } from "../models/hotel";

export type tManualDataFuel = keyof Pick<
  tHotelManualData,
  | "naturalGas"
  | "biomass"
  | "gasoline"
  | "diesel"
  | "propane"
  | "butane"
  | "ethanol"
>;

export const FUEL_TYPES: tManualDataFuel[] = [
  "biomass",
  "butane",
  "diesel",
  "gasoline",
  "propane",
  "naturalGas",
  "ethanol",
];

export type tManualDataWaste = keyof Required<tHotelManualData>["waste"];

export const WASTE_TYPES: tManualDataWaste[] = [
  "glass",
  "mixed",
  "organic",
  "paper",
  "plastic",
];
