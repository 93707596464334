import { find } from "lodash";
import React, { useMemo } from "react";
import { HotelStateContext } from "../..";
import { REQUEST_STATUS } from "../../../../utils/apiCall";
import { defaultHotel } from "../../../../utils/hotels";

const useHotelState = () => {
  const state = React.useContext(HotelStateContext);

  if (!state)
    throw Error("useHotelState must be used within HotelStateContext");

  const {
    data: { hotels, activeHotel },
    status,
  } = state;

  const hotelLoading = status === REQUEST_STATUS.PENDING;
  const hotelIsLoaded = status === REQUEST_STATUS.RESOLVED;
  const errorLoading = status === REQUEST_STATUS.REJECTED;

  const hotel = useMemo(() => {
    const found = find(hotels, (h) => h._id === activeHotel);
    return { ...defaultHotel, ...(found || hotels[0]) };
  }, [hotels, activeHotel]);

  const hotelId = hotel._id;

  return {
    hotelIsLoaded,
    hotelLoading,
    errorLoading,
    status,
    hotel,
    hotelId,
    activeHotel,
  };
};

export default useHotelState;
