import React, { useState } from "react";
import DataTypeToggle from "../../../components/DataTypeToggle";
import { useDataTypeToggle } from "../../../components/DataTypeToggle/useDataTypeToggle";
import GettingStarted, {
  GettingStartedFinalModal,
} from "../../../components/GettingStarted";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import useHotelState from "../../../context/Hotel/hooks/hotelState/useHotelState";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfileState from "../../../context/Profile/hooks/useProfileState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import Archives from "./archives";
import Live from "./live";

const DashboardWrapped2: React.FC = () => {
  const { activeSubscriptionIsEnterprise } = useHotelSubscriptionState();
  const dataTypeState = useDataTypeToggle();

  if (!activeSubscriptionIsEnterprise)
    return <Archives dataTypeToggle={null} />;

  const dataTypeToggle = <DataTypeToggle {...{ dataTypeState }} />;

  if (!dataTypeState.isResolved) return null;

  if (dataTypeState.dataTypeLive) return <Live {...{ dataTypeToggle }} />;

  return <Archives {...{ dataTypeToggle }} />;
};

const DashboardWrapped: React.FC = () => {
  const {
    hotel: { isSetup },
  } = useHotelState();
  const profilePermissions = useProfilePermissions();
  const [finalModal, setFinalModal] = useState(false);

  if (isSetup)
    return (
      <>
        <GettingStartedFinalModal
          open={finalModal}
          onClose={() => setFinalModal(false)}
        />
        <DashboardWrapped2 />
      </>
    );

  if (profilePermissions.canSetupHotel)
    return (
      <GettingStarted
        startModalOpen={true}
        onInsert={() => setFinalModal(true)}
      />
    );

  return null;
};

const Dashboard: React.FC = () => {
  const { trans } = useLocalizationState();
  const { profile } = useProfileState();

  const names = profile.name.split(" ");

  return (
    <>
      <TopBar
        page={{
          text: `${trans("pages.overview.title", {
            parameters: [
              names.length > 1 ? names.slice(0, -1).join(" ") : names[0],
            ],
          })} &#128075;`,
          asHTML: true,
        }}
      />
      <IpValidatorGuard>
        <DashboardWrapped />
      </IpValidatorGuard>
    </>
  );
};

export default Dashboard;
