import React, { useState } from "react";
import { ReactComponent as QueryStatsIcon } from "../../../assets/icons/query_stats.svg";
import AddManualDataButton from "../../../components/Buttons/addManualDataButton";
import Flex from "../../../components/Flex";
import IpValidatorGuard from "../../../components/Guards/IpValidatorGuard";
import ManualDataModal from "../../../components/Modals/ManualDataModal";
import PageBottomPadding from "../../../components/PageBottomPadding";
import PageSection from "../../../components/PageSection";
import RoadmapTable from "../../../components/RoadmapTable";
import TopBar from "../../../components/TopBar/PageNameTopBar";
import SecondaryTopBar from "../../../components/TopBar/SecondaryTopBar";
import useHotelSubscriptionState from "../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useLocalizationState from "../../../context/Localization/hooks/useLocalizationState";
import useProfilePermissions from "../../../hooks/useProfilePermissions";
import Graph from "./graph";
import List from "./list";
import Unlock from "./unlock";

const WasteWrapped2: React.FC = () => {
  const { trans } = useLocalizationState();
  return (
    <Flex column gap={40}>
      <Flex gap={20} column>
        <PageSection
          title={trans("pages.waste.archive.sections.overview.title")}
          icon={QueryStatsIcon}
          description={trans(
            "pages.waste.archive.sections.overview.description"
          )}
        />
        <Graph />
      </Flex>
      <List />
      <RoadmapTable filterByType="waste" />
    </Flex>
  );
};

const WasteWrapped: React.FC = () => {
  const profilePermissions = useProfilePermissions();
  const [manualDataModal, setManualDataModal] = useState({ open: false });

  return (
    <div>
      {profilePermissions.writeManualData && (
        <ManualDataModal
          open={manualDataModal.open}
          initialStep="waste"
          singleStep
          onClose={() => setManualDataModal({ open: false })}
        />
      )}
      <SecondaryTopBar>
        <div>
          {profilePermissions.writeManualData && (
            <AddManualDataButton
              onClick={() => setManualDataModal({ open: true })}
            />
          )}
        </div>
      </SecondaryTopBar>
      <WasteWrapped2 />
    </div>
  );
};

const WasteGuarded: React.FC = () => {
  const { activeSubscriptionIsStarter } = useHotelSubscriptionState();

  if (activeSubscriptionIsStarter) return <Unlock />;

  return <WasteWrapped />;
};

const Waste: React.FC = () => {
  return (
    <div>
      <TopBar
        page="general.waste"
        parentPages={[{ transKey: "general.consumption", to: "/consumption" }]}
      />

      <IpValidatorGuard>
        <WasteGuarded />
        <PageBottomPadding />
      </IpValidatorGuard>
    </div>
  );
};

export default Waste;
