import axios from "axios";
import moment from "moment";
import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, SelectPicker, useToaster } from "rsuite";
import Flex from "../../../../components/Flex";
import SimpleButton from "../../../../components/RsuiteWrapper/SimpleButton";
import InterTag from "../../../../components/Text/Inter";
import useHotelBillingState from "../../../../context/Hotel/hooks/hotelState/useHotelBillingState";
import useHotelSubscriptionState from "../../../../context/Hotel/hooks/hotelState/useHotelSubscriptionState";
import useHotelDispatch from "../../../../context/Hotel/hooks/useHotelDispatch";
import useLocalizationState from "../../../../context/Localization/hooks/useLocalizationState";
import usePutRequest from "../../../../hooks/apiRequests/usePutRequest";
import {
  tHotelBilling,
  tHotelSubscriptionModel,
} from "../../../../models/hotel";
import { apiAddress } from "../../../../utils/apiCall";
import { COLORS } from "../../../../utils/colors";
import { BILLING_TYPES } from "../../../../utils/hotels";
import { getErrorMessage } from "../../../../utils/httpResponses/others";
import { notification } from "../../../../utils/notifications";
import styles from "../styles.module.css";
import EditBillingDetailsModal from "./editBillingDetailsModal";
import PaymentMethods from "./paymentMethods";

interface iProps {}

const BillingSettings: React.FC<iProps> = () => {
  const navigate = useNavigate();
  const toaster = useToaster();
  const { trans } = useLocalizationState();
  const { hotelId, activeBillingInfo, hotel } = useHotelBillingState();
  const { activeSubscriptionType, activeSubscription } =
    useHotelSubscriptionState();
  const { updateHotel } = useHotelDispatch();
  const [modalBillingDetails, setModalBillingDetails] = useState(false);

  const request = usePutRequest();

  const updateSubscriptionModel = (type: tHotelSubscriptionModel["type"]) => {
    if (request.isLoading) return;
    request.pending();
    axios
      .put(`${apiAddress(false)}/v2/hotels/${hotelId}/subscription-model`, {
        type,
      })
      .then((res) => {
        const {
          data: { hotel },
        } = res;
        updateHotel(hotelId, hotel);
        request.resolve();
      })
      .catch((err) => {
        const error = getErrorMessage(err, trans);
        request.reject(error);
        toaster.push(notification("error", error), { placement: "topEnd" });
      });
  };

  const translateBillingType: Record<tHotelSubscriptionModel["type"], string> =
    {
      business: trans("general.subscription_types.business"),
      enterprise: trans("general.subscription_types.enterprise"),
      starter: trans("general.subscription_types.starter"),
    };

  const renderBillingInfo = () => {
    const entries: {
      label: string;
      key: keyof tHotelBilling;
    }[] = [
      { label: trans("general.name"), key: "name" },
      { label: trans("general.email"), key: "email" },
      { label: trans("general.address"), key: "address" },
      { label: trans("general.phone"), key: "phone" },
      { label: trans("general.tax_id"), key: "taxId" },
    ];
    return (
      <Fragment>
        <EditBillingDetailsModal
          open={modalBillingDetails}
          onClose={() => setModalBillingDetails(false)}
        />
        <Flex
          id="settings-billing-details"
          row
          between
          middle
          className={[
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans(
              "pages.settings.panels.billing.items.billing_details.label"
            )}
            color={COLORS.secondary}
          />

          <Button
            onClick={() => setModalBillingDetails(true)}
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
          >
            {trans("general.edit")}
          </Button>
        </Flex>
        {entries.map(({ key, label }) => {
          return (
            <Flex
              key={key}
              row
              between
              middle
              className={[
                styles["settings-item"],
                styles["settings-item-text"],
                styles["settings-item--not-last"],
              ].join(" ")}
            >
              <InterTag size={14} color={COLORS.secondary} text={label} />
              <InterTag
                size={14}
                color={COLORS.secondary}
                text={
                  activeBillingInfo && activeBillingInfo[key]
                    ? activeBillingInfo[key]
                    : "-"
                }
              />
            </Flex>
          );
        })}
      </Fragment>
    );
  };

  const renderCurrentPlans = () => {
    return (
      <Fragment>
        <Flex
          id="settings-billing-plan"
          row
          between
          middle
          className={[
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans(
              "pages.settings.panels.billing.items.current_plan.label"
            )}
            color={COLORS.secondary}
          />
          <SimpleButton
            onClick={() => navigate("/settings/billing/plan")}
            text={{ text: trans("general.manage"), color: COLORS.primary }}
            appearance="subtle"
          />
        </Flex>
        {activeSubscription && (
          <Flex
            row
            between
            middle
            className={[
              styles["settings-item"],
              styles["settings-item-text"],
              styles["settings-item--not-last"],
            ].join(" ")}
          >
            <InterTag
              text={translateBillingType[activeSubscription.type]}
              size={14}
              color={COLORS.secondary}
            />
            <Flex column gap={4} right>
              <InterTag
                text={
                  activeSubscription.endAt
                    ? trans(
                        "pages.settings.panels.billing.items.current_plan.renews_on_x_date",
                        {
                          parameters: [
                            moment(activeSubscription.endAt).format(
                              "MMMM DD, YYYY"
                            ),
                          ],
                        }
                      )
                    : "-"
                }
                size={14}
                color={COLORS.secondary}
              />
            </Flex>
          </Flex>
        )}
      </Fragment>
    );
  };

  const renderPaymentMethods = () => {
    return <PaymentMethods />;
  };

  const renderInvoices = () => {
    return (
      <Fragment>
        <Flex
          id="settings-billing-invoices"
          row
          between
          middle
          className={[
            styles["settings-item"],
            styles["settings-item--not-last"],
          ].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans("pages.settings.panels.billing.items.invoices.label")}
            color={COLORS.secondary}
          />

          <Button
            appearance="subtle"
            className="inter"
            style={{ color: COLORS.primary }}
            onClick={() => navigate("/settings/billing/invoices")}
          >
            {trans("general.see_all")}
          </Button>
        </Flex>
      </Fragment>
    );
  };
  const renderQuickUpdateSubscriptionModel = () => {
    if (hotel.name === "noytrall Hotel")
      return (
        <Flex
          row
          between
          middle
          className={[styles["settings-item"]].join(" ")}
        >
          <InterTag
            size={14}
            bold
            text={trans(
              "pages.settings.panels.billing.items.subscription_model.label"
            )}
            color={COLORS.secondary}
          />
          <SelectPicker
            style={{ width: "150px" }}
            placement="leftStart"
            searchable={false}
            loading={request.isLoading}
            data={BILLING_TYPES.map((t) => ({
              label: translateBillingType[t],
              value: t,
            }))}
            value={activeSubscriptionType}
            onChange={(t) => {
              if (
                t &&
                BILLING_TYPES.includes(t) &&
                t !== activeSubscriptionType
              ) {
                updateSubscriptionModel(t);
              }
            }}
            cleanable={false}
            disabled={request.isLoading}
          />
        </Flex>
      );
    return null;
  };

  return (
    <div>
      {renderBillingInfo()}
      {renderCurrentPlans()}
      {renderPaymentMethods()}
      {renderInvoices()}
      {renderQuickUpdateSubscriptionModel()}
    </div>
  );
};

export default BillingSettings;
